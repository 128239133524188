import { render, staticRenderFns } from "./SupportModal.vue?vue&type=template&id=3b2e32ec&scoped=true&"
import script from "./SupportModal.vue?vue&type=script&lang=ts&"
export * from "./SupportModal.vue?vue&type=script&lang=ts&"
import style0 from "./SupportModal.vue?vue&type=style&index=0&id=3b2e32ec&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b2e32ec",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ToggleChat: require('/app/components/ToggleChat/ToggleChat.vue').default})


import { StatusBlock } from '05-ui-kit';
import { computed, ref } from '@vue/composition-api';
import { useContext } from '@nuxtjs/composition-api';
import { useSocialStore } from '~/store/social';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    version
  } = require('~/package.json');
  const socialStore = useSocialStore();
  const {
    $gb
  } = useContext();
  const phone = computed(() => socialStore.social.phone);
  const appStoreUrl = computed(() => socialStore.social.appStoreUrl);
  const googlePlay = computed(() => socialStore.social.googlePlay);
  const email = computed(() => socialStore.social.email);
  const corpEmail = computed(() => socialStore.social.corpEmail);
  const showExperiment = ref($gb?.getFeatureValue('test-integration', false));
  const formatPhone = computed(() => {
    return phone.value.replace(/\s|-|[()]/g, '');
  });
  function getYear() {
    return new Date().getFullYear();
  }
  return {
    version,
    phone,
    appStoreUrl,
    googlePlay,
    email,
    corpEmail,
    showExperiment,
    formatPhone,
    getYear
  };
};
__sfc_main.components = Object.assign({
  StatusBlock
}, __sfc_main.components);
export default __sfc_main;

import { ref } from '@vue/composition-api'
import { defineStore } from 'pinia'

export const useResizeStore = defineStore('resize', () => {
  const isMobile = ref(false)
  const resize = ref<number>()

  function setIsMobile(val: boolean) {
    isMobile.value = val
  }

  function setResizeWindow(val: number) {
    resize.value = val
  }

  return {
    isMobile,
    resize,
    setIsMobile,
    setResizeWindow,
  }
})

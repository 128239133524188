var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "copy-code"
  }, [_c('div', {
    staticClass: "copy-code__wrapper",
    on: {
      "mouseleave": _vm.handleMouseLeave,
      "mouseenter": _vm.handleMouseEnter
    }
  }, [_c('div', {
    staticClass: "copy-code__top"
  }, [_vm._m(0), _vm._v(" "), _c('div', {
    staticClass: "copy-code__info"
  }, [_c('div', {
    staticClass: "copy-code__title"
  }, [_vm._v("\n          Промокод "), _c('span', {
    staticClass: "copy-code__code"
  }, [_vm._v(_vm._s(_vm.userToolStore.codeState.code))]), _vm._v(" скопирован\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "copy-code__desc"
  }, [_vm._v("\n          Примените его при оформлении заказа\n        ")])])]), _vm._v(" "), _vm.isActiveButton ? _c('div', {
    staticClass: "copy-code__buttons"
  }, [_c('Button', {
    staticClass: "copy-code__button",
    attrs: {
      "fluid": ""
    },
    on: {
      "click": _vm.handleToCart
    }
  }, [_vm._v("\n        Перейти в корзину\n      ")]), _vm._v(" "), _c('Button', {
    staticClass: "copy-code__button-mobile",
    attrs: {
      "size": "33",
      "fluid": ""
    },
    on: {
      "click": _vm.handleToCart
    }
  }, [_vm._v("\n        Перейти в корзину\n      ")])], 1) : _vm._e(), _vm._v(" "), _c('span', {
    staticClass: "copy-code__close",
    on: {
      "click": _vm.close
    }
  })])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "copy-code__img-box"
  }, [_c('img', {
    staticClass: "copy-code__img",
    attrs: {
      "src": require("@/assets/images/svg/copy-code.svg"),
      "alt": "код"
    }
  })]);
}]

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "counter"
  }, [_c('div', {
    staticClass: "counter__wrapper"
  }, [_c('button', {
    staticClass: "counter__button-number counter__button-number_minus",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.minus
    }
  }), _vm._v(" "), _vm.loading ? _c('div', {
    staticClass: "icon-loading-oval icon-loading-oval_white"
  }) : _c('div', {
    staticClass: "counter__number"
  }, [_vm._t("quantity", function () {
    return [_vm._v("\n        " + _vm._s(_vm.quantity) + "\n      ")];
  })], 2), _vm._v(" "), _c('button', {
    staticClass: "counter__button-number counter__button-number_plus",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.plus
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
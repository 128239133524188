
import Loader from '~/components/Loader.vue';
const __sfc_main = {};
__sfc_main.props = {
  active: {
    key: "active",
    required: false,
    type: Boolean,
    default: false
  },
  loading: {
    key: "loading",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  function add() {
    emit('add');
  }
  function remove() {
    emit('remove');
  }
  return {
    add,
    remove
  };
};
__sfc_main.components = Object.assign({
  Loader
}, __sfc_main.components);
export default __sfc_main;

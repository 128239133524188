import type { Events } from '~/plugins/emitter'

export default function orderRefund({ arr, id, bonus }: Events['order-reject-submitted']) {
  let value = 0
  const items = arr
    ? arr.map((el, i) => {
      value += el.price?.common.value ?? 0
      const path = el?.path?.match(/\/([^/]+)\/?$/)
      return {
        item_id: path && path[1],
        item_name: el.name,
        price: el.price?.common.value,
        item_category: el.category?.name,
        item_variant: el.offer?.length ? el.offer?.map(el => el.value).join(', ') : '',
        quantity: el.quantity,
        index: i + 1,
      }
    })
    : []

  const ecommerce = {
    transaction_id: String(id),
    bonus_sum_refund: bonus ?? 0,
    currency: 'RUB',
    value,
    items,
  }
  return {
    event: 'refund',
    ecommerce,
  }
}

import type { Context } from '@nuxt/types'
import { stringify } from 'qs'
import APIRequestStatus from '~/serializer/APIRequestStatus'

export default {
  async wholesaler(this: Context, { name, phone, email, city, category, products, files }: { name: string; phone: string; email: string; city: string; category: string; products: string; files: File[] }) {
    const { $axios } = this

    const response = await $axios.$post(
      '/api/v1/about/wholesaler',
      stringify({
        user_name: name,
        user_phone: phone,
        user_email: email,
        city,
        products_category: category,
        products,
        files,
      }),
    )

    return APIRequestStatus(response)
  },
  async provider(this: Context, { name, phone, email, category, message, files }: { name: string; phone: string; email: string; category: string; message: string; files?: File[] }) {
    const { $axios } = this

    const response = await $axios.$post(
      '/api/v1/about/provider',
      stringify({
        user_name: name,
        user_phone: phone,
        user_email: email,
        products_category: category,
        message,
        files,
      }),
    )

    return APIRequestStatus(response)
  },
  async getDeveloperRoles(this: Context) {
    const { $axios } = this

    const response = await $axios.$get('/api/v1/about/recommend/developer', {
      'axios-retry': {
        retries: 1,
      },
    })

    return APIRequestStatus(response)
  },
  async developer(this: Context, { name, phone, email, roles, candidateName, candidatePhone }: { name: string; phone: string; email: string; roles?: string; candidateName?: string; candidatePhone?: string }) {
    const { $axios } = this

    const response = await $axios.$post(
      '/api/v1/about/recommend/developer',
      stringify({
        user_name: name,
        user_phone: phone,
        user_email: email,
        candidate_profile: roles,
        candidate_name: candidateName,
        candidate_phone: candidatePhone,
      }),
    )
    return APIRequestStatus(response)
  },
}

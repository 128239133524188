import { render, staticRenderFns } from "./CompareInlineTool.vue?vue&type=template&id=142791b0&"
import script from "./CompareInlineTool.vue?vue&type=script&lang=ts&"
export * from "./CompareInlineTool.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('/app/components/Loader.vue').default})

import type { Context } from '@nuxt/types'
import { stringify } from 'qs'
import { getCancelToken } from './helper'
import { PanelProductSerializer } from '~/serializer/PanelProduct'
import { CompareCategorySerializer } from '~/serializer/compare/CompareCategory'
import type { APIRequestSuccess } from '~/type/APIRequestStatus'

export default {
  async removeProducts(this: Context): Promise<APIRequestSuccess> {
    const { $axios } = this

    const result = (await $axios.$delete('/api/v1/compare'))?.result

    return result
  },

  async removeCategoryProducts(this: Context, { sectionID, blockID }: { sectionID: number; blockID: number }): Promise<APIRequestSuccess> {
    const { $axios } = this

    const result = (await $axios.$delete(`/api/v1/compare/section/${sectionID}/${blockID}`))?.result
    return result
  },

  async getPanel(this: Context, { products }: { products: string }) {
    const { $axios } = this

    const result: any[] = (await $axios.$get(`/api/v1/compare/products?${stringify({ products })}`))?.result?.ITEMS

    return {
      result: {
        product: result ? result.map((el: any) => PanelProductSerializer(el)) : [],
      },
    }
  },

  add(this: Context, id: number) {
    const { $axios } = this

    return $axios.$put(`/api/v1/compare/${id}`, null, {
      'axios-retry': {
        retries: 2,
      },
    })
  },

  async remove(this: Context, id: number) {
    const { $axios } = this

    try {
      return await $axios.$delete(`/api/v1/compare/${id}`, {
        'axios-retry': {
          retries: 2,
        },
      })
    }
    catch (e: any) {
      /**
       * TODO - Пока не добавят роут для синхронизации сравнения
       * Это реализованно для того, чтобы удалялись товары которые бьли добавлены пользователем, когда он был неавторизован.
       */
      return e?.response?.data?.error?.description === 'Товар не существует' ? Promise.resolve('success') : Promise.reject(e)
    }
  },

  async getSection(this: Context, { products }: { products: string }) {
    const { $axios } = this
    const result: any[] = (await $axios.$get(`/api/v1/compare/sections?${stringify({ products })}`))?.result

    return {
      result: result?.map((el: any) => CompareCategorySerializer(el)) || [],
    }
  },

  async getProduct(this: Context, { id, products, cancel = false }: { id: number; products: string; cancel?: boolean }) {
    const { $axios } = this
    const cancelToken = cancel ? getCancelToken({ name: 'compare_product', cancelToken: $axios.CancelToken }) : undefined

    const result = (
      await $axios.$get(`/api/v1/compare?${stringify({ section_id: id, products })}`, {
        cancelToken,
      })
    )?.result

    return {
      result: CompareCategorySerializer(result),
    }
  },
}

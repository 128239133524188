import type { Context } from '@nuxt/types'
import { stringify } from 'qs'
import { getCancelToken } from './helper'
import { ProductSerialize } from '~/serializer/product/Product'
import { ProductBrandSerializer } from '~/serializer/ProductBrand'
import SortSerializer from '~/serializer/Sort'
import PaginationSerializer from '~/serializer/Pagination'
import SearchHintSerializer from '~/serializer/SearchHint'
import { CategorySerializer } from '~/serializer/Category'
import FilterListSerializer from '~/serializer/filter/FilterList'

export default {
  async quick(this: Context, { size, query, cancel = false, searchBrand = true }: { size: number; query: string; cancel: boolean; searchBrand: boolean }) {
    const { $api } = this

    const result = (await $api.search.extended.call(this, { size, q: query, cancel }))?.result

    let brand
    if (searchBrand) {
      try {
        brand = (await $api.search.brand.call(this, { query, cancel }))?.result
      }
      catch (e) { }
    }

    return {
      result: {
        product: result?.product?.length ? result.product : [],
        searchHints: result?.category?.length ? result.category.filter((_: unknown, index: number) => index < 5) : [],
        sort: result.sort,
        redirectTo: result.redirectTo,
        pagination: result.pagination,
        brand: brand ? ProductBrandSerializer(brand) : null,
      },
    }
  },

  async extended(this: Context,
    { page, size, section, sort, q, customQuery = {}, cancel = false }:
    { size: number; q: string; cancel: boolean; page?: number; customQuery?: { [key: string]: any }; section?: string; sort?: string },
  ) {
    const { $axios } = this
    const cancelToken = cancel ? getCancelToken({ name: 'search_product', cancelToken: this.$axios.CancelToken }) : undefined

    const response = (
      await $axios.$get<{
        result: {
          ITEMS: any[]
          PAGINATION: any
          SECTIONS_BLOCK: {
            ITEMS: any[]
          }
          FILTERS: any[]
          REDIRECT_TO: {
            SECTION_PAGE_PATH: string
          }
          SORT_TYPES: any[]
          CANONICAL_URL: any
        }
      }>(
        `/api/v2/search?${stringify({
          page,
          size,
          section,
          sort,
          q,
          ...customQuery,
        })}`,
        {
          cancelToken,
        },
      )
    )?.result

    return {
      result: {
        product: response?.ITEMS?.length ? response.ITEMS.map(item => ProductSerialize(item)) : [],
        category: response?.SECTIONS_BLOCK?.ITEMS?.length ? response.SECTIONS_BLOCK?.ITEMS?.map(el => CategorySerializer(el)) : [],
        redirectTo: response.REDIRECT_TO?.SECTION_PAGE_PATH,
        sort: response?.SORT_TYPES?.length ? response.SORT_TYPES.map(el => SortSerializer(el)) : [],
        filter: Array.isArray(response?.FILTERS) ? FilterListSerializer(response?.FILTERS) : [],
        pagination: PaginationSerializer(response.PAGINATION),
        canonicalUrl: response?.CANONICAL_URL,
      },
    }
  },

  async brand(this: Context, { query, cancel = false }: { query: string; cancel: boolean }) {
    const { $axios } = this
    const cancelToken = cancel ? getCancelToken({ name: 'searchBrand', cancelToken: this.$axios.CancelToken }) : undefined

    const result = (
      await $axios.$get(`/api/v1/search/brands/${query}`, {
        cancelToken,
      })
    )?.result

    return {
      result,
    }
  },

  async popular(this: Context, shop: number) {
    const { $api, $axios } = this
    const product = (await $api.slider.getByID.call(this, { type: 'POPULARS', id: shop }))?.result?.product
    const phrase = (await $axios.$get<{ result: string[] }>('/api/v1/search/phrases'))?.result

    return {
      result: {
        product: product.filter((_, index: number) => index < 5),
        phrase: phrase?.map(el => SearchHintSerializer(el)) || [],
      },
    }
  },
}

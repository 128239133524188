
import hoverintent from 'hoverintent';
import { computed, onMounted, onUnmounted, ref, shallowRef, useRoute, useRouter } from '@nuxtjs/composition-api';
import TSBonusCount from '~/components/ts-components/TSBonusCount/TSBonusCount.vue';
import { useAuthStore } from '~/store/modal/auth';
import { useBonusStore } from '~/store/bonus';
import { useCartStore } from '~/store/cart';
import { useUserStore } from '~/store/user';
import { useUserListStore } from '~/store/userLists';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const isActive = ref(false);
  let $hoverintent: ReturnType<typeof hoverintent> | null;
  const userStore = useUserStore();
  const {
    setLists
  } = useUserListStore();
  const userData = computed(() => userStore.user);
  const userName = computed(() => userStore.name);
  const {
    enable: showAuthModal
  } = useAuthStore();
  const {
    reset: cartReset
  } = useCartStore();
  const bonusStore = useBonusStore();
  const router = useRouter();
  const route = useRoute();
  let unsubscribe: () => void;
  onMounted(() => {
    if (userData.value) activateHoverIntent();
    unsubscribe = userStore.$onAction(mutation => {
      /**
      * Если мы только авторизовались/зарегистрировались, то вызываем библиотеку hoverintent
      */
      if (mutation.name === 'setData' || mutation.name === 'logout') window?.requestAnimationFrame(() => mutation.store.user ? activateHoverIntent() : destroyHoverIntent());
    });
  });
  onUnmounted(() => {
    if (unsubscribe) unsubscribe();
    destroyHoverIntent();
  });
  function onMouseOver() {
    isActive.value = true;
  }
  function onMouseOut() {
    isActive.value = false;
  }
  const userPanelWrap = shallowRef<HTMLDivElement>();
  function activateHoverIntent() {
    destroyHoverIntent();
    if (userPanelWrap.value) $hoverintent = hoverintent(userPanelWrap.value, onMouseOver, onMouseOut);
  }
  function destroyHoverIntent() {
    if ($hoverintent) {
      $hoverintent.remove();
      isActive.value = false;
      $hoverintent = null;
    }
  }
  async function logout() {
    await userStore.logout();
    setLists({
      type: 'guest',
      lists: {}
    });
    cartReset();
    if (route.value.path.includes('user')) router.push('/');
  }
  return {
    isActive,
    userData,
    userName,
    showAuthModal,
    bonusStore,
    userPanelWrap,
    logout
  };
};
__sfc_main.components = Object.assign({
  TSBonusCount
}, __sfc_main.components);
export default __sfc_main;


import { Button, Popover, StatusBlock } from '05-ui-kit';
import { computed } from '@vue/composition-api';
import Unavailable from '~/components/Unavailable/Unavailable.vue';
import type { CartProduct } from '~/type/product/Product';
const __sfc_main = {};
__sfc_main.props = {
  list: {
    key: "list",
    required: true,
    type: Array
  },
  count: {
    key: "count",
    required: false,
    type: Number
  },
  error: {
    key: "error",
    required: false,
    type: Boolean,
    default: false
  },
  pending: {
    key: "pending",
    required: false,
    type: Boolean,
    default: false
  },
  type: {
    key: "type",
    required: true,
    type: String
  },
  link: {
    key: "link",
    required: false,
    type: String,
    default: '#'
  },
  buttonText: {
    key: "buttonText",
    required: false,
    type: String,
    default: 'Перейти в раздел'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const emptyTitle = computed(() => {
    if (props.type === 'compare') return 'В сравнении нет товаров';else if (props.type === 'favorite') return 'В избранном нет товаров';else return 'В корзине нет товаров';
  });
  return {
    emit,
    emptyTitle
  };
};
__sfc_main.components = Object.assign({
  Popover,
  Unavailable,
  StatusBlock,
  Button
}, __sfc_main.components);
export default __sfc_main;

import type { ProductOffer } from '~/type/product/Product'

export default function (data: any, active: any): ProductOffer {
  function aggregateOfferType(type: string) {
    if (type === 'COLOR')
      return 'color'
    else
      return 'text'
  }

  function aggregateActiveOfferElement(el: any) {
    const active = data.ITEMS?.find((item: any) => (item.DESCRIPTION || item.VALUE) === el.VALUE)

    if (active) {
      return aggregateOfferElement(active)
    }
    else {
      return {
        name: el.DESCRIPTION || el.VALUE,
        value: el.VALUE,
        product: [],
      }
    }
  }

  function aggregateOfferElement(el: any) {
    const product = Object.keys(el.PRODUCTS)?.map(item => +item) || []

    return {
      name: el.DESCRIPTION || el.VALUE,
      value: el.VALUE,
      сolor: el.HEX_CODE,
      product,
    }
  }

  return {
    title: data.NAME,
    type: aggregateOfferType(data.TYPE),
    active: active ? aggregateActiveOfferElement(active) : undefined,
    list: data.ITEMS?.length ? data.ITEMS.map((el: any) => aggregateOfferElement(el)) : [],
  }
}

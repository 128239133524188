import { defineNuxtPlugin } from '@nuxtjs/composition-api'

export default defineNuxtPlugin((_, inject) => {
  inject('getCanonical', getCanonical)

  function getCanonical({ path, exclude }: { path: string; exclude?: RegExp }) {
    const result = process.env.projectURL + path.replace(/\?.*/, '')

    if (exclude) {
      const isExcluded = exclude.test(result)
      if (isExcluded)
        return null
    }

    return {
      hid: 'canonical',
      rel: 'canonical',
      href: result,
    }
  }
})

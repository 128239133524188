import type { CheckoutDeliveryDate } from '~/type/Checkout'

export function CheckoutDeliveryIntervalsSerialzier(el: { [ key: string ]: any } = {}): CheckoutDeliveryDate {
  return {
    date: el.DATE,
    timeIntervals: Array.isArray(el.TIME_INTERVALS) ? el.TIME_INTERVALS?.map(item => Time(item)) : [],
  }
}

function Time(el: any) {
  return {
    from: el.FROM,
    to: el.TO,
  }
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer__content"
  }, [_c('nav', {
    staticClass: "footer__content-left"
  }, [_c('div', {
    staticClass: "footer__content-left-col"
  }, [_vm._m(0), _vm._v(" "), _vm._l([{
    name: 'О нас',
    url: '/about/'
  }, {
    name: 'Магазины 05.ru',
    url: '/about/contacts/'
  }, {
    name: 'Вакансии',
    url: 'https://hr.05.ru/',
    isExternalLink: true
  }, {
    name: 'Оптовикам',
    url: '/about/optom/'
  }, {
    name: 'Бренды',
    url: '/brands/'
  }, {
    name: 'Каталог',
    url: '/catalog/'
  }, {
    name: 'Скидки',
    url: '/skidki/'
  }, {
    name: 'Порекомендуй разработчика',
    url: '/stuff-hunt/'
  }], function (el, index) {
    return _c('div', {
      key: index,
      staticClass: "footer__menu-link"
    }, [el.isExternalLink ? _c('a', {
      staticClass: "link link_wu",
      attrs: {
        "href": el.url
      }
    }, [_vm._v(_vm._s(el.name))]) : _c('nuxt-link', {
      staticClass: "link link_wu",
      attrs: {
        "to": el.url
      }
    }, [_vm._v("\n            " + _vm._s(el.name) + "\n          ")])], 1);
  })], 2), _vm._v(" "), _c('div', {
    staticClass: "footer__content-left-col"
  }, [_vm._m(1), _vm._v(" "), _vm._l([{
    name: 'Рассрочка',
    url: '/about/rassrochka/'
  }, {
    name: 'Кредит',
    url: '/about/kredit/'
  }, {
    name: 'Доставка и оплата',
    url: '/about/delivery/'
  }, {
    name: 'Бонусы',
    url: '/bonus/'
  }, {
    name: 'Возврат товара',
    url: '/about/guaranty/'
  }, {
    name: 'Как купить',
    url: '/about/howtobuy/'
  }, {
    name: 'Конфигуратор ПК',
    url: '/pc-builder/'
  }], function (el, index) {
    return _c('div', {
      key: index,
      staticClass: "footer__menu-link"
    }, [_c('nuxt-link', {
      staticClass: "link link_wu",
      attrs: {
        "to": el.url
      }
    }, [_vm._v("\n            " + _vm._s(el.name) + "\n          ")])], 1);
  })], 2), _vm._v(" "), _c('div', {
    staticClass: "footer__content-left-col"
  }, [_vm._m(2), _vm._v(" "), _vm._m(3), _vm._v(" "), _vm._l([{
    name: 'Поставщикам',
    url: '/about/shippers/'
  }, {
    name: 'Арендодателям',
    url: '/about/arenda/'
  }, {
    name: 'Корпоративным клиентам',
    url: '/about/corp/'
  }], function (el, index) {
    return _c('div', {
      key: index,
      staticClass: "footer__menu-link"
    }, [_c('nuxt-link', {
      staticClass: "link link_wu",
      attrs: {
        "to": el.url
      }
    }, [_vm._v("\n            " + _vm._s(el.name) + "\n          ")])], 1);
  })], 2)]), _vm._v(" "), _c('nav', {
    staticClass: "footer__content-right"
  }, [_c('div', {
    staticClass: "footer__col footer__col_large"
  }, [_vm.appStoreUrl ? _c('div', {
    staticClass: "footer__store footer__store_apple"
  }, [_c('a', {
    staticClass: "link link_wu",
    attrs: {
      "href": _vm.appStoreUrl
    }
  }, [_vm._v(" App Store ")])]) : _vm._e(), _vm._v(" "), _vm.googlePlay ? _c('div', {
    staticClass: "footer__store footer__store_google"
  }, [_c('a', {
    staticClass: "link link_wu",
    attrs: {
      "href": _vm.googlePlay
    }
  }, [_vm._v(" Google Play ")])]) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "footer__col footer__col_border"
  }, [_c('client-only', [_c('UIDataEntity', {
    attrs: {
      "get": _vm.$api.socialNetworks.get
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var response = _ref.response,
            success = _ref.success,
            loading = _ref.loading;
        return [loading ? _c('StatusBlock', {
          attrs: {
            "size": "s",
            "type": "loading"
          }
        }) : success ? _c('div', {
          staticClass: "footer__social-list"
        }, [_vm._l(response.result, function (social) {
          return [_c('div', {
            key: social.type,
            class: ("footer__social footer__social_" + (social.type))
          }, [_c('a', {
            staticClass: "footer__social-link link link_wu",
            attrs: {
              "href": social.path
            }
          }, [_vm._v(_vm._s(social.name))]), _vm._v(" "), _c('span', {
            staticClass: "grey-text"
          }, [_vm._v(_vm._s(social.subscriberAmount) + " ")])])];
        })], 2) : _vm._e()];
      }
    }])
  })], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "footer__col footer__col_large"
  }, [_vm.email ? _c('div', {
    staticClass: "footer__col-email"
  }, [_c('div', {
    staticClass: "footer__link-face"
  }, [_vm._v("\n            Физ. лицам:\n          ")]), _vm._v(" "), _c('a', {
    staticClass: "footer__link link link_wu",
    attrs: {
      "href": ("mailto:" + (_vm.email))
    }
  }, [_vm._v(_vm._s(_vm.email))])]) : _vm._e(), _vm._v(" "), _vm.corpEmail ? _c('div', {
    staticClass: "footer__col-email"
  }, [_c('div', {
    staticClass: "footer__link-face"
  }, [_vm._v("\n            Юр. лицам:\n          ")]), _vm._v(" "), _c('a', {
    staticClass: "footer__link link link_wu",
    attrs: {
      "href": ("mailto:" + (_vm.corpEmail))
    }
  }, [_vm._v(_vm._s(_vm.corpEmail))])]) : _vm._e(), _vm._v(" "), _vm.phone ? _c('div', {
    staticClass: "footer__col-phone"
  }, [_c('a', {
    staticClass: "footer__phone-link link link_wu",
    attrs: {
      "href": ("tel:" + (_vm.formatPhone))
    }
  }, [_vm._v("\n            " + _vm._s(_vm.phone) + "\n          ")])]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "footer__feedback"
  }, [_c('nuxt-link', {
    staticClass: "link link_wu",
    attrs: {
      "to": "/feedback"
    }
  }, [_vm._v("\n            Обратная связь\n          ")])], 1)])])]), _vm._v(" "), _c('div', {
    staticClass: "footer__bottom"
  }, [_c('div', {
    staticClass: "footer__bottom-left grey-text"
  }, [_vm._v("\n      " + _vm._s(_vm.getYear()) + " © 05.ru Цена на сайте носит информационный характер и не является публичной офертой.  \n      "), _c('nuxt-link', {
    staticClass: "footer__bottom-text-underline link_grey mr-5",
    attrs: {
      "to": "/about/terms-of-use/"
    }
  }, [_vm._v("\n        Пользовательское соглашение, публичная оферта"), _c('span', {
    staticClass: "footer__bottom-left-comma"
  }, [_vm._v(",")])]), _vm._v(" "), _c('nuxt-link', {
    staticClass: "footer__bottom-text-underline link_grey mr-5",
    attrs: {
      "to": "/privacy/"
    }
  }, [_vm._v("\n        Политика обработки данных.\n      ")]), _vm._v("\n      Версия " + _vm._s(_vm.showExperiment ? 'проекта' : '') + "\n      " + _vm._s(_vm.version) + "\n    ")], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer__menu-link footer__menu-link_title"
  }, [_c('span', {
    staticClass: "fz-16 b"
  }, [_vm._v("О компании")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer__menu-link footer__menu-link_title"
  }, [_c('span', {
    staticClass: "fz-16 b"
  }, [_vm._v("Помощь")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer__menu-link footer__menu-link_title"
  }, [_c('span', {
    staticClass: "fz-16 b"
  }, [_vm._v("Для бизнеса")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer__menu-link"
  }, [_c('a', {
    staticClass: "link link_wu",
    attrs: {
      "href": "https://market.05.ru/seller-promo/",
      "rel": "noopener",
      "target": "_blank"
    }
  }, [_vm._v(" Бизнес с 05.ru ")])]);
}]

export { render, staticRenderFns }

import { Tooltip } from '05-ui-kit';
/**
 * Слайд основного слайдера акций для главной страницы десктопа, без слайдера использовать смысла нет
 */
const __sfc_main = {};
__sfc_main.props = {
  active: {
    key: "active",
    required: false,
    type: Boolean,
    default: false
  },
  img: {
    key: "img",
    required: true,
    type: String
  },
  link: {
    key: "link",
    required: false,
    type: String,
    default: '#'
  },
  advertising: {
    key: "advertising",
    required: false,
    type: String
  }
};
__sfc_main.setup = (__props, __ctx) => {
  return {};
};
__sfc_main.components = Object.assign({
  Tooltip
}, __sfc_main.components);
export default __sfc_main;

import type { Events } from '~/plugins/emitter'
import { useUserStore } from '~/store/user'

export default function loanDetail(value: Events['loan-request-error']['error']) {
  const userStore = useUserStore()
  return {
    event: 'web_bnpl_error',
    data: {
      phone: value.phone || userStore.user?.phone,
      fullname: value.fullname || userStore.user?.name,
      error_code: value.code,
      error_text: value.name,
      error_message: value.native.fields,
    },
  }
}

import { defineStore } from 'pinia'
import { ref } from '@vue/composition-api'

export const useReportStore = defineStore('report', () => {
  const active = ref(false)
  const productID = ref<number>()

  function enableReport(value: number) {
    productID.value = value
    active.value = true
  }

  function disableReport() {
    productID.value = undefined
    active.value = false
  }

  return { enableReport, disableReport, active, productID }
})


import { Modal } from '05-ui-kit';
const __sfc_main = {};
__sfc_main.props = {
  isActive: {
    key: "isActive",
    required: false,
    type: Boolean,
    default: false
  },
  phone: {
    key: "phone",
    required: true,
    type: String
  },
  socials: {
    key: "socials",
    required: true,
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const data = [{
    name: 'Связь с поддержкой',
    list: [{
      name: 'Позвонить в службу поддержки',
      url: `tel:${props.phone}`,
      type: 'absoluteLink'
    }, {
      name: 'WhatsApp',
      url: props.socials.whatsApp,
      type: 'absoluteLink'
    }, {
      name: 'Telegram',
      url: props.socials.telegram,
      type: 'absoluteLink'
    }, {
      name: 'Email',
      url: `mailto:${props.socials.email}`,
      type: 'absoluteLink'
    }]
  }, {
    name: 'Заказы',
    list: [{
      name: 'Магазины и контакты',
      url: '/about/contacts/'
    }, {
      name: 'Доставка',
      url: '/about/delivery/'
    }, {
      name: 'Бонусы',
      url: '/bonus/'
    }, {
      name: 'Возврат товара',
      url: '/about/guaranty/'
    }]
  }, {
    name: 'Оплата',
    list: [{
      name: 'Рассрочка',
      url: '/about/rassrochka/'
    }]
  }, {
    name: 'Прочее',
    list: [{
      name: 'О нас',
      url: '/about/'
    }, {
      name: 'Вакансии',
      url: 'https://hr.05.ru/',
      type: 'absoluteLink'
    }, {
      name: 'Как купить',
      url: '/about/howtobuy/'
    }, {
      name: 'Порекомендуй разработчика',
      url: '/stuff-hunt/'
    }, {
      name: 'Корпоративным клиентам',
      url: '/about/corp/'
    }, {
      name: 'Поставщикам',
      url: '/about/shippers/'
    }, {
      name: 'Оптовикам',
      url: '/about/optom/'
    }, {
      name: 'Арендодателям',
      url: '/about/arenda/'
    }, {
      name: 'Бизнес с 05.ru',
      url: 'https://market.05.ru/seller-promo/',
      type: 'absoluteLink'
    }]
  }];
  function close() {
    emit('close');
  }
  return {
    data,
    close
  };
};
__sfc_main.components = Object.assign({
  Modal
}, __sfc_main.components);
export default __sfc_main;


import { computed, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import { useRoute } from '@nuxtjs/composition-api';
import { useCartStore } from '~/store/cart';
import ColorAvatar from '~/components/ColorAvatar/ColorAvatar.vue';
import { useUserStore } from '~/store/user';
import { useUserListStore } from '~/store/userLists';
const __sfc_main = {};
__sfc_main.props = {
  menuState: {
    key: "menuState",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const route = useRoute();
  const userStore = useUserStore();
  const userListStore = useUserListStore();
  const cartStore = useCartStore();
  const userData = computed(() => userStore.user);
  const compareLabel = ref(false);
  const favoriteLabel = ref(false);
  const userListStoreListener = userListStore.$onAction(mutation => {
    if (mutation.name === 'add') {
      if (mutation.store?.compare) compareLabel.value = true;
      if (mutation.store?.favorite) favoriteLabel.value = true;
    }
  });
  const userAuth = computed(() => userStore.userAuth);
  const favoriteCount = computed<number>(() => userListStore.favoriteAmount);
  const cartCount = computed(() => cartStore.cartAmount);
  const showDarkstoreTag = ref();
  watch(() => route.value.path, path => {
    if (path === '/compare/') compareLabel.value = false;
  });
  onMounted(() => {
    showDarkstoreTag.value = localStorage ? JSON.parse(localStorage.getItem('showDarkstoreTag') || 'true') : undefined;
  });
  onUnmounted(() => {
    if (userListStoreListener) userListStoreListener();
  });
  function onUserClick() {
    emit('disable');
    favoriteLabel.value = false;
    scrollTo(0, 0);
  }
  function changeDarkstoreTagVisible() {
    localStorage.setItem('showDarkstoreTag', 'false');
  }
  return {
    emit,
    userData,
    favoriteLabel,
    userAuth,
    favoriteCount,
    cartCount,
    showDarkstoreTag,
    onUserClick,
    changeDarkstoreTagVisible
  };
};
__sfc_main.components = Object.assign({
  ColorAvatar
}, __sfc_main.components);
export default __sfc_main;

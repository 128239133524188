
const __sfc_main = {};
__sfc_main.props = {
  icon: {
    key: "icon",
    required: true,
    type: String
  },
  injected: {
    key: "injected",
    required: false,
    type: Boolean,
    default: false
  }
};
export default __sfc_main;

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-page p"
  }, [_c('div', {
    staticClass: "main-page__wrap"
  }, [_vm.promoStatus() === true ? _c('div', {
    staticClass: "main-page__slider"
  }, [_c('MainPromoBanners', {
    attrs: {
      "data": {
        desktop: 'ramadan-banner.mp4',
        mobile: 'ramadan-banner-mobile.mp4',
        link: 'promo/65dc90f0895261395924dd53/?utm_source=05ru&utm_medium=referrer&utm_campaign=ramadan&utm_content=banner-from-main-page-web'
      }
    }
  })], 1) : _vm.response.slider.mobile.length || _vm.response.slider.desktop.length ? _c('ViewportToogle', {
    staticClass: "main-page__slider",
    scopedSlots: _vm._u([{
      key: "desktop",
      fn: function () {
        return [_c('MainSlider', {
          staticClass: "main-page__desktop-slider",
          attrs: {
            "list": _vm.response.slider.desktop,
            "overflow": "initial",
            "padding-bottom": "35px",
            "autoplay-interval": 4000,
            "transition-duration": "0.4s"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref) {
              var el = _ref.el,
                  active = _ref.active;
              return [_c('MainSliderEl', {
                key: el.id,
                attrs: {
                  "img": el.image,
                  "sale": el,
                  "link": ("/sales/" + (el.code) + "/"),
                  "active": active,
                  "advertising": el.advertising
                },
                nativeOn: {
                  "click": function ($event) {
                    return _vm.saleSelect();
                  }
                }
              })];
            }
          }])
        })];
      },
      proxy: true
    }, {
      key: "mobile",
      fn: function () {
        return [_c('BaseRowSlider', {
          attrs: {
            "list": _vm.response.slider.mobile,
            "offset-between-elements": 10
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var el = _ref2.el,
                  index = _ref2.index;
              return [_c('StoriesSlide', {
                attrs: {
                  "link": ("/sales/" + (el.code) + "/"),
                  "advertising": el.advertising,
                  "src": el.story,
                  "alt": el.name
                },
                on: {
                  "advertising": function ($event) {
                    var _vm$response$slider$m;

                    _vm.advertising = (_vm$response$slider$m = _vm.response.slider.mobile[index]) === null || _vm$response$slider$m === void 0 ? void 0 : _vm$response$slider$m.advertising, _vm.advertisingActive = true;
                  }
                },
                nativeOn: {
                  "click": function ($event) {
                    return _vm.saleSelect();
                  }
                }
              })];
            }
          }])
        })];
      },
      proxy: true
    }])
  }) : _vm._e(), _vm._v(" "), _vm.advertising ? _c('Modal', {
    attrs: {
      "show": _vm.advertisingActive,
      "title": "Информация"
    },
    on: {
      "close": function ($event) {
        _vm.advertisingActive = false;
      }
    }
  }, [_vm._v("\n      " + _vm._s(_vm.advertising) + "\n    ")]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "main-page__info-blocks"
  }, [_c('InfoBlockController')], 1), _vm._v(" "), Array.isArray(_vm.response.category) ? _c('CategoryGrid', {
    attrs: {
      "list": _vm.response.category
    },
    on: {
      "category-click": _vm.onCategoryClick
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "main-page__popular-category"
  }, [_c('PopularCategory')], 1), _vm._v(" "), _c('ActionCartExtended', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var add = _ref3.add,
            remove = _ref3.remove,
            cart = _ref3.cart;
        return [_vm.getPageDataByCityId ? _c('GGScrollController', {
          staticClass: "main-page__scroll-controller",
          attrs: {
            "has-next-page": _vm.getPageDataByCityId.pageInfo.hasNextPage,
            "loading": _vm.loading
          },
          on: {
            "load-more": _vm.loadMore
          }
        }, [_c('GGSectionList', {
          attrs: {
            "list": _vm.getPageDataByCityId.items,
            "meta": _vm.meta,
            "user-cart": cart
          },
          on: {
            "auth-needed": _vm.onAuthNeeded,
            "cart-add": function ($event) {
              add($event), _vm.onProductCartAddOrRemove('add', $event);
            },
            "cart-remove": function ($event) {
              remove($event), _vm.onProductCartAddOrRemove('remove', $event);
            }
          }
        })], 1) : _vm._e()];
      }
    }])
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
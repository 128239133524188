import type { Context } from '@nuxt/types'
import type { useContext } from '@nuxtjs/composition-api'
import { stringify } from 'qs'
import { AddressSerializer } from '~/serializer/Address'
import { AddressDadataSerializer } from '~/serializer/AddressDadata'
import type { Address } from '~/type/Address'

export default {
  async getAddress(this: Context, { lat, lng, count, radius }: { lat: number; lng: number; count: number; radius?: number }) {
    const { $axios } = this

    const result = (await $axios.$get(
      `https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address?${stringify({
        lat,
        lon: lng,
        count,
        radius_meters: radius,
      })}`,
      {
        headers: {
          'Authorization': 'Token 5966b14e0be09d1d37d971398510864342c42cbf',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      },
    ))?.suggestions?.[0]

    return AddressDadataSerializer(result)
  },

  async getHintsYandex(this: Context | ReturnType<typeof useContext>, { query, count, bbox, rspn = 0, ll, spn }:
    { query: string; count: number; bbox?: number; rspn?: number; ll?: string; spn?: string }): Promise<Address[]> {
    const { $axios } = this
    let request = `https://geocode-maps.yandex.ru/1.x/?apikey=${process.env.yandexMap}&format=json&geocode=${query}&count=${count}&rspn=${rspn}`

    if (bbox)
      request += `&bbox=${bbox}`
    if (ll)
      request += `&ll=${ll}`
    if (spn)
      request += `&spn=${spn}`

    const response = await $axios.$get(request)
    const result = response?.response?.GeoObjectCollection?.featureMember?.map((el: any) => AddressSerializer(el)) || []

    return result ? result?.filter((el: Address) => el.city || el.street) : []
  },

  async getAddressByCoordinate(this: Context, { lon, lat }: { lon: number; lat: number }): Promise<Address[]> {
    const { $axios } = this

    const response = ((await $axios.$get(
      `https://geocode-maps.yandex.ru/1.x/?apikey=${process.env.yandexMap}&format=json&geocode=${lon},${lat}&type=geo`,
    ))?.response?.GeoObjectCollection?.featureMember)

    const result = response.map((el: any) => AddressSerializer(el)) || []

    return result ? result?.filter((el: Address) => el.city || el.street)[0] : []
  },
}

import { stringify } from 'qs'
import type { Context } from '@nuxt/types'
import PaginationSerializer from '~/serializer/Pagination'
import QuestionSerializer from '~/serializer/Question'
import Grade from '~/serializer/Grade'

interface AnswerRequestType {
  id: number
  name: string
  email: string
  entity: string
  text: string
  showEntity: null
}

export default {
  async getByProductID(this: Context, { id, page, size, answers = 99 }: { id: number; page?: number; size: number; answers: number }) {
    const { $axios } = this

    const result = (
      await $axios.$get(`/api/v1/catalog/products/${id}/questions?${stringify({ page, size, answers_size: answers })}`)
    )?.result
    return {
      result: {
        ...result,
        pagination: PaginationSerializer(result?.PAGINATION),
        question: result?.ITEMS?.map((el: any) => QuestionSerializer(el)) || [],
      },
    }
  },
  async getByID(this: Context, { id }: { id: number }) {
    const { $axios } = this

    const response = (await $axios.$get(`/api/v1/question/entity/${id}`))?.result?.[id]
    return {
      result: QuestionSerializer(response),
    }
  },
  async addQuestion(this: Context, { id, name, email, text, showEntity }: AnswerRequestType) {
    const { $axios } = this
    const response = (
      await $axios.$post(
        '/api/v2/question',
        stringify({
          product_id: id,
          user_name: name,
          user_email: email,
          text,
          show_entity: showEntity || null,
        }),
      )
    )?.result

    return {
      result: QuestionSerializer(response),
    }
  },
  async addComment(this: Context, { id, entity, text, email, name, showEntity }: AnswerRequestType) {
    const { $axios } = this

    const response = (
      await $axios.$post(
        '/api/v2/question/answer',
        stringify({ product_id: id, entity_id: entity, user_name: name, user_email: email, text, show_entity: showEntity || null }),
      )
    )?.result

    return {
      result: QuestionSerializer(response),
    }
  },
  async like(this: Context, { id }: { id: number }) {
    const { $axios } = this

    const response = (await $axios.$put(`/api/v1/question/like/${id}`)).result
    return {
      result: Grade(response),
    }
  },
  async dislike(this: Context, { id }: { id: number }) {
    const { $axios } = this

    const response = (await $axios.$put(`/api/v1/question/dislike/${id}`))?.result
    return {
      result: Grade(response),
    }
  },
  async likeComment(this: Context, { id, questionId }: { id: number; questionId: string }) {
    const { $axios } = this

    const response = (await $axios.$put(`/api/v1/question/answer/like/${id}?${stringify({ parent_entity_id: questionId })}`)).result
    return {
      result: Grade(response),
    }
  },
  async dislikeComment(this: Context, { id, questionId }: { id: number; questionId: string }) {
    const { $axios } = this

    const response = (await $axios.$put(`/api/v1/question/answer/dislike/${id}?${stringify({ parent_entity_id: questionId })}`)).result
    return {
      result: Grade(response),
    }
  },
}

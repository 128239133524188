import { formatAddress } from '@artmizu/utils'
import type { Address } from '~/type/Address'

export function AddressSerializer(el: any): Address {
  function convertToObject(arr: { kind: string; name: string }[]) {
    const tmp = <{ [key: string]: string }>{}
    arr?.forEach((el) => {
      if (el?.kind)
        tmp[el.kind] = el?.name
    })
    return tmp
  }

  const address = el?.GeoObject?.metaDataProperty?.GeocoderMetaData?.Address || {}
  const tmp = convertToObject(address?.Components)
  const coords = el?.GeoObject?.Point?.pos?.split(' ')
  const result = {
    city: tmp?.locality,
    street: tmp?.street,
    district: tmp?.district,
    house: tmp?.house,
    region: el?.GeoObject?.description,
    lat: +coords[1],
    lon: +coords[0],
    formatted: '',
  }

  result.formatted = formatAddress(result)

  return result
}

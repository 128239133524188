import { render, staticRenderFns } from "./UserPanelMobile.vue?vue&type=template&id=92fd6aca&scoped=true&"
import script from "./UserPanelMobile.vue?vue&type=script&lang=ts&"
export * from "./UserPanelMobile.vue?vue&type=script&lang=ts&"
import style0 from "./UserPanelMobile.vue?vue&type=style&index=0&id=92fd6aca&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92fd6aca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ColorAvatar: require('/app/components/ColorAvatar/ColorAvatar.vue').default})

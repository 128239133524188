var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-auth"
  }, [_c('div', {
    staticClass: "info-auth__text"
  }, [_c('div', {
    staticClass: "info-auth__title"
  }, [_vm._v("\n      Войдите\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "info-auth__status"
  }), _vm._v(" "), _c('div', {
    staticClass: "info-auth__description"
  }, [_vm._v("\n      Чтобы отслеживать статус покупок\n    ")])]), _vm._v(" "), _c('div', {
    staticClass: "info-auth__icon-wrapper"
  }, [_c('div', {
    staticClass: "info-auth__icons"
  }, [_c('div', {
    staticClass: "icons__item icons__item_tick"
  }), _vm._v(" "), _c('div', {
    staticClass: "icons__item icons__item_time"
  }), _vm._v(" "), _c('div', {
    staticClass: "icons__item icons__item_cancel"
  })])])]);
}]

export { render, staticRenderFns }
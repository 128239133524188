// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/input-phone-mask-bg.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input-phone[data-v-6a5aa24e]{box-sizing:border-box}.input-phone__input[data-v-6a5aa24e]{width:100%;color:#000;background-color:initial;border:none;outline:none;-webkit-appearance:none;-moz-appearance:none;appearance:none}.input-phone__input[data-v-6a5aa24e]:-webkit-autofill{font-size:14px;line-height:16px;-webkit-box-shadow:0 0 0 50px #fffdf4 inset}.input-phone__input[data-v-6a5aa24e]::placeholder{color:#000;letter-spacing:5.6px}.input-phone__input[data-v-6a5aa24e]::-ms-clear,.input-phone__input[data-v-6a5aa24e]::-ms-reveal{display:none}.input-phone_none .input-phone__input[data-v-6a5aa24e]{height:39px;margin-bottom:3px;font-size:28px;line-height:32px;color:#000;letter-spacing:5px;background-color:initial;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:0 100%}.input-phone_border[data-v-6a5aa24e]{position:relative;box-sizing:border-box;display:flex;align-items:center;padding:0 14px;background-color:#fff;border:1px solid #e5e5e5;border-radius:4px}.input-phone_border .input-phone__input[data-v-6a5aa24e]{height:37px;padding:5px 0;font-size:14px;line-height:16px;letter-spacing:2px}.input-phone_border .input-phone__input[data-v-6a5aa24e]::placeholder{color:#757575;letter-spacing:.5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

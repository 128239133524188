var render = function () {
  var _vm$item$thumbnail, _vm$item$thumbnail2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-popup-item"
  }, [_c('span', {
    staticClass: "user-popup-item__clear",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.handleRemove.apply(null, arguments);
      }
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "user-popup-item__wrapper"
  }, [_c('nuxt-link', {
    staticClass: "user-popup-item__photo",
    attrs: {
      "to": _vm.item.path
    }
  }, [_c('LazyImg', {
    staticClass: "user-popup-item__img",
    attrs: {
      "img": (_vm$item$thumbnail = _vm.item.thumbnail) === null || _vm$item$thumbnail === void 0 ? void 0 : _vm$item$thumbnail.x1,
      "srcset": (_vm$item$thumbnail2 = _vm.item.thumbnail) === null || _vm$item$thumbnail2 === void 0 ? void 0 : _vm$item$thumbnail2.x2,
      "alt": _vm.item.name
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "user-popup-item__content"
  }, [_c('nuxt-link', {
    staticClass: "user-popup-item__title",
    attrs: {
      "to": _vm.item.path
    }
  }, [_vm._v("\n        " + _vm._s(_vm.item.name) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "user-popup-item__bottom"
  }, [_vm.item.price ? _c('TSPrice', {
    attrs: {
      "price": _vm.item.price
    }
  }) : _vm._e(), _vm._v(" "), _vm.enableQuantity ? _c('div', {
    staticClass: "user-popup-item__counter"
  }, [_c('TSCounter', {
    attrs: {
      "quantity": _vm.item.quantity || 1,
      "max": 999,
      "loading": _vm.loading
    },
    on: {
      "decrease": function ($event) {
        return _vm.onChange('minus', $event);
      },
      "increase": function ($event) {
        return _vm.onChange('plus', $event);
      }
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "user-popup-item__text"
  }, [_vm._v("\n            шт.\n          ")])], 1) : _vm._e()], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
import { createPersistedState } from '@artmizu/pinia-plugin-persistedstate-nuxt-2'
import { defineNuxtPlugin } from '@nuxtjs/composition-api'

export default defineNuxtPlugin (({ app }) => {
  app.$pinia.use(
    createPersistedState({
      storage: {
        getItem(key) {
          const el = app.$cookies.get(key, {
            parseJSON: false,
          })
          return el
        },
        setItem(key, value) {
          const date = new Date()
          date.setTime(date.getTime() + 1000 * 60 * 60 * 24 * 360)
          app.$cookies.set(key, value, {
            expires: date,
            maxAge: 60 * 60 * 24 * 360,
            path: '/',
          })
        },
      },
    }),
  )
})

import type { Events } from '~/plugins/emitter'

export default function installmentStep(step: Events['installment-step']['step']) {
  switch (step) {
    case 1:
      return {
        event: 'first_step_of_installment_plan',
      }
    case 2:
      return {
        event: 'second_step_of_installment_plan',
      }
    case 7:
      return {
        event: 'bid_sent_successfully',
      }
  }
}


import { Modal } from '05-ui-kit';
import { useSocialShareStore } from '~/store/modal/socialShare';
import { useUserStore } from '~/store/user';
const __sfc_main = {};
__sfc_main.props = {
  title: {
    key: "title",
    required: false,
    type: String
  },
  path: {
    key: "path",
    required: false,
    type: String
  },
  show: {
    key: "show",
    required: false,
    type: Boolean,
    default: false
  },
  active: {
    key: "active",
    required: false,
    type: Boolean,
    default: false
  },
  loading: {
    key: "loading",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const {
    enableSocialShare
  } = useSocialShareStore();
  const {
    isUser
  } = useUserStore();
  return {
    emit,
    enableSocialShare,
    isUser
  };
};
__sfc_main.components = Object.assign({
  Modal
}, __sfc_main.components);
export default __sfc_main;

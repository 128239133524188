import type UserAddress from '~/type/UserAddress'

export default function (el: any): UserAddress {
  return {
    id: +el.ID,
    city: el.DECODED_VALUE?.CITY,
    house: el.DECODED_VALUE?.HOUSE,
    street: el.DECODED_VALUE?.STREET,
    entrance: el.DECODED_VALUE?.ENTRANCE,
    apartment: el.DECODED_VALUE?.APARTMENT,
    floor: el.DECODED_VALUE?.FLOOR,
  }
}


import { computed } from '@vue/composition-api';
import { formatPrice } from '@artmizu/utils';
import type { Price } from '~/type/Price';
import type { Seller } from '~/type/Seller';
interface Props {
  price: Price;
  size?: 'small' | 'regular' | 'big';
  /* Если цена для карточки на главной или корзины */
  type?: 'promo' | 'cart';
  color?: 'grey' | 'yellow';
  seller?: Seller[];
  /**
   * Меняет положение обычной цены и цены со скидкой
   */
  reverseOrder?: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  price: {
    key: "price",
    required: true,
    type: null
  },
  size: {
    key: "size",
    required: false,
    type: String,
    default: 'regular'
  },
  type: {
    key: "type",
    required: false,
    type: String
  },
  color: {
    key: "color",
    required: false,
    type: String,
    default: 'grey'
  },
  seller: {
    key: "seller",
    required: false,
    type: Array,
    default: () => []
  },
  reverseOrder: {
    key: "reverseOrder",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const beautifyPriceCommon = computed(() => {
    return props.price.common?.value ? formatPrice(props.price.common.value) : '';
  });
  const beautifyPriceDiscount = computed(() => {
    return props.price.discount?.value ? formatPrice(props.price.discount.value) : '';
  });
  function getCodeStyle(priceCode?: string) {
    switch (priceCode) {
      case 'PROMO':
      case 'RRC':
        return 'crossed price__crossed';
    }
  }
  function getCodeStyleCommon(priceCode?: string) {
    switch (priceCode) {
      case 'PROMO':
      case 'RRC':
        return 'red-text';
    }
  }
  return {
    beautifyPriceCommon,
    beautifyPriceDiscount,
    getCodeStyle,
    getCodeStyleCommon
  };
};
export default __sfc_main;

import { ThumbnailSerializer } from '~/serializer/Thumbnail'
import type { PhotoFilter } from '~/type/PhotoFilter'

/**
 * Сериалайзер для сео-фильтров с фотками, на странице каталога над красными плашками для фильтров
 */
export default function (el: { [key: string]: any } = {}): PhotoFilter {
  return {
    id: +el.ID,
    name: el.NAME,
    image: el.IMAGE,
    thumbnail: ThumbnailSerializer(el.THUMBNAILS),
    link: el?.SECTION_PAGE_PATH || el.LINK?.VALUE || '#',
    price: el.PRINT_PRICE,
  }
}

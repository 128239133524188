import { render, staticRenderFns } from "./UserPopup.vue?vue&type=template&id=eeafe99c&scoped=true&"
import script from "./UserPopup.vue?vue&type=script&lang=ts&"
export * from "./UserPopup.vue?vue&type=script&lang=ts&"
import style0 from "./UserPopup.vue?vue&type=style&index=0&id=eeafe99c&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eeafe99c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Unavailable: require('/app/components/Unavailable/Unavailable.vue').default})

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "copy-text",
    class: [{
      'copy-text_fluid': _vm.fluid
    }],
    on: {
      "click": _vm.onClick
    }
  }, [_vm.isCopied ? _vm._t("copied") : _vm._t("notCopied")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
import Brand from '~/serializer/Brand'
import type BrandGroup from '~/type/BrandGroup'

export default function (el: { [key: string]: any }): BrandGroup {
  return {
    /* Заглавная буква в сортировке названий брендов по алфавиту на странице брендов */
    char: el.CHAR,
    list: el.LIST ? el.LIST.map((item: any) => Brand(item)) : [],
  }
}

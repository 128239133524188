import { stringify } from 'qs'
import type { Context } from '@nuxt/types'
import SliderSerializer from '~/serializer/Slider'
import OfferListSerializer from '~/serializer/OfferList'
import { ProductSerialize } from '~/serializer/product/Product'
import MetaBlockSerializer from '~/serializer/MetaBlock'
import PaginationSerializer from '~/serializer/Pagination'
import SortSerializer from '~/serializer/Sort'
import ProductExtendedSerializer from '~/serializer/product/ProductExtended'
import type { APIRequestSuccess } from '~/type/APIRequestStatus'
interface AccessoriesParams {
  size: number
  page: number
  sort: string
  categoryIds?: number
  productId: number
  getByField: string
}

export default {
  async getByID(this: Context, { id, removeCode = false, reviewSize = 2, questionSize = 2 }: { id: number; removeCode: boolean; reviewSize: number; questionSize: number }) {
    // параметр code добавили, чтобы не возникало коллизий, если есть товары с одинаковыми id и code
    const { $axios } = this
    const response = await $axios.$get(
      `/api/v1/catalog/products/${id}?${stringify({
        reviews_size: reviewSize,
        questions_size: questionSize,
        not_load: 'BASKET_AMOUNT,IN_BASKET,IN_FAVORITES,IN_COMPARE', // у нас есть пользовательские списки, инфа с бэка не нужна
      })}${removeCode ? '' : '&code=1'}`,
    )

    // Хлебные крошки
    function getBreadcrumb(current: any) {
      const list = [
        { name: '05.ru', url: '/' },
        { name: 'Каталог', url: '/catalog' },
      ]

      let parent = current.SECTION_NAV_CHAIN?.[0]

      while (parent) {
        list.push({ name: parent?.NAME, url: parent?.SECTION_PAGE_PATH })
        parent = parent?.CHILDREN?.[0]
      }

      list.push({ name: current?.NAME, url: current?.DETAIL_PAGE_PATH })
      return list
    }

    return {
      result: {
        product: ProductExtendedSerializer(response.result?.PRODUCT),
        category: { id: response?.result?.PRODUCT?.IBLOCK_SECTION_ID, name: response?.result?.PRODUCT?.SECTION },
        breadcrumb: getBreadcrumb(response?.result?.PRODUCT),
        offer: OfferListSerializer(response.result?.PRODUCT?.OFFERS, response.result?.PRODUCT?.DEFAULT_OFFERS),
        similar: SliderSerializer(response.result?.PRODUCT?.ADDITIONAL_PRODUCTS.length && response.result.PRODUCT.ADDITIONAL_PRODUCTS[0]),
        viewed: SliderSerializer(response.result?.PRODUCT?.ADDITIONAL_PRODUCTS?.length > 1 && response.result?.PRODUCT?.ADDITIONAL_PRODUCTS?.[1]),
        meta: MetaBlockSerializer(response.result?.PRODUCT?.META),
      },
    }
  },
  buyOneClick(this: Context, { code, number }: { code: string; number: string }): Promise<APIRequestSuccess<{ ORDER_ID: number }>> {
    const { $axios } = this
    return $axios.$post(
      '/api/v2/order/oneclick',
      stringify({
        product_id: code,
        phone_number: number,
        action: 'add',
      }),
    )
  },
  foundCheaper(this: Context, { number, source, code }: { code: string; number: string; source: string }): Promise<APIRequestSuccess<{}>> {
    const { $axios } = this

    return $axios.$post(
      '/api/v1/feedbacks/found_cheaper',
      stringify({
        UF_PHONE_NUMBER: number,
        UF_SOURCE: source,
        UF_ID_PROD_OR_SALE: code,
        action: 'add',
      }),
    )
  },
  async getAccessories(this: Context, { getByField, productId, page, categoryIds, size = 20, sort }: AccessoriesParams) {
    const { $axios } = this
    const response = (await $axios.$get(
      `/api/v1/product-page/${productId}/accessories?${stringify({
        get_by_field: getByField,
        page,
        size,
        sort,
        section_id: categoryIds ?? null,
      })}`,
    ))?.result

    return {
      result: {
        product: response.ITEMS.length
          ? response.ITEMS.map((el: any) => ProductSerialize({
            ...el,
            // получаем часть данных продукта в удобном виде
            IMAGE: el.IMAGE?.URL ?? undefined,
            THUMBNAILS: el.IMAGE?.THUMBNAILS ?? undefined,
            DETAIL_PAGE_PATH: el.URL,
            PRICES: [{ NAME: 'Цена продукта', PRICE: el?.PRICE?.VALUE, CODE: 'DEFAULT' }],
            ACTIONS: el?.ACTIONS?.length
              ? el.ACTIONS.map((el: any) => ({
                ...el,
                ID: +el?.ID,
                STICKER_TEXT: el?.NAME,
                COLOR: el?.COLOR,
              }))
              : [],
          }))
          : [],
        pagination: PaginationSerializer(response.PAGINATION) || undefined,
        sort: response.SORT_TYPES.length ? response.SORT_TYPES.map((el: any) => SortSerializer(el)) : [],
        filter: response.SECTIONS_BLOCK.length
          ? {
            // получаем фильтры в удобном виде
              list: [...response.SECTIONS_BLOCK.map((el: any) => ({ name: el.NAME, value: el.ID }))],
              value: {},
            }
          : undefined,
      },
    }
  },
}


import { computed } from '@vue/composition-api';
import { formatPrice } from '@artmizu/utils';
const __sfc_main = {};
__sfc_main.props = {
  id: {
    key: "id",
    required: true,
    type: String
  },
  price: {
    key: "price",
    required: true,
    type: Number
  },
  status: {
    key: "status",
    required: true,
    type: String
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const getOrderStatusImg = computed(() => {
    switch (props.status) {
      case 'vueprocessing':
        return 'clock';
      case 'vuecompleted':
        return 'clock';
      case 'vuedelivery':
        return 'delivery';
      default:
        return 'vuepickup';
    }
  });
  const getOrderStatus = computed(() => {
    switch (props.status) {
      case 'vueprocessing':
        return 'Заказ обрабатывается';
      case 'vuecompleted':
        return 'Заказ комплектуется';
      case 'vuedelivery':
        return 'Заказ на доставке';
      default:
        return 'Заказ готов к самовывозу';
    }
  });
  return {
    formatPrice,
    getOrderStatusImg,
    getOrderStatus
  };
};
export default __sfc_main;

import { formatAddress } from '@artmizu/utils'
import type { CourierAddress } from '~/type/DeliveryAddress'

export function CourierAddressSerializer(el: { [key: string]: any } = {}): CourierAddress {
  const tmp: CourierAddress = {
    type: 'courier',
    city: el.CITY,
    street: el.STREET,
    house: el.HOUSE,
    entrance: el.ENTRANCE,
    apartment: el.APARTMENT,
    floor: el.FLOOR,
  }

  tmp.formatted = formatAddress(tmp)

  return tmp
}

var render = function () {
  var _ref;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.color ? _c('div', {
    staticClass: "discount-marker",
    class: [(_ref = {}, _ref[("discount-marker_" + (_vm.color))] = _vm.color, _ref)]
  }, [_vm._v("\n  " + _vm._s(_vm.percent) + "%\n")]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
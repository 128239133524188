import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import { type FeatureApiResponse, GrowthBook } from '@growthbook/growthbook'
import { nanoid } from 'nanoid'
import { useUserStore } from '~/store/user'

// Время (час), через которое будет делаться повторный запрос, после последнего неудачного запроса
const checkInterval = 360000
let lastCheckTime: number | null
let growthbookPromise: Promise<FeatureApiResponse> | null = null

export default defineNuxtPlugin(async (context, inject) => {
  if (context.$config.growthbookUrl) {
    const timeToCheck = lastCheckTime ? Date.now() - lastCheckTime >= checkInterval : true
    let data: FeatureApiResponse | null = null

    /**
     * Варианты тестов грузим на сервере и передаем на клиент через ssr контекст,
     * чтобы не грузить варианты два раза и на сервере и на клиенте
     */
    if (process.server) {
      const nodeCache = (context.ssrContext as SSRContext)?.nodeCache

      /**
       * Кешируем варианты тестов, чтобы не грузить их каждый раз при каждом запросе
       */
      if (nodeCache) {
        const remain = nodeCache.getTtl('growthbookFeatures') || 0

        if (nodeCache.has('growthbookFeatures')) {
          if (remain < (60 * 2) && timeToCheck) {
            /**
             * Загрузка вариантов реализована так, что она не блочит загрузку
             * страниц проекта, потому что нет ожидания этого запроса. В итоге когда запрос завершится
             * он просто обновит значение вариантов в кеше, чтобы уже последующие клиенты получили
             * более свежие варианты тестов
             */
            growthbookPromise = growthbookPromise || context.$axios.$get(context.$config.growthbookUrl)
            growthbookPromise.then((response) => {
              nodeCache.set('growthbookFeatures', response, 60 * 10)
              lastCheckTime = null
            }).catch((e) => {
              lastCheckTime = Date.now()
              new context.$baseError({
                message: 'Не удалось получить варианты АБ тестов',
                native: e,
              })
            })
          }

          data = nodeCache.get('growthbookFeatures')!
        }
        else {
          try {
            if (timeToCheck) {
              growthbookPromise = growthbookPromise || context.$axios.$get(context.$config.growthbookUrl)
              data = await growthbookPromise
              nodeCache.set('growthbookFeatures', data, 60 * 10)
              lastCheckTime = null
            }
          }
          catch (e) {
            lastCheckTime = Date.now()
            new context.$baseError({
              message: 'Не удалось получить варианты АБ тестов',
              native: e,
            })
          }
        }
      }
      else {
        try {
          if (timeToCheck) {
            growthbookPromise = growthbookPromise || context.$axios.$get(context.$config.growthbookUrl)
            const response = await growthbookPromise
            data = response
            lastCheckTime = null
          }
        }
        catch (e) {
          lastCheckTime = Date.now()
          new context.$baseError({
            message: 'Не удалось получить варианты АБ тестов',
            native: e,
          })
        }
      }

      context.beforeNuxtRender(({ nuxtState }) => {
        nuxtState.growthbookData = data
      })
    }
    else {
      data = context.nuxtState?.growthbookData
    }

    let uuid = context.app.$cookies.get('05ruFrontEndUserUuid')
    if (!uuid) {
      uuid = nanoid()
      context.app.$cookies.set('05ruFrontEndUserUuid', uuid, {
        maxAge: 3650 * 60 * 24 * 30,
        path: '/',
      })
    }

    const user = useUserStore(context)
    const gb = new GrowthBook({
      enableDevMode: context.$config.deployEnvironment !== 'production' || false,
      subscribeToChanges: false,
      attributes: {
        id: uuid,
        email: user.user?.email,
        isAuth: user.isUser,
        gender: user.user?.gender,
        birthday: user.user?.birthday,
        cartProductCount: user.user?.cart || 0,
        favoriteProductCount: user.user?.favorite || 0,
        compareProductCount: user.user?.compare || 0,
      },
      trackingCallback: (experiment, result) => {
        if (process.client) {
          context.$emitter.emit('ab-test-viewed', {
            experimentId: experiment.key,
            variationId: result.key,
          })
        }
      },
    })

    if (data?.features)
      gb.setFeatures(data.features)

    inject('gb', gb)
    // Очищаем промис после использования данных для настройки Growthbook
    growthbookPromise = null
  }
  else {
    console.log('Growthbook: нет переменных окружения для инициализации')
  }
})

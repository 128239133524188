import type { Events } from '~/plugins/emitter'

export default function addToCompare({ product }: Events['add-to-compare']) {
  return {
    event: 'add_to_compare',
    data: {
      currency: 'RUB',
      value: product.price?.common?.value,
      items: [
        {
          item_name: product.name,
          item_id: product.path?.match(/\/([^/]+)\/?$/)?.[1] ?? product.id.toString(),
          price: product.price?.common?.value,
          item_category: product.category?.name,
          item_variant: product.offer?.length ? product.offer?.map(el => el.value || el.active?.name).join(', ') : '',
          quantity: 1,
        },
      ],
    },
  }
}


import { computed, onMounted, onUnmounted, shallowRef } from '@vue/composition-api';
import { useAuthStore } from '~/store/modal/auth';
import { useUserStore } from '~/store/user';
import bonusPopper from '~/helper/bonusPopper';
interface Props {
  count?: number;
  plus?: boolean;
  availablePay?: boolean;
  size?: 'x-small' | 'small' | 'regular';
  descriptionUnavailable?: boolean;
  description?: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  count: {
    key: "count",
    required: false,
    type: Number,
    default: undefined
  },
  plus: {
    key: "plus",
    required: false,
    type: Boolean,
    default: false
  },
  availablePay: {
    key: "availablePay",
    required: false,
    type: Boolean,
    default: true
  },
  size: {
    key: "size",
    required: false,
    type: String,
    default: 'small'
  },
  descriptionUnavailable: {
    key: "descriptionUnavailable",
    required: false,
    type: Boolean
  },
  description: {
    key: "description",
    required: false,
    type: Boolean,
    default: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    enable: enableAuthModal
  } = useAuthStore();
  const userStore = useUserStore();
  const isGuest = computed(() => userStore.isGuest);
  const handler = shallowRef<HTMLElement>();
  const unavailableHandler = shallowRef<HTMLElement>();
  const content = shallowRef<HTMLElement>();
  const unavailableContent = shallowRef<HTMLElement>();
  const bonusData = bonusPopper({
    handler,
    content
  });
  const unavailableBonusData = bonusPopper({
    handler: unavailableHandler,
    content: unavailableContent
  });
  onMounted(() => {
    if (props.count) bonusData.create();
    if (props.availablePay === false) unavailableBonusData.create();
  });
  onUnmounted(() => {
    bonusData.destroy();
    unavailableBonusData.destroy();
    bonusData.removeInstance();
    unavailableBonusData.removeInstance();
  });
  function showAuthModal() {
    enableAuthModal({
      subtitle: 'Войдите, чтобы получить бонусную карту, карта будет привязана к номеру телефона'
    });
  }
  return {
    isGuest,
    handler,
    unavailableHandler,
    content,
    unavailableContent,
    bonusData,
    unavailableBonusData,
    showAuthModal
  };
};
export default __sfc_main;

export function FeedbackTopicSerializer(responseArr: any): { id: number; name: string }[] {
  const list = responseArr || []

  return list.map((el: any) => {
    return {
      value: +el.ID,
      name: el.NAME,
    }
  })
}

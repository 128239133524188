
import { computed, inject, ref, watch } from '@vue/composition-api';
import { useRouter } from '@nuxtjs/composition-api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Button, Form, FormElement, InputText, Modal, Note, Popover } from '05-ui-kit';
import { declination } from '@artmizu/utils';
import { useCartStore } from '~/store/cart';
import { useUserToolStore } from '~/store/userTool';
import type { EmitteryType } from '~/plugins/emitter';
import type { CartModal } from '~/store/userTool';
const __sfc_main = {};
__sfc_main.props = {
  loading: {
    key: "loading",
    required: false,
    type: Boolean,
    default: false
  },
  success: {
    key: "success",
    required: false,
    type: Boolean,
    default: false
  },
  error: {
    key: "error",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const router = useRouter();
  const emitter = inject<EmitteryType>('emitter')!;
  const cartStore = useCartStore();
  const userToolStore = useUserToolStore();
  const show = ref(false);
  const submited = ref(false);
  const buyOneClick = ref<{
    code?: number;
    number?: string;
  }>({});
  const cartAmount = computed(() => cartStore.cartAmount);
  const cartInfo = computed(() => userToolStore.cartInfo);
  const productImage = computed(() => cartInfo.value?.image);
  const productName = computed(() => cartInfo.value?.name);
  const productLink = computed(() => cartInfo.value?.link);
  const productsCount = computed(() => `${cartAmount.value} ${declination(cartAmount.value, ['товар', 'товара', 'товаров'])}`);
  const productId = computed(() => cartInfo.value?.id);
  watch(() => productId.value, val => {
    buyOneClick.value.code = val;
    submited.value = false;
  });
  watch(() => props.success, val => {
    if (val) emit('success', {
      value: cartInfo.value,
      number: buyOneClick.value.number
    });
    submited.value = val;
  });
  buyOneClick.value.code = productId.value;
  function handleToCart() {
    router.push('/cart/', close);
  }
  function handleMouseEnter() {
    userToolStore.clearTimer();
  }
  function handleMouseLeave() {
    userToolStore.setTimer();
  }
  function close() {
    userToolStore.onChangeCart(false);
  }
  function open() {
    show.value = true;
  }
  function onSubmit() {
    /**
    * Срабатывает при отправки запроса
     */
    emit('send', buyOneClick.value);
    emitter.emit('fast-ordering', {
      phone: String(buyOneClick.value.number),
      product: {
        id: productId.value,
        name: productName.value,
        price: cartInfo.value?.price,
        category: cartInfo.value?.category
      }
    });
  }
  return {
    show,
    submited,
    buyOneClick,
    productImage,
    productName,
    productLink,
    productsCount,
    handleToCart,
    handleMouseEnter,
    handleMouseLeave,
    close,
    open,
    onSubmit
  };
};
__sfc_main.components = Object.assign({
  Button,
  Popover,
  ValidationObserver,
  Form,
  ValidationProvider,
  FormElement,
  InputText,
  Note,
  Modal
}, __sfc_main.components);
export default __sfc_main;

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.disable && _vm.img ? _c('img', {
    attrs: {
      "src": _vm.img,
      "alt": _vm.alt,
      "srcset": _vm.srcset && ((_vm.srcset) + " 2x")
    }
  }) : _vm.img ? _c('img', {
    directives: [{
      name: "lazy",
      rawName: "v-lazy",
      value: _vm.img,
      expression: "img"
    }],
    attrs: {
      "alt": _vm.alt,
      "src": _vm.img,
      "srcset": require('@/assets/images/svg/blank.svg'),
      "data-srcset": _vm.srcSetPath
    }
  }) : _c('img', {
    attrs: {
      "src": require("assets/images/empty.svg"),
      "alt": _vm.alt
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
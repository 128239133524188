
import { Button, Form, FormElement, InputText, Modal } from '05-ui-kit';
import { ref, watch } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import type { StatusType } from '~/type/StatusType';
const __sfc_main = {};
__sfc_main.props = {
  show: {
    key: "show",
    required: false,
    type: Boolean,
    default: false
  },
  email: {
    key: "email",
    required: true,
    type: String
  },
  status: {
    key: "status",
    required: true,
    type: null
  },
  errorMessage: {
    key: "errorMessage",
    required: false,
    type: String
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const statusRef = ref(props.status);
  const emailRef = ref(props.email);
  watch(() => props.status, value => {
    statusRef.value = value;
  });
  watch(() => props.email, value => {
    emailRef.value = value;
  });
  function onSubmit() {
    emit('submit', emailRef.value);
  }
  function close() {
    emit('close');
  }
  return {
    statusRef,
    emailRef,
    onSubmit,
    close
  };
};
__sfc_main.components = Object.assign({
  Modal,
  ValidationObserver,
  Form,
  ValidationProvider,
  FormElement,
  InputText,
  Button
}, __sfc_main.components);
export default __sfc_main;

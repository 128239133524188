import { ThumbnailSerializer } from './Thumbnail'
import type Category from '~/type/Category'

export function CategorySerializer(el: { [key: string]: any } = {}): Category {
  return {
    id: el?.ID,
    /**
     * На странице товара, el приходит с апи в виде строки с названием категории. На остальных страницах в виде объекта
     * Поэтому делаем проверку, если это объект, то берем у него свойство NAME, иначе просто el
     */
    name: el?.NAME || el,
    thumbnail: ThumbnailSerializer(el?.THUMBNAILS),
    path: el?.SECTION_PAGE_PATH,
    children: el?.CHILDREN?.length ? el.CHILDREN.map((item: any) => CategorySerializer(item)) : [],
    image: el?.MAIN_PAGE_IMAGE || el?.IMAGE,
    color: el?.MAIN_PAGE_IMAGE_BG_COLOR,
    count: el?.PRODUCTS_COUNT,
    description: el?.PARENT_NAME || el?.NAME,
  }
}

import { parse, toDate } from 'date-fns'
import { ru } from 'date-fns/locale'
import { utcToZonedTime } from 'date-fns-tz'
import type { Context } from '@nuxt/types'
import { useContext } from '@nuxtjs/composition-api'
import { useSharedStore } from '~/store/shared'

/**
 * Используя текущую дату, дату начала акции и дату окончания акции, определяем отображение её страниц и блоков в проекте
 */

export function getStatusPromo({ startDate, endDate }: { startDate: string; endDate: string }, ctx?: Context): boolean {
  const { $cookies, $pinia, $config } = ctx || useContext()

  const { currentDate } = useSharedStore($pinia)

  const start = toDate(+utcToZonedTime(parse(startDate, 'yyyy.MM.d', new Date(), { locale: ru }), 'Europe/Moscow'))
  const end = toDate(+utcToZonedTime(parse(endDate, 'yyyy.MM.d', new Date(), { locale: ru }), 'Europe/Moscow'))

  const currentDateFormat = toDate(currentDate || +utcToZonedTime(Date.now(), 'Europe/Moscow'))

  /**
  * Статус акции будет true если соблюдено одно из условий:
  * 1. Текущая дата находится в интервале проведения акции
  * 2. Включен devMode
  * 3. Не продакшен
  */
  return (currentDateFormat >= start && currentDateFormat < end) || !!$cookies.get('devMode') || $config.deployEnvironment !== 'production'
}

import { FilterGroupSerializer, FilterTypeSerializer } from './Filter'
import type { FilterList, FilterType } from '~/type/Filter'

interface Group {
  name?: string
  list: any[]
}

function getActiveFilter({ arr, name, type }: { arr: any[]; name: string; type: FilterType }) {
  const value: any = {}
  const group: Group = {
    name,
    list: [],
  }

  if (type === 'checkbox') {
    arr.forEach((el: any) => {
      if (el.SELECTED) {
        value[el.NAME] = el.HTML_VALUE
        group.list.push(el.VALUE)
      }
    })
  }

  if (type === 'range') {
    arr.forEach((el: any, index) => {
      if (el.VALUE) {
        value[el.NAME] = el.VALUE
        group.list.push(index === 0 ? `от ${el.VALUE}` : `до ${el.VALUE}`)
      }
    })
  }

  return { value, group }
}

export default function (el: any[]): FilterList {
  // порой в el приходит null с бэка, который как бы говорит что фильтров нет
  const list = el || []
  let filterValue = {}
  const groupValue: Group[] = []

  list.forEach((el: any) => {
    const type = FilterTypeSerializer(el.DISPLAY_TYPE)
    const { value, group } = getActiveFilter({ arr: el.VALUES, name: el.NAME, type })

    if (Object.keys(value)?.length) {
      el.active = true
      filterValue = { ...filterValue, ...value }
      groupValue.push(group)
    }
    else {
      el.active = false
    }
  })
  /**
   * Проверяем есть ли вообще фильтры и есть ли основные
   */
  const isBasic = list.some((item: any) => item.BASIC_FILTER)
  const isFilterItems = !isBasic && list.length

  return {
    /**
    * Если, нет  ни одного основного фильтра, то делаем их основными, но при этом раскрываем первые 4 (цена, производитель, наличие товара, участвует в акции)
    * Если есть хотя бы один основной фильтр, то ничего не меняем и возвращаем все как есть, раскрываем первые 4 и как передает бэк (isMain),
    * чтобы не было пустых фильтров
    */
    list: isFilterItems
      ? list.map((item: any, index: number) => { return FilterGroupSerializer({ ...item, BASIC_FILTER: true, isOpenFilter: isBasic ? item.BASIC_FILTER : index < 4 }, filterValue) })
      : list.map((item: any, index: number) => { return FilterGroupSerializer({ ...item, BASIC_FILTER: index < 4 ? true : item.BASIC_FILTER, isOpenFilter: item.BASIC_FILTER || index < 4 }, filterValue) }),
    value: filterValue,
    groupValue,
  }
}

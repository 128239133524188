var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "review-card"
  }, [_c('div', {
    staticClass: "review-card__content"
  }, [_c('div', {
    staticClass: "review-card__product"
  }, [_c('nuxt-link', {
    staticClass: "review-card__product-photo-wrapper",
    attrs: {
      "to": _vm.linkProduct
    }
  }, [_vm.photoLazyload ? [_c('LazyImg', {
    staticClass: "review-card__product-photo",
    attrs: {
      "img": _vm.img,
      "alt": _vm.title
    }
  })] : [_vm.img ? _c('img', {
    staticClass: "review-card__product-photo",
    attrs: {
      "src": _vm.img,
      "alt": _vm.title
    }
  }) : _c('img', {
    staticClass: "review-card__product-photo",
    attrs: {
      "src": require("assets/images/empty.svg"),
      "alt": _vm.title
    }
  })]], 2), _vm._v(" "), _c('div', {
    staticClass: "review-card__product-content"
  }, [_c('nuxt-link', {
    staticClass: "link link_wu review-card__product-title",
    attrs: {
      "to": _vm.linkProduct,
      "title": _vm.title
    }
  }, [_vm._v("\n          " + _vm._s(_vm.title) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "review-card__product-rating-wrap"
  }, [_c('TSProductRating', {
    staticClass: "review-card__product-rating",
    attrs: {
      "is-active": _vm.averageRate,
      "size": "12"
    }
  }), _vm._v(" "), _c('nuxt-link', {
    staticClass: "review-card__product-rating-count link link_wu",
    attrs: {
      "to": _vm.linkAllReview
    }
  }, [_vm._v("\n            " + _vm._s(_vm.declOfNumGoods) + "\n          ")])], 1)], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "review-card__user"
  }, [_c('div', {
    staticClass: "review-card__user-row"
  }, [_c('ColorAvatar', {
    staticClass: "review-card__user-avatar",
    attrs: {
      "size": "30",
      "name": _vm.name
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "review-card__user-info-wrap"
  }, [_c('div', {
    staticClass: "review-card__user-info"
  }, [_c('div', {
    staticClass: "review-card__user-date-wrap"
  }, [_c('nuxt-link', {
    staticClass: "review-card__user-name link link_wu",
    attrs: {
      "to": _vm.linkAllReview
    }
  }, [_vm._v("\n                " + _vm._s(_vm.name) + "\n              ")]), _vm._v(" "), _c('div', {
    staticClass: "review-card__user-date"
  }, [_vm._v("\n                " + _vm._s(_vm.date) + "\n              ")])], 1), _vm._v(" "), _c('TSProductRating', {
    staticClass: "review-card__user-rating",
    attrs: {
      "is-active": _vm.rating,
      "show-status": "",
      "size": "12"
    }
  })], 1)])], 1), _vm._v(" "), _c('div', {
    staticClass: "review-card__user-commentary"
  }, [_c('nuxt-link', {
    staticClass: "link link_wu review-card__user-commentary-text",
    attrs: {
      "to": _vm.linkAllReview
    }
  }, [_vm._v("\n          " + _vm._s(_vm.comment) + "\n        ")]), _vm._v(" "), _c('nuxt-link', {
    staticClass: "review-card__user-commentary-link link link_wu link_blue",
    attrs: {
      "to": _vm.linkAllReview
    }
  }, [_vm._v("\n          Читать отзыв полностью »\n        ")])], 1)]), _vm._v(" "), _c('nuxt-link', {
    staticClass: "review-card__button",
    attrs: {
      "to": _vm.linkAllReview
    }
  }, [_vm._v("\n      Читать все отзывы\n    ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
import { stringify } from 'qs'
import type { Context } from '@nuxt/types'
import { getFormData } from '@artmizu/utils'
import PaginationSerializer from '~/serializer/Pagination'
import SortSerializer from '~/serializer/Sort'
import ReviewSerializer from '~/serializer/Review'
import { ProductSerialize } from '~/serializer/product/Product'
import type { Comment } from '~/type/Comment'
import type { Review } from '~/type/Review'

export default {
  async getByProductID(this: Context, { id, page, size, commentSize = 99, sort }: { id: number; page?: number; size: number; commentSize: number; sort: string }) {
    const { $axios } = this
    const result = (await $axios.$get(`/api/v1/review/${id}?${stringify({ page, size, comments_size: commentSize, sort })}`))
      ?.result
    return {
      result: {
        ...result,
        pagination: PaginationSerializer(result?.PAGINATION) || null,
        sort: result?.SORT_TYPES?.map((el: any) => SortSerializer(el)) || [],
        review: result?.ITEMS?.map((el: any) => ReviewSerializer(el)) || [],
      },
    }
  },

  async getByID(this: Context, { id }: { id: number }) {
    const { $axios } = this
    const result = (await $axios.$get(`/api/v1/review/entity/${id}`))?.result

    return {
      result: ReviewSerializer(result),
    }
  },

  async getByUser(this: Context, { page, size, comments = 99 }: { page?: number; size: number; comments: number }) {
    const { $axios } = this
    const result = (await $axios.$get<{ result: { ITEMS: any[]; PAGINATION: any } }>(`/api/v1/user/reviews?${stringify({ page, size, comments_size: comments })}`))?.result
    return {
      result: {
        pagination: PaginationSerializer(result?.PAGINATION) || null,
        review: result?.ITEMS?.map((el: any) => ReviewSerializer(el)) || [],
      },
    }
  },

  async getAwaitingReview(this: Context, { page, size }: { page?: number; size: number }) {
    const { $axios } = this
    const result = (await $axios.$get<{ result: { ITEMS: any[]; PAGINATION: any; TOTAL_PRODUCTS_COUNT: number } }>(`/api/v1/user/reviews/products/notreviewed?${stringify({ page, size })}`))?.result
    return {
      result: {
        pagination: PaginationSerializer(result?.PAGINATION) || null,
        products: result?.ITEMS?.map(el => ProductSerialize(el)) || [],
        totalCount: result.TOTAL_PRODUCTS_COUNT,
        countAwaitingReviewProduct: result?.PAGINATION?.ROWS,
      },
    }
  },

  remove(this: Context, { id }: { id: number }) {
    const { $axios } = this
    return $axios.$delete(`/api/v1/user/reviews/products/notreviewed/${id}`)
  },

  addReview(this: Context, data: Review) {
    const { $axios } = this
    return $axios.$post(
      '/api/v2/review',
      getFormData({
        product_id: data.code || '',
        product_rate: data.rate || 0,
        advantages: data.advantage || '',
        limitations: data.limitation || '',
        text: data.text || '',
        images: data.image,
        user_name: data.name || '',
      }),
      {
        headers: {
          // Нужно чтоб браузер сам проставил нужные заголовки для formdata
          post: null,
        },
      },
    )
  },

  editReview(this: Context, { reviewId, data }: { reviewId: string; data: Review }) {
    const { $axios } = this
    return $axios.$post(
      `/api/v2/review/edit/${reviewId}`,
      getFormData({
        product_id: data.code || '',
        product_rate: data.rate || 0,
        advantages: data.advantage || '',
        limitations: data.limitation || '',
        text: data.text || '',
        user_name: data.name || '',
      }),
      {
        headers: {
          // Нужно чтоб браузер сам проставил нужные заголовки для formdata
          post: null,
        },
      },
    )
  },

  addComment(this: Context, { id, productId, text, name }: Comment) {
    const { $axios } = this
    return $axios.$post(
      '/api/v2/review/comment',
      stringify({
        product_id: productId,
        entity_id: id,
        user_name: name,
        text,
      }),
    )
  },

  like(this: Context, { id }: { id: string }) {
    const { $axios } = this
    return $axios.$put(`/api/v1/review/like/${id}`)
  },

  dislike(this: Context, { id }: { id: string }) {
    const { $axios } = this
    return $axios.$put(`/api/v1/review/dislike/${id}`)
  },

  likeComment(this: Context, { id, reviewId }: { id: string; reviewId: string }) {
    const { $axios } = this
    return $axios.$put(`/api/v1/review/comment/like/${id}?${stringify({ parent_entity_id: reviewId })}`)
  },

  dislikeComment(this: Context, { id, reviewId }: { id: string; reviewId: string }) {
    const { $axios } = this
    return $axios.$put(`/api/v1/review/comment/dislike/${id}?${stringify({ parent_entity_id: reviewId })}`)
  },
}

import type { Events } from '~/plugins/emitter'

export default function productSelect(data: Events['product-select']) {
  return {
    event: 'select_item',
    ecommerce: {
      items: [
        {
          item_name: data.product.name,
          item_id: data.product.path.match(/\/([^/]+)\/?$/)?.[1],
          price: data.product.price?.common?.value,
          item_category: data.product.category?.name,
          item_list_name: data.listName,
          item_variant: data.product.offer?.length ? data.product.offer?.map(el => el.value).join(', ') : '',
          quantity: 1,
          index: typeof data.index === 'number' ? data.index + 1 : '',
          promotion_name: data.product.sale?.length ? data.product.sale[0].name : '',
          promotion_id: data.product.sale?.length ? data.product.sale[0].id.toString() : '',
        },
      ],
    },
  }
}

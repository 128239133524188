var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "input-phone",
    class: ("input-phone_" + (_vm.type))
  }, [_c('input', {
    ref: "root",
    staticClass: "input-phone__input",
    attrs: {
      "type": "tel",
      "placeholder": _vm.placeholder
    },
    domProps: {
      "value": _vm.currentValue
    },
    on: {
      "input": _vm.onInput,
      "keydown": _vm.handleKeyPress
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    attrs: {
      "show": _vm.isActive,
      "padding": "0 20px 0 0"
    },
    on: {
      "close": _vm.close
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('div', {
          staticClass: "support-modal__title"
        }, [_vm._v("\n      Помощь\n    ")])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c('div', {
    staticClass: "support-modal"
  }, [_c('ToggleChat', {
    staticClass: "support-modal__button mb-8"
  }, [_c('div', {
    staticClass: "support-modal__button-icon-support"
  }), _vm._v(" "), _c('span', {
    staticClass: "support-modal__button-text"
  }, [_vm._v("Написать в чат")]), _vm._v(" "), _c('div', {
    staticClass: "support-modal__button-icon-arrow"
  })]), _vm._v(" "), _vm._l(_vm.data, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "support-modal__list"
    }, [_c('div', {
      staticClass: "support-modal__list-title"
    }, [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]), _vm._v(" "), _vm._l(item.list, function (link, idx) {
      return _c('div', {
        key: idx,
        staticClass: "support-modal__list-el"
      }, [link.type === 'absoluteLink' ? _c('a', {
        staticClass: "support-modal__list-link link link_wu",
        attrs: {
          "href": link.url
        },
        on: {
          "click": _vm.close
        }
      }, [_vm._v("\n          " + _vm._s(link.name) + "\n          "), _c('div', {
        staticClass: "support-modal__list-link-icon"
      })]) : _c('nuxt-link', {
        staticClass: "support-modal__list-link link link_wu",
        attrs: {
          "to": link.url
        },
        nativeOn: {
          "click": function ($event) {
            return _vm.close.apply(null, arguments);
          }
        }
      }, [_vm._v("\n          " + _vm._s(link.name) + "\n          "), _c('div', {
        staticClass: "support-modal__list-link-icon"
      })])], 1);
    })], 2);
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
/**
 * Миксин для загрузки сущности сразу при загрузке страницы
 */

import baseDataMixin from '~/mixins/baseDataMixin'

export default {
  mixins: [baseDataMixin],
  props: {
    /**
     * Первоначальные данные для компонента, если они прокинуты, то первоначальная загрузка не произойдет и в виде данных в success будет прокинут этот пропс
     */
    initialValue: {
      type: Object,
      default: null,
    },
    /**
     * Функция, которая будет вызвана при загрузке компонента в fetch'e, зачастую нужно передавать функцию из API-модуля
     */
    get: {
      type: Function,
    },
    /**
     * Объект с параметрами, объект напрямую прокидывается в функцию из пропса get
     */
    params: {
      type: Object,
      default: () => ({}),
    },
    /**
     * если поставить true, то при обновлении объекта в пропсе params будет происходить запрос с обновленными параметрами
     */
    watchParamsChanges: {
      type: Boolean,
      default: false,
    },
  },
  async fetch() {
    if (this.initialValue) {
      this.getRequestParams().onSuccess(this.initialValue)
      this.changeStatus('success')
      this.$emit('success', { ...this.externalAPI, ...this._api })
    }
    else {
      await this.request(this.getRequestParams())
    }
  },
  data() {
    return {
      externalAPI: {
        response: null,
        initialLoad: true,
      },
    }
  },
  created() {
    this.appendMethodToAPI({
      name: 'get',
      fn: () => {
        this.request(this.getRequestParams())
      },
    })

    if (this.watchParamsChanges) {
      this.resetWatcher = this.$watch(
        'params',
        () => {
          this.request(this.getRequestParams())
        },
        {
          deep: true,
        },
      )
    }
  },
  beforeMount() {
    this.clientPipeline()
  },
  methods: {
    /**
     * При загрузке странице с нуля сначала на сервере и потом на клиенте, накст не видит изменения в data-свойствах, если эти изменения произошли через emit,
     * и поэтому он эти изменения не может передать на клиент. Поэтому нужно повторно вызывать нужный emit еще раз на клиенте, чтобы на клинте также отображалось валидное состояние
     */
    clientPipeline() {
      if (this.externalAPI.status === 'success')
        this.$emit('success', { ...this.externalAPI, ...this._api })
      else if (this.externalAPI.status === 'error')
        this.$emit('error', { ...this.externalAPI, ...this._api })
    },
    /**
     * Функция которая возвращает объект, который будет использоваться при запросе к api
     */
    getRequestParams() {
      return {
        requestStrategy: this.params.cancel ? 'last-request-priority' : 'first-request-priority',
        api: () => {
          /**
           * getRequest по умолчанию вовзращает данные из пропса get
           * Мы создали этот метод, чтобы его можно легко кастомизировать в конкретных случаях в других умных компонентах.
           * Пример этой кастомизации можно посмотреть в компоненте DataEntityCities
           */
          return this.getRequest()(this.params)
        },
        onSuccess: (data) => {
          this.externalAPI.response = data
          this.externalAPI.initialLoad = false
        },
        onError: (error) => {
          this.externalAPI.response = error?.response?.data?.error?.description || 'Ошибка, попробуйте позже'
        },
      }
    },
    getRequest() {
      return this.get
    },
  },
}

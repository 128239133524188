import type { Context } from '@nuxt/types'
import { stringify } from 'qs'
import { getCancelToken } from './helper'
import { ProductSerialize } from '~/serializer/product/Product'
import SortSerializer from '~/serializer/Sort'
import PaginationSerializer from '~/serializer/Pagination'
import SalePromoSerializer from '~/serializer/SalePromo'
import FilterListSerializer from '~/serializer/filter/FilterList'
import CategoryExtended from '~/serializer/CategoryExtended'
import { useLocationStore } from '~/store/location'
import MetaBlockSerializer from '~/serializer/MetaBlock'
import { BreadcrumbsSerializer } from '~/serializer/Breadcrumbs'
import type SortType from '~/type/Sort'
import type { BaseFilter } from '~/type/Filter'

export default {
  async sections(this: Context) {
    const { $axios, $pinia } = this
    const locationStore = useLocationStore($pinia)

    const response: any[] = (
      await $axios.$get('/api/v1/discounts/products/sections', {
        cache: {
          maxAge: 10 * 60 * 1000,
          key: locationStore.currentCity?.name as string,
        },
      })
    )?.result
    return response?.length ? response.map((el: any) => CategoryExtended(el)) : []
  },

  async products(
    this: Context,
    {
      page,
      sectionID,
      sort,
      cancel = false,
      size = 60,
    }: { sectionID?: number; page?: number; sort: string | (string | null)[]; cancel?: boolean; size: number },
  ) {
    const { $axios } = this
    const cancelToken = cancel ? getCancelToken({ name: 'sale_page', cancelToken: $axios.CancelToken }) : undefined

    const response: {
      ITEMS: any[]
      SORT_TYPES: any[]
      PAGINATION: any
      META_BLOCK: any
      BREADCRUMBS: any[]
    } = (
      await $axios.$get(
        `/api/v1/discounts/products?${stringify({
          ...(sectionID ? { sectionId: sectionID } : {}),
          page,
          sort,
          size,
        })}`,
        {
          cancelToken,
        },
      )
    )?.result

    const product: any[] = Array.isArray(response?.ITEMS) ? response?.ITEMS : []
    const sortRes: any[] = Array.isArray(response?.SORT_TYPES) ? response?.SORT_TYPES : []
    const pagination: any = response?.PAGINATION
    const count: number = response?.PAGINATION?.ROWS
    const meta: any = response?.META_BLOCK
    const breadcrumbs: any[] = Array.isArray(response?.BREADCRUMBS) ? response?.BREADCRUMBS : []

    return {
      result: {
        product: product ? product.map((item: any) => ProductSerialize(item)) : [],
        sort: sortRes ? sortRes.map((el: any) => SortSerializer(el)) : [],
        meta: MetaBlockSerializer(meta) || [],
        breadcrumbs: breadcrumbs.map(item => BreadcrumbsSerializer(item)) || [],
        pagination: pagination ? PaginationSerializer(pagination) : undefined,
        count: count || 0,
      },
    }
  },

  async get(this: Context) {
    const { $axios } = this
    const response = (await $axios.$get('/api/v1/discounts'))?.result

    const discounList: any[] = Array.isArray(response.DISCOUNTS) ? response?.DISCOUNTS : []

    return {
      result: {
        list: discounList ? discounList.map((el: any) => SalePromoSerializer(el)) : [],
      },
    }
  },

  async getByID(this: Context, { id, sort, page, customQuery, section, size }: { id: string; sort: SortType; size?: number; page: string; customQuery: {}; section?: string }) {
    const { $axios } = this
    const result = (await $axios.$get(`/api/v1/discounts/${id}?${stringify({ ...customQuery, SECTION_ID: section, sort, page, size })}`))?.result

    const resultSort: any[] = Array.isArray(result?.DISCOUNT?.PRODUCTS?.SORT_TYPES) ? result?.DISCOUNT?.PRODUCTS?.SORT_TYPES : []
    const resultProduct: any[] = Array.isArray(result?.DISCOUNT?.PRODUCTS?.ITEMS) ? result?.DISCOUNT?.PRODUCTS?.ITEMS : []
    const count: number = result?.DISCOUNT?.PRODUCTS?.PAGINATION?.ROWS

    // Фильтр категории по ID из списка фильтров
    const getCategoryFilterList = result?.DISCOUNT?.PRODUCTS?.FILTERS ? result.DISCOUNT.PRODUCTS.FILTERS.filter((el: any) => el.NAME === 'Раздел') : undefined
    const getMainFilterList = result?.DISCOUNT?.PRODUCTS?.FILTERS ? result.DISCOUNT.PRODUCTS.FILTERS.filter((el: any) => el.NAME !== 'Раздел') : undefined

    const filterCategoryList = FilterListSerializer(getCategoryFilterList).list[0]?.filter as BaseFilter[]
    const filterCategoryActiveValue = Object.values(FilterListSerializer(getCategoryFilterList).value)?.[0] as string

    return {
      result: {
        filter: {
          category: {
            list: filterCategoryList,
            value: filterCategoryList?.find(el => el.value === filterCategoryActiveValue),
          },
          main: getMainFilterList ? FilterListSerializer(getMainFilterList) : undefined,
        },
        sale: SalePromoSerializer(result?.DISCOUNT) || undefined,
        sort: resultSort ? resultSort.map((el: any) => SortSerializer(el)) : [],
        product: resultProduct ? resultProduct.map((item: any) => ProductSerialize(item)) : [],
        pagination: result?.DISCOUNT?.PRODUCTS?.PAGINATION
          ? PaginationSerializer(result?.DISCOUNT?.PRODUCTS?.PAGINATION)
          : undefined,
        count: count || 0,
      },
    }
  },

  getMerchSale(this: Context) {
    const { $axios } = this
    return Promise.all([
      $axios.$get('/api/v1/discounts/844011', { headers: { 'Ignore-Activity': true } }),
      $axios.$get('/api/v1/discounts/845246', { headers: { 'Ignore-Activity': true } }),
      $axios.$get('/api/v1/discounts/845250', { headers: { 'Ignore-Activity': true } }),
      $axios.$get('/api/v1/discounts/845244', { headers: { 'Ignore-Activity': true } }),
      $axios.$get('/api/v1/discounts/845248', { headers: { 'Ignore-Activity': true } }),
    ]).then((responses) => {
      const shirts = responses?.[0]?.result?.DISCOUNT?.PRODUCTS?.ITEMS
      const shopper = responses?.[1]?.result?.DISCOUNT?.PRODUCTS?.ITEMS
      const bracelet = responses?.[2]?.result?.DISCOUNT?.PRODUCTS?.ITEMS
      const postcards = responses?.[3]?.result?.DISCOUNT?.PRODUCTS?.ITEMS
      const bookmarks = responses?.[4]?.result?.DISCOUNT?.PRODUCTS?.ITEMS

      return {
        shirts: shirts?.map((item: any) => ProductSerialize(item)) || undefined,
        shopper: shopper?.map((item: any) => ProductSerialize(item)) || undefined,
        bracelet: bracelet?.map((item: any) => ProductSerialize(item)) || undefined,
        postcards: postcards?.map((item: any) => ProductSerialize(item)) || undefined,
        bookmarks: bookmarks?.map((item: any) => ProductSerialize(item)) || undefined,
      }
    })
  },
}

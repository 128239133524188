import type { Token } from '~/type/Token'

export function TokenSerializer(el: { [ key: string ]: any } = {}): Token {
  return {
    accessToken: {
      value: el?.access_token,
      expire: el?.expires?.access_token,
    },
    refreshToken: {
      value: el?.refresh_token,
      expire: el?.expires?.refresh_token,
    },
  }
}


const __sfc_main = {};
__sfc_main.props = {
  height: {
    key: "height",
    required: false,
    type: String,
    default: '72px'
  },
  width: {
    key: "width",
    required: false,
    type: String,
    default: '100%'
  },
  title: {
    key: "title",
    required: false,
    type: String
  }
};
export default __sfc_main;

var render = function () {
  var _vm$bonusStore, _vm$bonusStore$bonusD, _vm$bonusStore2, _vm$bonusStore2$bonus, _vm$bonusStore3, _vm$bonusStore3$bonus;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-menu",
    class: [{
      'user-menu_active': _vm.isActive
    }]
  }, [_vm.userData ? [_c('div', {
    staticClass: "user-menu__wrap"
  }, [_c('div', {
    ref: "userPanelWrap",
    staticClass: "user-menu__name-wrap"
  }, [_c('nuxt-link', {
    staticClass: "user-menu__name",
    attrs: {
      "to": "/user/"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.userName) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "user-menu__hidden-block"
  }, [_vm._l([{
    name: 'Мои заказы',
    url: '/user/order/'
  }, {
    name: 'Отзывы и оценки',
    url: '/user/review/'
  }, {
    name: 'Бонусы',
    url: '/user/bonus/'
  }, {
    name: 'Настройки',
    url: '/user/setting/'
  }], function (el, index) {
    return _c('nuxt-link', {
      key: index,
      staticClass: "user-menu__hidden-el link link_wu",
      attrs: {
        "to": el.url
      }
    }, [_vm._v("\n            " + _vm._s(el.name) + "\n          ")]);
  }), _vm._v(" "), _c('span', {
    staticClass: "user-menu__hidden-el link link_wu",
    on: {
      "click": function ($event) {
        return _vm.logout();
      }
    }
  }, [_vm._v("Выход")])], 2)], 1), _vm._v(" "), (_vm$bonusStore = _vm.bonusStore) !== null && _vm$bonusStore !== void 0 && (_vm$bonusStore$bonusD = _vm$bonusStore.bonusData) !== null && _vm$bonusStore$bonusD !== void 0 && _vm$bonusStore$bonusD.balance || ((_vm$bonusStore2 = _vm.bonusStore) === null || _vm$bonusStore2 === void 0 ? void 0 : (_vm$bonusStore2$bonus = _vm$bonusStore2.bonusData) === null || _vm$bonusStore2$bonus === void 0 ? void 0 : _vm$bonusStore2$bonus.balance) === 0 ? _c('TSBonusCount', {
    staticClass: "user-menu__bonuses",
    attrs: {
      "count": (_vm$bonusStore3 = _vm.bonusStore) === null || _vm$bonusStore3 === void 0 ? void 0 : (_vm$bonusStore3$bonus = _vm$bonusStore3.bonusData) === null || _vm$bonusStore3$bonus === void 0 ? void 0 : _vm$bonusStore3$bonus.balance
    }
  }) : _vm._e()], 1)] : [_c('span', {
    staticClass: "link link_wu",
    attrs: {
      "data-e2e": "autorization"
    },
    on: {
      "click": function ($event) {
        return _vm.showAuthModal();
      }
    }
  }, [_vm._v("Вход или регистрация")])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
/**
 * Миксин, который содержит базовый функционал для экшен-компонентов. Экшен-компоненты должны использоваться для запросов,
 * которые происходят в ответ на какие-либо действия: пользователь на кнопку нажал и прочее
 */

import baseDataMixin from '~/mixins/baseDataMixin'

export default {
  mixins: [baseDataMixin],
  props: {
    /**
     * Объект, который содержит экшены, который будут записаны напрямую
     * в externalAPI и будут доступны по итогу через scoped-slot, структура объекта:
     * {
     *  name: function,
     *  ....
     * }
     */
    actions: {
      type: Object,
      default: () => ({}),
    },
  },
  /**
   * Проходимся по всем свойствам объекта actions и записываем значения в externalAPI
   */
  created() {
    for (const prop in this.actions) {
      const fn = this.makeRequestFunction({ api: this.actions[prop] })
      this.$set(this.externalAPI, prop, fn)
    }
  },
  data() {
    return {
      externalAPI: {
        response: null,
      },
    }
  },
  methods: {
    /**
     *
     * @param {function} obj.api - функция для запроса по api, зачастую нужно использовать запросы из API-модуля
     * @returns {function} - функция, которую нужно вызвать, чтобы осуществить запрос, в параметры нужно передать данные которые будут переданы в API
     */
    makeRequestFunction({ api = () => {} } = {}) {
      return (data) => {
        this.request({
          requestStrategy: data?.cancel ? 'last-request-priority' : 'first-request-priority',
          api: () => {
            return api(data)
          },
          onSuccess: (data) => {
            this.externalAPI.response = data
          },
          onError: (error) => {
            this.externalAPI.response = error?.response?.data?.error?.description || 'Ошибка, попробуйте позже'
          },
        })
      }
    },
  },
}

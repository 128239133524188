
import { Modal } from '05-ui-kit';
import { computed } from '@vue/composition-api';
import { useSocialShareStore } from '~/store/modal/socialShare';
const __sfc_main = {};
__sfc_main.props = {
  name: {
    key: "name",
    required: false,
    type: String
  },
  path: {
    key: "path",
    required: false,
    type: String
  },
  show: {
    key: "show",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const socialShareStore = useSocialShareStore();
  const locationPath = computed(() => {
    if (process.client) {
      /**
       * Если передали ссылку, берём её и добавляем к хосту (https://05.ru/), если нет, берём ссылку из адресной строки
       */
      if (props.path) return window?.location?.origin + props.path;else return window?.location?.href;
    } else {
      return '';
    }
  });

  /**
    * Параметр utm метки
    * Если передана ссылка и в переданной ссылке есть параметры, то utm должен идти как ещё один параметр (с '&' в начале)
  */
  const utm = computed(() => props.path && props.path.includes('?') ? '&utm_source=share' : '?utm_source=share');
  function onClick(name: string) {
    switch (name) {
      case 'vk':
        {
          window.open(`https://vk.com/share.php?url=${encodeURIComponent(locationPath.value)}
              &description=${encodeURIComponent(socialShareStore.sendCustomText ?? locationPath.value + utm.value)}&comment=${encodeURIComponent(socialShareStore.sendCustomText ?? locationPath.value + utm.value)}`);
          break;
        }
      case 'wa':
        {
          window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(socialShareStore.sendCustomText ?? locationPath.value + utm.value)}`);
          break;
        }
      case 'tg':
        {
          let baseUrl = 'tg://msg_url?';
          if (socialShareStore.sendCustomText) baseUrl += `url=&text=${encodeURIComponent(socialShareStore.sendCustomText)}`;else baseUrl += `url=${encodeURIComponent(locationPath.value + utm.value)}`;
          window.open(baseUrl);
          break;
        }
    }
  }
  function onClose() {
    /**
     * Срабатывает при нажатии на крестик
     */
    emit('close', false);
  }
  return {
    locationPath,
    utm,
    onClick,
    onClose
  };
};
__sfc_main.components = Object.assign({
  Modal
}, __sfc_main.components);
export default __sfc_main;


export default {
  name: 'GGGroup',
  props: {
    order: {
      type: String,
      default: 'ltr',
    },
    link: {
      type: String,
      default: null,
    },
    linkText: {
      type: String,
      default: null,
    },
  },
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Popover', {
    staticClass: "user-popup",
    attrs: {
      "event": "hover",
      "width": "350px",
      "padding": "0",
      "overflow": "hidden",
      "time": 100
    },
    on: {
      "enable": function ($event) {
        return _vm.emit('enable');
      }
    },
    scopedSlots: _vm._u([{
      key: "handler",
      fn: function () {
        return [_vm._t("button")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "user-popup__el-wrapper"
        }, [!_vm.count ? _c('div', {
          staticClass: "user-popup__unavailable"
        }, [_c('Unavailable', {
          attrs: {
            "icon": _vm.type,
            "injected": ""
          }
        }, [_c('div', {
          staticClass: "h5 mb-8"
        }, [_vm._v("\n            " + _vm._s(_vm.emptyTitle) + "\n          ")]), _vm._v(" "), _c('div', {
          staticClass: "p"
        }, [_vm._v("\n            Вы можете найти на сайте интересные товары и добавить их в список\n          ")])])], 1) : _vm.error ? _c('div', {
          staticClass: "user-popup__error"
        }, [_c('StatusBlock', {
          attrs: {
            "size": "m",
            "title": "Произошла ошибка",
            "description": "При получении списка произошла ошибка",
            "type": "error"
          }
        })], 1) : _vm.pending ? _c('div', {
          staticClass: "user-popup__loading"
        }, [_c('StatusBlock', {
          attrs: {
            "size": "m",
            "type": "loading"
          }
        })], 1) : [_c('div', {
          staticClass: "user-popup__list"
        }, _vm._l(_vm.list, function (item) {
          return _c('div', {
            key: item.id,
            staticClass: "user-popup__list-el"
          }, [_vm._t("default", null, {
            "item": item
          })], 2);
        }), 0), _vm._v(" "), _c('div', {
          staticClass: "user-popup__button"
        }, [_vm._t("buttonBottom", function () {
          return [_c('nuxt-link', {
            staticClass: "link link_wu",
            attrs: {
              "to": _vm.link
            }
          }, [_c('Button', {
            attrs: {
              "fluid": "",
              "size": "39",
              "theme": "red"
            }
          }, [_vm._v("\n                " + _vm._s(_vm.buttonText) + "\n              ")])], 1)];
        })], 2)]], 2)];
      },
      proxy: true
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
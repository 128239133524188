
/**
 * Базовый экшен, подходит под большинство запросов, который не требуют доп. обработки
 */

import dataActionMixin from '~/mixins/dataActionMixin'

export default {
  mixins: [dataActionMixin],
}

import { render, staticRenderFns } from "./UserPanel.vue?vue&type=template&id=908c211e&scoped=true&"
import script from "./UserPanel.vue?vue&type=script&lang=ts&"
export * from "./UserPanel.vue?vue&type=script&lang=ts&"
import style0 from "./UserPanel.vue?vue&type=style&index=0&id=908c211e&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "908c211e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserMenu: require('/app/components/user/UserMenu/UserMenu.vue').default,UserPopupItem: require('/app/components/user/UserPopupItem/UserPopupItem.vue').default,ActionCompare: require('/app/action/ActionCompare.vue').default,UserPopup: require('/app/components/user/UserPopup/UserPopup.vue').default,ActionEntity: require('/app/action/ActionEntity.vue').default,ActionFavorite: require('/app/action/ActionFavorite.vue').default,ActionCart: require('/app/action/ActionCart.vue').default})

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "error"
  }, [_vm.$route.path.includes('sales') && _vm.error.statusCode === 403 ? _c('SaleError') : _c('div', {
    staticClass: "error__block"
  }, [_c('h1', {
    staticClass: "error__title"
  }, [_vm._v("\n      " + _vm._s(_vm.error.statusCode || 500) + "\n    ")]), _vm._v(" "), _c('h3', {
    staticClass: "error__subtitle b"
  }, [_vm.error.message ? [_vm._v("\n        " + _vm._s(_vm.error.message) + "\n      ")] : _vm.error.statusCode === 401 ? [_vm._v("\n        Проблема с токеном API\n      ")] : _vm.error.statusCode === 503 ? [_vm._v("\n        Ошибка доступа\n      ")] : [_vm._v("\n        Упс, что-то сломалось\n      ")]], 2), _vm._v(" "), _c('div', {
    staticClass: "p"
  }, [_vm._v("\n      Попробуйте обновить страницу или воспользоваться сайтом позднее. Если проблема не решается, напишите нам\n      "), _c('a', {
    staticClass: "link",
    attrs: {
      "href": "mailto:support@05.ru"
    }
  }, [_vm._v("support@05.ru")])]), _vm._v(" "), _c('nuxt-link', {
    staticClass: "link",
    attrs: {
      "to": "/"
    }
  }, [_c('Button', {
    staticClass: "mt-26",
    attrs: {
      "size": "39",
      "theme": "white",
      "fluid": ""
    }
  }, [_vm._v("\n        Перейти на главную\n      ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
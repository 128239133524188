import { render, staticRenderFns } from "./InfoBlockAuthAnimated.vue?vue&type=template&id=6631dc70&scoped=true&"
var script = {}
import style0 from "./InfoBlockAuthAnimated.vue?vue&type=style&index=0&id=6631dc70&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6631dc70",
  null
  
)

export default component.exports
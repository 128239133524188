
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  function onClick() {
    window.jivo_api?.open();
  }
  return {
    onClick
  };
};
export default __sfc_main;

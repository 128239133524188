var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nuxt-link', {
    staticClass: "link link_wu banner",
    attrs: {
      "to": _vm.data.link
    }
  }, [_c('video', {
    key: _vm.data.desktop,
    staticClass: "banner__video banner__video-desktop",
    attrs: {
      "playsinline": "",
      "autoplay": "",
      "loop": "",
      "muted": "",
      "src": require(("~/assets/images/promo/" + (_vm.data.desktop)))
    },
    domProps: {
      "muted": true
    }
  }), _vm._v(" "), _c('video', {
    key: _vm.data.mobile,
    staticClass: "banner__video banner__video-mobile",
    attrs: {
      "playsinline": "",
      "autoplay": "",
      "loop": "",
      "muted": "",
      "src": require(("~/assets/images/promo/" + (_vm.data.mobile)))
    },
    domProps: {
      "muted": true
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }
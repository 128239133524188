
import { onMounted, ref } from '@vue/composition-api';
import { later, remove, timeHasCome } from '~/helper/notifications';
import NotifyModalOrder from '~/components/Notification/modal/NotifyModalOrder.vue';
interface NotificationOrderType {
  id: number;
  payload: {
    productId: number;
    date: number;
  };
}
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const orders = ref<NotificationOrderType[]>([]);
  onMounted(() => {
    orders.value = timeHasCome({
      key: 'order'
    });
  });
  function close(id: number) {
    orders.value = orders.value.filter(n => n.id !== id);
  }
  function handleRemove(order: NotificationOrderType) {
    remove({
      key: 'order',
      id: order.id
    });
    close(order.id);
  }
  function handleLater(order: NotificationOrderType) {
    later({
      key: 'order',
      id: order.id,
      remind: 1000 * 60 * 60 * 24 * 5
    }); // Напомним через 5 дней
    close(order?.id);
  }
  return {
    orders,
    handleRemove,
    handleLater
  };
};
__sfc_main.components = Object.assign({
  NotifyModalOrder
}, __sfc_main.components);
export default __sfc_main;

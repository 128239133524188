import { render, staticRenderFns } from "./UserMenu.vue?vue&type=template&id=2914c14a&scoped=true&"
import script from "./UserMenu.vue?vue&type=script&lang=ts&"
export * from "./UserMenu.vue?vue&type=script&lang=ts&"
import style0 from "./UserMenu.vue?vue&type=style&index=0&id=2914c14a&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2914c14a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TSBonusCount: require('/app/components/ts-components/TSBonusCount/TSBonusCount.vue').default})

import type MetaBlock from '~/type/MetaBlock'

export default function (el: any): MetaBlock {
  return {
    element: {
      description: el.ELEMENT_META_DESCRIPTION,
      title: el.ELEMENT_META_TITLE,
      keywords: el.ELEMENT_META_KEYWORDS,
    },
    category: {
      description: el.SECTION_META_DESCRIPTION,
      title: el.SECTION_META_TITLE,
      keywords: el.SECTION_META_KEYWORDS,
    },
  }
}

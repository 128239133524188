
const __sfc_main = {};
__sfc_main.props = {
  data: {
    key: "data",
    required: true,
    type: Object
  }
};
export default __sfc_main;

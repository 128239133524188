export const Loader = () => import('../../components/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const AccessoriesProductCard = () => import('../../components/AccessoriesProductCard/AccessoriesProductCard.vue' /* webpackChunkName: "components/accessories-product-card" */).then(c => wrapFunctional(c.default || c))
export const ActionCartExtended = () => import('../../components/ActionCartExtended/ActionCartExtended.vue' /* webpackChunkName: "components/action-cart-extended" */).then(c => wrapFunctional(c.default || c))
export const AddToCart = () => import('../../components/AddToCart/AddToCart.vue' /* webpackChunkName: "components/add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const AppAdvertisement = () => import('../../components/AppAdvertisement/index.vue' /* webpackChunkName: "components/app-advertisement" */).then(c => wrapFunctional(c.default || c))
export const AppPromo = () => import('../../components/AppPromo/AppPromo.vue' /* webpackChunkName: "components/app-promo" */).then(c => wrapFunctional(c.default || c))
export const AppleAuthorized = () => import('../../components/AppleAuthorized/AppleAuthorized.vue' /* webpackChunkName: "components/apple-authorized" */).then(c => wrapFunctional(c.default || c))
export const AuthModal = () => import('../../components/AuthModal/AuthModal.vue' /* webpackChunkName: "components/auth-modal" */).then(c => wrapFunctional(c.default || c))
export const AvailabilityContent = () => import('../../components/AvailabilityContent/AvailabilityContent.vue' /* webpackChunkName: "components/availability-content" */).then(c => wrapFunctional(c.default || c))
export const AvailabilityModal = () => import('../../components/AvailabilityModal/AvailabilityModal.vue' /* webpackChunkName: "components/availability-modal" */).then(c => wrapFunctional(c.default || c))
export const BrandGrid = () => import('../../components/BrandGrid/BrandGrid.vue' /* webpackChunkName: "components/brand-grid" */).then(c => wrapFunctional(c.default || c))
export const BrandPromoBlocks = () => import('../../components/BrandPromoBlocks/BrandPromoBlocks.vue' /* webpackChunkName: "components/brand-promo-blocks" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumb = () => import('../../components/Breadcrumb/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const Business05 = () => import('../../components/Business05/Business05.vue' /* webpackChunkName: "components/business05" */).then(c => wrapFunctional(c.default || c))
export const CartProduct = () => import('../../components/CartProduct/CartProduct.vue' /* webpackChunkName: "components/cart-product" */).then(c => wrapFunctional(c.default || c))
export const CartProductList = () => import('../../components/CartProductList/CartProductList.vue' /* webpackChunkName: "components/cart-product-list" */).then(c => wrapFunctional(c.default || c))
export const CartTool = () => import('../../components/CartTool/CartTool.vue' /* webpackChunkName: "components/cart-tool" */).then(c => wrapFunctional(c.default || c))
export const CatalogWideBanner = () => import('../../components/CatalogWideBanner/CatalogWideBanner.vue' /* webpackChunkName: "components/catalog-wide-banner" */).then(c => wrapFunctional(c.default || c))
export const CategoriesGrid = () => import('../../components/CategoriesGrid/CategoriesGrid.vue' /* webpackChunkName: "components/categories-grid" */).then(c => wrapFunctional(c.default || c))
export const CategoryGrid = () => import('../../components/CategoryGrid/CategoryGrid.vue' /* webpackChunkName: "components/category-grid" */).then(c => wrapFunctional(c.default || c))
export const CategoryMenu = () => import('../../components/CategoryMenu/CategoryMenu.vue' /* webpackChunkName: "components/category-menu" */).then(c => wrapFunctional(c.default || c))
export const CategorySidebar = () => import('../../components/CategorySidebar/CategorySidebar.vue' /* webpackChunkName: "components/category-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ChooseProject = () => import('../../components/ChooseProject/ChooseProject.vue' /* webpackChunkName: "components/choose-project" */).then(c => wrapFunctional(c.default || c))
export const CitySearchField = () => import('../../components/CitySearchField/CitySearchField.vue' /* webpackChunkName: "components/city-search-field" */).then(c => wrapFunctional(c.default || c))
export const ColorAvatar = () => import('../../components/ColorAvatar/ColorAvatar.vue' /* webpackChunkName: "components/color-avatar" */).then(c => wrapFunctional(c.default || c))
export const Compare = () => import('../../components/Compare/Compare.vue' /* webpackChunkName: "components/compare" */).then(c => wrapFunctional(c.default || c))
export const CompareHeaderMobile = () => import('../../components/CompareHeaderMobile/CompareHeaderMobile.vue' /* webpackChunkName: "components/compare-header-mobile" */).then(c => wrapFunctional(c.default || c))
export const CompareProductCard = () => import('../../components/CompareProductCard/CompareProductCard.vue' /* webpackChunkName: "components/compare-product-card" */).then(c => wrapFunctional(c.default || c))
export const CompareTable = () => import('../../components/CompareTable/CompareTable.vue' /* webpackChunkName: "components/compare-table" */).then(c => wrapFunctional(c.default || c))
export const CopyText = () => import('../../components/CopyText/CopyText.vue' /* webpackChunkName: "components/copy-text" */).then(c => wrapFunctional(c.default || c))
export const Creditor = () => import('../../components/Creditor/Creditor.vue' /* webpackChunkName: "components/creditor" */).then(c => wrapFunctional(c.default || c))
export const DarkstoreBar = () => import('../../components/DarkstoreBar/DarkstoreBar.vue' /* webpackChunkName: "components/darkstore-bar" */).then(c => wrapFunctional(c.default || c))
export const DarkstoreMakhachkalaBar = () => import('../../components/DarkstoreMakhachkalaBar/DarkstoreMakhachkalaBar.vue' /* webpackChunkName: "components/darkstore-makhachkala-bar" */).then(c => wrapFunctional(c.default || c))
export const DefaultLayout = () => import('../../components/DefaultLayout/DefaultLayout.vue' /* webpackChunkName: "components/default-layout" */).then(c => wrapFunctional(c.default || c))
export const DeliveryType = () => import('../../components/DeliveryType/DeliveryType.vue' /* webpackChunkName: "components/delivery-type" */).then(c => wrapFunctional(c.default || c))
export const DotSlider = () => import('../../components/DotSlider/DotSlider.vue' /* webpackChunkName: "components/dot-slider" */).then(c => wrapFunctional(c.default || c))
export const ErrorNote = () => import('../../components/ErrorNote/index.vue' /* webpackChunkName: "components/error-note" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FoundCheaper = () => import('../../components/FoundCheaper/FoundCheaper.vue' /* webpackChunkName: "components/found-cheaper" */).then(c => wrapFunctional(c.default || c))
export const FoundError = () => import('../../components/FoundError/FoundError.vue' /* webpackChunkName: "components/found-error" */).then(c => wrapFunctional(c.default || c))
export const Game = () => import('../../components/Game/Game.vue' /* webpackChunkName: "components/game" */).then(c => wrapFunctional(c.default || c))
export const GiftItem = () => import('../../components/GiftItem/GiftItem.vue' /* webpackChunkName: "components/gift-item" */).then(c => wrapFunctional(c.default || c))
export const GiftTab = () => import('../../components/GiftTab/GiftTab.vue' /* webpackChunkName: "components/gift-tab" */).then(c => wrapFunctional(c.default || c))
export const GlobalTools = () => import('../../components/GlobalTools/GlobalTools.vue' /* webpackChunkName: "components/global-tools" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const InfoBlockController = () => import('../../components/InfoBlockController/InfoBlockController.vue' /* webpackChunkName: "components/info-block-controller" */).then(c => wrapFunctional(c.default || c))
export const InfoBlockLargeEquipment = () => import('../../components/InfoBlockLargeEquipment/InfoBlockLargeEquipment.vue' /* webpackChunkName: "components/info-block-large-equipment" */).then(c => wrapFunctional(c.default || c))
export const InfoBonus = () => import('../../components/InfoBonus/InfoBonus.vue' /* webpackChunkName: "components/info-bonus" */).then(c => wrapFunctional(c.default || c))
export const InfoFooter = () => import('../../components/InfoFooter/InfoFooter.vue' /* webpackChunkName: "components/info-footer" */).then(c => wrapFunctional(c.default || c))
export const InputCode = () => import('../../components/InputCode/InputCode.vue' /* webpackChunkName: "components/input-code" */).then(c => wrapFunctional(c.default || c))
export const InputPhone = () => import('../../components/InputPhone/InputPhone.vue' /* webpackChunkName: "components/input-phone" */).then(c => wrapFunctional(c.default || c))
export const InstallmentBank = () => import('../../components/InstallmentBank/InstallmentBank.vue' /* webpackChunkName: "components/installment-bank" */).then(c => wrapFunctional(c.default || c))
export const LazyImg = () => import('../../components/LazyImg/LazyImg.vue' /* webpackChunkName: "components/lazy-img" */).then(c => wrapFunctional(c.default || c))
export const InstallmentCalculate = () => import('../../components/InstallmentCalculate/InstallmentCalculate.vue' /* webpackChunkName: "components/installment-calculate" */).then(c => wrapFunctional(c.default || c))
export const Loan = () => import('../../components/Loan/Loan.vue' /* webpackChunkName: "components/loan" */).then(c => wrapFunctional(c.default || c))
export const LostConnection = () => import('../../components/LostConnection/LostConnection.vue' /* webpackChunkName: "components/lost-connection" */).then(c => wrapFunctional(c.default || c))
export const LoyaltyLevelСarousel = () => import('../../components/LoyaltyLevelСarousel/LoyaltyLevelСarousel.vue' /* webpackChunkName: "components/loyalty-levelсarousel" */).then(c => wrapFunctional(c.default || c))
export const LoyaltyProgressBar = () => import('../../components/LoyaltyProgressBar/LoyaltyProgressBar.vue' /* webpackChunkName: "components/loyalty-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const MiniCard = () => import('../../components/MiniCard/MiniCard.vue' /* webpackChunkName: "components/mini-card" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../components/Notification/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const OptionController = () => import('../../components/OptionController/OptionController.vue' /* webpackChunkName: "components/option-controller" */).then(c => wrapFunctional(c.default || c))
export const Order = () => import('../../components/Order/Order.vue' /* webpackChunkName: "components/order" */).then(c => wrapFunctional(c.default || c))
export const OrderHistory = () => import('../../components/OrderHistory/index.vue' /* webpackChunkName: "components/order-history" */).then(c => wrapFunctional(c.default || c))
export const OrderPaymentTimer = () => import('../../components/OrderPaymentTimer/OrderPaymentTimer.vue' /* webpackChunkName: "components/order-payment-timer" */).then(c => wrapFunctional(c.default || c))
export const OrderProduct = () => import('../../components/OrderProduct/index.vue' /* webpackChunkName: "components/order-product" */).then(c => wrapFunctional(c.default || c))
export const OrderStatus = () => import('../../components/OrderStatus/OrderStatus.vue' /* webpackChunkName: "components/order-status" */).then(c => wrapFunctional(c.default || c))
export const OrderSummary = () => import('../../components/OrderSummary/index.vue' /* webpackChunkName: "components/order-summary" */).then(c => wrapFunctional(c.default || c))
export const PageNotFound = () => import('../../components/PageNotFound/PageNotFound.vue' /* webpackChunkName: "components/page-not-found" */).then(c => wrapFunctional(c.default || c))
export const PageNotFoundContent = () => import('../../components/PageNotFoundContent/PageNotFoundContent.vue' /* webpackChunkName: "components/page-not-found-content" */).then(c => wrapFunctional(c.default || c))
export const PhotoUpload = () => import('../../components/PhotoUpload/PhotoUpload.vue' /* webpackChunkName: "components/photo-upload" */).then(c => wrapFunctional(c.default || c))
export const PopularCategory = () => import('../../components/PopularCategory/PopularCategory.vue' /* webpackChunkName: "components/popular-category" */).then(c => wrapFunctional(c.default || c))
export const PriceSummary = () => import('../../components/PriceSummary/PriceSummary.vue' /* webpackChunkName: "components/price-summary" */).then(c => wrapFunctional(c.default || c))
export const ProductDiscountMarker = () => import('../../components/ProductDiscountMarker/ProductDiscountMarker.vue' /* webpackChunkName: "components/product-discount-marker" */).then(c => wrapFunctional(c.default || c))
export const ProductEmptyBlock = () => import('../../components/ProductEmptyBlock/ProductEmptyBlock.vue' /* webpackChunkName: "components/product-empty-block" */).then(c => wrapFunctional(c.default || c))
export const ProductOffer = () => import('../../components/ProductOffer/ProductOffer.vue' /* webpackChunkName: "components/product-offer" */).then(c => wrapFunctional(c.default || c))
export const ProductOfferList = () => import('../../components/ProductOfferList/ProductOfferList.vue' /* webpackChunkName: "components/product-offer-list" */).then(c => wrapFunctional(c.default || c))
export const ProductPriceBlock = () => import('../../components/ProductPrice/ProductPriceBlock.vue' /* webpackChunkName: "components/product-price-block" */).then(c => wrapFunctional(c.default || c))
export const ProductStatistics = () => import('../../components/ProductStatistics/ProductStatistics.vue' /* webpackChunkName: "components/product-statistics" */).then(c => wrapFunctional(c.default || c))
export const ProductStatus = () => import('../../components/ProductStatus/ProductStatus.vue' /* webpackChunkName: "components/product-status" */).then(c => wrapFunctional(c.default || c))
export const PromoGrid = () => import('../../components/PromoGrid/PromoGrid.vue' /* webpackChunkName: "components/promo-grid" */).then(c => wrapFunctional(c.default || c))
export const PromoVideoBaner = () => import('../../components/PromoVideoBaner/PromoVideoBaner.vue' /* webpackChunkName: "components/promo-video-baner" */).then(c => wrapFunctional(c.default || c))
export const Promocode = () => import('../../components/Promocode/Promocode.vue' /* webpackChunkName: "components/promocode" */).then(c => wrapFunctional(c.default || c))
export const RadioListBlock = () => import('../../components/RadioListBlock/index.vue' /* webpackChunkName: "components/radio-list-block" */).then(c => wrapFunctional(c.default || c))
export const RassrochkaPromo = () => import('../../components/RassrochkaPromo/RassrochkaPromo.vue' /* webpackChunkName: "components/rassrochka-promo" */).then(c => wrapFunctional(c.default || c))
export const ReviewCard = () => import('../../components/ReviewCard/ReviewCard.vue' /* webpackChunkName: "components/review-card" */).then(c => wrapFunctional(c.default || c))
export const ReviewPhotoRow = () => import('../../components/ReviewPhotoRow/ReviewPhotoRow.vue' /* webpackChunkName: "components/review-photo-row" */).then(c => wrapFunctional(c.default || c))
export const ReviewsThumbs = () => import('../../components/ReviewsThumbs/ReviewsThumbs.vue' /* webpackChunkName: "components/reviews-thumbs" */).then(c => wrapFunctional(c.default || c))
export const RichContent = () => import('../../components/RichContent/RichContent.vue' /* webpackChunkName: "components/rich-content" */).then(c => wrapFunctional(c.default || c))
export const RowRadioFilter = () => import('../../components/RowRadioFilter/RowRadioFilter.vue' /* webpackChunkName: "components/row-radio-filter" */).then(c => wrapFunctional(c.default || c))
export const SaleError = () => import('../../components/SaleError/SaleError.vue' /* webpackChunkName: "components/sale-error" */).then(c => wrapFunctional(c.default || c))
export const SaleGridEl = () => import('../../components/SaleGridEl/SaleGridEl.vue' /* webpackChunkName: "components/sale-grid-el" */).then(c => wrapFunctional(c.default || c))
export const SaleMarker = () => import('../../components/SaleMarker/SaleMarker.vue' /* webpackChunkName: "components/sale-marker" */).then(c => wrapFunctional(c.default || c))
export const SaleMarkerList = () => import('../../components/SaleMarkerList/SaleMarkerList.vue' /* webpackChunkName: "components/sale-marker-list" */).then(c => wrapFunctional(c.default || c))
export const SaleTermsModal = () => import('../../components/SaleTermsModal/SaleTermsModal.vue' /* webpackChunkName: "components/sale-terms-modal" */).then(c => wrapFunctional(c.default || c))
export const ScrollGradient = () => import('../../components/ScrollGradient/ScrollGradient.vue' /* webpackChunkName: "components/scroll-gradient" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/SearchBar/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const SearchCategory = () => import('../../components/SearchCategory/SearchCategory.vue' /* webpackChunkName: "components/search-category" */).then(c => wrapFunctional(c.default || c))
export const SearchCategoryDesktop = () => import('../../components/SearchCategoryDesktop/SearchCategoryDesktop.vue' /* webpackChunkName: "components/search-category-desktop" */).then(c => wrapFunctional(c.default || c))
export const SearchCategoryMobile = () => import('../../components/SearchCategoryMobile/SearchCategoryMobile.vue' /* webpackChunkName: "components/search-category-mobile" */).then(c => wrapFunctional(c.default || c))
export const SearchSemantic = () => import('../../components/SearchSemantic/SearchSemantic.vue' /* webpackChunkName: "components/search-semantic" */).then(c => wrapFunctional(c.default || c))
export const SellerList = () => import('../../components/SellerList/SellerList.vue' /* webpackChunkName: "components/seller-list" */).then(c => wrapFunctional(c.default || c))
export const SettingHistory = () => import('../../components/SettingHistory/index.vue' /* webpackChunkName: "components/setting-history" */).then(c => wrapFunctional(c.default || c))
export const ShowHiddenList = () => import('../../components/ShowHiddenList/ShowHiddenList.vue' /* webpackChunkName: "components/show-hidden-list" */).then(c => wrapFunctional(c.default || c))
export const SidebarSticky = () => import('../../components/SidebarSticky/SidebarSticky.vue' /* webpackChunkName: "components/sidebar-sticky" */).then(c => wrapFunctional(c.default || c))
export const SmallCallback = () => import('../../components/SmallCallback/SmallCallback.vue' /* webpackChunkName: "components/small-callback" */).then(c => wrapFunctional(c.default || c))
export const SmartCountdown = () => import('../../components/SmartCountdown/SmartCountdown.vue' /* webpackChunkName: "components/smart-countdown" */).then(c => wrapFunctional(c.default || c))
export const Sort = () => import('../../components/Sort/Sort.vue' /* webpackChunkName: "components/sort" */).then(c => wrapFunctional(c.default || c))
export const SortRow = () => import('../../components/SortRow/SortRow.vue' /* webpackChunkName: "components/sort-row" */).then(c => wrapFunctional(c.default || c))
export const SortSelect = () => import('../../components/SortSelect/SortSelect.vue' /* webpackChunkName: "components/sort-select" */).then(c => wrapFunctional(c.default || c))
export const Specifications = () => import('../../components/Specifications/Specifications.vue' /* webpackChunkName: "components/specifications" */).then(c => wrapFunctional(c.default || c))
export const SupportModal = () => import('../../components/SupportModal/SupportModal.vue' /* webpackChunkName: "components/support-modal" */).then(c => wrapFunctional(c.default || c))
export const Timer = () => import('../../components/Timer/Timer.vue' /* webpackChunkName: "components/timer" */).then(c => wrapFunctional(c.default || c))
export const ToggleWrapper = () => import('../../components/ToggleWrapper/ToggleWrapper.vue' /* webpackChunkName: "components/toggle-wrapper" */).then(c => wrapFunctional(c.default || c))
export const TreeList = () => import('../../components/TreeList/TreeList.vue' /* webpackChunkName: "components/tree-list" */).then(c => wrapFunctional(c.default || c))
export const ToggleChat = () => import('../../components/ToggleChat/ToggleChat.vue' /* webpackChunkName: "components/toggle-chat" */).then(c => wrapFunctional(c.default || c))
export const UserAssemblyPc = () => import('../../components/UserAssemblyPc/UserAssemblyPc.vue' /* webpackChunkName: "components/user-assembly-pc" */).then(c => wrapFunctional(c.default || c))
export const Unavailable = () => import('../../components/Unavailable/Unavailable.vue' /* webpackChunkName: "components/unavailable" */).then(c => wrapFunctional(c.default || c))
export const UserSuggest = () => import('../../components/UserSuggest/UserSuggest.vue' /* webpackChunkName: "components/user-suggest" */).then(c => wrapFunctional(c.default || c))
export const UserTool = () => import('../../components/UserTool/UserTool.vue' /* webpackChunkName: "components/user-tool" */).then(c => wrapFunctional(c.default || c))
export const UserTools = () => import('../../components/UserTools/UserTools.vue' /* webpackChunkName: "components/user-tools" */).then(c => wrapFunctional(c.default || c))
export const ViewportToogle = () => import('../../components/ViewportToogle/ViewportToogle.vue' /* webpackChunkName: "components/viewport-toogle" */).then(c => wrapFunctional(c.default || c))
export const Keyboard = () => import('../../components/Wordle/Keyboard.vue' /* webpackChunkName: "components/keyboard" */).then(c => wrapFunctional(c.default || c))
export const WordleGame = () => import('../../components/Wordle/WordleGame.vue' /* webpackChunkName: "components/wordle-game" */).then(c => wrapFunctional(c.default || c))
export const ToggleSwitch = () => import('../../components/WriteOffBonuses/ToggleSwitch.vue' /* webpackChunkName: "components/toggle-switch" */).then(c => wrapFunctional(c.default || c))
export const WriteOffBonuses = () => import('../../components/WriteOffBonuses/WriteOffBonuses.vue' /* webpackChunkName: "components/write-off-bonuses" */).then(c => wrapFunctional(c.default || c))
export const WriteOffBonusesInfo = () => import('../../components/WriteOffBonuses/WriteOffBonusesInfo.vue' /* webpackChunkName: "components/write-off-bonuses-info" */).then(c => wrapFunctional(c.default || c))
export const PromoBanner = () => import('../../components/banner/PromoBanner.vue' /* webpackChunkName: "components/promo-banner" */).then(c => wrapFunctional(c.default || c))
export const SaleBanner = () => import('../../components/banner/SaleBanner.vue' /* webpackChunkName: "components/sale-banner" */).then(c => wrapFunctional(c.default || c))
export const GGDefaultSection = () => import('../../components/grid-generation/GGDefaultSection.vue' /* webpackChunkName: "components/g-g-default-section" */).then(c => wrapFunctional(c.default || c))
export const GGDiscountMarker = () => import('../../components/grid-generation/GGDiscountMarker.vue' /* webpackChunkName: "components/g-g-discount-marker" */).then(c => wrapFunctional(c.default || c))
export const GGGroup = () => import('../../components/grid-generation/GGGroup.vue' /* webpackChunkName: "components/g-g-group" */).then(c => wrapFunctional(c.default || c))
export const GGLayout = () => import('../../components/grid-generation/GGLayout.vue' /* webpackChunkName: "components/g-g-layout" */).then(c => wrapFunctional(c.default || c))
export const GGLink = () => import('../../components/grid-generation/GGLink.vue' /* webpackChunkName: "components/g-g-link" */).then(c => wrapFunctional(c.default || c))
export const GGProduct = () => import('../../components/grid-generation/GGProduct.vue' /* webpackChunkName: "components/g-g-product" */).then(c => wrapFunctional(c.default || c))
export const GGReviewSection = () => import('../../components/grid-generation/GGReviewSection.vue' /* webpackChunkName: "components/g-g-review-section" */).then(c => wrapFunctional(c.default || c))
export const GGSaleSection = () => import('../../components/grid-generation/GGSaleSection.vue' /* webpackChunkName: "components/g-g-sale-section" */).then(c => wrapFunctional(c.default || c))
export const GGShrinkGrid = () => import('../../components/grid-generation/GGShrinkGrid.vue' /* webpackChunkName: "components/g-g-shrink-grid" */).then(c => wrapFunctional(c.default || c))
export const GGWideGrid = () => import('../../components/grid-generation/GGWideGrid.vue' /* webpackChunkName: "components/g-g-wide-grid" */).then(c => wrapFunctional(c.default || c))
export const CatalogPage = () => import('../../components/page-component/CatalogPage.vue' /* webpackChunkName: "components/catalog-page" */).then(c => wrapFunctional(c.default || c))
export const ProductDiscountsPage = () => import('../../components/page-component/ProductDiscountsPage.vue' /* webpackChunkName: "components/product-discounts-page" */).then(c => wrapFunctional(c.default || c))
export const ProductPage = () => import('../../components/page-component/ProductPage.vue' /* webpackChunkName: "components/product-page" */).then(c => wrapFunctional(c.default || c))
export const PromoBrandPage = () => import('../../components/page-component/PromoBrandPage.vue' /* webpackChunkName: "components/promo-brand-page" */).then(c => wrapFunctional(c.default || c))
export const SalesFilterPage = () => import('../../components/page-component/SalesFilterPage.vue' /* webpackChunkName: "components/sales-filter-page" */).then(c => wrapFunctional(c.default || c))
export const SalesPage = () => import('../../components/page-component/SalesPage.vue' /* webpackChunkName: "components/sales-page" */).then(c => wrapFunctional(c.default || c))
export const BrandEl = () => import('../../components/Brands/BrandEl/BrandEl.vue' /* webpackChunkName: "components/brand-el" */).then(c => wrapFunctional(c.default || c))
export const BrandSearchField = () => import('../../components/Brands/BrandSearchField/BrandSearchField.vue' /* webpackChunkName: "components/brand-search-field" */).then(c => wrapFunctional(c.default || c))
export const CheckoutController = () => import('../../components/Checkout/CheckoutController/CheckoutController.vue' /* webpackChunkName: "components/checkout-controller" */).then(c => wrapFunctional(c.default || c))
export const CourierAddressSelect = () => import('../../components/Checkout/CourierAddressSelect/index.vue' /* webpackChunkName: "components/courier-address-select" */).then(c => wrapFunctional(c.default || c))
export const DeliveryAddressHints = () => import('../../components/Checkout/DeliveryAddressHints/DeliveryAddressHints.vue' /* webpackChunkName: "components/delivery-address-hints" */).then(c => wrapFunctional(c.default || c))
export const DeliveryMap = () => import('../../components/Checkout/DeliveryMap/DeliveryMap.vue' /* webpackChunkName: "components/delivery-map" */).then(c => wrapFunctional(c.default || c))
export const OrderSummaryMobile = () => import('../../components/Checkout/OrderSummaryMobile/OrderSummaryMobile.vue' /* webpackChunkName: "components/order-summary-mobile" */).then(c => wrapFunctional(c.default || c))
export const OrderTotalPrice = () => import('../../components/Checkout/OrderTotalPrice/index.vue' /* webpackChunkName: "components/order-total-price" */).then(c => wrapFunctional(c.default || c))
export const OrderTypeSelect = () => import('../../components/Checkout/OrderTypeSelect/index.vue' /* webpackChunkName: "components/order-type-select" */).then(c => wrapFunctional(c.default || c))
export const PickupAddressSelect = () => import('../../components/Checkout/PickupAddressSelect/index.vue' /* webpackChunkName: "components/pickup-address-select" */).then(c => wrapFunctional(c.default || c))
export const PromocodeList = () => import('../../components/Checkout/PromocodeList/PromocodeList.vue' /* webpackChunkName: "components/promocode-list" */).then(c => wrapFunctional(c.default || c))
export const StepContact = () => import('../../components/Checkout/StepContact/StepContact.vue' /* webpackChunkName: "components/step-contact" */).then(c => wrapFunctional(c.default || c))
export const StepController = () => import('../../components/Checkout/StepController/StepController.vue' /* webpackChunkName: "components/step-controller" */).then(c => wrapFunctional(c.default || c))
export const StepDelivery = () => import('../../components/Checkout/StepDelivery/StepDelivery.vue' /* webpackChunkName: "components/step-delivery" */).then(c => wrapFunctional(c.default || c))
export const StepPayment = () => import('../../components/Checkout/StepPayment/StepPayment.vue' /* webpackChunkName: "components/step-payment" */).then(c => wrapFunctional(c.default || c))
export const Summary = () => import('../../components/Checkout/Summary/Summary.vue' /* webpackChunkName: "components/summary" */).then(c => wrapFunctional(c.default || c))
export const CustomPcComponent = () => import('../../components/CustomPC/CustomPcComponent/CustomPcComponent.vue' /* webpackChunkName: "components/custom-pc-component" */).then(c => wrapFunctional(c.default || c))
export const CustomPcComponentModal = () => import('../../components/CustomPC/CustomPcComponentModal/CustomPcComponentModal.vue' /* webpackChunkName: "components/custom-pc-component-modal" */).then(c => wrapFunctional(c.default || c))
export const CustomPcSummary = () => import('../../components/CustomPC/CustomPcSummary/CustomPcSummary.vue' /* webpackChunkName: "components/custom-pc-summary" */).then(c => wrapFunctional(c.default || c))
export const PCBuilder = () => import('../../components/CustomPC/PCBuilder/PCBuilder.vue' /* webpackChunkName: "components/p-c-builder" */).then(c => wrapFunctional(c.default || c))
export const InfoBlockAuthAnimated = () => import('../../components/InfoBlocks/InfoBlockAuthAnimated/InfoBlockAuthAnimated.vue' /* webpackChunkName: "components/info-block-auth-animated" */).then(c => wrapFunctional(c.default || c))
export const InfoBlockAuthBonus = () => import('../../components/InfoBlocks/InfoBlockAuthBonus/InfoBlockAuthBonus.vue' /* webpackChunkName: "components/info-block-auth-bonus" */).then(c => wrapFunctional(c.default || c))
export const InfoBlockAuthCard = () => import('../../components/InfoBlocks/InfoBlockAuthCard/InfoBlockAuthCard.vue' /* webpackChunkName: "components/info-block-auth-card" */).then(c => wrapFunctional(c.default || c))
export const InfoBlockAuthorization = () => import('../../components/InfoBlocks/InfoBlockAuthorization/InfoBlockAuthorization.vue' /* webpackChunkName: "components/info-block-authorization" */).then(c => wrapFunctional(c.default || c))
export const InfoBlockBonus = () => import('../../components/InfoBlocks/InfoBlockBonus/InfoBlockBonus.vue' /* webpackChunkName: "components/info-block-bonus" */).then(c => wrapFunctional(c.default || c))
export const InfoBlockCart = () => import('../../components/InfoBlocks/InfoBlockCart/InfoBlockCart.vue' /* webpackChunkName: "components/info-block-cart" */).then(c => wrapFunctional(c.default || c))
export const InfoBlockDarkstore = () => import('../../components/InfoBlocks/InfoBlockDarkstore/InfoBlockDarkstore.vue' /* webpackChunkName: "components/info-block-darkstore" */).then(c => wrapFunctional(c.default || c))
export const InfoBlockDarkstoreMakhachkala = () => import('../../components/InfoBlocks/InfoBlockDarkstoreMakhachkala/InfoBlockDarkstoreMakhachkala.vue' /* webpackChunkName: "components/info-block-darkstore-makhachkala" */).then(c => wrapFunctional(c.default || c))
export const InfoBlockInstallment = () => import('../../components/InfoBlocks/InfoBlockInstallment/InfoBlockInstallment.vue' /* webpackChunkName: "components/info-block-installment" */).then(c => wrapFunctional(c.default || c))
export const InfoBlockMarketplace = () => import('../../components/InfoBlocks/InfoBlockMarketplace/InfoBlockMarketplace.vue' /* webpackChunkName: "components/info-block-marketplace" */).then(c => wrapFunctional(c.default || c))
export const InfoBlockNewYear = () => import('../../components/InfoBlocks/InfoBlockNewYear/InfoBlockNewYear.vue' /* webpackChunkName: "components/info-block-new-year" */).then(c => wrapFunctional(c.default || c))
export const NotifyModalOrder = () => import('../../components/Notification/modal/NotifyModalOrder.vue' /* webpackChunkName: "components/notify-modal-order" */).then(c => wrapFunctional(c.default || c))
export const QuizBanner = () => import('../../components/Quiz/QuizBanner/index.vue' /* webpackChunkName: "components/quiz-banner" */).then(c => wrapFunctional(c.default || c))
export const QuizControllerPictureSelect = () => import('../../components/Quiz/QuizController/QuizControllerPictureSelect.vue' /* webpackChunkName: "components/quiz-controller-picture-select" */).then(c => wrapFunctional(c.default || c))
export const QuizController = () => import('../../components/Quiz/QuizController/index.vue' /* webpackChunkName: "components/quiz-controller" */).then(c => wrapFunctional(c.default || c))
export const QuizQuestion = () => import('../../components/Quiz/QuizQuestion/index.vue' /* webpackChunkName: "components/quiz-question" */).then(c => wrapFunctional(c.default || c))
export const QuziWrap = () => import('../../components/Quiz/QuziWrap/index.vue' /* webpackChunkName: "components/quzi-wrap" */).then(c => wrapFunctional(c.default || c))
export const StickerCard = () => import('../../components/Stickers/StickerCard/StickerCard.vue' /* webpackChunkName: "components/sticker-card" */).then(c => wrapFunctional(c.default || c))
export const StickerGrid = () => import('../../components/Stickers/StickerGrid/StickerGrid.vue' /* webpackChunkName: "components/sticker-grid" */).then(c => wrapFunctional(c.default || c))
export const BPCardActivate = () => import('../../components/bonus/BPCardActivate/BPCardActivate.vue' /* webpackChunkName: "components/b-p-card-activate" */).then(c => wrapFunctional(c.default || c))
export const BPCardAuthForm = () => import('../../components/bonus/BPCardAuthForm/BPCardAuthForm.vue' /* webpackChunkName: "components/b-p-card-auth-form" */).then(c => wrapFunctional(c.default || c))
export const BPCardRegister = () => import('../../components/bonus/BPCardRegister/BPCardRegister.vue' /* webpackChunkName: "components/b-p-card-register" */).then(c => wrapFunctional(c.default || c))
export const BPGiftAuthForm = () => import('../../components/bonus/BPGiftAuthForm/BPGiftAuthForm.vue' /* webpackChunkName: "components/b-p-gift-auth-form" */).then(c => wrapFunctional(c.default || c))
export const BPInputCode = () => import('../../components/bonus/BPInputCode/BPInputCode.vue' /* webpackChunkName: "components/b-p-input-code" */).then(c => wrapFunctional(c.default || c))
export const BPQuestion = () => import('../../components/bonus/BPQuestion/BPQuestion.vue' /* webpackChunkName: "components/b-p-question" */).then(c => wrapFunctional(c.default || c))
export const BPSlider = () => import('../../components/bonus/BPSlider/BPSlider.vue' /* webpackChunkName: "components/b-p-slider" */).then(c => wrapFunctional(c.default || c))
export const BonusCardCondition = () => import('../../components/bonus/BonusCardCondition/BonusCardCondition.vue' /* webpackChunkName: "components/bonus-card-condition" */).then(c => wrapFunctional(c.default || c))
export const BonusCardPhysicalActivate = () => import('../../components/bonus/BonusCardPhysicalActivate/BonusCardPhysicalActivate.vue' /* webpackChunkName: "components/bonus-card-physical-activate" */).then(c => wrapFunctional(c.default || c))
export const CatalogFilter = () => import('../../components/filter/CatalogFilter/CatalogFilter.vue' /* webpackChunkName: "components/catalog-filter" */).then(c => wrapFunctional(c.default || c))
export const CheckboxListFilter = () => import('../../components/filter/CheckboxListFilter/CheckboxListFilter.vue' /* webpackChunkName: "components/checkbox-list-filter" */).then(c => wrapFunctional(c.default || c))
export const FilterModal = () => import('../../components/filter/FIlterModal/FilterModal.vue' /* webpackChunkName: "components/filter-modal" */).then(c => wrapFunctional(c.default || c))
export const FilterButton = () => import('../../components/filter/FilterButton/FilterButton.vue' /* webpackChunkName: "components/filter-button" */).then(c => wrapFunctional(c.default || c))
export const FilterCheckbox = () => import('../../components/filter/FilterCheckbox/FilterCheckbox.vue' /* webpackChunkName: "components/filter-checkbox" */).then(c => wrapFunctional(c.default || c))
export const FilterGroupWrapper = () => import('../../components/filter/FilterGroupWrapper/FilterGroupWrapper.vue' /* webpackChunkName: "components/filter-group-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FilterGroupWrapperModal = () => import('../../components/filter/FilterGroupWrapperModal/FilterGroupWrapperModal.vue' /* webpackChunkName: "components/filter-group-wrapper-modal" */).then(c => wrapFunctional(c.default || c))
export const FilterRange = () => import('../../components/filter/FilterRange/FilterRange.vue' /* webpackChunkName: "components/filter-range" */).then(c => wrapFunctional(c.default || c))
export const FilterRow = () => import('../../components/filter/FilterRow/FilterRow.vue' /* webpackChunkName: "components/filter-row" */).then(c => wrapFunctional(c.default || c))
export const FilterRowWrapper = () => import('../../components/filter/FilterRow/FilterRowWrapper.vue' /* webpackChunkName: "components/filter-row-wrapper" */).then(c => wrapFunctional(c.default || c))
export const RowPhotoFilter = () => import('../../components/filter/RowPhotoFilter/RowPhotoFilter.vue' /* webpackChunkName: "components/row-photo-filter" */).then(c => wrapFunctional(c.default || c))
export const SelectFilter = () => import('../../components/filter/SelectFilter/SelectFilter.vue' /* webpackChunkName: "components/select-filter" */).then(c => wrapFunctional(c.default || c))
export const TagHorizontalFilter = () => import('../../components/filter/TagHorizontalFilter/TagHorizontalFilter.vue' /* webpackChunkName: "components/tag-horizontal-filter" */).then(c => wrapFunctional(c.default || c))
export const MerchProductCard = () => import('../../components/merch/MerchProductCard/MerchProductCard.vue' /* webpackChunkName: "components/merch-product-card" */).then(c => wrapFunctional(c.default || c))
export const MerchProductCardSlider = () => import('../../components/merch/MerchProductCardSlider/MerchProductCardSlider.vue' /* webpackChunkName: "components/merch-product-card-slider" */).then(c => wrapFunctional(c.default || c))
export const MerchProductPhotoSlider = () => import('../../components/merch/MerchProductPhotoSlider/MerchProductPhotoSlider.vue' /* webpackChunkName: "components/merch-product-photo-slider" */).then(c => wrapFunctional(c.default || c))
export const MerchSlide = () => import('../../components/merch/MerchSlide/MerchSlide.vue' /* webpackChunkName: "components/merch-slide" */).then(c => wrapFunctional(c.default || c))
export const MerchSlider = () => import('../../components/merch/MerchSlider/MerchSlider.vue' /* webpackChunkName: "components/merch-slider" */).then(c => wrapFunctional(c.default || c))
export const AchievementModal = () => import('../../components/modal/AchievementModal/AchievementModal.vue' /* webpackChunkName: "components/achievement-modal" */).then(c => wrapFunctional(c.default || c))
export const AddCart = () => import('../../components/modal/AddCart/AddCart.vue' /* webpackChunkName: "components/add-cart" */).then(c => wrapFunctional(c.default || c))
export const AddCode = () => import('../../components/modal/AddCode/AddCode.vue' /* webpackChunkName: "components/add-code" */).then(c => wrapFunctional(c.default || c))
export const AddCompare = () => import('../../components/modal/AddCompare/AddCompare.vue' /* webpackChunkName: "components/add-compare" */).then(c => wrapFunctional(c.default || c))
export const ChooseCity = () => import('../../components/modal/ChooseCity/ChooseCity.vue' /* webpackChunkName: "components/choose-city" */).then(c => wrapFunctional(c.default || c))
export const CopyCode = () => import('../../components/modal/CopyCode/CopyCode.vue' /* webpackChunkName: "components/copy-code" */).then(c => wrapFunctional(c.default || c))
export const EmailConfirm = () => import('../../components/modal/EmailConfirm/EmailConfirm.vue' /* webpackChunkName: "components/email-confirm" */).then(c => wrapFunctional(c.default || c))
export const FoundErrorModal = () => import('../../components/modal/FoundErrorModal/FoundErrorModal.vue' /* webpackChunkName: "components/found-error-modal" */).then(c => wrapFunctional(c.default || c))
export const MobileAppModal = () => import('../../components/modal/MobileApp/MobileAppModal.vue' /* webpackChunkName: "components/mobile-app-modal" */).then(c => wrapFunctional(c.default || c))
export const OrderFeedbackModal = () => import('../../components/modal/OrderFeedbackModal/OrderFeedbackModal.vue' /* webpackChunkName: "components/order-feedback-modal" */).then(c => wrapFunctional(c.default || c))
export const Photoswipe = () => import('../../components/modal/Photoswipe/Photoswipe.vue' /* webpackChunkName: "components/photoswipe" */).then(c => wrapFunctional(c.default || c))
export const ProductMore = () => import('../../components/modal/ProductMore/ProductMore.vue' /* webpackChunkName: "components/product-more" */).then(c => wrapFunctional(c.default || c))
export const Report = () => import('../../components/modal/Report/Report.vue' /* webpackChunkName: "components/report" */).then(c => wrapFunctional(c.default || c))
export const SocialShare = () => import('../../components/modal/SocialShare/SocialShare.vue' /* webpackChunkName: "components/social-share" */).then(c => wrapFunctional(c.default || c))
export const CancelOrderModal = () => import('../../components/modal/cancelOrderModal/cancelOrderModal.vue' /* webpackChunkName: "components/cancel-order-modal" */).then(c => wrapFunctional(c.default || c))
export const AnniversaryAuthForm = () => import('../../components/nam20/AnniversaryAuthForm/AnniversaryAuthForm.vue' /* webpackChunkName: "components/anniversary-auth-form" */).then(c => wrapFunctional(c.default || c))
export const AnniversaryFond = () => import('../../components/nam20/AnniversaryFond/AnniversaryFond.vue' /* webpackChunkName: "components/anniversary-fond" */).then(c => wrapFunctional(c.default || c))
export const MainPromoBanners = () => import('../../components/nam20/MainPromoBanners/index.vue' /* webpackChunkName: "components/main-promo-banners" */).then(c => wrapFunctional(c.default || c))
export const PromoCard = () => import('../../components/nam20/PromoCard/index.vue' /* webpackChunkName: "components/promo-card" */).then(c => wrapFunctional(c.default || c))
export const AnswerForm = () => import('../../components/product/AnswerForm/AnswerForm.vue' /* webpackChunkName: "components/answer-form" */).then(c => wrapFunctional(c.default || c))
export const QuickOrderToggle = () => import('../../components/product/QuickOrderToggle/QuickOrderToggle.vue' /* webpackChunkName: "components/quick-order-toggle" */).then(c => wrapFunctional(c.default || c))
export const SaleTimer = () => import('../../components/product/SaleTimer/SaleTimer.vue' /* webpackChunkName: "components/sale-timer" */).then(c => wrapFunctional(c.default || c))
export const SaleTimerCyberMonday = () => import('../../components/product/SaleTimerCyberMonday/SaleTimerCyberMonday.vue' /* webpackChunkName: "components/sale-timer-cyber-monday" */).then(c => wrapFunctional(c.default || c))
export const UserAddressForm = () => import('../../components/profile/UserAddressForm/UserAddressForm.vue' /* webpackChunkName: "components/user-address-form" */).then(c => wrapFunctional(c.default || c))
export const UserAvatarEdit = () => import('../../components/profile/UserAvatarEdit/UserAvatarEdit.vue' /* webpackChunkName: "components/user-avatar-edit" */).then(c => wrapFunctional(c.default || c))
export const UserAwaitingReview = () => import('../../components/profile/UserAwaitingReview/UserAwaitingReview.vue' /* webpackChunkName: "components/user-awaiting-review" */).then(c => wrapFunctional(c.default || c))
export const UserAwaitingReviewRange = () => import('../../components/profile/UserAwaitingReviewRange/UserAwaitingReviewRange.vue' /* webpackChunkName: "components/user-awaiting-review-range" */).then(c => wrapFunctional(c.default || c))
export const UserBonusBlock = () => import('../../components/profile/UserBonusBlock/UserBonusBlock.vue' /* webpackChunkName: "components/user-bonus-block" */).then(c => wrapFunctional(c.default || c))
export const UserBonusBlockList = () => import('../../components/profile/UserBonusBlockList/UserBonusBlockList.vue' /* webpackChunkName: "components/user-bonus-block-list" */).then(c => wrapFunctional(c.default || c))
export const UserBonusCardAuth = () => import('../../components/profile/UserBonusCardAuth/UserBonusCardAuth.vue' /* webpackChunkName: "components/user-bonus-card-auth" */).then(c => wrapFunctional(c.default || c))
export const UserBonusCardBalance = () => import('../../components/profile/UserBonusCardBalance/UserBonusCardBalance.vue' /* webpackChunkName: "components/user-bonus-card-balance" */).then(c => wrapFunctional(c.default || c))
export const UserBonusHistory = () => import('../../components/profile/UserBonusHistory/UserBonusHistory.vue' /* webpackChunkName: "components/user-bonus-history" */).then(c => wrapFunctional(c.default || c))
export const UserCreateAddress = () => import('../../components/profile/UserCreateAddress/UserCreateAddress.vue' /* webpackChunkName: "components/user-create-address" */).then(c => wrapFunctional(c.default || c))
export const UserEditAddress = () => import('../../components/profile/UserEditAddress/UserEditAddress.vue' /* webpackChunkName: "components/user-edit-address" */).then(c => wrapFunctional(c.default || c))
export const UserExpiryBonuses = () => import('../../components/profile/UserExpiryBonuses/UserExpiryBonuses.vue' /* webpackChunkName: "components/user-expiry-bonuses" */).then(c => wrapFunctional(c.default || c))
export const UserExpiryEl = () => import('../../components/profile/UserExpiryBonuses/UserExpiryEl.vue' /* webpackChunkName: "components/user-expiry-el" */).then(c => wrapFunctional(c.default || c))
export const UserHybridPagination = () => import('../../components/profile/UserHybridPagination/UserHybridPagination.vue' /* webpackChunkName: "components/user-hybrid-pagination" */).then(c => wrapFunctional(c.default || c))
export const UserLayout = () => import('../../components/profile/UserLayout/index.vue' /* webpackChunkName: "components/user-layout" */).then(c => wrapFunctional(c.default || c))
export const UserOrderCheck = () => import('../../components/profile/UserOrderCheck/UserOrderCheck.vue' /* webpackChunkName: "components/user-order-check" */).then(c => wrapFunctional(c.default || c))
export const UserPersonalData = () => import('../../components/profile/UserPersonalData/UserPersonalData.vue' /* webpackChunkName: "components/user-personal-data" */).then(c => wrapFunctional(c.default || c))
export const UserProfileHeader = () => import('../../components/profile/UserProfileHeader/UserProfileHeader.vue' /* webpackChunkName: "components/user-profile-header" */).then(c => wrapFunctional(c.default || c))
export const UserReceiptForm = () => import('../../components/profile/UserReceiptForm/UserReceiptForm.vue' /* webpackChunkName: "components/user-receipt-form" */).then(c => wrapFunctional(c.default || c))
export const UserTabList = () => import('../../components/profile/UserTabList/UserTabList.vue' /* webpackChunkName: "components/user-tab-list" */).then(c => wrapFunctional(c.default || c))
export const CommentaryQuestion = () => import('../../components/question/CommentaryQuestion/CommentaryQuestion.vue' /* webpackChunkName: "components/commentary-question" */).then(c => wrapFunctional(c.default || c))
export const Question = () => import('../../components/question/Question/Question.vue' /* webpackChunkName: "components/question" */).then(c => wrapFunctional(c.default || c))
export const QuestionMobile = () => import('../../components/question/Question/QuestionMobile.vue' /* webpackChunkName: "components/question-mobile" */).then(c => wrapFunctional(c.default || c))
export const Comment = () => import('../../components/review-component/Comment/Comment.vue' /* webpackChunkName: "components/comment" */).then(c => wrapFunctional(c.default || c))
export const Review = () => import('../../components/review-component/Review/Review.vue' /* webpackChunkName: "components/review" */).then(c => wrapFunctional(c.default || c))
export const ReviewMobile = () => import('../../components/review-component/Review/ReviewMobile.vue' /* webpackChunkName: "components/review-mobile" */).then(c => wrapFunctional(c.default || c))
export const ReviewComment = () => import('../../components/review-component/ReviewComment/ReviewComment.vue' /* webpackChunkName: "components/review-comment" */).then(c => wrapFunctional(c.default || c))
export const ReviewRating = () => import('../../components/review-component/ReviewRating/ReviewRating.vue' /* webpackChunkName: "components/review-rating" */).then(c => wrapFunctional(c.default || c))
export const TextareaButton = () => import('../../components/review-component/TextareaButton/TextareaButton.vue' /* webpackChunkName: "components/textarea-button" */).then(c => wrapFunctional(c.default || c))
export const BaseInfiniteSlider = () => import('../../components/sliders/BaseInfiniteSlider/BaseInfiniteSlider.vue' /* webpackChunkName: "components/base-infinite-slider" */).then(c => wrapFunctional(c.default || c))
export const BaseRowSlider = () => import('../../components/sliders/BaseRowSlider/BaseRowSlider.vue' /* webpackChunkName: "components/base-row-slider" */).then(c => wrapFunctional(c.default || c))
export const MainSlider = () => import('../../components/sliders/MainSlider/MainSlider.vue' /* webpackChunkName: "components/main-slider" */).then(c => wrapFunctional(c.default || c))
export const MainSliderEl = () => import('../../components/sliders/MainSlider/MainSliderEl.vue' /* webpackChunkName: "components/main-slider-el" */).then(c => wrapFunctional(c.default || c))
export const NewsCard = () => import('../../components/sliders/NewsCard/NewsCard.vue' /* webpackChunkName: "components/news-card" */).then(c => wrapFunctional(c.default || c))
export const ProductPhotoSlider = () => import('../../components/sliders/ProductSlider/ProductPhotoSlider.vue' /* webpackChunkName: "components/product-photo-slider" */).then(c => wrapFunctional(c.default || c))
export const RowSliderNew = () => import('../../components/sliders/RowSliderNew/RowSliderNew.vue' /* webpackChunkName: "components/row-slider-new" */).then(c => wrapFunctional(c.default || c))
export const StoriesSlide = () => import('../../components/sliders/StoriesSlide/StoriesSlide.vue' /* webpackChunkName: "components/stories-slide" */).then(c => wrapFunctional(c.default || c))
export const TSBonusCount = () => import('../../components/ts-components/TSBonusCount/TSBonusCount.vue' /* webpackChunkName: "components/t-s-bonus-count" */).then(c => wrapFunctional(c.default || c))
export const TSCounter = () => import('../../components/ts-components/TSCounter/TSCounter.vue' /* webpackChunkName: "components/t-s-counter" */).then(c => wrapFunctional(c.default || c))
export const TSHybridPagination = () => import('../../components/ts-components/TSHybridPagination/TSHybridPagination.vue' /* webpackChunkName: "components/t-s-hybrid-pagination" */).then(c => wrapFunctional(c.default || c))
export const TSPrice = () => import('../../components/ts-components/TSPrice/TSPrice.vue' /* webpackChunkName: "components/t-s-price" */).then(c => wrapFunctional(c.default || c))
export const TSProductCard = () => import('../../components/ts-components/TSProductCard/TSProductCard.vue' /* webpackChunkName: "components/t-s-product-card" */).then(c => wrapFunctional(c.default || c))
export const TSProductCardGrid = () => import('../../components/ts-components/TSProductCardGrid/TSProductCardGrid.vue' /* webpackChunkName: "components/t-s-product-card-grid" */).then(c => wrapFunctional(c.default || c))
export const TSProductCardSlider = () => import('../../components/ts-components/TSProductCardSlider/TSProductCardSlider.vue' /* webpackChunkName: "components/t-s-product-card-slider" */).then(c => wrapFunctional(c.default || c))
export const TSProductGrid = () => import('../../components/ts-components/TSProductGrid/TSProductGrid.vue' /* webpackChunkName: "components/t-s-product-grid" */).then(c => wrapFunctional(c.default || c))
export const TSProductPromo = () => import('../../components/ts-components/TSProductPromo/TSProductPromo.vue' /* webpackChunkName: "components/t-s-product-promo" */).then(c => wrapFunctional(c.default || c))
export const TSProductRating = () => import('../../components/ts-components/TSProductRating/TSProductRating.vue' /* webpackChunkName: "components/t-s-product-rating" */).then(c => wrapFunctional(c.default || c))
export const TSRadioListSelect = () => import('../../components/ts-components/TSRadioListSelect/TSRadioListSelect.vue' /* webpackChunkName: "components/t-s-radio-list-select" */).then(c => wrapFunctional(c.default || c))
export const LoyaltyBanner = () => import('../../components/user/LoyaltyBanner/LoyaltyBanner.vue' /* webpackChunkName: "components/loyalty-banner" */).then(c => wrapFunctional(c.default || c))
export const OrderReview = () => import('../../components/user/OrderReview/OrderReview.vue' /* webpackChunkName: "components/order-review" */).then(c => wrapFunctional(c.default || c))
export const PhoneConfirmation = () => import('../../components/user/PhoneConfirmation/PhoneConfirmation.vue' /* webpackChunkName: "components/phone-confirmation" */).then(c => wrapFunctional(c.default || c))
export const ReviewPromoSuggest = () => import('../../components/user/ReviewPromoSuggest/ReviewPromoSuggest.vue' /* webpackChunkName: "components/review-promo-suggest" */).then(c => wrapFunctional(c.default || c))
export const UserBonusCardForm = () => import('../../components/user/UserBonusCardForm/UserBonusCardForm.vue' /* webpackChunkName: "components/user-bonus-card-form" */).then(c => wrapFunctional(c.default || c))
export const UserBonusesAwaiting = () => import('../../components/user/UserBonusesAwaiting/UserBonusesAwaiting.vue' /* webpackChunkName: "components/user-bonuses-awaiting" */).then(c => wrapFunctional(c.default || c))
export const UserConfirmCode = () => import('../../components/user/UserConfirmCode/UserConfirmCode.vue' /* webpackChunkName: "components/user-confirm-code" */).then(c => wrapFunctional(c.default || c))
export const UserMenu = () => import('../../components/user/UserMenu/UserMenu.vue' /* webpackChunkName: "components/user-menu" */).then(c => wrapFunctional(c.default || c))
export const UserOrderFilter = () => import('../../components/user/UserOrderFilter/UserOrderFilter.vue' /* webpackChunkName: "components/user-order-filter" */).then(c => wrapFunctional(c.default || c))
export const UserOrderRejectForm = () => import('../../components/user/UserOrderRejectForm/UserOrderRejectForm.vue' /* webpackChunkName: "components/user-order-reject-form" */).then(c => wrapFunctional(c.default || c))
export const UserPanel = () => import('../../components/user/UserPanel/UserPanel.vue' /* webpackChunkName: "components/user-panel" */).then(c => wrapFunctional(c.default || c))
export const UserPanelMobile = () => import('../../components/user/UserPanelMobile/UserPanelMobile.vue' /* webpackChunkName: "components/user-panel-mobile" */).then(c => wrapFunctional(c.default || c))
export const UserPopup = () => import('../../components/user/UserPopup/UserPopup.vue' /* webpackChunkName: "components/user-popup" */).then(c => wrapFunctional(c.default || c))
export const UserPopupItem = () => import('../../components/user/UserPopupItem/UserPopupItem.vue' /* webpackChunkName: "components/user-popup-item" */).then(c => wrapFunctional(c.default || c))
export const UserProfileReview = () => import('../../components/user/UserProfileReview/UserProfileReview.vue' /* webpackChunkName: "components/user-profile-review" */).then(c => wrapFunctional(c.default || c))
export const UserQuickNavigation = () => import('../../components/user/UserQuickNavigation/UserQuickNavigation.vue' /* webpackChunkName: "components/user-quick-navigation" */).then(c => wrapFunctional(c.default || c))
export const DeliveryBlock = () => import('../../components/user-dashboard/DeliveryBlock/DeliveryBlock.vue' /* webpackChunkName: "components/delivery-block" */).then(c => wrapFunctional(c.default || c))
export const UserBonusExpired = () => import('../../components/user-dashboard/UserBonusExpired/UserBonusExpired.vue' /* webpackChunkName: "components/user-bonus-expired" */).then(c => wrapFunctional(c.default || c))
export const UserInfoCheck = () => import('../../components/user-dashboard/UserInfoCheck/UserInfoCheck.vue' /* webpackChunkName: "components/user-info-check" */).then(c => wrapFunctional(c.default || c))
export const UserPersonalCard = () => import('../../components/user-dashboard/UserPersonalCard/UserPersonalCard.vue' /* webpackChunkName: "components/user-personal-card" */).then(c => wrapFunctional(c.default || c))
export const CompareInlineTool = () => import('../../components/UserTools/tools/CompareInlineTool/CompareInlineTool.vue' /* webpackChunkName: "components/compare-inline-tool" */).then(c => wrapFunctional(c.default || c))
export const FavoriteInlineTool = () => import('../../components/UserTools/tools/FavoriteInlineTool/FavoriteInlineTool.vue' /* webpackChunkName: "components/favorite-inline-tool" */).then(c => wrapFunctional(c.default || c))
export const DataEntity = () => import('../../data/DataEntity/DataEntity.vue' /* webpackChunkName: "components/data-entity" */).then(c => wrapFunctional(c.default || c))
export const DataEntityList = () => import('../../data/DataEntityList/DataEntityList.vue' /* webpackChunkName: "components/data-entity-list" */).then(c => wrapFunctional(c.default || c))
export const UIActionList = () => import('../../data/UIActionList/UIActionList.vue' /* webpackChunkName: "components/u-i-action-list" */).then(c => wrapFunctional(c.default || c))
export const UIActionEntity = () => import('../../data/UIActionEntity/UIActionEntity.vue' /* webpackChunkName: "components/u-i-action-entity" */).then(c => wrapFunctional(c.default || c))
export const ActionCart = () => import('../../action/ActionCart.vue' /* webpackChunkName: "components/action-cart" */).then(c => wrapFunctional(c.default || c))
export const ActionCompare = () => import('../../action/ActionCompare.vue' /* webpackChunkName: "components/action-compare" */).then(c => wrapFunctional(c.default || c))
export const ActionEntity = () => import('../../action/ActionEntity.vue' /* webpackChunkName: "components/action-entity" */).then(c => wrapFunctional(c.default || c))
export const ActionFavorite = () => import('../../action/ActionFavorite.vue' /* webpackChunkName: "components/action-favorite" */).then(c => wrapFunctional(c.default || c))
export const ActionList = () => import('../../action/ActionList.vue' /* webpackChunkName: "components/action-list" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}

/** Определяет, нужно ли отображать блок с гарантией Apple у товара и категории, или нет.
 * Блок должен показываться на странице товара и на странице категории с фильтрами только определенных категорий Apple (айфоны, айпады, макбуки, аймаки, аирподсы, вотчи), и должен быть скрыт у акссессуаров и периферии Apple.
 * Временный хелпер, до момента пока это не будет реализовано в апи.
 * @property {string} category - название товара или категории каталога
 * @property {string} path - url страницы товара или категории каталога
 */

export default function isAppleWarranty({ category, path }: { category: string; path: string }) {
  const categories = ['AirPods', 'iPad', 'iPhone', 'MacBook', 'iMac', 'Apple Watch']
  const ignoredPages = ['aksessuary_dlya_gadghetov', 'periferiya']
  return categories.some(q => category && category.includes(q)) && !ignoredPages.some(q => path && path.includes(q))
}

const NOTIFY_KEY = 'notify'

interface Notification {
  key: string
  id?: number
  remind?: number
  date?: number
  payload?: any
  timestamp?: number
}

function add({ key, payload, remind }: Notification) {
  const savedNotify: { [key: string]: any }[] = localStorage ? (JSON.parse(localStorage.getItem(`${NOTIFY_KEY}-${key}`)!) || []) : []
  localStorage.setItem(`${NOTIFY_KEY}-${key}`, JSON.stringify([...savedNotify, { timestamp: Date.now(), id: Date.now(), remind, payload }]))
}

function remove({ key, id }: Notification) {
  const savedNotify: { [key: string]: any }[] = localStorage ? (JSON.parse(localStorage.getItem(`${NOTIFY_KEY}-${key}`)!) || []) : []
  localStorage.setItem(`${NOTIFY_KEY}-${key}`, JSON.stringify([...savedNotify.filter(n => n.id !== id)]))
}

function later({ key, id, remind }: Notification) {
  const savedNotify: { [key: string]: any }[] = localStorage ? (JSON.parse(localStorage.getItem(`${NOTIFY_KEY}-${key}`)!) || []) : []
  const notify = savedNotify.find(n => n.id === id)
  if (notify) {
    remove({ key, id })
    add({
      key,
      timestamp: Date.now(),
      remind: remind || notify.remind,
      payload: notify.payload,
    })
  }
  else {
    remove({ key, id })
  }
}

function removeAll({ key }: Notification) {
  localStorage.removeItem(`${NOTIFY_KEY}-${key}`)
}

function timeHasCome({ key }: Notification) {
  const notifications = localStorage ? (JSON.parse(localStorage.getItem(`${NOTIFY_KEY}-${key}`)!) || []) : []
  const timestamp = Date.now()
  /*
  * Фильтруем напоминания по productId, чтобы уведомления были только на уникальные товары
  */
  return notifications.filter((notify: any) => +notify.timestamp + +notify.remind < timestamp).reduce((acc: Notification[], item: Notification) => {
    if (!acc.find(i => i.payload?.productId === item.payload.productId))
      acc.push(item)
    return acc
  }, [])
}

export { add, remove, later, timeHasCome, removeAll }
export default add

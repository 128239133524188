import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import introspectionQueryResultData from './fragmentTypes.json'

export default defineNuxtPlugin(({ app }): any => {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  })

  return {
    httpEndpoint: app.$config.graphqlUrl,
    getAuth: () => app.$pinia?.state.value.token.accessToken.value,
    cache: new InMemoryCache({
      fragmentMatcher,
    }),
  }
})

import type { Context } from '@nuxt/types'
import constructor from './constructor.api'
import catalog from './catalog.api'
import sale from './sale.api'
import review from './review.api'
import search from './search.api'
import question from './question.api'
import location from './location.api'
import auth from './auth.api'
import bonus from './bonus.api'
import cart from './cart.api'
import slider from './slider.api'
import order from './order.api'
import user from './user.api'
import promo from './promo.api'
import game from './game.api'
import feedback from './feedback.api'
import product from './product.api'
import brand from './brand.api'
import compare from './compare.api'
import favorite from './favorite.api'
import geo from './geo.api'
import about from './about.api'
import installment from './installment.api'
import support from './support.api'
import pcBuild from './pcBuild.api'
import userAddress from './userAddress.api'
import google from './google.api'
import socialNetworks from './socialNetworks.api'
import promocode from './promocode.api'

function mapBindMethods<T extends { [k in keyof T]: T[k] extends Function ? Function : never }>(ctx: Context, methods: T): T {
  const tmp = { ...methods }
  for (const key in methods)
    tmp[key] = methods[key].bind(ctx)

  return tmp
}

export default function generateApi(context: Context) {
  return {
    product: mapBindMethods(context, product),
    constructor: mapBindMethods(context, constructor),
    catalog: mapBindMethods(context, catalog),
    sale: mapBindMethods(context, sale),
    review: mapBindMethods(context, review),
    search: mapBindMethods(context, search),
    question: mapBindMethods(context, question),
    location: mapBindMethods(context, location),
    auth: mapBindMethods(context, auth),
    bonus: mapBindMethods(context, bonus),
    cart: mapBindMethods(context, cart),
    slider: mapBindMethods(context, slider),
    order: mapBindMethods(context, order),
    socialNetworks: mapBindMethods(context, socialNetworks),
    user: mapBindMethods(context, user),
    game: mapBindMethods(context, game),
    feedback: mapBindMethods(context, feedback),
    promo: mapBindMethods(context, promo),
    brand: mapBindMethods(context, brand),
    compare: mapBindMethods(context, compare),
    favorite: mapBindMethods(context, favorite),
    geo: mapBindMethods(context, geo),
    about: mapBindMethods(context, about),
    installment: mapBindMethods(context, installment),
    support: mapBindMethods(context, support),
    pcBuild: mapBindMethods(context, pcBuild),
    userAddress: mapBindMethods(context, userAddress),
    google: mapBindMethods(context, google),
    promocode: mapBindMethods(context, promocode),
  }
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    attrs: {
      "show": _vm.show
    },
    on: {
      "close": _vm.onClose
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('div', {
          staticClass: "h5 mt-1"
        }, [_vm._v("\n      Поделиться информацией в социальной сети\n    ")])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c('div', {
    staticClass: "social-share"
  }, [_vm.name ? _c('div', {
    staticClass: "social-share__desc"
  }, [_vm._v("\n      О товаре «" + _vm._s(_vm.name) + "».\n    ")]) : _vm._e(), _vm._v(" "), _vm._l([{
    name: 'Вконтакте',
    slug: 'vk',
    icon: 'vk'
  }, {
    name: 'WhatsApp',
    slug: 'wa',
    icon: 'whatsapp'
  }, // Комментим до лучших времён
  // { name: 'Twitter', slug: 'tw', icon: 'twitter' },
  {
    name: 'Telegram',
    slug: 'tg',
    icon: 'telegram'
  } ], function (item, index) {
    return _c('a', {
      key: index,
      staticClass: "social-share__el",
      on: {
        "click": function ($event) {
          return _vm.onClick(item.slug);
        }
      }
    }, [_c('div', {
      staticClass: "social-share__icon",
      class: [("social-share__icon_" + (item.icon))]
    }), _vm._v(" "), _c('div', {
      staticClass: "social-share__text"
    }, [_vm._v(_vm._s(item.name))])]);
  }), _vm._v(" "), _c('CopyText', {
    staticClass: "social-share__el social-share__el_share",
    attrs: {
      "copy": _vm.locationPath + _vm.utm
    },
    scopedSlots: _vm._u([{
      key: "notCopied",
      fn: function () {
        return [_c('div', {
          staticClass: "social-share__icon social-share__icon_share"
        }), _vm._v(" "), _c('div', {
          staticClass: "social-share__text"
        }, [_vm._v("\n          Скопировать ссылку\n        ")])];
      },
      proxy: true
    }, {
      key: "copied",
      fn: function () {
        return [_c('div', {
          staticClass: "social-share__icon social-share__icon_share-green"
        }), _vm._v(" "), _c('div', {
          staticClass: "social-share__text social-share__text_green"
        }, [_vm._v("\n          Ссылка скопирована\n        ")])];
      },
      proxy: true
    }])
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
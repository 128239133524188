
import { Button, Modal } from '05-ui-kit';
import { computed, useRouter } from '@nuxtjs/composition-api';
import LoyaltyProgressBar from '~/components/LoyaltyProgressBar/LoyaltyProgressBar.vue';
import type Achievement from '~/type/Achievement';
const __sfc_main = {};
__sfc_main.props = {
  show: {
    key: "show",
    required: true,
    type: Boolean
  },
  achievement: {
    key: "achievement",
    required: true,
    type: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const router = useRouter();
  function onClose() {
    emit('close');
    if (!props.achievement.isDone) router.push(props.achievement.buttonLink);
  }
  const buttonText = computed(() => {
    return !props.achievement.isDone && props.achievement.buttonLink && props.achievement.buttonText ? props.achievement.buttonText : 'Закрыть';
  });
  return {
    emit,
    onClose,
    buttonText
  };
};
__sfc_main.components = Object.assign({
  Modal,
  LoyaltyProgressBar,
  Button
}, __sfc_main.components);
export default __sfc_main;

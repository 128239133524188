var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bonus-count__wrapper"
  }, [_vm.count ? _c('div', {
    staticClass: "bonus-count",
    class: [("bonus-count_" + (_vm.size)), {
      'bonus-count_active': _vm.bonusData.isActive
    }],
    on: {
      "mouseenter": _vm.bonusData.onMouseEnter,
      "mouseleave": _vm.bonusData.onMouseLeave
    }
  }, [_c('div', {
    ref: "handler",
    class: ("bonus-count__tooltip-head bonus-count__tooltip-head_" + (_vm.size))
  }, [_c('div', {
    staticClass: "bonus-count__text"
  }, [_vm._v("\n        " + _vm._s(_vm.plus ? ("+" + (_vm.count)) : _vm.count) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "bonus-count__img"
  })]), _vm._v(" "), _vm.description ? _c('div', {
    ref: "content",
    staticClass: "bonus-count__tippy"
  }, [_vm._v("\n      Бонусы за покупку будут начислены на вашу карту.\n      "), _c('div', {
    staticClass: "bonus-count__tippy-wrapper"
  }, [_vm._m(0), _vm._v(" "), _vm._m(1), _vm._v(" "), _vm.isGuest ? _c('div', {
    staticClass: "bonus-count__tippy-item",
    on: {
      "click": _vm.showAuthModal
    }
  }, [_vm._m(2)]) : _vm._e()])]) : _vm._e()]) : _vm._e(), _vm._v(" "), _vm.availablePay === false ? _c('div', {
    staticClass: "bonus-count ml-4",
    class: {
      'bonus-count_active': _vm.unavailableBonusData.isActive
    },
    on: {
      "mouseenter": _vm.unavailableBonusData.onMouseEnter,
      "mouseleave": _vm.unavailableBonusData.onMouseLeave
    }
  }, [_c('div', {
    ref: "unavailableHandler",
    staticClass: "bonus-count__unavailable-pay"
  }, [_c('div', {
    staticClass: "bonus-count__unavailable-img"
  })]), _vm._v(" "), _c('div', {
    ref: "unavailableContent",
    staticClass: "bonus-count__tippy"
  }, [_vm._v("\n      Этот товар "), _c('span', [_vm._v("нельзя оплатить бонусами")])])]) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bonus-count__tippy-item"
  }, [_c('div', {
    staticClass: "bonus-count__tippy-text"
  }, [_c('span', [_vm._v("1 "), _c('span', {
    staticClass: "bonus-count__tippy-icon"
  }), _vm._v(" = 1₽")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bonus-count__tippy-item"
  }, [_c('div', {
    staticClass: "bonus-count__tippy-text"
  }, [_vm._v("\n            Оплачивайте "), _c('span', [_vm._v("полную стоимость покупки")]), _vm._v(" бонусами\n          ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bonus-count__tippy-text"
  }, [_c('span', {
    staticClass: "bonus-count__tippy-text-dashed"
  }, [_vm._v("Войдите")]), _vm._v(", чтобы получить бонусную карту\n          ")]);
}]

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    attrs: {
      "show": _vm.show,
      "title": "Отправка жалобы"
    },
    on: {
      "close": _vm.onClose
    }
  }, [_c('ValidationObserver', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('Form', {
          attrs: {
            "type": "vertical"
          },
          on: {
            "submit": function ($event) {
              return handleSubmit(_vm.onSubmit);
            }
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "имя"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var errors = _ref2.errors;
              return [_c('FormElement', {
                attrs: {
                  "title": "Имя",
                  "error": errors[0]
                }
              }, [_c('InputText', {
                model: {
                  value: _vm.fields.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.fields, "name", $$v);
                  },
                  expression: "fields.name"
                }
              })], 1)];
            }
          }], null, true)
        }), _vm._v(" "), _c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "текст"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref3) {
              var errors = _ref3.errors;
              return [_c('FormElement', {
                attrs: {
                  "error": errors[0],
                  "title": "Описание:"
                }
              }, [_c('Textarea', {
                model: {
                  value: _vm.fields.text,
                  callback: function ($$v) {
                    _vm.$set(_vm.fields, "text", $$v);
                  },
                  expression: "fields.text"
                }
              })], 1)];
            }
          }], null, true)
        }), _vm._v(" "), _vm.error ? _c('Note', {
          staticClass: "mb-10",
          attrs: {
            "theme": "reddish"
          }
        }, [_vm._v("\n        " + _vm._s(_vm.response || 'Что-то пошло не так') + "\n      ")]) : _vm._e(), _vm._v(" "), _vm.loading ? _c('Button', {
          attrs: {
            "theme": "red",
            "loading": ""
          }
        }) : _c('Button', [_vm._v("\n        Отправить\n      ")])], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }

import type Brand from '~/type/Brand';
const __sfc_main = {};
__sfc_main.props = {
  list: {
    key: "list",
    required: true,
    type: Array
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  return {
    emit
  };
};
export default __sfc_main;

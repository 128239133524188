var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "loader"
  }, [_c('div', {
    staticClass: "loader__loading",
    style: {
      width: _vm.width,
      height: _vm.height
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "grey-text"
  }, [_vm._v(_vm._s(_vm.title))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-auth"
  }, [_c('div', {
    staticClass: "info-auth__text"
  }, [_c('div', {
    staticClass: "info-auth__text-title"
  }, [_vm._v("\n      Авторизуйтесь\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "info-auth__text-title info-auth__text-title_mobile"
  }, [_vm._v("\n      Войдите\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "info-auth__text-description"
  }, [_vm._v("\n      Для отслеживания заказов\n    ")])]), _vm._v(" "), _c('div', {
    staticClass: "info-auth__img-wrap"
  }, [_c('img', {
    staticClass: "info-auth__img",
    attrs: {
      "src": require("@/assets/images/info-block-authorization.png"),
      "srcset": require("@/assets/images/info-block-authorization.png") + " 1x, " + require("@/assets/images/info-block-authorization2x.png") + " 2x",
      "alt": "Авторизуйтесь"
    }
  })])]);
}]

export { render, staticRenderFns }
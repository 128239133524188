var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-auth"
  }, [_c('div', {
    staticClass: "info-auth__text"
  }, [_c('div', {
    staticClass: "info-auth__title"
  }, [_vm._v("\n      Бонусная регистрация\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "info-auth__description"
  }, [_c('span', {
    staticClass: "description__text"
  }, [_vm._v("Регистрируйтесь и получите")]), _vm._v(" "), _c('span', {
    staticClass: "description__text"
  }, [_vm._v("бонусы")]), _vm._v(" "), _c('img', {
    staticClass: "description__icon",
    attrs: {
      "src": require("@/assets/images/infoblocks-auth/bonus-icon.svg"),
      "alt": "Плюс 300 бонусов"
    }
  })])]), _vm._v(" "), _c('img', {
    staticClass: "info-auth__img",
    attrs: {
      "src": require("@/assets/images/infoblocks-auth/coin.png"),
      "srcset": require("assets/images/infoblocks-auth/coin.png") + " 1x, " + require("assets/images/infoblocks-auth/coin@2x.png") + " 2x, " + require("assets/images/infoblocks-auth/coin@3x.png") + " 3x",
      "alt": "Сверкающая золотая монетка"
    }
  })]);
}]

export { render, staticRenderFns }
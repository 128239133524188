import type { Context } from '@nuxt/types'
import { stringify } from 'qs'
import type { useContext } from '@nuxtjs/composition-api'
import { CalculateSerialize, ProviderSerialize } from '../serializer/pages/Installment'
import { ProductSerialize } from '~/serializer/product/Product'
import type { Product } from '~/type/product/Product'
import type { APIRequestSuccess } from '~/type/APIRequestStatus'
import type Banner from '~/type/Banner'
import BannerSerializer from '~/serializer/Banner'
import { FaqSerializer } from '~/serializer/Faq'

export default {
  async calculate(this: Context, { code, price, period, initial_fee }: { code: string; price: number; period: number; initial_fee: number }) {
    const { $axios } = this
    const { result } = await $axios.$get(
      `/api/v1/installment/calculate?${stringify({
        provider_code: code,
        product_price: price,
        period,
        initial_fee,
      })}`)
    return CalculateSerialize(result)
  },

  async provider(this: Context, { providerCode }: { providerCode: string }) {
    const { $axios } = this
    const { result } = await $axios.$get(
      `/api/v1/installment/providers/${providerCode}`,
    )
    return ProviderSerialize(result)
  },

  async products(this: Context, { providerCode, size, page }: { providerCode: string; size: number; page: 1 }) {
    const { $axios } = this
    const { result } = await $axios.$get<APIRequestSuccess<{ ITEMS: any[] }>>(
      `/api/v1/installment/products?${stringify({
        provider_code: providerCode,
        size,
        page,
      })}`,
    )
    return result.map((item: Product) => ProductSerialize(item))
  },

  async banners(this: Context, { type }: { type?: string }) {
    const { $axios } = this
    const { result } = await $axios.$get<APIRequestSuccess<any[]>>(
      `/api/v1/banners?${stringify({
        action_type: type,
      })}`,
    )
    return Array.isArray(result) ? result.map((item: Banner) => BannerSerializer(item)) : []
  },
  async sendInstallment(this: Context | ReturnType<typeof useContext>,
    { phone, productId, comment }: { phone: string; productId: number; comment: string },
  ) {
    const { $axios } = this

    const response = (
      await $axios.$post<APIRequestSuccess>(
        'api/v1/installment/requests',
        stringify({
          provider_code: 'Installment05ru',
          phone,
          product_id: productId,
          comment: `Второй номер телефона клиента: ${comment} `,
        }),
      )
    )?.result

    return response
  },
  async getApplicationData(this: Context | ReturnType<typeof useContext>, { orderId }: { orderId: number }) {
    const { $axios } = this

    const { result } = (await $axios.get<{ result: { clientPhone: string } }>(
      `api/v1/service/poscredit/bid-data?${stringify({
        bid_id: orderId,
      })}`,
    )).data

    return {
      result,
    }
  },
  async getQuestions(this: Context) {
    const { $axios } = this

    const response = (await $axios.$get<{ result: any[] }>('/api/v1/installment/faq'))

    return {
      result: response.result.length ? response.result.map(el => FaqSerializer(el)) : [],
    }
  },
}

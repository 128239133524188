import { render, staticRenderFns } from "./GlobalTools.vue?vue&type=template&id=a1ddd616&scoped=true&"
import script from "./GlobalTools.vue?vue&type=script&lang=ts&"
export * from "./GlobalTools.vue?vue&type=script&lang=ts&"
import style0 from "./GlobalTools.vue?vue&type=style&index=0&lang=postcss&"
import style1 from "./GlobalTools.vue?vue&type=style&index=1&id=a1ddd616&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1ddd616",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ToggleChat: require('/app/components/ToggleChat/ToggleChat.vue').default,AuthModal: require('/app/components/AuthModal/AuthModal.vue').default,ActionCompare: require('/app/action/ActionCompare.vue').default,ActionEntity: require('/app/action/ActionEntity.vue').default,SocialShare: require('/app/components/modal/SocialShare/SocialShare.vue').default,ChooseCity: require('/app/components/modal/ChooseCity/ChooseCity.vue').default,Notification: require('/app/components/Notification/Notification.vue').default,EmailConfirm: require('/app/components/modal/EmailConfirm/EmailConfirm.vue').default,UIActionEntity: require('/app/data/UIActionEntity/UIActionEntity.vue').default,AchievementModal: require('/app/components/modal/AchievementModal/AchievementModal.vue').default})

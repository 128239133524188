
import { computed, inject, onBeforeUnmount, ref } from '@vue/composition-api';
import { useContext } from '@nuxtjs/composition-api';
import { useAuthStore } from '~/store/modal/auth';
import { useBonusStore } from '~/store/bonus';
import { useCartStore } from '~/store/cart';
import { useUserStore } from '~/store/user';
import type { EmitteryType } from '~/plugins/emitter';
type RequestCartApiType = Awaited<ReturnType<typeof context.$api.cart.getTotalForUser>>;
type RequestOrderApiType = Awaited<ReturnType<typeof context.$api.order.getOrderStatus>>;
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const loading = ref(true);
  const hideDKBanner = ref(false);
  const error = ref({
    order: false,
    cart: false
  });
  const context = useContext();
  let getCart: () => void;
  const {
    $gb
  } = useContext();
  const bonusStore = useBonusStore();
  const cartStore = useCartStore();
  const userStore = useUserStore();
  const {
    enable: showAuthModal
  } = useAuthStore();
  const bonus = computed(() => bonusStore.bonusData);
  const cartCount = computed(() => cartStore.cartAmount);
  const isUser = computed(() => userStore.isUser);
  const emitter = inject<EmitteryType>('emitter')!;
  const unsubscribe = cartStore.$onAction(mutation => {
    if (mutation.name === 'remove' || mutation.name === 'add') getCart?.();
    if (mutation.name === 'remove' && cartCount.value === 0) loading.value = false;
  });
  onBeforeUnmount(() => {
    if (unsubscribe) unsubscribe();
  });
  function onCartSuccess({
    get
  }: {
    get: () => RequestCartApiType;
  }) {
    getCart = get;
    loading.value = false;
  }
  function onOrderSuccess({
    response
  }: {
    response: RequestOrderApiType;
  }) {
    /**
     * Если заказ завершён или у пользователя в принципе нет заказов, не показываем ему инфо-блок заказа
     * */
    if (response?.order?.status?.typeMain === 'notshow' || !response?.order) error.value.order = true;else hideDKBanner.value = true;
    loading.value = false;
  }
  const showExperiment = ref($gb?.getFeatureValue('auth-banners', 1));
  function onAuthBannerClick() {
    showAuthModal({
      onComplete: () => emitter.emit('mainpage/on-infoblock-auth-click')
    });
    if (showExperiment.value) {
      emitter.emit('mainpage/on-auth-banner-click', {
        experimentId: 'auth-banners',
        variationId: showExperiment.value
      });
    }
  }
  return {
    loading,
    hideDKBanner,
    error,
    bonus,
    isUser,
    onCartSuccess,
    onOrderSuccess,
    showExperiment,
    onAuthBannerClick
  };
};
export default __sfc_main;


import { mapActions, mapState } from 'pinia'
import { defineComponent, inject } from '@vue/composition-api'
import { GGScrollController, GGSectionList } from '@constructor/component'
import type { Context } from '@nuxt/types'
import { Modal } from '05-ui-kit'
import getPageDataByCityId from '~/graphql/PageDataByCityID.gql'
import ErrorSerializer from '~/serializer/Error'
import type { EmitteryType } from '~/plugins/emitter'
import getOpenGraphMarkup from '~/helper/getOpenGraphMarkup'
import { useAuthStore } from '~/store/modal/auth'
import { useLocationStore } from '~/store/location'
import StoriesSlide from '~/components/sliders/StoriesSlide/StoriesSlide.vue'
import { getStatusPromo } from '~/helper/getStatusPromo'
import MainPromoBanners from '~/components/nam20/MainPromoBanners/index.vue'

export default defineComponent({
  name: 'IndexPage',
  components: {
    GGSectionList,
    GGScrollController,
    StoriesSlide,
    MainPromoBanners,
    Modal,
  },
  transition: 'fade',
  setup() {
    const emitter = inject<EmitteryType>('emitter')!

    function promoStatus(this: Context) {
      return getStatusPromo({ startDate: '2024.03.10', endDate: '2024.04.15' })
    }
    return { emitter, promoStatus }
  },
  async asyncData({ app, $pageError }) {
    try {
      return {
        response: (await app.$api.constructor.main())?.result,
      }
    }
    catch (e) {
      new $pageError({ ...ErrorSerializer(e), message: 'Ошибка при загрузке' })
    }
  },
  data(): { response: any; getPageDataByCityId: any; offset: number; loading: boolean; advertisingActive: boolean; advertising: string; locationStore?: ReturnType<typeof useLocationStore> } {
    return {
      response: null,
      getPageDataByCityId: null,
      offset: 0,
      loading: false,
      advertisingActive: false,
      advertising: '',
    }
  },
  head(): { [key: string]: unknown } {
    return {
      meta: getOpenGraphMarkup({
        type: 'website',
        url: this.$nuxt.context.env.projectURL,
        title: '05.ru – интернет-магазин',
        image: this.$nuxt.context.env.projectURL + require('~/assets/images/05-avatar.png'),
        siteName: 'Интернет-магазин 05ру',
        description: 'Интернет - Магазин 05.ru предлагает вашему вниманию широчайший выбор электроники и бытовой техники. Мы гарантируем качество по самым низким ценам в Дагестане. Доставка бесплатная',
      }),
    }
  },
  computed: {
    meta() {
      return {
        isUser: !!this.$pinia.state.value.user.user,
      }
    },
    ...mapState(useLocationStore, {
      cityId: store => store.currentCity?.id,
    }),
  },
  watch: {
    cityId(newCityId) {
      if (newCityId)
        this.enable()
    },
  },
  created() {
    /**
     * Ставим категорию телефонов первой в списке
     */
    this.response.category = this.response.category.sort((a: { id: string }) => {
      return +a.id === 705 ? -1 : 1
    })
  },
  mounted() {
    if (this.cityId) {
      this.enable()
    }

    else {
      this.locationStore?.$onAction((mutation) => {
        if (mutation.name === 'setCurrentCity')
          this.enable()
      })
    }
  },
  methods: {
    ...mapActions(useAuthStore, { showAuthModal: 'enable' }),
    async enable() {
      /**
       * TODO заменить при переход на вью 3
       * Идиотизм с кастомной переменной для лоадинга нужен потому что
       * встроенный в пакет vue-apollo не работает, прикольно придумали
       */
      this.loading = true
      try {
        const response = await this.request()
        this.getPageDataByCityId = response.data.getPageDataByCityId
      }
      catch (e) {
        new this.$simpleError({
          message: 'Ошибка при загрузке секций конструктора',
          native: e,
        })
      }
      this.loading = false
    },
    request() {
      return this.$apollo.query({
        query: getPageDataByCityId,
        variables: {
          id: this.cityId,
          offset: this.offset,
        },
      })
    },
    async loadMore() {
      this.loading = true
      this.offset++

      try {
        const response = await this.request()
        const data = response.data.getPageDataByCityId

        if (!data.pageInfo.hasNextPage && this.meta.isUser) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error
          // @ts-ignore
          this.$api.user.addUserAchievement({ code: 'deeper_issledovatel' })
        }

        this.getPageDataByCityId = {
          ...data,
          items: [...this.getPageDataByCityId.items, ...data.items],
        }
      }
      catch (e) {
        new this.$simpleError({
          message: 'Ошибка при загрузке секций конструктора',
          native: e,
        })
      }

      this.loading = false
    },
    onAuthNeeded() {
      this.showAuthModal({
        subtitle: 'Войдите, чтобы получить бонусную карту, карта будет привязана к номеру телефона',
      })
    },
    onProductCartAddOrRemove(type: 'add' | 'remove', id: number) {
      this.emitter.emit(`cart-product-${type}`, {
        product: {
          id,
          quantity: 1,
        },
      })
    },
    onCategoryClick() {
      this.emitter.emit('mainpage/on-category-click')
    },
    saleSelect() {
      this.emitter.emit('mainpage/on-sale-banner-click')
    },
  },
})

import type Achievement from '~/type/Achievement'

export default function (el: any): Achievement[] {
  return el.map((achievement: any) => {
    return {
      title: achievement.NAME,
      description: achievement.DESCRIPTION,
      buttonLink: achievement.LINK,
      buttonText: achievement.BUTTON_TEXT,
      img: achievement.ICON,
      currentProgress: achievement.CURRENT_PROGRESS,
      targetProgress: achievement.TARGET_PROGRESS,
      isDone: achievement.IS_DONE,
    }
  })
}

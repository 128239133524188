/**
 * Настраиваем работу аксиоса:
 * 1. Добавляем таймаут для запросов, иначе порой они слишком долго висят
 * 2. Добавляем опциональное кеширование для запросов, для его активации нужно передать доп параметры
 * 3. Добавляем трассировку. Получаем traceparent из заголовков nginx и сохраняем его в store.
 *    После этого, добавляем traceparent в заголовки при каждом запросе axios. При этом, при каждой смене
 *    роута, мы генерируем новый TraceId и обновляем его в TraceParent
 * 4. Добавляем mindboxDeviceUUID для mindbox событий
 */

import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import { AWSXRayIdGenerator } from '@opentelemetry/id-generator-aws-xray'
import axiosCacheAdapter from '~/modules/nodeCache/axiosCacheAdapter'
import { useSharedStore } from '~/store/shared'

export default defineNuxtPlugin((ctx) => {
  ctx.$axios.defaults.timeout = 20000
  const sharedStore = useSharedStore(ctx.$pinia)

  if (process.server) {
    const cache = (ctx.ssrContext as SSRContext).axiosCache

    if (ctx.$axios.defaults.adapter && cache)
      ctx.$axios.defaults.adapter = axiosCacheAdapter(ctx.$axios.defaults.adapter, cache)
    if (ctx.req.headers.traceparent && !Array.isArray(ctx.req.headers.traceparent))
      sharedStore.setTraceParent(ctx.req.headers.traceparent)
  }
  const idGenerator = new AWSXRayIdGenerator()
  let baseRequestUrl = ctx.route?.path

  ctx.$axios.onRequest((config) => {
    const traceIdRegEx = /[^-]*[^-]/g
    let traceparent = sharedStore.traceParent
    const traceParentMatch = traceparent?.match(traceIdRegEx)

    if (baseRequestUrl !== ctx.route.path && traceParentMatch && traceParentMatch[1]) {
      const newTraceId = idGenerator.generateTraceId()
      baseRequestUrl = ctx.route.path
      traceparent = sharedStore.traceParent?.replace(traceParentMatch[1], newTraceId)
      if (traceparent)
        sharedStore.setTraceParent(traceparent)
    }

    const deviceUUID = ctx.app.$cookies.get('mindboxDeviceUUID')

    if (!config.headers.common)
      config.headers.common = {}

    if (traceparent)
      config.headers.common.traceparent = traceparent

    if (deviceUUID)
      config.headers.common['Device-Uuid'] = deviceUUID
  })
})

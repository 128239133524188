import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import { useSharedStore } from '~/store/shared'

export default defineNuxtMiddleware ((context) => {
  if (process.client) {
    const sharedStore = useSharedStore(context.$pinia)
    const isOffline = window?.$nuxt?.isOffline

    if (isOffline !== context.$pinia.state.value.shared.isOffline)
      sharedStore.setIsOffline(isOffline)
  }
})

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "search-bar",
    class: [{
      'search-bar_show-quicksearch': _vm.isVisible
    }]
  }, [_c('div', {
    staticClass: "search-bar__main-field"
  }, [_c('div', {
    staticClass: "search-bar__quicksearch-wrapper"
  }, [_c('div', {
    staticClass: "search-bar__field-wrapper"
  }, [_c('label', {
    staticClass: "search-bar__field"
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "search-bar__input-icon search-bar__input-icon_loading icon-loading-oval"
  }), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.query,
      expression: "query",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "search-bar__input",
    attrs: {
      "type": "text",
      "placeholder": _vm.declCityName ? ("Быстрый поиск в " + (_vm.declCityName)) : 'Быстрый поиск'
    },
    domProps: {
      "value": _vm.query
    },
    on: {
      "keyup": [_vm.debouncedOnInput, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.searchData.apply(null, arguments);
      }],
      "focus": _vm.init,
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.query = $event.target.value.trim();
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.query && !_vm.loading,
      expression: "query && !loading"
    }],
    staticClass: "search-bar__input-icon search-bar__input-icon_close",
    on: {
      "click": function ($event) {
        _vm.query = '';
      }
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "search-bar__input-icon search-bar__input-icon_search"
  })])]), _vm._v(" "), _c('div', {
    staticClass: "search-bar__quicksearch-list-wrap"
  }, [_c('div', {
    staticClass: "search-bar__mobile-search"
  }, [_c('div', {
    staticClass: "search-bar__mobile-search-icon-arrow",
    on: {
      "click": _vm.hideModal
    }
  }), _vm._v(" "), _c('label', {
    staticClass: "search-bar__mobile-search-field"
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "search-bar__mobile-input-icon icon-loading-oval"
  }), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.query,
      expression: "query",
      modifiers: {
        "trim": true
      }
    }],
    ref: "mobileInput",
    staticClass: "search-bar__mobile-input",
    attrs: {
      "type": "text",
      "placeholder": _vm.declCityName ? ("Быстрый поиск в " + (_vm.declCityName)) : 'Быстрый поиск'
    },
    domProps: {
      "value": _vm.query
    },
    on: {
      "keyup": [_vm.debouncedOnInput, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.searchData.apply(null, arguments);
      }],
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.query = $event.target.value.trim();
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.query,
      expression: "query"
    }],
    staticClass: "search-bar__mobile-search-icon-close",
    on: {
      "click": _vm.onClickClear
    }
  })]), _vm._v(" "), _vm.loading && _vm.query ? _c('div', {
    staticClass: "search-bar__quicksearch-error p"
  }, [_vm._v("\n          Ищем ...\n        ")]) : _vm.isNotProducts ? _c('div', {
    staticClass: "search-bar__no-results p grey-text"
  }, [_c('p', [_vm._v("По запросу «" + _vm._s(_vm.query) + "» нет результатов")])]) : _vm.searchResult ? [_vm.query ? [Array.isArray(_vm.searchHints) && _vm.searchHints.length ? _c('div', {
    staticClass: "search-bar__hints p"
  }, _vm._l(_vm.searchHints, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "search-bar__hints-el"
    }, [_c('nuxt-link', {
      staticClass: "link link_wu search-bar__hints-link",
      attrs: {
        "to": item.path,
        "title": item.name
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.onSearchHintClick({
            index: index,
            item: item
          });
        }
      }
    }, [_c('span', {
      staticClass: "search-bar__hints-span"
    }, [_vm._v(_vm._s(item.name))])]), _vm._v(" "), _c('div', {
      staticClass: "search-bar__hints-el-icon"
    })], 1);
  }), 0) : _vm._e()] : [_vm.savedPhrases.length ? _c('div', {
    staticClass: "search-bar__saved-phrases p"
  }, [_vm._l(_vm.savedPhrases, function (item, index) {
    return [index < 5 ? _c('div', {
      key: item.name,
      staticClass: "search-bar__saved-phrases-el"
    }, [_c('div', {
      key: ("search-" + index),
      staticClass: "icon-search icon-search_black search-bar__saved-phrases-icon search-bar__saved-phrases-icon_search"
    }), _vm._v(" "), _c('nuxt-link', {
      staticClass: "search-bar__saved-phrases-link",
      attrs: {
        "to": item.path,
        "title": item.name
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.onSavedPhrasesClick({
            index: index,
            item: item
          });
        }
      }
    }, [_c('span', {
      staticClass: "search-bar__saved-phrases-span"
    }, [_vm._v(_vm._s(item.name))])]), _vm._v(" "), _c('div', {
      key: index,
      staticClass: "search-bar__saved-phrases-icon search-bar__saved-phrases-icon_close",
      on: {
        "click": function ($event) {
          return _vm.removePhraseStorage(item);
        }
      }
    })], 1) : _vm._e()];
  })], 2) : _vm.popularPhrases.length ? _c('div', {
    staticClass: "search-bar__popular-phrases p"
  }, _vm._l(_vm.popularPhrases, function (item, index) {
    return _c('div', {
      key: item.name,
      staticClass: "search-bar__popular-phrases-el"
    }, [_c('div', {
      staticClass: "icon-search icon-search_black search-bar__popular-phrases-icon search-bar__popular-phrases-icon_search"
    }), _vm._v(" "), _c('nuxt-link', {
      staticClass: "search-bar__popular-phrases-link",
      attrs: {
        "to": item.path,
        "title": item.name
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.onPopularPhrasesClick({
            item: item,
            index: index
          });
        }
      }
    }, [_c('span', {
      staticClass: "search-bar__popular-phrases-span"
    }, [_vm._v(_vm._s(item.name))])]), _vm._v(" "), _c('div', {
      staticClass: "search-bar__popular-phrases-icon search-bar__popular-phrases-icon_arrow"
    })], 1);
  }), 0) : _vm._e()], _vm._v(" "), Array.isArray(_vm.items) && _vm.items.length ? [_c('div', {
    staticClass: "search-bar__quicksearch-list p"
  }, [_c('div', {
    staticClass: "search-bar__quicksearch-list-title"
  }, [_vm._v("\n                " + _vm._s(_vm.itemsTitle) + "\n              ")]), _vm._v(" "), _vm._l(_vm.items, function (item, index) {
    var _item$status, _item$price$discount, _item$price$common, _item$category;

    return _c('div', {
      key: item.id,
      staticClass: "search-bar__quicksearch-el-wrap",
      attrs: {
        "title": item.name
      }
    }, [_c('div', {
      staticClass: "search-bar__quicksearch-el",
      class: [{
        'search-bar__quicksearch-el_brand': (item === null || item === void 0 ? void 0 : (_item$status = item.status) === null || _item$status === void 0 ? void 0 : _item$status.name) === 'brand'
      }]
    }, [_c('div', {
      staticClass: "search-bar__quicksearch-photo-wrap",
      on: {
        "click": function ($event) {
          return _vm.onProductSelectClick({
            index: index,
            item: item
          });
        }
      }
    }, [_c('LazyImg', {
      key: item.id,
      staticClass: "search-bar__quicksearch-photo",
      attrs: {
        "img": item.thumbnail.x1 || item.image,
        "srcset": item.thumbnail.x2,
        "alt": item.name
      }
    })], 1), _vm._v(" "), _c('div', {
      staticClass: "search-bar__quicksearch-text"
    }, [_c('div', {
      staticClass: "search-bar__quicksearch-row"
    }, [item.name ? _c('div', {
      staticClass: "p search-bar__quicksearch-name",
      on: {
        "click": function ($event) {
          return _vm.onProductSelectClick({
            item: item,
            index: index
          });
        }
      }
    }, [_vm._v("\n                        " + _vm._s(item.name) + "\n                      ")]) : _vm._e(), _vm._v(" "), item.price ? _c('div', {
      staticClass: "search-bar__quicksearch-price b"
    }, [(_item$price$discount = item.price.discount) !== null && _item$price$discount !== void 0 && _item$price$discount.value ? _c('span', {
      staticClass: "search-bar__quicksearch-price-discount"
    }, [_vm._v("\n                          " + _vm._s(_vm.getBeautifyPrice(item.price.discount.value.toString())) + "₽\n                        ")]) : _vm._e(), _vm._v(" "), (_item$price$common = item.price.common) !== null && _item$price$common !== void 0 && _item$price$common.value ? _c('span', {
      staticClass: "search-bar__quicksearch-price-common"
    }, [_vm._v("\n                          " + _vm._s(_vm.getBeautifyPrice(item.price.common.value.toString())) + "₽\n                        ")]) : _vm._e()]) : _vm._e()]), _vm._v(" "), (_item$category = item.category) !== null && _item$category !== void 0 && _item$category.name ? _c('div', {
      staticClass: "search-bar__quicksearch-category",
      on: {
        "click": function ($event) {
          var _item$category2;

          return _vm.onCategoryClick((_item$category2 = item.category) === null || _item$category2 === void 0 ? void 0 : _item$category2.path);
        }
      }
    }, [_vm._v("\n                      " + _vm._s(item.category.name) + "\n                    ")]) : _vm._e()]), _vm._v(" "), _c('div', {
      staticClass: "search-bar__quicksearch-arrow"
    })])]);
  })], 2), _vm._v(" "), _vm.query ? _c('nuxt-link', {
    staticClass: "search-bar__quicksearch-show p b",
    attrs: {
      "to": _vm.linkTo,
      "title": "Все результаты"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.allResults();
      }
    }
  }, [_vm._v("\n              Все результаты\n            ")]) : _vm._e()] : _vm._e()] : _vm._e()], 2)])]), _vm._v(" "), _c('button', {
    staticClass: "search-bar__button",
    on: {
      "click": _vm.searchData
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }
import { defineStore } from 'pinia'
import { ref } from '@vue/composition-api'

export const useChooseCityStore = defineStore('chooseCity', () => {
  const active = ref(false)

  function enableChooseCity() {
    active.value = true
  }

  function disableChooseCity() {
    active.value = false
  }

  return { enableChooseCity, disableChooseCity, active }
})

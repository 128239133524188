
/**
 * Экшен для работы с избранным, с помощью него можно легко удалять или добавлять в избранное нужные товары
 */
import { mapState } from 'pinia'
import Tooltip from '05-ui-kit/lib/Tooltip'
import dataActionMixin from '~/mixins/dataActionMixin'
import { useUserStore } from '~/store/user'
import { useUserToolStore } from '~/store/userTool'
import { useUserListStore } from '~/store/userLists'

export default {
  name: 'ActionFavorite',
  mixins: [dataActionMixin],
  inject: ['emitter'],
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    active: {
      type: Boolean,
      default: null,
    },
    /**
     * Товар, будет использоваться для аналитики и для запросов
     */
    product: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Название списка, будет использоваться для аналитики
     */
    listName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      externalAPI: {
        active: this.active,
      },
    }
  },
  computed: {
    ...mapState(useUserStore, {
      isUser: store => store.isUser,
    }),
    ...mapState(useUserListStore, {
      favorite: store => store.favorite,
    }),
    isActive() {
      return !!this.favorite[this.id]
    },
  },
  watch: {
    isActive(active) {
      this.externalAPI.active = active
    },
  },
  created() {
    this.userToolStore = useUserToolStore(this.$pinia)
    this.userListStore = useUserListStore(this.$pinia)

    this.appendMethodToAPI({
      name: 'add',
      fn: () => {
        this.request({
          api: () => {
            /* Ограничение на длину пользовательских списков определил бизнес, сейчас лимит 100 */
            if (this.userListStore.favoriteAmount >= 100 && !this.$cookies.get('devMode')) {
              return Tooltip({
                type: 'error',
                title: 'Ошибка',
                description: 'Превышен лимит товаров в избранном',
                mobileOffset: [5, 5, 63, 5],
              })
            }
            else if (this.isUser) {
              return this.$api.favorite.add(this.id)
            }
            else {
              return Promise.resolve('success')
            }
          },
          onSuccess: (data) => {
            /* Ограничение на длину пользовательских списков определил бизнес, сейчас лимит 100  */
            if (this.userListStore.favoriteAmount < 100 || this.$cookies.get('devMode')) {
              this.userListStore.add({ type: 'favorite', id: this.id })
              this.externalAPI.response = data
              this.externalAPI.active = true

              this.userToolStore.onChangeFavorite()

              this.emitter.emit('add-to-favorite', { product: this.product, listName: this.listName, index: this.index })
            }
          },
          onError: (error) => {
            this.userToolStore.closeAllTools()

            this.externalAPI.response
              = error?.response?.data?.error?.description || 'Ошибка при добавлении товара в избранное, попробуйте позже'
            Tooltip({ type: 'error', title: 'Ошибка', description: 'Не удалось добавить товар в избранное', mobileOffset: [5, 5, 63, 5] })
          },
        })
      },
    })

    this.appendMethodToAPI({
      name: 'remove',
      fn: ({ id } = {}) => {
        this.request({
          api: () => {
            if (this.isUser)
              return this.$api.favorite.remove(id || this.id)
            else
              return Promise.resolve('success')
          },
          onSuccess: (data) => {
            this.userListStore.remove({ type: 'favorite', id: id || this.id })
            this.externalAPI.response = data
            this.externalAPI.active = false

            this.userToolStore.onChangeFavorite()

            this.emitter.emit('remove-from-favorite', (id || this.id))
          },
          onError: (error) => {
            this.userToolStore.onChangeFavorite()

            this.externalAPI.response
              = error?.response?.data?.error?.description || 'Ошибка при удалении товара из избранного, попробуйте позже'
            Tooltip({ type: 'error', title: 'Ошибка', description: 'Не удалось удалить товар из избранного', mobileOffset: [5, 5, 63, 5] })
          },
        })
      },
    })

    this.appendMethodToAPI({
      name: 'removeProducts',
      fn: () => {
        this.request({
          api: () => {
            if (this.isUser)
              return this.$api.favorite.removeAll()
            else
              return Promise.resolve('success')
          },
          onSuccess: (data) => {
            this.userListStore.removeProducts('favorite')
            this.externalAPI.response = data
            this.externalAPI.active = false
          },
          onError: (error) => {
            this.externalAPI.response
              = error?.response?.data?.error?.description || 'Ошибка при удалении товаров из избранного, попробуйте позже'
            Tooltip({ type: 'error', title: 'Ошибка', description: 'Не удалось удалить товары из избранного', mobileOffset: [5, 5, 63, 5] })
          },
        })
      },
    })
  },
  mounted() {
    if (this.externalAPI.active === null)
      this.externalAPI.active = this.favorite[this.id] || false
  },
}

import type Sale from '~/type/Sale'

export function SaleSerialize(el: { [key: string]: any } = {}): Sale {
  return {
    id: el.ID,
    name: el.NAME,
    code: el.CODE,
    marker: {
      name: el.STICKER_TEXT,
      background: el.COLOR,
      text: el.STICKER_TEXT_COLOR,
      border: el.STICKER_BORDER_COLOR,
    },
  }
}

import Comment from './Comment'
import type { Review } from '~/type/Review'

export default function (data: any): Review {
  return {
    ...Comment(data),
    rate: +data.UF_REVIEW_RATE || 0,
    image: data.UF_REVIEW_IMAGES || [],
    comment: data.UF_REVIEW_COMMENTS?.map((item: any) => Comment(item)) || [],
    advantage: data.UF_ADVANTAGES,
    limitation: data.UF_LIMITATIONS,
    status: data.UF_REVIEW_STATUS,
    product: {
      id: +data.UF_PRODUCT_ID,
      name: data.UF_PRODUCT_NAME,
      path: data.UF_PRODUCT_LINK,
      image: data.UF_PRODUCT_IMAGE,
      price: data.UF_PRODUCT_PRICE,
    },
  }
}

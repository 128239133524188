import type { CheckoutPayment } from '~/type/Checkout'

export function CheckoutPaymentSeriazlier(el: { [key: string]: any }): CheckoutPayment {
  return {
    id: +el.ID,
    title: el.NAME,
    description: getDescription(el.NAME),
    image: el.LOGOTIP ?? null,
  }
}
function getDescription(title: string) {
  if (title === 'Сбербанк Онлайн') {
    return `
        <b>QR код появится после оформления заказа</b>, отсканируйте его с помощью камеры вашего смартфона или приложения вашего банка Сбербанк или Тинькофф.
        <b>После перехода в приложение банка</b>, проверьте стоймость вашего заказ и подтвердите платеж.`
  }
  else if (title === 'Рассрочка') {
    return `
        Окно калькулятора появится после оформления заказа, <b>рассчитайте стоимость на  рассрочку ваших товаров на калькуляторе</b>, изменяя параметры срока оформления и первого взноса.
        <b>Заполните максимально подробно форму</b> с персональными данными.
        <b>Ожидайте звонка</b> специалиста.
      `
  }
  else if (title === 'Наличными при получении') {
    return 'В магазине 05.ru'
  }
}

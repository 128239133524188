import { defineStore } from 'pinia'
import { ref } from '@vue/composition-api'

export const useFoundErrorStore = defineStore('foundError', () => {
  const active = ref(false)
  const productID = ref<number>()

  function enableFoundError(value: number) {
    productID.value = value
    active.value = true
  }

  function disableFoundError() {
    productID.value = undefined
    active.value = false
  }

  return { enableFoundError, disableFoundError, active, productID }
})


import { mapState } from 'pinia'
import { useSharedStore } from '~/store/shared'

export default {
  name: 'DefaultLayout',
  props: {
    contentPadding: {
      type: String,
      default: '8px 20px 35px',
    },
  },
  head() {
    const canonical = this.$getCanonical({ path: this.$route.fullPath, exclude: /\/(catalog.*\d+)\/.+/ })
    return {
      link: canonical ? [canonical] : [],
    }
  },
  computed: {
    ...mapState(useSharedStore, {
      isOffline: store => store.isOffline,
    }),
  },
}

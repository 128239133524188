var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "news-card"
  }, [_c('nuxt-link', {
    directives: [{
      name: "lazy",
      rawName: "v-lazy:background-image",
      value: _vm.image,
      expression: "image",
      arg: "background-image"
    }],
    staticClass: "news-card__photo",
    attrs: {
      "to": _vm.link
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "news-card__title"
  }, [_c('nuxt-link', {
    staticClass: "link link_wu",
    attrs: {
      "to": _vm.link
    }
  }, [_vm._v("\n      " + _vm._s(_vm.name) + "\n    ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
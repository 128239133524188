import { ref } from '@vue/composition-api'
import { defineStore } from 'pinia'

export const useSocialStore = defineStore('social', () => {
  const social = ref({
    telegramGroup: 'https://t.me/the05rushop',
    telegram: 'https://t.me/help05rubot',
    whatsApp: 'https://wa.me/79884498396',
    appStoreUrl: 'https://apps.apple.com/ru/app/05-ru/id1539023015',
    googlePlay: 'https://play.google.com/store/apps/details?id=com.x05ru.release&hl=ru',
    vk: 'https://vk.com/05ru.shop',
    youTube: 'https://www.youtube.com/user/The05ru',
    instagram: 'https://www.instagram.com/05ru/',
    email: 'info@05.ru',
    corpEmail: 'corp@05.ru',
    phone: '8 (800) 511-05-05',
  })
  return { social }
})

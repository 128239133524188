import type { Events } from '~/plugins/emitter'
import type { CartProduct } from '~/type/product/Product'

export default function beginCheckout(data: Events['begin-checkout']) {
  let value = 0
  const items = data.productList
    ? data.productList.map((el: CartProduct, i: number) => {
      value += el.price?.common?.value ?? 0
      return {
        item_id: el.path?.match(/\/([^/]+)\/?$/)?.[1],
        item_name: el.name,
        price: el.price?.common?.value,
        item_category: el.category?.name,
        item_variant: el.offer?.length ? el.offer?.map(el => el.value).join(', ') : '',
        quantity: el.quantity,
        index: i + 1,
        promotion_name: el.sale?.length ? el.sale[0].name : '',
        promotion_id: el.sale?.length ? el.sale[0].id.toString() : '',
      }
    })
    : []

  const ecommerce = {
    currency: 'RUB',
    value,
    items,
  }
  return {
    event: 'begin_checkout',
    ecommerce,
  }
}

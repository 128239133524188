
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Button } from '05-ui-kit';
import { computed, inject, onMounted } from '@vue/composition-api';
import { useRouter } from '@nuxtjs/composition-api';
import type { EmitteryType } from '~/plugins/emitter';
const __sfc_main = {};
__sfc_main.props = {
  name: {
    key: "name",
    required: true,
    type: String
  },
  detail: {
    key: "detail",
    required: true,
    type: String
  },
  date: {
    key: "date",
    required: true,
    type: Number
  },
  price: {
    key: "price",
    required: false,
    type: Number
  },
  img: {
    key: "img",
    required: false,
    type: String
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const formatedDate = computed(() => format(props.date, 'd MMMM', {
    locale: ru
  }));
  const emitter = inject<EmitteryType>('emitter')!;
  const router = useRouter();
  onMounted(() => {
    emitter.emit('show-order-review');
  });
  function handleReview(path: string) {
    emitter.emit('click-order-review');
    router.push(`${path}review/create/`);
    emit('remove');
  }
  return {
    emit,
    formatedDate,
    handleReview
  };
};
__sfc_main.components = Object.assign({
  Button
}, __sfc_main.components);
export default __sfc_main;


import debounce from 'lodash.debounce';
interface Props {
  quantity: number;
  min?: number;
  max?: number;
  loading?: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  quantity: {
    key: "quantity",
    required: true,
    type: Number
  },
  min: {
    key: "min",
    required: false,
    type: Number,
    default: 1
  },
  max: {
    key: "max",
    required: false,
    type: Number,
    default: Infinity
  },
  loading: {
    key: "loading",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const emitDebounce = debounce(emit, 300);
  function plus() {
    const newValue = props.quantity + 1;
    emitDebounce('increase', updateValue(newValue));
  }
  function minus() {
    const newValue = props.quantity - 1;
    emitDebounce('decrease', updateValue(newValue));
  }
  function updateValue(val: number) {
    if (val > props.max) return props.max;else return val;
  }
  return {
    plus,
    minus
  };
};
export default __sfc_main;


export default {
  name: 'GGProduct',
  props: {
    /**
     * Объект с товаром
     */
    product: {
      type: Object,
      default: () => {},
    },
    size: {
      type: String,
      validator(val) {
        return ['regular', 'big'].includes(val)
      },
      default: 'regular',
    },
    cart: {
      type: Boolean,
      default: true,
    },
  },
}

import type { Context } from '@nuxt/types'
import SocialNetworkSerializer from '~/serializer/SocialNetwork'

export default {
  async get(this: Context) {
    const { $axios } = this
    const { result } = await $axios.$get('/api/v1/medias/social-networks')
    return {
      result: result?.length ? result.map((el: any) => SocialNetworkSerializer(el)) : [],
    }
  },
}

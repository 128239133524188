
import { Note } from '05-ui-kit';
import { computed } from '@vue/composition-api';
import type { ResponseError } from '~/type/Error';
const __sfc_main = {};
__sfc_main.props = {
  error: {
    key: "error",
    required: true,
    type: Object
  },
  customSerializer: {
    key: "customSerializer",
    required: false,
    type: Function,
    default: undefined
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const innerError = computed(() => {
    if (typeof props.customSerializer === 'function') return props.customSerializer(props.error);else return serialize(props.error.error);
  });
  function serialize(data: ResponseError) {
    return {
      code: data.message,
      title: data.description,
      field: data.fields && getField(data.fields)
    };
  }
  function getField(fields: unknown[]) {
    const arr = [];
    for (const prop in fields) {
      arr.push({
        field: prop,
        description: fields[prop]
      });
    }
    return arr;
  }
  return {
    innerError
  };
};
__sfc_main.components = Object.assign({
  Note
}, __sfc_main.components);
export default __sfc_main;

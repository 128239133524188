import type { Context } from '@nuxt/types'
import PromocodeSerializer from '~/serializer/Promocode'

export default {
  async getByUser(this: Context) {
    const { $axios } = this
    const response: any[] = (await $axios.$get('/api/v1/user/coupons'))?.result

    return {
      result: response.length ? response.map(el => PromocodeSerializer(el)) : [],
    }
  },
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/line-loader.gif");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".info-controller__wrap[data-v-5985ee24]{display:flex;grid-column-gap:15px;-moz-column-gap:15px;column-gap:15px;height:90px}.info-controller__wrap-connected[data-v-5985ee24]{width:100%;max-width:490px}.info-controller__order[data-v-5985ee24]{height:100%}.info-controller__left-side[data-v-5985ee24]{position:relative;height:100%}.info-controller__loading[data-v-5985ee24]{position:absolute;top:0;right:0;bottom:0;left:0;z-index:1;display:flex;align-items:space-between;justify-content:center;height:92px;background:#fff url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:75px;border:1px solid #e5e5e5;border-radius:5px}@media (max-width:1450px){.info-controller__wrap-connected[data-v-5985ee24]{max-width:380px}}@media (max-width:1250px){.info-controller__wrap-connected[data-v-5985ee24]{max-width:350px}}@media (max-width:1000px){.info-controller__wrap-connected[data-v-5985ee24]{max-width:304px}}@media (max-width:900px){.info-controller__wrap[data-v-5985ee24]{grid-gap:5px;gap:5px}.info-controller__wrap-connected[data-v-5985ee24]{max-width:250px}}@media (max-width:900px){.info-controller__loading[data-v-5985ee24]{width:100%}.info-controller__wrap-connected[data-v-5985ee24]{max-width:97px}.info-controller__wrap-connected_full-width[data-v-5985ee24]{max-width:100%}.info-controller_mobile .info-controller__crazy-friday[data-v-5985ee24],.info-controller_mobile .info-controller__dk[data-v-5985ee24],.info-controller_mobile .info-controller__new-year[data-v-5985ee24]{display:none}.info-controller_mobile .info-controller__wrap-connected[data-v-5985ee24]{max-width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

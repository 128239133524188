
import { Modal } from '05-ui-kit';
const __sfc_main = {};
__sfc_main.props = {
  show: {
    key: "show",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  return {};
};
__sfc_main.components = Object.assign({
  Modal
}, __sfc_main.components);
export default __sfc_main;

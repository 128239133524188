
import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'pinia'
import Tooltip from '05-ui-kit/lib/Tooltip'
import { useCartStore } from '~/store/cart'

interface UserCartItem {
  quantity: number
  loading?: boolean
  error?: boolean
}

interface UserCart {
  [key: number]: UserCartItem
}

/**
 * Пайплайн
 * 1. pre hook, проверяем тут например не уперлись ли мы в лимит по корзине
 * 2. вызов целевого действия, например добавление в корзину
 * 3. post hook, вызов шины событий
 */

export default defineComponent({
  data(): { innerState: UserCart } {
    return {
      innerState: {},
    }
  },
  computed: {
    state(): UserCart {
      return { ...this.cart, ...this.innerState }
    },
    ...mapState(useCartStore, {
      cart: store => store.data,
    }),
  },
  methods: {
    ...mapActions(useCartStore, { addToCart: 'add', removeFromCart: 'remove' }),
    async beforeAdd(id: number, quantity = 1) {
      if (this.isAboveMaxAmount())
        throw new Error('Превышен лимит товаров в корзине')

      this.$set(this.innerState, id, {
        quantity,
        loading: true,
      })
      await this.$api.cart.add.call(this.$nuxt.context, { id, quantity })
    },
    async add(id: number, quantity = 1) {
      try {
        await this.beforeAdd(id, quantity)
        this.$set(this.innerState, id, {
          quantity,
        })
        this.afterAdd(id, quantity)
      }
      catch (e) {
        this.showError({
          message: typeof e === 'string' ? e : 'Ошибка при добавлении товара в корзину',
        })
      }
    },
    afterAdd(id: number, quantity = 1) {
      this.addToCart({
        id,
        payload: {
          quantity,
        },
      })
    },
    async beforeRemove(id: number) {
      this.$set(this.innerState, id, {
        loading: true,
      })
      await this.$api.cart.remove.call(this.$nuxt.context, id)
    },
    async remove(id: number) {
      try {
        await this.beforeRemove(id)
        this.$delete(this.innerState, id)
        this.afterRemove(id)
      }
      catch (e) {
        this.showError({
          message: typeof e === 'string' ? e : 'Ошибка при удалении товара из корзины',
        })
      }
    },
    afterRemove(id: number) {
      this.removeFromCart({ id })
    },
    showError({ message, error }: { message: string; error?: Error }) {
      Tooltip({
        type: 'error',
        title: 'Ошибка',
        description: message,
        mobileOffset: [5, 5, 63, 5],
      })
      new this.$baseError({
        message: 'Ошибка при изменении корзины',
        native: error,
      })
    },
    isAboveMaxAmount() {
      return this.cart && Object.keys(this.cart).length >= 200
    },
  },
})

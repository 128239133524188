import type { PromoBrand, PromoBrandSection, PromoBrandStandardType } from '~/type/PromoBrand'

function getBanner(el: { [key: string]: any }): PromoBrandStandardType {
  return {
    title: el.NAME,
    img: el.IMAGE,
    link: linkTransform(el.REF),
  }
}

function linkTransform(link: string) {
  const hostRegExp = /^\w+:\/\/.+\.\w+\//
  let tmp = link || '#'
  if (hostRegExp.test(tmp))
    tmp = tmp.replace(hostRegExp, '/')

  return tmp
}

function getAction(el: { [key: string]: any }): PromoBrandStandardType {
  return {
    title: el.NAME,
    img: el.IMAGE,
    link: linkTransform(el.REF),
  }
}

function getSection(el: { [key: string]: any }): PromoBrandSection {
  return {
    id: el.ID,
    title: el.NAME,
    img: el.IMAGE,
    link: linkTransform(el.REF),
    grid: el.EMPTY_DESCRIPTION_PRODUCTS ? el.EMPTY_DESCRIPTION_PRODUCTS.map((item: any) => getProduct(item)) : [],
    block: el.PRODUCTS ? el.PRODUCTS.map((item: any) => getProduct(item)) : [],
  }
}

function getProduct(el: { [key: string]: any }): PromoBrandStandardType {
  return {
    title: el.NAME,
    img: el.IMAGE,
    link: linkTransform(el.REF),
    description: el.DESC ? el.DESC : null,
  }
}

function getVideo(el: { [key: string]: any }): PromoBrandStandardType {
  return {
    title: el.NAME,
    link: el.REF,
  }
}

export default function (el: { [key: string]: any }): PromoBrand {
  return {
    id: el.ID,
    title: el.NAME,
    code: el.CODE,
    logo: el.LOGO,
    banner: el.BANNERS ? el.BANNERS.map((item: any) => getBanner(item)) : [],
    action: el.ACTIONS ? el.ACTIONS.map((item: any) => getAction(item)) : [],
    section: el.SECTIONS ? el.SECTIONS.map((item: any) => getSection(item)) : [],
    video: el.VIDEOS ? el.VIDEOS.map((item: any) => getVideo(item)) : [],
  }
}

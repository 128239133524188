/**
 * Хелпер для работы с капчей Яндекса
 * Документация капчи (невидимая): https://yandex.cloud/ru/docs/smartcaptcha/concepts/invisible-captcha
 */

import type { SmartCaptchaOptions } from '../index'

/**
 * ID текущей активной капчи
 */
let captchaId = 0

function init() {
  const script = document.createElement('script')
  /**
   * Невидимую капчу нужно подключить расширенным методом, поэтому в параметры onload передаем передаем функцию.
   * https://yandex.cloud/ru/docs/smartcaptcha/concepts/widget-methods#extended-method
   */
  script.src = 'https://smartcaptcha.yandexcloud.net/captcha.js?render=onload&onload=onCreateCaptcha'
  script.async = true
  script.defer = true

  document.head.append(script)
}

function create({
  template = 'captcha-container',
  options,
  onSuccess = () => {},
  onError = () => {},
}: {
  template?: string
  options: SmartCaptchaOptions
  onSuccess?: (token: string) => void
  onError?: () => void
}) {
  captchaId = window.smartCaptcha.render(template, {
    sitekey: options.sitekey,
    invisible: options?.invisible || true,
    hideShield: options?.hideShield || true,
    callback: (token) => {
      onSuccess(token)
    },
  })

  window.smartCaptcha.subscribe(captchaId, 'javascript-error', onError)
}

function execute(uuid: number) {
  window.smartCaptcha.execute(uuid)
}

function destroy(uuid: number) {
  window.smartCaptcha.destroy(uuid)
}

function getToken() {
  return window.smartCaptcha.getResponse(captchaId)
}

function handle<T extends (...args: any[]) => void>(params: { token: string; sitekey: string; callback: T }) {
  /**
   * Так как токен от капчи одноразовый, невозможно отправить один и тот же токен дважды.
   * Поэтому при повторном отправки запроса, капча будет создаваться заново (новая капча), а старая будет удаляться.
   * И есть следующий очень важный момент:
   * После того, как капча создала успешный токен, сервис удаляют/обнуляют отрисованную капчу,
   * сохраняют только токен и повторно получить/перезаписать капчу невозможно, потому что уже запускать нечего,
   * поэтому каждый раз создаем новую капчу.
   */
  if (!params.token) {
    execute(captchaId)
  }
  else {
    /**
    * Удаляем дополнительно вручную, чтобы удалить полностью старую капчу (со всеми обработчиками, токенами и т.д)
    * https://yandex.cloud/ru/docs/smartcaptcha/concepts/widget-methods#destroy
    */
    destroy(captchaId)

    create({
      options: { sitekey: params.sitekey },
      onSuccess: params.callback,
    })
    execute(captchaId)
  }
}

export {
  init,
  create,
  handle,
  execute,
  destroy,
  getToken,
}


import { computed, ref, watch } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Button, Form, FormElement, InputText, Modal, Note, Textarea } from '05-ui-kit';
import { useUserStore } from '~/store/user';
interface SendDataType {
  code: string | number;
  name: string;
  source?: string;
  text?: string;
  email?: string;
}
const __sfc_main = {};
__sfc_main.props = {
  productId: {
    key: "productId",
    required: true,
    type: Number
  },
  show: {
    key: "show",
    required: true,
    type: Boolean
  },
  loading: {
    key: "loading",
    required: false,
    type: Boolean,
    default: false
  },
  success: {
    key: "success",
    required: false,
    type: Boolean,
    default: false
  },
  error: {
    key: "error",
    required: false,
    type: Boolean,
    default: false
  },
  response: {
    key: "response",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const userStore = useUserStore();
  const userName = computed(() => userStore.name);
  const userEmail = computed(() => userStore.user?.email);
  const fields = ref<SendDataType>({
    code: props.productId,
    name: userName.value,
    email: userEmail.value
  });
  watch(() => props.success, val => {
    if (val) fields.value.text = undefined;
  });
  function onSubmit() {
    /**
     * Срабатывает при отправки запроса
     */
    emit('send', fields.value);
  }
  function onClose() {
    /**
     * Срабатывает при нажатии на крестик
     */
    emit('close', false);
  }
  return {
    fields,
    onSubmit,
    onClose
  };
};
__sfc_main.components = Object.assign({
  Modal,
  ValidationObserver,
  Form,
  ValidationProvider,
  FormElement,
  InputText,
  Textarea,
  Note,
  Button
}, __sfc_main.components);
export default __sfc_main;

import { ThumbnailSerializer } from './Thumbnail'
import type ProductBrand from '~/type/ProductBrand'

export function ProductBrandSerializer(el: any): ProductBrand {
  return {
    id: +el.ID,
    name: el.NAME,
    image: el.IMAGE,
    thumbnail: ThumbnailSerializer(el.THUMBNAILS),
    category: {
      name: 'Бренд',
      path: el.LINK,
    },
    path: el.LINK,
    status: {
      type: 'brand',
    },
    price: undefined,
  }
}

import { stringify } from 'qs'
import type { Context } from '@nuxt/types'
import { getFormData } from '@artmizu/utils'
import UserSerializer from '~/serializer/User'
import { BonusCardSerializer } from '~/serializer/BonusCard'
import { PanelSerializer } from '~/serializer/Panel'
import LoyaltyLevelsSerializer from '~/serializer/LoyaltyLevels'
import AchievementsSerializer from '~/serializer/Achievements'
import OrderSerializer from '~/serializer/Order'
import OrderPromoSerializer from '~/serializer/OrderPromo'
import ReviewSerializer from '~/serializer/Review'
import BonusRewardSerializer from '~/serializer/BonusReward'
import type User from '~/type/User'
import type { UserList } from '~/type/UserList'

export default {
  async get(this: Context) {
    const { $axios } = this

    const response = (await $axios.$get('/api/v2/user')).result
    return {
      result: {
        user: response && UserSerializer(response),
        bonus: response?.BONUS_CARD ? BonusCardSerializer(response?.BONUS_CARD) : undefined,
        panel: PanelSerializer(response || {}),
      },
    }
  },

  async getProfile(this: Context) {
    const { $axios } = this

    const response = (await $axios.$get('/api/v1/user/profile')).result
    return {
      lastOrder: response?.ACTIVE_ORDER && OrderSerializer(response.ACTIVE_ORDER),
      lastReview: response?.LAST_REVIEW && ReviewSerializer(response.LAST_REVIEW),
      lastOrderWithoutReview: response?.LAST_ORDER_WITHOUT_REVIEW && OrderPromoSerializer(response.LAST_ORDER_WITHOUT_REVIEW),
      bonusReward: response?.BONUS_REWARDS && BonusRewardSerializer(response.BONUS_REWARDS),
    }
  },

  async getUserLoyaltyLevel(this: Context) {
    const { $axios } = this

    const response = (await $axios.$get('/api/v1/user/loyalty-level')).result

    return {
      result: LoyaltyLevelsSerializer(response),
    }
  },

  async enterLoyaltyProgram(this: Context) {
    const { $axios } = this

    return (
      await $axios.$patch('/api/v1/user/loyalty-level/enter')
    )?.result
  },

  async exitLoyaltyProgram(this: Context) {
    const { $axios } = this

    return (
      await $axios.$patch('/api/v1/user/loyalty-level/exit')
    )?.result
  },

  async getUserAchievements(this: Context) {
    const { $axios } = this

    const response = (await $axios.$get('/api/v1/user/achievement')).result

    return {
      result: AchievementsSerializer(response),
    }
  },

  addUserAchievement(this: Context, { code }: any) {
    const { $axios } = this

    return (
      $axios.$patch(`/api/v1/user/achievement?${stringify({
          code,
        })}`,
      )
    )
  },

  changeUserPersonalInfo(this: Context, { firstName, lastName, secondName }: any) {
    const { $axios } = this

    return $axios.$post(
      '/api/v2/user',
      stringify({
        name: firstName,
        last_name: lastName,
        second_name: secondName,
      }),
    )
  },

  changeEmail(this: Context, { email }: any) {
    const { $axios } = this

    return $axios.$post(
      '/api/v2/user',
      stringify({
        email,
      }),
    )
  },

  addUserAvatar(this: Context, data: File) {
    const { $axios } = this

    return $axios.$post('/api/v2/user', getFormData({ photo: data }), {
      headers: {
        post: null,
      },
    })
  },

  removeUserAvatar(this: Context) {
    const { $axios } = this

    return $axios.$post('/api/v2/user', getFormData({ photo: new File([''], '') }), {
      headers: {
        post: null,
      },
    })
  },

  changePassword(this: Context, { currentPassword, newPassword }: any) {
    const { $axios } = this

    return $axios.$post(
      '/api/v2/user',
      stringify({
        current_password: currentPassword,
        password: newPassword,
      }),
    )
  },

  changeUserPersonal(this: Context, data: User) {
    const { $axios } = this

    return $axios.$post(
      '/api/v2/user',
      stringify({
        name: data.name,
        last_name: data.lastName,
        date: data.birthday,
        gender: data.gender,
        email: data.email,
        telegram: data.socials?.telegram,
      }),
    )
  },

  sync(this: Context, { compare, favorite }: { compare: UserList; favorite: UserList }) {
    const { $axios } = this

    return $axios.$post('/api/v1/user/sync/products', {
      compare: { product: compare },
      favorite: { product: favorite },
    })
  },
}

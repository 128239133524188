import ProductPromoBlockSerializer from '~/serializer/product/ProductPromoBlock'
import SalePromoSerializer from '~/serializer/SalePromo'
import { CategorySerializer } from '~/serializer/Category'
import { ReviewPromoSerializer } from '~/serializer/ReviewPromo'
import BrandSerializer from '~/serializer/Brand'

export default function PageMainSerializer(data: {
  BANNER_SLIDERS: any[]
  CATEGORIES_LIST: any[]
  BRANDS_BLOCK: any[]
  PRODUCTS_BLOCK: any
  PRODUCTS_LIST: any[]
  DISCOUNTS_LIST: any[]
  POPULAR_REVIEWS_BLOCK: {
    ITEMS: any[]
  }
}) {
  let mainSlider = data?.BANNER_SLIDERS?.length ? data.BANNER_SLIDERS.map(el => SalePromoSerializer(el)) : []
  mainSlider = mainSlider.filter((_, index) => index < 10)

  return {
    slider: {
      desktop: mainSlider,
      mobile: mainSlider.filter(el => el.story),
    },
    category: data?.CATEGORIES_LIST?.length ? data.CATEGORIES_LIST.map(el => CategorySerializer(el)) : [],
    brand: data?.BRANDS_BLOCK ? data.BRANDS_BLOCK.map(el => BrandSerializer(el)) : [],
    deal: data?.PRODUCTS_BLOCK ? ProductPromoBlockSerializer(data?.PRODUCTS_BLOCK) : undefined,
    dealList: data?.PRODUCTS_LIST?.length ? data.PRODUCTS_LIST.map(el => ProductPromoBlockSerializer(el)) : [],
    review: data?.POPULAR_REVIEWS_BLOCK?.ITEMS?.length
      ? data.POPULAR_REVIEWS_BLOCK.ITEMS.map(el => ReviewPromoSerializer(el))
      : [],
    sale: data?.DISCOUNTS_LIST?.length ? data.DISCOUNTS_LIST.map(el => SalePromoSerializer(el)) : [],

  }
}


import { computed } from '@vue/composition-api';
import { useRouter } from '@nuxtjs/composition-api';
import { Button } from '05-ui-kit';
import { useUserToolStore } from '~/store/userTool';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const userToolStore = useUserToolStore();
  const router = useRouter();
  const state = computed(() => userToolStore.codeState.type);
  function handleToCode() {
    router.push('/user/promocode/', close);
  }
  function handleMouseEnter() {
    userToolStore.clearTimer();
  }
  function handleMouseLeave() {
    userToolStore.setTimer();
  }
  function close() {
    userToolStore.onAddCode({
      active: false
    });
  }
  return {
    state,
    handleToCode,
    handleMouseEnter,
    handleMouseLeave,
    close
  };
};
__sfc_main.components = Object.assign({
  Button
}, __sfc_main.components);
export default __sfc_main;

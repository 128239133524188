var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    staticClass: "achievement__modal",
    attrs: {
      "show": _vm.show,
      "title": "",
      "max-width": "320px"
    },
    on: {
      "close": function ($event) {
        return _vm.emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "achievement"
  }, [_c('img', {
    staticClass: "achievement__img",
    attrs: {
      "src": _vm.achievement.img
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "achievement__info"
  }, [_c('p', {
    staticClass: "achievement__title"
  }, [_vm._v("\n        " + _vm._s(_vm.achievement.title) + "\n      ")]), _vm._v(" "), _c('p', {
    staticClass: "achievement__desc"
  }, [_vm._v("\n        " + _vm._s(_vm.achievement.description) + "\n      ")]), _vm._v(" "), _vm.achievement.currentProgress && _vm.achievement.targetProgress ? _c('LoyaltyProgressBar', {
    attrs: {
      "current": _vm.achievement.currentProgress,
      "target": _vm.achievement.targetProgress
    }
  }) : _vm._e(), _vm._v(" "), _c('Button', {
    staticClass: "achievement__button",
    attrs: {
      "size": "49"
    },
    on: {
      "click": function ($event) {
        return _vm.onClose();
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.buttonText) + "\n      ")])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
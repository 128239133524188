
import { Button } from '05-ui-kit';
import { watch } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  watch(() => '$nuxt.isOnline', isOnline => {
    if (isOnline) reload();
  });
  function reload() {
    location.reload();
  }
  return {
    reload
  };
};
__sfc_main.components = Object.assign({
  Button
}, __sfc_main.components);
export default __sfc_main;

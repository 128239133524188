import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import refiner from 'refiner-js'
import { useUserStore } from '~/store/user'

declare module '@nuxt/types' {
  interface Context {
    $refiner: ({ type, data: { id, force, phone } }: { type: string; data: { id?: string; force?: boolean; phone?: string } }) => void
  }
}

export default defineNuxtPlugin((context, inject) => {
  inject('refiner', ref)
  const userStore = useUserStore(context)

  function ref({ type, data: { id, force, phone } }: { type: string; data: { id?: string; force?: boolean; phone?: string } }) {
    refiner('setProject', 'f0ea4370-36fa-11ec-b477-fd3314accdff')

    if (userStore.isUser) {
      refiner('identifyUser', {
        id: String(userStore.user?.id),
        email: userStore.user?.email ? userStore.user?.email : undefined,
        name:
          (userStore.user?.name && userStore.user?.lastName)
            ? `${userStore.user.name} ${userStore.user?.lastName}`
            : (userStore.user?.name || userStore.user?.lastName),
        phone: userStore.user?.phone || 'Нет номера телефона',
      })
    }

    if (type === 'identifyUser' && phone) {
      refiner('identifyUser', {
        phone: phone || 'Нет номера телефона',
      })
    }

    if (type === 'showForm' && id)
      refiner('showForm', id, force)

    else
      throw new Error('Не удалось активировать refiner')
  }
})

import type { Promocode } from '~/type/Promocode'

export default function (el: { [key: string]: any }): Promocode {
  return {
    id: el.ID,
    code: el.CODE,
    name: el.NAME,
    description: el.DESCRIPTIONS,
    expirationDate: el.ACTIVE_TO,
  }
}

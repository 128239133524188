
import { ref, shallowRef } from '@vue/composition-api';
interface Props {
  error?: boolean;
  loading?: boolean;
  success?: boolean;
  disabled?: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  error: {
    key: "error",
    required: false,
    type: Boolean,
    default: false
  },
  loading: {
    key: "loading",
    required: false,
    type: Boolean,
    default: false
  },
  success: {
    key: "success",
    required: false,
    type: Boolean,
    default: false
  },
  disabled: {
    key: "disabled",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const count = 4;
  const root = shallowRef();
  const data = ref(Array(count).fill(''));
  function handleInput(index: number, e: Event) {
    if (e.target instanceof HTMLInputElement) {
      if (e.target.value.length > 1) {
        data.value.map((_, index) => root.value[index].disabled = false);
        root.value[data.value.length - 1].focus();
        data.value = e.target.value.substring(0, 4).split('');
      } else if (e.target.value.length <= 1) {
        data.value[index] = e.target.value;
        if (e.target.value !== '') focusNextInput(index);
      }
      if (data.value.length === count) submitData();
    }
  }
  function submitData() {
    emit('update:model-value', data.value.join(''));
  }
  function handleKeyPress(e: KeyboardEvent) {
    // Проверка на ввод только цифр
    const nonDigitPattern = /\D/;
    if (nonDigitPattern.test(e.key) || props.disabled) e.preventDefault();
  }
  function handleKeyDown(index: number, e: KeyboardEvent) {
    // Удаление символов через backspace если пользователь ошибся в вводе
    if (e.key === 'Backspace' && data.value[index] === '') focusPreviousInput(index);
  }
  function handlePaste(e: ClipboardEvent) {
    // Если пользователь решил вставить код из буфера обмена, вместо того чтобы ввести
    e.preventDefault();
    if (props.disabled) return;
    data.value.map((_, index) => root.value[index].disabled = false);
    const copiedData = e.clipboardData?.getData('text/plain').substring(0, 4);
    root.value[data.value.length - 1].focus();
    if (copiedData) {
      data.value = copiedData.split('');
      if (data.value.length === count) submitData();
    }
  }
  function focusNextInput(index: number) {
    if (index < data.value.length - 1) {
      root.value[index + 1].disabled = false;
      root.value[index + 1].focus();
      root.value[index].disabled = true;
    }
  }
  function focusPreviousInput(index: number) {
    if (index > 0) {
      root.value[index - 1].disabled = false;
      root.value[index - 1].focus();
      root.value[index].disabled = true;
    }
  }
  return {
    count,
    root,
    data,
    handleInput,
    handleKeyPress,
    handleKeyDown,
    handlePaste
  };
};
export default __sfc_main;

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._t("default", null, {
    "activate": _vm.activate,
    "reactivate": _vm.reactivate,
    "deactivate": _vm.deactivate,
    "time": _vm.innerTime
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
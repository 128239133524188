// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/svg/sad.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/svg/compare-2.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/svg/favorite-2.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/images/svg/cart-3.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/images/svg/glad.svg");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../assets/images/svg/promo.svg");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../../assets/images/svg/search-error.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".unavailable[data-v-4cddfe32]{display:flex;align-items:center;justify-content:center;width:100%;height:100%;min-height:320px}.unavailable_injected[data-v-4cddfe32]{min-height:auto;padding:35px 10px 37px}.unavailable_injected .unavailable__img[data-v-4cddfe32]{width:90px;height:90px;margin-bottom:10px}.unavailable__wrapper[data-v-4cddfe32]{max-width:300px;text-align:center}.unavailable__button[data-v-4cddfe32]{display:block;max-width:200px;margin:20px auto 0;text-decoration:none}.unavailable__img[data-v-4cddfe32]{width:120px;height:120px;margin:0 auto 20px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:50%;background-size:contain}.unavailable__img_compare[data-v-4cddfe32]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.unavailable__img_favorite[data-v-4cddfe32]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.unavailable__img_cart[data-v-4cddfe32]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.unavailable__img_glad[data-v-4cddfe32]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}.unavailable__img_promo[data-v-4cddfe32]{width:125px;height:145px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ")}.unavailable__img_search-error[data-v-4cddfe32]{width:100px;height:100px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ")}@media (max-width:550px){.unavailable__wrapper[data-v-4cddfe32]{max-width:100%}.unavailable__img[data-v-4cddfe32]{width:90px;height:90px;margin-bottom:10px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

import { CategorySerializer } from './Category'
import { ProductSerialize } from './product/Product'
import Brand from './Brand'
import type CategoryExtended from '~/type/CategoryExtended'

export default function CategoryExtendedSerializer(el: { [ key: string ]: any } = {}): CategoryExtended {
  const products: any[] = el.PRODUCTS
  const brands: any[] = el.SECTION_POPULAR_BRANDS
  return {
    ...CategorySerializer(el),
    product: products?.length ? products.map((item: any) => ProductSerialize(item)) : [],
    brands: brands?.length ? brands.map((item: any) => Brand(item)) : [],
  }
}

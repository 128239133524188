var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sale-error"
  }, [_c('img', {
    staticClass: "sale-error__img",
    attrs: {
      "src": require("assets/images/svg/closed-action.svg"),
      "alt": "Закрытая акция"
    }
  }), _vm._v(" "), _c('h2', {
    staticClass: "sale-error__title"
  }, [_vm._v("\n    Это закрытая акция\n  ")]), _vm._v(" "), _c('p', {
    staticClass: "sale-error__text"
  }, [_vm._v("\n    Она доступна только для участников программы лояльности 2-го уровня и выше\n  ")]), _vm._v(" "), _c('nuxt-link', {
    staticClass: "sale-error__link mb-12",
    attrs: {
      "to": "/about/loyalty/",
      "target": "_blank"
    }
  }, [_c('Button', {
    attrs: {
      "size": "49",
      "fluid": ""
    }
  }, [_vm._v("\n      Узнать подробности\n    ")])], 1), _vm._v(" "), _c('nuxt-link', {
    staticClass: "sale-error__link",
    attrs: {
      "to": "/sales/"
    }
  }, [_c('Button', {
    attrs: {
      "fluid": "",
      "theme": "white",
      "size": "49"
    }
  }, [_vm._v("\n      Другие акции\n    ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
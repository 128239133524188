import { defineStore } from 'pinia'
import { computed, ref } from '@vue/composition-api'
import { useUserStore } from '~/store/user'
import type { CompareStoreState } from '~/type/Compare'

interface UserListsData {
  user: CompareStoreState
  guest: CompareStoreState
}

export const useUserListStore = defineStore('user-lists', () => {
  const data = ref<UserListsData>({
    user: {
      compare: {},
      favorite: {},
    },
    guest: {
      compare: {},
      favorite: {},
    },
  })

  const userStore = useUserStore()

  const currentList = computed(() => userStore.isUser ? 'user' : 'guest')
  /**
     * Получаем сравнение
     */
  const compare = computed(() => data.value[currentList.value].compare)
  /**
     * Получаем избранное
     */
  const favorite = computed(() => data.value[currentList.value].favorite)
  /**
   * Строка из id товаров добавленных в список разделённая запятыми для запросов
   */
  const compareIds = computed(() => Object.keys(data.value[currentList.value].compare).join(','))
  /**
   * Массив айдишников товаров в списке сравнения
   */
  const compareIDArray = computed(() => Object.keys(data.value[currentList.value].compare))
  /**
   * Строка из id товаров добавленных в избранное разделённая запятыми для запросов
   */
  const favoriteIds = computed(() => Object.keys(data.value[currentList.value].favorite).join(','))
  /**
   * Массив айдишников товаров в списке избранного
   */
  const favoriteIDArray = computed(() => Object.keys(data.value[currentList.value].favorite))
  const compareAmount = computed(() => Object.keys(data.value[currentList.value].compare).length)
  const favoriteAmount = computed(() => Object.keys(data.value[currentList.value].favorite).length)

  function setList({ type, list }: { type: 'compare' | 'favorite'; list: CompareStoreState }) {
    data.value[currentList.value] = {
      ...data.value[currentList.value],
      [type]: list,
    }
  }

  function setLists({ type, lists }: { type: 'guest' | 'user'; lists: CompareStoreState | {} }) {
    data.value[type] = { compare: {}, favorite: {}, ...lists }
  }

  function add({ type, id, payload = true }: { type: 'compare' | 'favorite'; id: number; payload: boolean }) {
    data.value[currentList.value] = {
      ...data.value[currentList.value],
      [type]: { ...data.value[currentList.value][type], [id]: payload },
    }
  }

  function remove({ type, id }: { type: 'compare' | 'favorite'; id: number }) {
    const list = { ...data.value[currentList.value][type] }
    delete list[id]
    data.value[currentList.value] = { ...data.value[currentList.value], [type]: list }
  }

  function removeProducts(type: 'compare' | 'favorite') {
    data.value[currentList.value][type] = {}
  }

  function removeCategoryProducts({ type, ids }: { type: 'compare' | 'favorite'; ids: number[] }) {
    const list = { ...data.value[currentList.value][type] }
    Object.keys(list).forEach(el => ids.includes(+el) && delete list[+el])
    data.value[currentList.value] = { ...data.value[currentList.value], [type]: list }
  }

  return {
    data,
    currentList,
    compare,
    favorite,
    compareIds,
    compareIDArray,
    favoriteIds,
    favoriteIDArray,
    compareAmount,
    favoriteAmount,
    setList,
    setLists,
    add,
    remove,
    removeProducts,
    removeCategoryProducts,
  }
}, {
  persist: true,
  share: {
    enable: true,
  },
})

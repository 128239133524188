import type { FilterCheckbox, FilterGroup, FilterRange, FilterType } from '~/type/Filter'

export function FilterTypeSerializer(type: string): FilterType {
  return type === 'A' ? 'range' : 'checkbox'
}

export function FilterRangeSerializer(el: any): FilterRange {
  return {
    min: el?.[0]?.MIN,
    max: el?.[1]?.MAX,
    from: {
      property: el?.[0]?.NAME,
      value: +el?.[0]?.VALUE,
    },
    to: {
      property: el?.[1]?.NAME,
      value: +el?.[1]?.VALUE,
    },
  }
}

export function FilterCheckboxSerializer(arr: { [key: string]: any }, value: { [key: string]: any }) {
  function getSortList(list: FilterCheckbox[], value: { [key: string]: any }) {
    return list.sort((a, b) => {
      /**
       * Сортируем фильтры по алфавиту, активности и количеству больше нуля
       */
      const firstValue = value[a.property]
      const secondValue = value[b.property]

      if (a.name! > b.name! && firstValue && !secondValue)
        return -1
      else if (a.name! < b.name! && !firstValue && secondValue)
        return 1
      else if ((a.count === 0 && b.count === 0) || (a.count !== 0 && b.count !== 0))
        return a.name! > b.name! ? 1 : a.name! < b.name! ? -1 : 0
      else if (a.count === 0)
        return 1
      else
        return -1
    })
  }

  const list = arr.map(
    (el: { [key: string]: any }): FilterCheckbox => ({
      name: el.VALUE,
      property: el.NAME,
      value: el.HTML_VALUE,
      count: el.ELEMENT_COUNT,
    }),
  )

  return getSortList(list, value)
}

export function FilterGroupSerializer(el: any, value: any): FilterGroup {
  const type: FilterType = el.DISPLAY_TYPE === 'A' ? 'range' : 'checkbox'

  if (type === 'range') {
    return {
      id: el.ID,
      name: el.NAME,
      active: el.active,
      isMain: el.BASIC_FILTER,
      isOpenFilter: el.isOpenFilter,
      type,
      filter: FilterRangeSerializer(el.VALUES),
    }
  }
  else {
    return {
      id: el.ID,
      name: el.NAME,
      active: el.active,
      isMain: el.BASIC_FILTER,
      isOpenFilter: el.isOpenFilter,
      type,
      filter: FilterCheckboxSerializer(el.VALUES, value),
    }
  }
}

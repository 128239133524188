var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-rating",
    class: [("product-rating_h-" + (_vm.size)), ("product-rating_mobile-h-" + (_vm.mobileSize)), {
      'product-rating_interactive': _vm.interactive
    }],
    on: {
      "mouseout": function ($event) {
        _vm.interactive && (_vm.hoverCount = _vm.count);
      }
    }
  }, [_vm._l(5, function (el) {
    return _c('div', {
      key: el,
      staticClass: "product-rating__el-wrap",
      on: {
        "click": function ($event) {
          _vm.interactive && _vm.onClick(el);
        },
        "mousemove": function ($event) {
          _vm.interactive && _vm.onMouseove(el);
        }
      }
    }, [_c('div', {
      staticClass: "product-rating__el",
      class: [{
        'product-rating__el_hover': _vm.hoverCount >= el,
        'product-rating__el_active': _vm.count >= el,
        'product-rating__el_yellow': _vm.yellow
      }]
    })]);
  }), _vm._v(" "), _vm.showStatus ? _c('div', {
    staticClass: "product-rating__text",
    class: [{
      'product-rating__text_orange': _vm.isActive === 5
    }]
  }, [_vm._v("\n    " + _vm._s(_vm.status) + "\n  ")]) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
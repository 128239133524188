
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Button, Form, FormElement, InputText, Popover, RadioList } from '05-ui-kit';
import { formatPhoneAndMask } from '@artmizu/utils';
import { ref } from '@vue/composition-api';
interface Data {
  subject: number;
  phone: string;
  name?: string;
}
const __sfc_main = {};
__sfc_main.props = {
  loading: {
    key: "loading",
    required: false,
    type: Boolean,
    default: false
  },
  error: {
    key: "error",
    required: false,
    type: Boolean,
    default: false
  },
  success: {
    key: "success",
    required: false,
    type: Boolean,
    default: false
  },
  responseError: {
    key: "responseError",
    required: false,
    type: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const data = ref<Data>({
    subject: 12,
    phone: ''
  });
  const list = [{
    name: 'Хочу сделать заказ',
    value: 12
  }, {
    name: 'Гарантийное обслуживание',
    value: 11
  }, {
    name: 'Консультация',
    value: 10
  }];
  function onInput(val: string) {
    data.value.phone = formatPhoneAndMask(val);
  }
  function send() {
    emit('submit', {
      ...data.value
    });
  }
  return {
    data,
    list,
    onInput,
    send
  };
};
__sfc_main.components = Object.assign({
  Popover,
  ValidationObserver,
  Form,
  FormElement,
  InputText,
  ValidationProvider,
  RadioList,
  Button
}, __sfc_main.components);
export default __sfc_main;

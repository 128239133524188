import type { Events } from '~/plugins/emitter'

export default function addToCartAccessory({ product, type }: Events['add-to-cart-accessory']) {
  return {
    name: type === 'card' ? 'click_add_to_cart_from_product_card_accessory' : 'click_add_to_cart_from_tab_accessory',
    data: {
      items: [
        {
          item_name: product.name,
          item_id: product.id,
          price: product.price?.common?.value,
          item_brand: product.brand?.name,
          item_category: product.category?.name,
          item_variant: product.offer?.length ? product.offer?.map(el => el.value || el.active?.name).join(', ') : '',
          quantity: 1,
        },
      ],
    },
  }
}

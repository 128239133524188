import { defineStore } from 'pinia'
import { ref } from '@vue/composition-api'

export const useSocialShareStore = defineStore('socialStore', () => {
  const active = ref(false)
  const title = ref<string>()
  const path = ref<string>()
  const sendCustomText = ref<string>()

  function enableSocialShare(data: { title?: string; path?: string; sendCustomText?: string }) {
    active.value = true
    if (data.title)
      title.value = data.title
    if (data.path)
      path.value = data.path
    if (data.sendCustomText)
      sendCustomText.value = data.sendCustomText
  }

  function disableSocialShare() {
    active.value = false
    title.value = undefined
    path.value = undefined
    sendCustomText.value = undefined
  }

  return { enableSocialShare, disableSocialShare, active, title, path, sendCustomText }
})

import type { Grade } from '~/type/Grade'

export default function (el: { [ key: string ]: any } = {}): Grade {
  return {
    amounts: {
      dislikes: el.AMOUNTS.DISLIKES,
      likes: el.AMOUNTS.LIKES,
    },
    disliked: el.DISLIKED,
    dislikes: el.DISLIKES,
    liked: el.LIKED,
    likes: el.LIKES,
  }
}

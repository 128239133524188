var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nuxt-link', {
    staticClass: "info-cart",
    attrs: {
      "to": "/cart/"
    }
  }, [_c('div', {
    staticClass: "info-cart__text"
  }, [_c('div', {
    staticClass: "info-cart__text-title"
  }, [_vm._v("\n      Ваша корзина\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "info-cart__text-title info-cart__text-title_mobile"
  }, [_vm._v("\n      Корзина\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "info-cart__gradient-left"
  }), _vm._v(" "), _c('div', {
    staticClass: "info-cart__text-description"
  }, [_vm._v("\n      " + _vm._s(_vm.quantity) + " " + _vm._s(_vm.declinationValue(_vm.quantity)) + ", на сумму:\n      "), _c('div', {
    staticClass: "info-cart__text-description-price"
  }, [_c('span', {
    staticClass: "info-cart__text-description-price-value"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.price)) + "₽")])])])]), _vm._v(" "), _c('div', {
    staticClass: "info-cart__img-wrap"
  }, [_c('img', {
    staticClass: "info-cart__img",
    attrs: {
      "src": require("@/assets/images/info-block-cart.png"),
      "srcset": require("@/assets/images/info-block-cart.png") + " 1x, " + require("@/assets/images/info-block-cart2x.png") + " 2x",
      "alt": "Корзина"
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "info-cart__gradient-right"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }
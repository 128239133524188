import { CategorySerializer } from '~/serializer/Category'
import PaginationSerializer from '~/serializer/Pagination'
import SalePromoSerializer from '~/serializer/SalePromo'
import SortSerialiazer from '~/serializer/Sort'
import { ProductSerialize } from '~/serializer/product/Product'
import type Pagination from '~/type/Pagination'
import type SalePromo from '~/type/SalePromo'
import type Sort from '~/type/Sort'
import type { Product } from '~/type/product/Product'
import type Category from '~/type/Category'
import type Brand from '~/type/Brand'

export interface BrandPage extends Brand {
  path: string
  description: string
  pagination?: Pagination
  section: Category[]
  sectionChildren: Category[]
  sale: SalePromo[]
  product: Product[]
  sort: Sort[]
}

export default function (el: { [key: string]: any }): BrandPage {
  const section = el.SECTIONS ? el.SECTIONS.map((item: any) => CategorySerializer(item)) : []
  return {
    id: el.ID,
    code: el.CODE,
    name: el.NAME,
    path: el.DETAIL_PAGE_URL,
    image: el.IMAGE,
    description: el.FULL_DESCRIPTION,
    pagination: PaginationSerializer(el?.PRODUCTS.PAGINATION || null),
    section,
    /**
    * Категория товаров на странице каждой брендов (возвращаем один массив)
    */
    sectionChildren: section.flatMap((el: any) => el.children),
    sale: el.ACTIONS ? el.ACTIONS.map((item: any) => SalePromoSerializer(item)) : [],
    product: el.PRODUCTS.ITEMS ? el.PRODUCTS.ITEMS.map((item: any) => ProductSerialize(item)) : [],
    sort: el.PRODUCTS?.SORT_TYPES?.length ? el.PRODUCTS.SORT_TYPES.map((item: any) => SortSerialiazer(item)) : [],
  }
}

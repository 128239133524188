import { defineStore } from 'pinia'
import { ref } from '@vue/composition-api'

interface EnableParams {
  onComplete?: noop
  title?: string
  subtitle?: string
  note?: string
}

export const useAuthStore = defineStore('authModal', () => {
  const active = ref(false)
  const title = ref<string>()
  const subtitle = ref<string>()
  const note = ref<string>()
  let onAuthComplete: noop

  function enable(data?: EnableParams) {
    active.value = true
    if (data?.title)
      title.value = data.title
    if (data?.subtitle)
      subtitle.value = data.subtitle
    if (data?.note)
      note.value = data.note

    if (data?.onComplete)
      onAuthComplete = data.onComplete
  }

  function disabled(data: boolean) {
    active.value = false
    title.value = undefined
    subtitle.value = undefined
    note.value = undefined
    /**
     * Если при открытии модалки, передали коллбэк и также если авторизовались,
     *  то вызываем функцию onAuthComplete
     */
    if (typeof onAuthComplete === 'function' && data)
      onAuthComplete()
  }

  return { enable, disabled, active, title, subtitle, note }
})

import type { Events } from '~/plugins/emitter'

export default function saleSelect({ banner }: Events['banner-select']) {
  return {
    event: 'banner_promotion',
    ecommerce: {
      items: [
        {
          promotion_id: banner?.code,
          promotion_name: banner?.name,
        },
      ],
    },
  }
}

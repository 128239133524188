var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showChat ? _c('ToggleChat', [_c('div', {
    staticClass: "chat-custom-widget"
  })]) : _vm._e(), _vm._v(" "), _c('keep-alive', [_vm.authStore.active ? _c('AuthModal') : _vm._e()], 1), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "code"
    }
  }, [_vm.userTool.codeTool ? _c('LazyAddCode') : _vm._e()], 1), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "code"
    }
  }, [_vm.userTool.copyCodeTool ? _c('LazyCopyCode') : _vm._e()], 1), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "bounce"
    }
  }, [_vm.userTool.compareTool ? _c('LazyAddCompare') : _vm._e()], 1), _vm._v(" "), _vm.productMoreStore.active ? _c('LazyHydrate', {
    attrs: {
      "never": "",
      "trigger-hydration": _vm.productMoreStore.active
    }
  }, [_c('ActionCompare', {
    attrs: {
      "id": _vm.productMoreStore.id,
      "product": _vm.productMoreStore
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var add = _ref.add,
            remove = _ref.remove,
            active = _ref.active,
            loading = _ref.loading;
        return [_vm.productMoreStore.active ? _c('LazyProductMore', {
          attrs: {
            "show": _vm.productMoreStore.active,
            "title": _vm.productMoreStore.title,
            "path": _vm.productMoreStore.path,
            "active": active,
            "loading": loading
          },
          on: {
            "add": add,
            "remove": remove,
            "close": function ($event) {
              return _vm.productMoreStore.disableProductMore();
            }
          }
        }) : _vm._e()];
      }
    }], null, false, 1084739094)
  })], 1) : _vm._e(), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "bounce"
    }
  }, [_vm.userTool.cartTool ? _c('LazyActionEntity', {
    attrs: {
      "actions": {
        send: _vm.$api.product.buyOneClick
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var send = _ref2.send,
            success = _ref2.success,
            error = _ref2.error,
            loading = _ref2.loading;
        return [_c('LazyAddCart', {
          attrs: {
            "success": success,
            "loading": loading,
            "error": error
          },
          on: {
            "send": function ($event) {
              send($event), _vm.emitter.emit('submit-form-data', {
                phone: $event.number
              });
            },
            "success": function ($event) {
              return _vm.onSuccessFastOrder($event.value);
            }
          }
        })];
      }
    }], null, false, 2835909349)
  }) : _vm._e()], 1), _vm._v(" "), _c('ActionEntity', {
    attrs: {
      "actions": {
        send: _vm.$api.feedback.foundError
      }
    },
    on: {
      "success": function ($event) {
        return _vm.foundErrorStore.disableFoundError();
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var send = _ref3.send,
            error = _ref3.error,
            loading = _ref3.loading,
            success = _ref3.success,
            response = _ref3.response;
        return [_vm.foundErrorStore.active ? _c('LazyFoundErrorModal', {
          attrs: {
            "show": _vm.foundErrorStore.active,
            "product-id": Number(_vm.foundErrorStore.productID),
            "loading": loading,
            "success": success,
            "error": !!error,
            "response": response
          },
          on: {
            "send": function ($event) {
              return send($event);
            },
            "close": function ($event) {
              return _vm.foundErrorStore.disableFoundError();
            }
          }
        }) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c('ActionEntity', {
    attrs: {
      "actions": {
        send: _vm.$api.feedback.complaint
      }
    },
    on: {
      "success": function ($event) {
        return _vm.reportStore.disableReport();
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var send = _ref4.send,
            error = _ref4.error,
            loading = _ref4.loading,
            success = _ref4.success,
            response = _ref4.response;
        return [_vm.reportStore.active ? _c('LazyReport', {
          attrs: {
            "show": _vm.reportStore.active,
            "product-id": Number(_vm.reportStore.productID),
            "loading": loading,
            "success": success,
            "error": !!error,
            "response": response
          },
          on: {
            "send": function ($event) {
              return send($event);
            },
            "close": function ($event) {
              return _vm.reportStore.disableReport();
            }
          }
        }) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _vm.socialShareStore.active ? _c('LazyHydrate', {
    attrs: {
      "never": "",
      "trigger-hydration": _vm.socialShareStore.active
    }
  }, [_c('SocialShare', {
    attrs: {
      "name": _vm.socialShareStore.title,
      "path": _vm.socialShareStore.path,
      "show": _vm.socialShareStore.active
    },
    on: {
      "close": function ($event) {
        return _vm.socialShareStore.disableSocialShare();
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.chooseCityStore.active ? _c('LazyHydrate', {
    attrs: {
      "never": "",
      "trigger-hydration": _vm.chooseCityStore.active
    }
  }, [_c('ActionEntity', {
    attrs: {
      "actions": {
        send: _vm.$api.location.setCityCurrent
      }
    },
    on: {
      "success": _vm.onSuccessChangeCity,
      "error": _vm.errorChangeCity
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        var send = _ref5.send,
            success = _ref5.success;
        return [_c('ChooseCity', {
          attrs: {
            "success": success,
            "is-modal-active": _vm.chooseCityStore.active
          },
          on: {
            "select-city": function ($event) {
              send($event), _vm.setCurrentCityData($event);
            },
            "close": function ($event) {
              return _vm.chooseCityStore.disableChooseCity();
            }
          }
        })];
      }
    }], null, false, 2705761547)
  })], 1) : _vm._e(), _vm._v(" "), _c('Notification'), _vm._v(" "), _c('UIActionEntity', {
    attrs: {
      "get": _vm.$api.auth.sendVerificationEmail
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        var _vm$userStore$user, _response$error;

        var get = _ref6.get,
            response = _ref6.response,
            status = _ref6.status;
        return [(_vm$userStore$user = _vm.userStore.user) !== null && _vm$userStore$user !== void 0 && _vm$userStore$user.email ? _c('EmailConfirm', {
          attrs: {
            "show": _vm.emailConfirmStore.active,
            "email": _vm.userStore.user.email,
            "status": status,
            "error-message": response === null || response === void 0 ? void 0 : (_response$error = response.error) === null || _response$error === void 0 ? void 0 : _response$error.description
          },
          on: {
            "submit": function ($event) {
              get($event), _vm.emitter.emit('submit-form-data', {
                email: $event
              });
            },
            "close": function ($event) {
              _vm.emailConfirmStore.active = false;
            }
          }
        }) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _vm.achievementStore.achievement ? _c('AchievementModal', {
    attrs: {
      "show": _vm.achievementStore.active,
      "achievement": _vm.achievementStore.achievement
    },
    on: {
      "close": function ($event) {
        return _vm.achievementStore.disable();
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "order-modal"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('span', {
    staticClass: "header__title"
  }, [_c('ViewportToogle', {
    scopedSlots: _vm._u([{
      key: "desktop",
      fn: function () {
        return [_vm._v(" Оцените товар из заказа от " + _vm._s(_vm.formatedDate) + " ")];
      },
      proxy: true
    }, {
      key: "mobile",
      fn: function () {
        return [_vm._v(" Как вам товар? ")];
      },
      proxy: true
    }])
  })], 1), _vm._v(" "), _c('button', {
    staticClass: "header__close",
    on: {
      "click": function ($event) {
        return _vm.$emit('remove');
      }
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "body"
  }, [_c('nuxt-link', {
    staticClass: "link link_black link_wu body__link",
    attrs: {
      "to": _vm.detail
    }
  }, [_c('LazyImg', {
    staticClass: "body__image",
    attrs: {
      "img": _vm.img,
      "alt": _vm.name
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "body__name"
  }, [_vm._v(_vm._s(_vm.name))]), _vm._v(" "), _vm.price ? _c('span', {
    staticClass: "body__price"
  }, [_vm._v(_vm._s(_vm.price) + "₽")]) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "body__control"
  }, [_c('Button', {
    staticClass: "body__control-later",
    attrs: {
      "theme": "white"
    },
    on: {
      "click": function ($event) {
        return _vm.emit('later');
      }
    }
  }, [_vm._v("\n        Позже\n      ")]), _vm._v(" "), _c('Button', {
    staticClass: "body__control-review",
    attrs: {
      "theme": "red"
    },
    on: {
      "click": function ($event) {
        return _vm.handleReview(_vm.detail);
      }
    }
  }, [_vm._v("\n        Оценить\n      ")])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
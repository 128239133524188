var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.list.length ? _c('div', {
    staticClass: "grid"
  }, _vm._l(_vm.list, function (brand) {
    var _brand$thumbnail, _brand$thumbnail2;

    return _c('nuxt-link', {
      key: brand.id,
      staticClass: "grid__el",
      attrs: {
        "to": ("/brands/" + (brand.code || brand.id) + "/"),
        "title": brand.name
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.emit('brand-click');
        }
      }
    }, [_c('div', {
      staticClass: "grid__brand"
    }, [_c('img', {
      staticClass: "grid__img",
      attrs: {
        "src": (_brand$thumbnail = brand.thumbnail) === null || _brand$thumbnail === void 0 ? void 0 : _brand$thumbnail.x1,
        "srcset": (((_brand$thumbnail2 = brand.thumbnail) === null || _brand$thumbnail2 === void 0 ? void 0 : _brand$thumbnail2.x2) + " x2"),
        "alt": brand.name
      }
    })])]);
  }), 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }

import { onUnmounted, ref } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  time: {
    key: "time",
    required: false,
    type: Number,
    default: 5
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const innerTime = ref(props.time);
  const timer = ref<NodeJS.Timer>();
  if (process.client) activate();
  onUnmounted(() => deactivate());
  function countdown() {
    innerTime.value--;
    if (innerTime.value <= 0 && timer.value) {
      clearInterval(timer.value);
      timer.value = undefined;
      emit('end');
    }
  }
  function activate() {
    timer.value = setInterval(countdown, 1000);
  }
  function deactivate() {
    if (timer.value) {
      clearInterval(timer.value);
      timer.value = undefined;
    }
  }
  function reactivate() {
    innerTime.value = props.time;
    activate();
  }
  return {
    innerTime,
    activate,
    deactivate,
    reactivate
  };
};
export default __sfc_main;

import type { Context } from '@nuxt/types'
import { stringify } from 'qs'
import { getCancelToken } from './helper'
import { PanelProductSerializer } from '~/serializer/PanelProduct'
import { CheckoutTotalSerializer } from '~/serializer/Checkout/Total'
import { ProductCartSerialize } from '~/serializer/product/ProductCart'
import type { UserList } from '~/type/UserList'
import { useCartStore } from '~/store/cart'

let syncPromiseResponse: any

export default {
  sync(this: Context, { product }: { product: UserList }) {
    const { $axios } = this
    return $axios.$post('/api/v1/basket/sync', {
      product,
    })
  },

  async requestSync(this: Context, { product }: { product: UserList }) {
    const self = this
    const { $api } = this
    const result = await refreshSyncResponse()
    syncPromiseResponse = null

    function refreshSyncResponse() {
      return (syncPromiseResponse = syncPromiseResponse || requestCartSync())
    }

    function requestCartSync() {
      return new Promise((resolve, reject) => {
        $api.cart
          .sync.call(self, { product })
          .then((data) => {
            resolve(data?.result)
          })
          .catch((error) => {
            console.log(error)
            reject(Error('При синхронизации товаров произошла ошибка'))
          })
      })
    }

    return {
      result,
    }
  },

  async syncActiveProducts(this: Context, { activeIds }: { activeIds: number[] }) {
    const self = this
    const { $api, $pinia } = this
    const { data } = useCartStore($pinia)

    const product = Object.entries<{ quantity: number }>(data).map(product => ({
      id: product[0],
      quantity: product[1].quantity || 1,
      is_active: activeIds ? activeIds.includes(+product[0]) : true,
    }))

    // Делаем запрос на бэк чтобы просунуть в него активные товары, чтобы относительно них посчитать оформление
    try {
      await $api.cart.requestSync.call(self, { product })
    }
    catch (e: any) {
      throw new Error('Ошибка при синхронизации корзины', e)
    }
  },

  async get(this: Context) {
    const result: {
      BASKET: { ITEMS: any[] }
    } = (
      await this.$axios.$get(
        '/api/v1/basket',
      )
    )?.result
    return {
      result: result?.BASKET?.ITEMS?.length ? result.BASKET.ITEMS.map((el: any) => ProductCartSerialize(el)) : [],
    }
  },

  async getAccessories(this: Context) {
    const { $axios } = this
    const response: any[] = (await $axios.$get('/api/v1/basket/accessories'))?.result
    return {
      result: response?.length ? response.map((el: any) => ProductCartSerialize(el)) : [],
    }
  },

  async getTotalForUser(this: Context) {
    const { $axios } = this
    const response = (await $axios.$get('/api/v1/basket'))?.result
    const productList = response?.BASKET?.ITEMS?.map((el: any) => ProductCartSerialize(el)) || []
    return {
      total: CheckoutTotalSerializer({ total: response.BASKET.TOTAL, list: productList, availableBonuses: undefined }),
    }
  },

  async getPanel(this: Context, { id, cancel = false }: { id: number; cancel: boolean }) {
    const { $axios } = this
    const cancelToken = cancel ? getCancelToken({ name: 'cart_panel', cancelToken: $axios.CancelToken }) : undefined

    const result = (
      await $axios.$get(
        `/api/v1/basket?${stringify({
          basket_id: id,
        })}`,
        {
          cancelToken,
        },
      )
    )?.result
    return {
      result: {
        product: result?.BASKET?.ITEMS?.length ? result?.BASKET?.ITEMS?.map((el: any) => PanelProductSerializer(el)) : [],
      },
    }
  },

  remove(this: Context, id: number, { retryConfig }: { retryConfig?: any } = {}) {
    return this.$axios.$delete(`/api/v1/basket/${id}`, {
      'axios-retry': {
        retries: 2,
        ...retryConfig,
      },
    })
  },

  removeProducts(this: Context, { productArray }: { productArray: number[] }) {
    return this.$axios.$delete('/api/v1/basket', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data: { products: productArray },
    })
  },

  add(this: Context, { id, quantity }: { id: number; quantity: number }) {
    return this.$axios.$post(
      '/api/v1/basket',
      stringify({
        product_id: id,
        quantity,
      }),
    )
  },

  async addList(this: Context, { list }: { list: { id: number; quantity: number }[] }) {
    const { $api } = this

    for (const el of list)
      await new Promise(resolve => resolve($api.cart.add.call(this, el)))
  },

  reset(this: Context) {
    return this.$axios.$delete('/api/v1/basket/all')
  },
}

import { defineStore } from 'pinia'
import { ref } from '@vue/composition-api'
import type { Context } from '@nuxt/types'
import type { Token } from '~/type/Token'

interface TokenItem {
  expire: number
  value: string
}

export const useTokenStore = function wrapperFuncStore(ctx?: Context) {
  /* Функция обёртка wrapperFuncStore нужна чтобы можно было прокидывать контекст в стор и использовать его, например, для запросов к API */
  const tmp = defineStore('token', () => {
    const accessToken = ref<TokenItem>()
    const refreshToken = ref<TokenItem>()

    function set(token: Token) {
      accessToken.value = token.accessToken
      refreshToken.value = token.refreshToken
      ctx?.$axios.setToken(token?.accessToken.value, 'Bearer')
    }

    function clearStore() {
      accessToken.value = undefined
      refreshToken.value = undefined
    }

    async function getBaseToken() {
      try {
        const { result: token } = await ctx?.$api.auth.getToken.call(ctx, {
          clientID: process.env.CLIENT_ID!,
          clientSecret: process.env.CLIENT_SECRET!,
        })

        set({
          accessToken: {
            value: token.access_token,
            expire: token.expires.access_token,
          },
          refreshToken: {
            value: token.refresh_token,
            expire: token.expires.refresh_token,
          },
        })
      }
      catch (e) {
        throw new Error(`Ошибка при получении базового токена для доступа к API: ${e}`)
      }
    }

    async function refresh() {
      try {
        ctx?.$axios.setToken(false)
        const { result: token } = await ctx?.$api.auth.refreshToken.call(ctx, {
          refreshToken: refreshToken.value?.value as string,
        })

        set({
          accessToken: {
            value: token.access_token,
            expire: token.expires.access_token,
          },
          refreshToken: {
            value: token.refresh_token,
            expire: token.expires.refresh_token,
          },
        })
      }
      catch (e) {
        throw new Error(`Ошибка при обновлении токена: ${e}`)
      }
    }

    async function reset() {
      try {
        const { result: token } = await ctx?.$api.auth.resetToken.call(ctx, {
          refreshToken: refreshToken.value?.value as string,
        })

        set({
          accessToken: {
            value: token.access_token,
            expire: token.expires.access_token,
          },
          refreshToken: {
            value: token.refresh_token,
            expire: token.expires.refresh_token,
          },
        })
      }
      catch (e) {
        throw new Error(`Ошибка при получении токена авторизации: ${e}`)
      }
    }

    return { accessToken, refreshToken, set, clearStore, getBaseToken, refresh, reset }
  }, {
    persist: true,
    share: {
      enable: true,
    },
  })
  return tmp()
}

import type { Calculate, Provider } from '~/type/Installment'

export function ProviderSerialize(el: { [key: string]: any }): Provider {
  return {
    name: el.UF_NAME,
    initialPercent: +el.UF_INITIAL_FEE_PERCENTS,
    min: +el.UF_MIN_INSTALLMENT_PERIOD,
    max: +el.UF_MAX_INSTALLMENT_PERIOD,
    age: '23',
    markup: '2-4,5',
    phone: '+7 (800) 511-05-05',
  }
}

export function CalculateSerialize(el: { [key: string]: any }): Calculate {
  return {
    title: el.PERIOD.NAME,
    initial:
    {
      value: el.PERIOD.INITIAL_FEE.VALUE,
      name: el.PERIOD.INITIAL_FEE.NAME,
    },
    overpayment: {
      value: el.PERIOD.OVERPAYMENT.VALUE,
      name: el.PERIOD.OVERPAYMENT.NAME,
    },
    monthlyPayment: {
      value: el.PERIOD.MONTHLY_PAYMENT.VALUE,
      name: el.PERIOD.MONTHLY_PAYMENT.NAME,
    },
    total: {
      value: el.PERIOD.TOTAL.VALUE,
      name: el.PERIOD.TOTAL.NAME,
    },
  }
}

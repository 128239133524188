import { ProductSerialize } from '~/serializer/product/Product'
import type { CompareCategory } from '~/type/Compare'

export function CompareCategorySerializer(el: any): CompareCategory {
  const products: any[] = el?.ITEMS
  const properties: any[] = el?.PROPERTIES

  return {
    id: +el?.ID,
    blockID: +el?.IBLOCK_ID || undefined,
    name: el?.NAME,
    product: products?.map(product => ProductSerialize(product)) || [],
    property: properties?.map(group => CharacteristicGroupSerializer(group)) || [],
    quantity: +el.QUANTITY,
  }
}

function CharacteristicGroupSerializer(el: any) {
  const properties: any[] = el?.PROPERTIES

  return {
    name: el?.NAME,
    characteristic: properties?.map(property => CharacteristicSerializer(property)) || [],
  }
}

function CharacteristicSerializer(el: any) {
  const specs: any[] = el?.VALUES

  return {
    name: el.NAME,
    description: el?.DESCRIPTION,
    specification: specs?.map(specification => SpecificationSerializer(specification)) || [],
  }
}

function SpecificationSerializer(el: any) {
  return {
    value: el?.VALUE,
  }
}

import type Store from '~/type/Store'

export default function (el: { [key: string]: any }): Store {
  return {
    id: el.ID,
    lat: el.GPS_N,
    lon: el.GPS_S,
    title: el.TITLE,
    address: el.ADDRESS,
    phone: el.PHONE,
    schedule: el.SCHEDULE,
    city: el.CITY,
    postalCode: el.POSTAL_CODE,
    scheduleAdvanced: {
      from: el.SCHEDULE_ADVANCED?.FROM,
      to: el.SCHEDULE_ADVANCED?.TO,
    },
    workDays: el.WORK_DAYS,
  }
}

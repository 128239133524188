export function PanelSerializer(el: any) {
  function getProducts({ data, type }: { data: {}; type?: string }) {
    const list = type === 'cart' ? Object.entries(data).map(([key, value]) => [key, { quantity: value }]) : Object.keys(data).map(key => [key, true])
    return Object.fromEntries(list)
  }

  return {
    compare: getProducts({ data: el?.COMPARE_PRODUCTS || {} }),
    favorite: getProducts({ data: el?.FAVORITE_PRODUCTS || {} }),
    cart: getProducts({ data: el?.BASKET_PRODUCTS || {}, type: 'cart' }),
  }
}

/**
 * Миддлвеер для перенаправления на ЛК рассрочки.
 * Так как ссылка на ЛК рассрочки отправляется пользователям по СМС и
 * когда имена бывают слишком длинные и двойные - это смущает пользователей и платим x2 ставку по СМС.
 * Поэтому в СМС будет ссылка с /r/, а мы будет редикретить на ЛК рассрочки
 */
import { defineNuxtMiddleware } from '@nuxtjs/composition-api'

export default defineNuxtMiddleware((context) => {
  if (context.route.path === '/r/')
    context.redirect('/user/?bnpl=1')
})

import { ThumbnailSerializer } from './Thumbnail'
import { PriceSerializer } from './Price'
import type { PanelProduct } from '~/type/PanelProduct'

export function PanelProductSerializer(el: any): PanelProduct {
  return {
    id: +el.ID,
    name: el.NAME,
    thumbnail: el.THUMBNAILS ? ThumbnailSerializer(el.THUMBNAILS) : undefined,
    price: el.PRICES ? PriceSerializer(el.PRICES) : undefined,
    path: el.DETAIL_PAGE_PATH,
    quantity: el.QUANTITY ? el.QUANTITY : 0,
  }
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-tools__compare-wrap",
    attrs: {
      "data-e2e": "compare-inline-tool"
    },
    on: {
      "click": function ($event) {
        _vm.active ? _vm.remove() : _vm.add();
      }
    }
  }, [_c('div', {
    staticClass: "user-tools__item",
    class: [{
      'user-tools__item_compare': !_vm.loading
    }, {
      'user-tools__item_is-active': _vm.active
    }]
  }, [_vm.loading ? _c('Loader', {
    attrs: {
      "title": undefined,
      "width": "21px",
      "height": "21px"
    }
  }) : _c('span', {
    attrs: {
      "data-e2e": "compare-btn"
    }
  }, [_vm._v(_vm._s(_vm.active ? 'В сравнении' : 'В сравнение'))])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
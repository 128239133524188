import checkoutAccessories from './checkout-accessories'
import checkoutExpressDelivery from './checkout-express-delivery'
export default {
  /**
   * Файлы с экспериментами
   */
  experiments: [checkoutAccessories, checkoutExpressDelivery],
  /**
   * Счетчик гугл аналитики
   */
  googleAnaliticsTreckingID: 'UA-74048954-1',
}

import { defineStore } from 'pinia'
import { ref } from '@vue/composition-api'
import type Achievement from '~/type/Achievement'

export const useAchievementStore = defineStore('achievementStore', () => {
  const active = ref(false)
  const achievement = ref<Achievement | null>(null)

  function enable(data: Achievement) {
    achievement.value = data
    active.value = true
  }

  function disable() {
    active.value = false
    achievement.value = null
  }

  return { enable, disable, active, achievement }
})

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    attrs: {
      "show": _vm.show,
      "title": "Сообщение об ошибке"
    },
    on: {
      "close": function ($event) {
        return _vm.onClose();
      }
    }
  }, [_c('div', {
    staticClass: "p pb-15"
  }, [_vm._v("\n    Опишите ошибку подробнее: это ошибка в описании или какой-то функционал сайта работает неправильно?\n  ")]), _vm._v(" "), _c('ValidationObserver', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('Form', {
          attrs: {
            "type": "vertical"
          },
          on: {
            "submit": function ($event) {
              return handleSubmit(_vm.onSubmit);
            }
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "поле"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var errors = _ref2.errors;
              return [_c('FormElement', {
                attrs: {
                  "hint-min-height": "0",
                  "error": errors[0]
                }
              }, [_c('Textarea', {
                attrs: {
                  "data-e2e": "found-error-modal-comment"
                },
                model: {
                  value: _vm.text,
                  callback: function ($$v) {
                    _vm.text = $$v;
                  },
                  expression: "text"
                }
              })], 1)];
            }
          }], null, true)
        }), _vm._v(" "), _vm.error ? _c('Note', {
          staticClass: "mb-10",
          attrs: {
            "theme": "reddish"
          }
        }, [_vm._v("\n        " + _vm._s(_vm.response || 'Что-то пошло не так') + "\n      ")]) : _vm._e(), _vm._v(" "), _vm.loading ? _c('Button', {
          attrs: {
            "size": "39",
            "theme": "red",
            "loading": ""
          }
        }) : _c('Button', {
          attrs: {
            "size": "39",
            "data-e2e": "found-error-modal-submit"
          }
        }, [_vm._v("\n        Отправить сообщение\n      ")])], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
import { ThumbnailSerializer } from '../Thumbnail'
import { PriceSerializer } from '../Price'
import { SaleSerialize } from '../Sale'
import { CategorySerializer } from '../Category'
import type { Product, ProductOffer, ProductStatus } from '~/type/product/Product'

export function ProductOfferSerializer(el: any): ProductOffer {
  return {
    type: el.TYPE,
    value: el.VALUE,
  }
}

export function ProductStatusSerialize(el: any): ProductStatus {
  return {
    /* TODO: С name'ом вынужденный костыль, т.к когда товара нет в наличии, API не возвращает NAME */
    name: el.NAME ? el.NAME : el.TEXT,
    color: el.COLOR,
    code: el.CODE,
  }
}

export function ProductSerialize(el: { [key: string]: any }): Product {
  /* С API превью приходят без заглавной фотки, поэтому мы её туда помещаем сами, вручную */
  const image: string = el.IMAGE
  const thumbnail = ThumbnailSerializer(el.THUMBNAILS)
  const thumbnailArray = Array.isArray(el.IMAGES_THUMBNAILS)
    ? el.IMAGES_THUMBNAILS.map((el: any) => {
      return {
        image: el.image,
        thumbnail: ThumbnailSerializer(el.THUMBNAILS),
      }
    })
    : []
  thumbnailArray.unshift({
    image,
    thumbnail,
  })
  return {
    id: +el.ID,
    name: el.NAME,
    image,
    thumbnail,
    thumbnailArray,
    price: el.PRICES?.length ? PriceSerializer(el.PRICES) : undefined,
    status: el.STATUS && ProductStatusSerialize(el.STATUS),
    offer: el.DEFAULT_OFFERS ? el.DEFAULT_OFFERS.map((item: any) => ProductOfferSerializer(item)) : [],
    // Последней в массиве бывает самая актуальная акция, поэтому надо применить reverse()
    sale: el.ACTIONS ? el.ACTIONS.reverse().map((item: any) => SaleSerialize(item)) : [],
    compare: el.IN_COMPARE || false,
    favorite: el.IN_FAVORITES || false,
    cart: Boolean(el.IN_BASKET),
    path: el.DETAIL_PAGE_PATH,
    code: el?.ATTRIBUTES?.find((item: any) => item?.TYPE === 'CODE')?.VALUE,
    category: el.SECTION ? CategorySerializer(el.SECTION) : undefined,
    availableBonusPay: el.AVAILABLE_BONUS_PAY,
    mainProperty: el.POPULAR_PROPERTIES ? Object.entries(el.POPULAR_PROPERTIES).map(([name, value]: any) => ({ name, value })) : [],
    // Роут каталога, в котором мы используем этот сериалайзер продукта старый, и возможно rate придётся менять
    rate: {
      rating: +el.RATING || undefined,
      count: +el.REVIEWS_COUNT || undefined,
    },
    /* Цена товара в рассрочку, используется только на странице рассрочки. */
    installmentPrice: el.INSTALLMENT_MONTHLY_PRICE || undefined,
  }
}

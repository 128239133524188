import { ProductSerialize } from './Product'
import type { Price } from '~/type/Price'
import type { CartProduct } from '~/type/product/Product'

export function ProductCartSerialize(el: any = {}): CartProduct {
  function getCommonPrice({ data, price }: { data: Price; price: number }) {
    return {
      ...data.common,
      value: price || data.common?.value,
    }
  }

  function getDiscountPrice({ data, discount = 0 }: { data: Price; discount: number }) {
    let value

    if (!data?.discount?.value && discount > 0)
      value = data?.common?.value || 0
    else if (data?.discount?.value)
      value = data?.discount?.value || 0

    return value
      ? {
          ...data?.discount,
          type: data?.discount?.type || 'PROMO',
          value,
        }
      : undefined
  }

  function getStorageData(el: any) {
    return {
      id: +el.ID,
      amount: el.AMOUNT,
    }
  }

  const productBase = ProductSerialize(el)

  return {
    ...ProductSerialize(el),
    isActive: el.IS_ACTIVE,
    price: productBase.price
      ? {
          ...productBase.price,
          common: getCommonPrice({ data: productBase.price, price: el.PRICE }),
          discount: getDiscountPrice({ data: productBase.price, discount: el.DISCOUNT }),
        }
      : undefined,
    quantity: el.QUANTITY || 0,
    gift: el?.GIFT_PRODUCTS?.map((el: any) => ProductSerialize(el)) || [],
    warehouse: el?.STORES_AMOUNT?.map((item: any) => getStorageData(item)) ?? [],
    availableBonusPay: !!el.AVAILABLE_BONUS_PAY,
  }
}

import { stringify } from 'qs'
import type { Context } from '@nuxt/types'
import { TokenSerializer } from '~/serializer/Token'
import UserSerializer from '~/serializer/User'
import { BonusCardSerializer } from '~/serializer/BonusCard'
import { PanelSerializer } from '~/serializer/Panel'

interface SuccessResult {
  success: true
  result: true
}

async function login(this: Context, { username, password, accessToken, type }: { username: string; password: string; accessToken: string; type: 'password' }): Promise<any>
async function login(this: Context, { code, accessToken, type, referalKey }: { code: string; accessToken: string; type: 'sms'; referalKey?: string }): Promise<any>
async function login(this: Context, { username, password, code, accessToken, type = 'password', referalKey }: { username?: string; password?: string; code?: string; accessToken: string; type: 'password' | 'sms'; referalKey?: string }) {
  const { $axios } = this

  const result = (
    await $axios.$post(
      '/api/v2/oauth/token',
      stringify({
        grant_type: type,
        username,
        password,
        confirm_code: code,
        access_token: accessToken,
        referral_key: referalKey,
      }),
    )
  )?.result

  return {
    result: {
      token: TokenSerializer(result || {}),
      user: result?.user && UserSerializer(result.user),
      bonus: result?.user?.BONUS_CARD ? BonusCardSerializer(result.user.BONUS_CARD) : undefined,
      panel: PanelSerializer(result?.user || {}),
    },
  }
}

export default {
  getToken(this: Context, { clientID, clientSecret }: { clientID: string; clientSecret: string }) {
    const { $axios } = this

    return $axios.$post(
      '/api/v2/oauth/token',
      stringify({
        grant_type: 'client_credentials',
        client_id: clientID,
        client_secret: clientSecret,
      }),
      {
        params: {
          isTokenPath: true,
        },
      },
    )
  },

  getTokenInfo(this: Context, { token }: { token: string }) {
    const { $axios } = this

    return $axios.$get('/api/v1/oauth/token', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  },

  refreshToken(this: Context, { refreshToken }: { refreshToken: string }) {
    const { $axios } = this

    return $axios.$post(
      '/api/v2/oauth/token',
      stringify({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      }),
      {
        params: {
          isTokenPath: true,
        },
      },
    )
  },

  resetToken(this: Context, { refreshToken }: { refreshToken: string }) {
    const { $axios } = this

    return $axios.$post(
      '/api/v2/oauth/token',
      stringify({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        reset_authorization: true,
      }),
    )
  },

  login,

  sendConfirmCode(this: Context, { phone, short, recaptchaToken }: { phone: string; short: boolean; recaptchaToken: string }): Promise<SuccessResult> {
    const { $axios } = this

    /**
    * Удаление дополнительных символов из номера телефона.
    * Тестовые номера должны передаваться без дополнительных символов, поэтому, чтобы не хардкодить опеределенные тестовые номера, символы удаляем абсолютно всех номеров
    */
    const formattedPhone = phone.replace(/\D/g, '')

    return $axios.$get(
      `/api/v1/oauth/code/send/${formattedPhone}?${stringify({
        short,
        token: recaptchaToken,
      })}`,
    )
  },

  checkConfirmCode(this: Context, { code }: { code: string }): Promise<SuccessResult> {
    const { $axios } = this
    return $axios.$get(`/api/v1/register/code/check/${code}`)
  },

  async signup(this: Context, { name, code, password, email }: { name: string; code: string; password: string; email: string }) {
    const { $axios } = this

    const result = (
      await $axios.$post(
        '/api/v2/register',
        stringify({
          name,
          password,
          email,
          confirm_code: code,
        }),
      )
    )?.result
    return {
      result: {
        token: TokenSerializer(result || {}),
        user: result?.user && UserSerializer(result.user),
      },
    }
  },

  sendPhoneVerificationCode(this: Context, { phone }: { phone: string }): Promise<SuccessResult> {
    const { $axios } = this
    return $axios.$get(`/api/v2/register/code/send/${phone}`)
  },

  sendForgetConfirmationCode(this: Context, { login }: { login: string }): Promise<SuccessResult> {
    const { $axios } = this
    return $axios.$get(`/api/v2/user/recover/send/${login}`)
  },

  changeForgetPassword(this: Context, { password, code }: { password: string; code: string }): Promise<SuccessResult> {
    const { $axios } = this
    return $axios.$post(
      '/api/v2/user/recover/change/password',
      stringify({
        password,
        confirm_code: code,
      }),
    )
  },

  sendVerificationEmail(this: Context, email: string): Promise<SuccessResult> {
    return this.$axios.$get(`/api/v2/user/verify/email/${email}`)
  },

  async confirmEmail(this: Context, { id, hash }: { id: string; hash: string }): Promise<SuccessResult> {
    const { $axios } = this
    return (
      await $axios.$patch(
        `/api/v1/user/confirm/email?${stringify({
          user_id: id,
          hash,
        })}`,
        null,
        {},
      )
    )?.result
  },

  sendVerificationCode(this: Context): Promise<SuccessResult> {
    const { $axios } = this
    return $axios.$get('/api/v2/user/verify/phone/send')
  },

  verifyPhone(this: Context, code: string): Promise<SuccessResult> {
    const { $axios } = this
    return $axios.$get(`/api/v1/user/verify/phone?confirm_code=${code}`)
  },

  async checkCodeAndLogin(this: Context, { code, type, accessToken }: { code: string; type: 'sms'; accessToken: string }) {
    const { $api } = this

    await $api.auth.checkConfirmCode.apply(this, [{ code }])

    const response = await $api.auth.login.apply(this, [{ code, type, accessToken, referalKey: this.$cookies.get('referalKey') as string || '' }])
    this.$cookies.remove('referalKey')
    return response
  },
}

import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import generateApi from '~/api'
import { useUserStore } from '~/store/user'
import { useTokenStore } from '~/store/token'

export default defineNuxtPlugin(async (context, inject) => {
  inject('api', generateApi(context))

  let refreshTokenPromiseResponse: Promise<void> | null = null
  let refreshTokenPromiseRequest: Promise<void> | null = null

  const userStore = useUserStore(context)
  const tokenStore = useTokenStore(context)
  /**
   * Простановка токена на клиенте при первоначальной загрузке сайта, если токен есть во pinia`е.
   */
  if (process.server && !tokenStore?.accessToken?.value) {
    try {
      await tokenStore.getBaseToken()
      userStore.setData()
    }
    catch (e) {
      new context.$pageError({
        message: 'Ошибка получения базового токена на сервере',
        code: 503,
      })
      return
    }
  }
  else if (process.client && tokenStore?.accessToken?.value) {
    context.$axios.setToken(tokenStore?.accessToken?.value, 'Bearer')
  }

  /**
   * При каждом запросе проверяем токен для обращения к АПИ на актуальность и если нужно его рефрешнуть - рефшерим
   * и после повторяем изначальный запрос. Если рефреш не проходит - скидываем авторизацию и пытаемся еще раз получить токен,
   * если мы находимся на сервере. Если на клиенте - прокидываем ошибку
   */
  context.app.$axios.interceptors.request.use(
    async (config) => {
      const notAPIRequest = config?.url?.[0] !== '/' && !config?.url?.includes(config.baseURL!)
      const isRefreshPath = config?.params?.isTokenPath
      if (notAPIRequest || isRefreshPath)
        return config

      const isAccessTokenExpired = isTokenExpire(tokenStore?.accessToken?.expire as number)
      if (isAccessTokenExpired) {
        const isRefreshTokenExpired = isTokenExpire(tokenStore?.refreshToken?.expire as number)
        if (!isRefreshTokenExpired) {
          try {
            await refreshTokenRequest()
            refreshTokenPromiseRequest = null
          }
          catch (e) {
            if (process.server) {
              try {
                await tokenStore.getBaseToken()
                userStore.setData()
              }
              catch (e) {
                throw new context.$pageError({
                  message: 'Ошибка получения базового токена на сервере',
                  code: 503,
                  native: e,
                })
              }
            }
            else {
              throw new context.$pageError({
                message: 'Ошибка при попытке обновить токен на клиенте',
                code: 503,
                native: e,
              })
            }
          }
        }
        else {
          tokenStore.clearStore() // Чтоб удалить сгоревшие токены
          if (process.client) {
            throw new context.$pageError({
              message: 'Рефреш токен просрочен на клиенте',
              code: 503,
            })
          }
          else {
            new context.$baseError({
              message: 'Рефреш токен клиента просрочен на сервере, делаем логаут',
            })
            await tokenStore.getBaseToken()
            userStore.setData()
          }
        }
      }

      const token = tokenStore?.accessToken?.value
      config.headers.Authorization = `Bearer ${token}`

      return config
    },
    (error) => {
      new context.$baseError({
        message: 'Произошла ошибка при запросе',
        native: error,
      })
      return Promise.reject(error)
    },
  )

  /**
   * Если в ответ на запрос к апи вернулась ошибка 401 и информация об ошибке авторизации,
   * пытаемся рефрешнуть токен, если не получилось и мы находимся на сервере - пытаемся получить базовый токен
   * Если находимся на клиенте - прокидываем ошибку
   */
  context.app.$axios.interceptors.response.use(
    res => res,
    async (error) => {
      const notAPIResponse
        = error?.response?.config?.url[0] !== '/' && !error?.response?.config?.url.includes(error?.response?.config?.baseURL)
      if (notAPIResponse)
        return Promise.reject(error)

      const originalRequest = error.config

      if (error?.response.status === 401 && error?.response.data.error.message === 'unauthorized' && !originalRequest._retry) {
        originalRequest._retry = true

        try {
          await refreshTokenResponse()
          refreshTokenPromiseResponse = null
          return context.$axios(originalRequest)
        }
        catch (e) {
          if (process.server) {
            try {
              await tokenStore.getBaseToken()
              userStore.setData()
            }
            catch (e) {
              throw new context.$pageError({
                message: 'При запросе бэк вернул ошибку авторизации и после не удалось обновить базовый токен на сервере',
                code: 503,
                native: e,
              })
            }
          }
          else {
            throw new context.$pageError({
              message: 'При запросе бэк вернул ошибку авторизации и после не удалось обновить базовый токен на клиенте',
              code: 503,
              native: e,
            })
          }
        }
      }
      else if (originalRequest?._retry) {
        if (process.server) {
          try {
            await tokenStore.getBaseToken()
            userStore.setData()
          }
          catch (e) {
            throw new context.$pageError({
              message: 'При запросе бэк вернул ошибку авторизации и после не удалось обновить базовый токен на сервере',
              code: 503,
              native: e,
            })
          }
        }
        else {
          throw new context.$pageError({
            message: 'При запросе бэк вернул ошибку авторизации и после не удалось обновить базовый токен на клиенте',
            code: 503,
            native: error,
          })
        }
      }

      new context.$baseError({
        message: 'Произошла ошибка при получении ответа на запрос с бэка',
        native: error,
      })
      return Promise.reject(error)
    },
  )

  function refreshTokenResponse() {
    return (refreshTokenPromiseResponse = refreshTokenPromiseResponse || requestRefreshToken())
  }

  function refreshTokenRequest() {
    return (refreshTokenPromiseRequest = refreshTokenPromiseRequest || requestRefreshToken())
  }

  async function requestRefreshToken() {
    try {
      await tokenStore.refresh()
    }
    catch (e) {
      if (process.server) {
        try {
          await tokenStore.getBaseToken()
          userStore.setData()
        }
        catch (e) {
          throw new context.$pageError({
            message: 'Ошибка при попытке обновить рефреш токен на сервере',
            code: 503,
            native: e,
          })
        }
      }
      else {
        new context.$baseError({
          message: 'Ошибка при попытке обновить базовый токен на клиенте',
          native: e,
        })
        throw new Error('Ошибка при попытке обновить базовый токен на клиенте')
      }
    }
  }

  function isTokenExpire(expired: number) {
    if (expired)
      return expired * 1000 < Date.now() + 2 * 60 * 60 * 1000 // Если токен сгорит через 2 часа (2 * 60 * 60 * 1000) вернётся true
    else return false
  }
})

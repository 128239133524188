
import FastAverageColor from 'fast-average-color';
import Color from 'color';
import { computed, onMounted, ref, shallowRef } from '@vue/composition-api';
import { useContext } from '@nuxtjs/composition-api';
import type Category from '~/type/Category';
const __sfc_main = {};
__sfc_main.props = {
  list: {
    key: "list",
    required: true,
    type: Array
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const colorList = ref<{
    [key: number]: string;
  }[]>([]);
  const context = useContext();
  const imgRef = shallowRef<HTMLImageElement[]>([]);
  const count = computed(() => props.list.length);
  const largeCount = computed(() => count.value >= 7 ? 1 : 0);
  onMounted(async () => {
    if (Array.isArray(imgRef.value)) {
      const fac = new FastAverageColor();
      for (const [index, el] of imgRef.value.entries()) {
        try {
          const color = await fac.getColorAsync(el, {
            algorithm: 'sqrt',
            ignoredColor: [[255, 255, 255, 170], [0, 0, 0, 255, 170]]
          });
          let c = Color(color.rgb);
          if (c.isDark()) c = c.fade(0.5);
          c = c.saturate(1).lightness(96);
          const updatedColorState = {
            ...colorList.value
          };
          updatedColorState[index] = c.rgb().toString();
          colorList.value = updatedColorState;
        } catch (e) {
          new context.$baseError({
            message: 'Не удалось определить средний цвет для фона',
            native: e
          });
          const updatedColorState = {
            ...colorList.value
          };
          updatedColorState[index] = '#f8f8f8';
          colorList.value = updatedColorState;
        }
      }
    }
  });
  return {
    emit,
    colorList,
    imgRef,
    count,
    largeCount
  };
};
export default __sfc_main;

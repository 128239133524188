import type { Events } from '~/plugins/emitter'

export default function viewCatalogPage({ id }: Events['view-catalog-page']) {
  return {
    name: 'view_category',
    data: {
      category_id: id,
    },
  }
}

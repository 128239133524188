import { CreateActionEntityMixin, CreateActionListMixin, CreateDataEntityMixin } from '05-ui-kit'
import Vue from 'vue'
import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import ErrorSerializer from '~/serializer/Error'

export default defineNuxtPlugin((context) => {
  Vue.component(
    'UIActionEntity',
    CreateActionEntityMixin({
      onError(error: { [key: string ]: unknown }) {
        const e = ErrorSerializer(error)
        /**
         * Если ошибка была не от API то пробрасываем её выше в глобальные обработчики и Sentry
         */
        if (e.type !== 'api')
          throw new context.$baseError(e)
      },
    }),
  )

  Vue.component(
    'UIActionList',
    CreateActionListMixin({
      onError(error: { [key: string ]: unknown }) {
        const e = ErrorSerializer(error)
        if (e.type !== 'api')
          throw new context.$baseError(e)
      },
    }),
  )

  Vue.component(
    'UIDataEntity',
    CreateDataEntityMixin({
      onError(error: { [key: string ]: unknown }) {
        const e = ErrorSerializer(error)
        if (e.type !== 'api')
          throw new context.$baseError(e)
      },
    }),
  )
})


/**
 * Экшен для работы с корзиной, с помощью него можно легко удалять или добавлять в корзину нужный товар
 */
import Tooltip from '05-ui-kit/lib/Tooltip'
import { mapActions, mapState } from 'pinia'
import dataActionMixin from '~/mixins/dataActionMixin'
import { useCartStore } from '~/store/cart'
import { useResizeStore } from '~/store/resize'
import { useUserToolStore } from '~/store/userTool'

export default {
  name: 'ActionCart',
  mixins: [dataActionMixin],
  inject: ['emitter'],
  props: {
    /* *
     * id товара, будет использоваться при запросе
     */
    id: {
      type: [Number, String],
      required: true,
    },
    /**
     * Товар, будет использоваться для аналитики и для запросов
     */
    product: {
      type: Object,
      default: () => ({}),
    },
    /**
     * начальное состояние элемента т.е. добавлен ли он уже в список на момент первоначальной загрузки компонента
     */
    active: {
      type: Boolean,
      default: null,
    },
    /**
     * Количество товара в корзине
     */
    quantity: {
      type: Number,
      default: null,
    },
    /**
     * Получать ли при добавлении тоаара количество с апи.
     */
    receiveQuantityFromApi: {
      type: Boolean,
      default: false,
    },
    /**
     * Показывает модальное окно при добавлении товара в корзину
     * @param { String } modal.image Изображение для превью в модалке
     * @param { String } modal.link Адрес детальной страницы
     * @param { String } modal.name Название товара для alt изображения
     * @param { String } modal.category Категория товара
     * @param { String } modal.offer Варинаты товара, цвет, память и т.д.
     * @param { String } modal.price Цена товара
     */
    modal: {
      type: Object,
      default: null,
    },
    // Место, откуда вызывается метод корзины, необходимо для аналитики
    source: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      externalAPI: {
        active: this.active,
        quantity: this.quantity,
      },
    }
  },
  computed: {
    ...mapState(useCartStore, {
      cartData: store => store?.data,
    }),
    ...mapState(useResizeStore, {
      isMobile: store => store.isMobile,
    }),
    isActive() {
      return !!this.cartData?.[this.id]
    },
    quantityItem() {
      return this.cartData?.[this.id]?.quantity
    },
  },
  watch: {
    isActive(active) {
      this.externalAPI.active = active
    },
    quantityItem(quantity) {
      this.externalAPI.quantity = quantity
    },
  },
  created() {
    this.userToolStore = useUserToolStore(this.$pinia)

    this.appendMethodToAPI({
      name: 'add',
      /**
       * @param {number} quantity количество товара которое надо добавить в корзину
       */
      fn: ({ id, quantity, modal } = {}) => {
        this.request({
          api: () => {
            /*  Ограничение на длину пользовательских списков определил бизнес, сейчас лимит 200  */
            if (this.cartData && Object.keys(this.cartData).length >= 200 && !this.$cookies.get('devMode')) {
              return Tooltip({
                type: 'error',
                title: 'Ошибка',
                description: 'Превышен лимит товаров в корзине',
                mobileOffset: [5, 5, 63, 5],
              })
            }
            else {
              return this.$api.cart.add({ id: id || this.id, quantity: quantity || 1 })
            }
          },
          onSuccess: (data) => {
            const count = this.receiveQuantityFromApi ? data : quantity
            /* Ограничение на длину пользовательских списков определил бизнес, сейчас лимит 200 */
            if (Object.keys(this.cartData || {}).length < 200 || this.$cookies.get('devMode')) {
              this.addToCart({
                id: id || this.id,
                payload: {
                  quantity: count || 1,
                },
              })
              this.externalAPI.response = data
              this.externalAPI.active = true
              if ((modal || this.modal) && !this.isMobile)
                this.userToolStore.showModalCart({ ...modal, ...this.modal, id: id || this.id })

              this.emitter.emit('cart-product-add', { product: { ...this.product, quantity: count ?? 1, source: this.source }, cart: this.cartData })
            }
          },
          onError: (error) => {
            const err = { ...error?.response }
            this.userToolStore.closeAllTools()

            this.externalAPI.response
              = error?.response?.data?.error?.description || 'Ошибка при добавлении товара в корзину, попробуйте позже'
            Tooltip({ type: 'error', title: 'Ошибка', description: err?.data?.error?.messages?.[0] || 'Не удалось добавить товар в корзину', mobileOffset: [5, 5, 63, 5] })
          },
        })
      },
    })

    this.appendMethodToAPI({
      name: 'remove',
      fn: ({ id } = {}) => {
        this.request({
          api: () => {
            return this.$api.cart.remove(id || this.id)
          },
          onSuccess: (data) => {
            this.removeFromCart({ id: id || this.id })
            this.externalAPI.response = data
            this.externalAPI.active = false

            if (this.modal)
              this.userToolStore.onChangeCart(false)

            this.emitter.emit('cart-product-remove', { product: { quantity: this.quantity, ...this.product }, cart: this.cartData })
          },
          onError: (error) => {
            /**
             * удаляем из pinia'и , чтобы убрать состояние, которое пользователь старается на клиенте изменить, но из-за ошибки не может
             * такая ситуация может возникнуть, если на сервере товара нет в списке, а на клиенте он есть
             */
            this.removeFromCart({ id: id || this.id })
            this.userToolStore.closeAllTools()

            this.externalAPI.response
              = error?.response?.data?.error?.description || 'Ошибка при удалении товара из корзины, попробуйте позже'
            Tooltip({ type: 'error', title: 'Ошибка', description: 'Не удалось удалить товар из корзины', mobileOffset: [5, 5, 63, 5] })
          },
        })
      },
    })

    this.appendMethodToAPI({
      name: 'increaseAmount',
      /**
       * @param {number} quantity количество товара которое надо добавить в корзину
       */
      fn: ({ id, quantity } = {}) => {
        this.request({
          api: () => {
            /* Ограничение на длину пользовательских списков определил бизнес, сейчас лимит 200 */
            if (Object.keys(this.cartData).length >= 200 && !this.$cookies.get('devMode')) {
              return Tooltip({
                type: 'error',
                title: 'Ошибка',
                description: 'Превышен лимит товаров в корзине',
              })
            }
            else {
              return this.$api.cart.add({ id: id || this.id, quantity: quantity || 1 })
            }
          },
          onSuccess: (data) => {
            const count = this.receiveQuantityFromApi ? data : quantity
            /* Ограничение на длину пользовательских списков определил бизнес, сейчас лимит 200 */
            if (Object.keys(this.cartData).length < 200 || this.$cookies.get('devMode')) {
              this.addToCart({
                id: id || this.id,
                payload: {
                  quantity: count || 1,
                },
              })
              this.externalAPI.response = data
              this.externalAPI.active = true
              this.emitter.emit('cart-product-add', { product: { quantity: count || 1, ...this.product, source: this.source } })
            }
          },
          onError: (error) => {
            this.userToolStore.closeAllTools()

            this.externalAPI.response
              = error?.response?.data?.error?.description || 'Ошибка при добавлении товара в корзину, попробуйте позже'
            Tooltip({ type: 'error', title: 'Ошибка', description: error?.response?.data?.error?.description || 'Не удалось добавить товар в корзину' })
          },
        })
      },
    })

    this.appendMethodToAPI({
      name: 'decreaseAmount',
      /**
       * @param {number} quantity количество товара которое надо добавить в корзину
       */
      fn: ({ id, quantity } = {}) => {
        this.request({
          api: () => {
            if (quantity) {
              /* Ограничение на длину пользовательских списков определил бизнес, сейчас лимит 200  */
              if (Object.keys(this.cartData).length >= 200 && !this.$cookies.get('devMode')) {
                return Tooltip({
                  type: 'error',
                  title: 'Ошибка',
                  description: 'Превышен лимит товаров в корзине',
                })
              }
              else {
                return this.$api.cart.add({ id: id || this.id, quantity: quantity || 1 })
              }
            }
            else {
              this.$emit('delete', id || this.id)
              return this.$api.cart.remove(id || this.id)
            }
          },
          onSuccess: (data) => {
            if (quantity) {
              /* Ограничение на длину пользовательских списков определил бизнес, сейчас лимит 200  */
              if (Object.keys(this.cartData).length < 200 || this.$cookies.get('devMode')) {
                this.addToCart({
                  id: id || this.id,
                  payload: {
                    quantity: quantity || 1,
                  },
                })
                this.externalAPI.response = data
                this.externalAPI.active = true
              }
            }
            else {
              this.removeFromCart({ id: id || this.id })
              this.externalAPI.response = data
              this.externalAPI.active = false
              this.emitter.emit('cart-product-remove', { product: { quantity, ...this.product } })
            }
          },
          onError: (error) => {
            this.userToolStore.closeAllTools()

            this.externalAPI.response
              = error?.response?.data?.error?.description || 'Ошибка при удалении товара из корзины, попробуйте позже'
            Tooltip({ type: 'error', title: 'Ошибка', description: 'Не удалось удалить товар из корзины' })
          },
        })
      },
    })
  },
  mounted() {
    if (this.externalAPI.active === null)
      this.externalAPI.active = this.isActive

    if (this.externalAPI.quantity === null)
      this.externalAPI.quantity = this.quantityItem
  },
  methods: {
    ...mapActions(useCartStore, {
      addToCart: 'add',
      removeFromCart: 'remove',
    }),
  },
}

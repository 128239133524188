var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    attrs: {
      "mobile-fullscreen": "",
      "entry-animation": "fade-in",
      "exit-animation": "fade-out-left-big",
      "show": _vm.isModalActive,
      "padding": "0 20px 0 0",
      "max-width": _vm.showCityList ? '1120px' : '650px'
    },
    on: {
      "close": function ($event) {
        return _vm.emit('close');
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm.selectedCity ? _c('h3', {
          staticClass: "h3 choose-city__title"
        }, [_vm._v("\n      Выберите город\n    ")]) : _c('h4', {
          staticClass: "h4"
        }, [_vm._v("\n      Ваш город "), _c('span', {
          staticClass: "choose-city__highlighted"
        }, [_vm._v(_vm._s(_vm.currentCityName))]), _vm._v(" ?\n    ")])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c('div', {
    staticClass: "choose-city"
  }, [_vm.isDagestanConfirmed && !_vm.selectedCity ? _c('div', [_c('p', {
    staticClass: "choose-city__text mb-12"
  }, [_vm._v("\n        Важно! От выбранного города зависят актуальные цены, наличие и доставка товаров на сайте.\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "choose-city__buttons"
  }, [_c('Button', {
    staticClass: "fs-13 mr-13",
    on: {
      "click": _vm.onSelectCity
    }
  }, [_vm._v("\n          Да\n        ")]), _vm._v(" "), _c('Button', {
    staticClass: "fs-13",
    attrs: {
      "theme": "white"
    },
    on: {
      "click": _vm.showCities
    }
  }, [_vm._v("\n          Нет, выбрать другой\n        ")])], 1)]) : _vm._e(), _vm._v(" "), _vm.showCityList ? _c('UIDataEntity', {
    attrs: {
      "get": _vm.$api.location.getCitiesWithCache
    },
    on: {
      "success": _vm.onCityListLoad
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var _vm$cityList, _vm$searchResult;

        var loading = _ref.loading,
            error = _ref.error;
        return [loading ? _c('div', [_c('InputText', {
          staticClass: "my-13",
          attrs: {
            "placeholder": "Введите город",
            "icon": "search",
            "disabled": ""
          },
          model: {
            value: _vm.query,
            callback: function ($$v) {
              _vm.query = $$v;
            },
            expression: "query"
          }
        }), _vm._v(" "), _c('StatusBlock', {
          attrs: {
            "size": "s",
            "type": "loading"
          }
        })], 1) : error ? _c('div', {
          staticClass: "mt-22"
        }, [_c('StatusBlock', {
          attrs: {
            "size": "s",
            "type": "error",
            "title": "При загрузке списка городов произошла ошибка"
          }
        })], 1) : _c('div', [(_vm$cityList = _vm.cityList) !== null && _vm$cityList !== void 0 && _vm$cityList.length ? _c('CitySearchField', {
          staticClass: "choose-city__field",
          attrs: {
            "list": _vm.cityList
          },
          on: {
            "input": _vm.input,
            "search-result": _vm.getSearchResult
          }
        }) : _vm._e(), _vm._v(" "), _c('div', {
          staticClass: "choose-city__top-cities"
        }, _vm._l(_vm.topCities, function (city) {
          return _c('span', {
            key: city.id,
            staticClass: "choose-city__top-city",
            on: {
              "click": function ($event) {
                return _vm.onClick(city);
              }
            }
          }, [_vm._v(_vm._s(city.name))]);
        }), 0), _vm._v(" "), _c('div', {
          staticClass: "choose-city__scroll-wrap"
        }, [_c('ScrollGradient', [!_vm.query ? _c('div', {
          staticClass: "choose-city__top-cities-mobile"
        }, _vm._l(_vm.topCities, function (city) {
          return _c('div', {
            key: city.id,
            staticClass: "choose-city__list-el"
          }, [_c('span', {
            staticClass: "link link_wu",
            on: {
              "click": function ($event) {
                return _vm.onClick(city);
              }
            }
          }, [_vm._v(_vm._s(city.name))])]);
        }), 0) : _vm._e(), _vm._v(" "), _vm.cityList && _vm.cityList.length ? _c('div', {
          staticClass: "choose-city__grid"
        }, [_vm.query ? _c('div', [(_vm$searchResult = _vm.searchResult) !== null && _vm$searchResult !== void 0 && _vm$searchResult.length ? _c('div', {
          staticClass: "choose-city__col"
        }, _vm._l(_vm.searchResult, function (cityListBlock, i) {
          return _c('div', {
            key: i,
            staticClass: "choose-city__col",
            class: [{
              'choose-city__col_last': _vm.searchResult.length === 1
            }]
          }, [_c('div', {
            staticClass: "choose-city__col-wrap"
          }, [_c('div', {
            staticClass: "choose-city__col-char"
          }, [_vm._v("\n                        " + _vm._s(cityListBlock.item.char) + "\n                      ")]), _vm._v(" "), _c('div', {
            staticClass: "choose-city__list"
          }, _vm._l(cityListBlock === null || cityListBlock === void 0 ? void 0 : cityListBlock.matches, function (city, index) {
            return _c('div', {
              key: index,
              staticClass: "choose-city__list-el"
            }, [_c('span', {
              staticClass: "link link_wu",
              on: {
                "click": function ($event) {
                  return _vm.onClickSearch(city.value);
                }
              }
            }, [_vm._v(_vm._s(city.value))])]);
          }), 0)])]);
        }), 0) : _c('div', {
          staticClass: "h5 mt-15 grey-text choose-city__col choose-city__not-found"
        }, [_vm._v("\n                  Город не найден\n                  "), _c('img', {
          staticClass: "choose-city__smile",
          attrs: {
            "src": require("@/assets/images/svg/smile.svg"),
            "width": "25",
            "height": "25"
          }
        })])]) : _vm.cityList.length ? _vm._l(_vm.cityList, function (city, index) {
          var _city$list;

          return _c('div', {
            key: index,
            staticClass: "choose-city__col"
          }, [city !== null && city !== void 0 && (_city$list = city.list) !== null && _city$list !== void 0 && _city$list.length ? _c('div', {
            staticClass: "choose-city__col-wrap"
          }, [_c('div', {
            staticClass: "choose-city__col-char"
          }, [_vm._v("\n                      " + _vm._s(city.char) + "\n                    ")]), _vm._v(" "), _c('div', {
            staticClass: "choose-city__list"
          }, _vm._l(city.list, function (item) {
            return _c('div', {
              key: item.id,
              staticClass: "choose-city__list-el"
            }, [_c('span', {
              staticClass: "link link_wu",
              on: {
                "click": function ($event) {
                  return _vm.onClick(item);
                }
              }
            }, [_vm._v(_vm._s(item.name))])]);
          }), 0)]) : _vm._e()]);
        }) : _vm._e()], 2) : _vm._e()])], 1)], 1)];
      }
    }], null, false, 42816140)
  }) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
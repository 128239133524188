var render = function () {
  var _vm$bonus, _vm$bonus2, _vm$bonus3, _vm$bonus4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-controller",
    class: {
      'info-controller_mobile': _vm.hideDKBanner
    }
  }, [_c('div', {
    staticClass: "info-controller__wrap"
  }, [_c('div', {
    staticClass: "info-controller__wrap-connected"
  }, [_vm.isUser ? _c('div', {
    staticClass: "info-controller__left-side"
  }, [_vm.loading ? _c('div', {
    staticClass: "info-controller__loading"
  }) : _vm._e(), _vm._v(" "), _c('client-only', [!_vm.error.order ? _c('UIDataEntity', {
    key: "order",
    staticClass: "info-controller__order",
    attrs: {
      "get": _vm.$api.order.getOrderStatus,
      "params": {
        size: 1
      }
    },
    on: {
      "success": _vm.onOrderSuccess,
      "loading": function ($event) {
        _vm.loading = true;
      },
      "error": function ($event) {
        _vm.error.order = true;
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var _response$order, _response$order2, _response$order3, _response$order3$stat;

        var response = _ref.response,
            success = _ref.success;
        return [success ? _c('OrderStatus', {
          attrs: {
            "id": response === null || response === void 0 ? void 0 : (_response$order = response.order) === null || _response$order === void 0 ? void 0 : _response$order.id,
            "price": response === null || response === void 0 ? void 0 : (_response$order2 = response.order) === null || _response$order2 === void 0 ? void 0 : _response$order2.price,
            "status": response === null || response === void 0 ? void 0 : (_response$order3 = response.order) === null || _response$order3 === void 0 ? void 0 : (_response$order3$stat = _response$order3.status) === null || _response$order3$stat === void 0 ? void 0 : _response$order3$stat.typeMain
          }
        }) : _vm._e()];
      }
    }], null, false, 278043872)
  }) : (_vm$bonus = _vm.bonus) !== null && _vm$bonus !== void 0 && _vm$bonus.balance && ((_vm$bonus2 = _vm.bonus) === null || _vm$bonus2 === void 0 ? void 0 : _vm$bonus2.balance) < 30 || !_vm.error.cart ? _c('UIDataEntity', {
    key: "cart",
    attrs: {
      "get": _vm.$api.cart.getTotalForUser
    },
    on: {
      "success": _vm.onCartSuccess,
      "loading": function ($event) {
        _vm.loading = true;
      },
      "error": function ($event) {
        _vm.error.cart = true;
        _vm.loading = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var response = _ref2.response,
            success = _ref2.success;
        return [success ? _c('InfoBlockCart', {
          attrs: {
            "quantity": response.total.quantity,
            "price": response.total.price.total
          }
        }) : _vm._e()];
      }
    }], null, false, 2603035724)
  }) : (_vm$bonus3 = _vm.bonus) !== null && _vm$bonus3 !== void 0 && _vm$bonus3.balance ? _c('InfoBlockBonus', {
    staticClass: "info-controller__bonus",
    attrs: {
      "count-bonuses": (_vm$bonus4 = _vm.bonus) === null || _vm$bonus4 === void 0 ? void 0 : _vm$bonus4.balance
    }
  }) : _vm._e()], 1)], 1) : _c('div', {
    staticClass: "info-controller__authorization",
    on: {
      "click": _vm.onAuthBannerClick
    }
  }, [!_vm.showExperiment || _vm.showExperiment === 1 ? _c('InfoBlockAuthorization') : _vm._e(), _vm._v(" "), _vm.showExperiment === 2 ? _c('InfoBlockAuthAnimated') : _vm._e(), _vm._v(" "), _vm.showExperiment === 3 ? _c('InfoBlockAuthBonus') : _vm._e(), _vm._v(" "), _vm.showExperiment === 4 ? _c('InfoBlockAuthCard') : _vm._e()], 1)]), _vm._v(" "), _c('InfoBlockDarkstoreMakhachkala', {
    staticClass: "info-controller__dk"
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./ColorAvatar.vue?vue&type=template&id=16507d0e&scoped=true&"
import script from "./ColorAvatar.vue?vue&type=script&lang=ts&"
export * from "./ColorAvatar.vue?vue&type=script&lang=ts&"
import style0 from "./ColorAvatar.vue?vue&type=style&index=0&id=16507d0e&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16507d0e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LazyImg: require('/app/components/LazyImg/LazyImg.vue').default})

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/main-slider/dots.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".slide[data-v-6b3d5028]{position:relative;flex-shrink:0}.slide[data-v-6b3d5028]:hover{cursor:pointer}.slide__el[data-v-6b3d5028]{z-index:0;display:block;width:960px;height:345px;background-repeat:no-repeat;background-position:50%;background-size:cover;border-radius:3px;outline:none!important}.slide__tooltip[data-v-6b3d5028]{position:absolute;right:0;bottom:0;display:flex;justify-content:flex-end;width:100%}.slide__advertising[data-v-6b3d5028]{position:relative;padding:2px 20px 2px 8px;font-size:11px;background:hsla(0,0%,100%,.9490196078431372);border-radius:4px 0 0 0}.slide__advertising[data-v-6b3d5028]:after{position:absolute;right:0;width:20px;height:16px;content:\"\";background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:20px}@media (max-width:1220px){.slide__el[data-v-6b3d5028]{width:770px;height:276px}}@media (max-width:1000px){.slide__el[data-v-6b3d5028]{width:550px;height:197px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

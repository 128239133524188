
import { CreateActionListMixin } from '05-ui-kit'
import ErrorSerializer from '~/serializer/Error'

export default {
  mixins: [CreateActionListMixin({
    onError(error: { [key: string ]: unknown }) {
      const e = ErrorSerializer(error)
      /**
         * Если ошибка была не от API то пробрасываем её выше в глобальные обработчики и Sentry
         */
      if (e.type !== 'api')
        throw new this.$baseError(e)
    },
  })],
}

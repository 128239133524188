// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/svg/chat-icon.svg?original");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".chat-custom-widget[data-v-a1ddd616]{position:fixed;right:25px;bottom:25px;z-index:100;width:49px;height:49px;cursor:pointer;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),linear-gradient(134.83deg,#ff6b27 -17.61%,red 85.71%);background-repeat:no-repeat;background-position:50%;border-radius:50%;transition:background .2s}.chat-custom-widget[data-v-a1ddd616]:hover{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),linear-gradient(134.83deg,#9f4115 -17.61%,red 85.71%);background-repeat:no-repeat;background-position:50%}@media (max-width:800px){.chat-custom-widget[data-v-a1ddd616]{display:none!important}}.bounce-enter-active[data-v-a1ddd616]{animation:bounce-in-data-v-a1ddd616 .5s}.bounce-leave-active[data-v-a1ddd616]{animation:bounce-in-data-v-a1ddd616 .5s reverse}.code-enter-active[data-v-a1ddd616]{animation:code-in-data-v-a1ddd616 .5s}.code-leave-active[data-v-a1ddd616]{animation:code-in-data-v-a1ddd616 .5s reverse}@keyframes bounce-in-data-v-a1ddd616{0%{transform:translate(-50%,100px)}50%{transform:translate(-50%,-85px)}to{transform:translate(-50%,-35px)}}@keyframes code-in-data-v-a1ddd616{0%{transform:translate(-50%,100px)}50%{transform:translate(-50%,-85px)}to{transform:translate(-50%,-35px)}}@media (max-width:800px){@keyframes bounce-in-data-v-a1ddd616{0%{transform:translateY(100px)}to{transform:translateY(min(calc(-50px - env(safe-area-inset-bottom)),-58px))}}@keyframes code-in-data-v-a1ddd616{0%{transform:translate(-50%,100px)}to{transform:translate(-50%,min(calc(-50px - env(safe-area-inset-bottom)),-66px))}}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

import type { Context } from '@nuxt/types'
import { stringify } from 'qs'
import BrandSerializer from '~/serializer/Brand'
import BrandGroupSerializer from '~/serializer/BrandGroup'
import BrandPageSerializer from '~/serializer/pages/BrandPage'
import PromoBrandSerializer from '~/serializer/pages/PromoBrand'
import type Brand from '~/type/Brand'

export default {
  async getByID(this: Context, { id, sort, page, size }: { id: number; sort: string; page: string; size: number }) {
    const result = (await this.$axios.$get(`/api/v1/brand/${id}?${stringify({ sort, page, size })}`))?.result
    return {
      result: BrandPageSerializer(result),
    }
  },

  async get(this: Context, { count = 40 } = {}) {
    const { $axios, $nodeCache } = this
    return await $nodeCache.helper<{
      groupped: ReturnType<typeof BrandGroupSerializer>[]
      popular: ReturnType<typeof BrandSerializer>[]
    }>({
      key: 'brand-list',
      request: async () => {
        const list = {
          groupped: (await $axios.$get('/api/v1/brand/groupped')).result,
          popular: (await $axios.$get(`/api/v1/brand/populars?size=${count}`)).result,
        }

        list.popular = list.popular.map((el: any) => BrandSerializer(el))
        list.groupped = list.groupped.map((el: any) => BrandGroupSerializer(el))

        return {
          result: { list },
        }
      },
    })
  },

  async getPopular(this: Context, { count }: { count: number }) {
    const { $axios } = this
    const response: Brand[] = (await $axios.$get(`/api/v1/brand/populars?size=${count}`))?.result.map((el: any) => BrandSerializer(el))

    return {
      result: response || [],
    }
  },

  async getPromoByCode(this: Context, { code }: { code: string }) {
    const { $axios } = this
    const result = (await $axios.$get(`/api/v1/promo-brands/${code}`))?.result

    return {
      result: PromoBrandSerializer(result),
    }
  },
}

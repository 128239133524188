interface Params {
  type: 'website' | 'product' | 'product.group'
  title?: string
  url: string
  image: string
  description?: string
  priceAmount?: number
  siteName?: string
}

interface Meta {
  property: string
  hid: string
  content?: string | number
}

/**
 * Функция для получения разметки Open Graph, подключается в head
 */
export default function getOpenGraphMarkup(
  { type, title, url, description, image, priceAmount, siteName }: Params,
): Meta[] {
  const meta = [
    { property: 'og:title', hid: 'og:title', content: title },
    { property: 'og:site_name', hid: 'og:site_name', content: siteName ?? '05.ru' },
    { property: 'og:url', hid: 'og:url', content: url },
    { property: 'og:type', hid: 'og:type', content: type },
    { property: 'og:image', hid: 'og:image', content: image },
    { property: 'og:description', hid: 'og:description', content: description ?? '' },
  ]

  if (type !== 'product')
    return meta
  else
    return [...meta, { property: 'product:price.amount', hid: 'product:price.amount', content: priceAmount }, { property: 'product:price.currency', hid: 'product:price.currency', content: 'RUB' }]
}


import { computed, inject, ref } from '@vue/composition-api';
import { onMounted, watch } from '@nuxtjs/composition-api';
import type { EmitteryType } from '~/plugins/emitter';
import { useResizeStore } from '~/store/resize';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const resizeStore = useResizeStore();
  const emitter = inject<EmitteryType>('emitter')!;
  const pageWidth = resizeStore.resize;
  const data = ref([{
    id: 'scrollbar_element1',
    key: 'iphone16',
    name: 'IPhone 16',
    link: '/sales/iphone_16_series_start_prodagh/',
    isNew: true
  }, {
    id: 'scrollbar_market',
    key: 'market',
    name: 'Маркетплейс \n от 05.ru',
    link: 'https://market.05.ru/?utm_source=05ru&utm_medium=referrer&utm_campaign=scroll-bar-main-web'
  }, {
    id: 'scrollbar_element2',
    key: 'sale',
    name: 'Акции',
    link: '/sales/'
  }, {
    id: 'scrollbar_element3',
    key: 'installment',
    name: 'Рассрочка',
    link: '/about/rassrochka/'
  }, {
    id: 'scrollbar_element4',
    key: 'assembly',
    name: 'Сборки ПК',
    link: '/promo/66a21b3deae9773207f38bb0/'
  }, {
    id: 'scrollbar_element5',
    key: 'iphone15',
    name: 'IPhone 15',
    link: '/brands/apple2023/'
  }, {
    id: 'scrollbar_element6',
    key: 'phone-button',
    name: 'Кнопочные',
    link: '/catalog/portativ/sotovye_telefony/'
  }, {
    id: 'scrollbar_element7',
    key: 'router',
    name: 'Wi-Fi роутеры',
    link: '/catalog/network/net/'
  }, {
    id: 'scrollbar_element8',
    key: 'clock',
    name: 'Смарт-часы',
    link: '/catalog/portativ/smart_chasy_i_braslety/'
  }, {
    id: 'scrollbar_element9',
    key: 'kitchen',
    name: 'Для кухни',
    link: '/catalog/appliance/small_kitchen/'
  }, {
    id: 'scrollbar_element10',
    key: 'house',
    name: 'Для дома',
    link: '/catalog/appliance/home_appliances/'
  }, {
    id: 'scrollbar_element11',
    key: 'for-kids',
    name: 'Для детей',
    link: '/catalog/bytovaya_himiya_i_gigiena/detskaya_gigiena/'
  }, {
    id: 'scrollbar_element12',
    key: 'tablet',
    name: 'Планшеты',
    link: '/catalog/portativ/tablets/'
  }, {
    id: 'scrollbar_element13',
    key: 'armchair',
    name: 'Кресла',
    link: '/catalog/remont/kresla/'
  }, {
    id: 'scrollbar_app',
    key: 'app',
    name: 'Приложение',
    link: 'http://onelink.to/awdzw'
  }]);
  const isOverlayHide = ref(true);
  watch(() => pageWidth, () => {
    isOverlayHandler();
  });
  onMounted(() => {
    isOverlayHandler();
  });
  const getCategory = computed(() => {
    // Так как мы часто добавляем в популярные категории разного рода акции
    // проставляем айди для категорий тут чтобы не менять их вручную

    return data.value.map((el, index) => {
      if (el.key === 'app' || el.key === 'market') {
        return el;
      } else {
        return {
          ...el,
          id: `scrollbar_element${index + 1}`
        };
      }
    });
  });
  function isOverlayHandler(isEnd?: boolean) {
    if (pageWidth && pageWidth < 1940) isEnd ? isOverlayHide.value = true : isOverlayHide.value = false;else isOverlayHide.value = true;
  }
  return {
    emitter,
    isOverlayHide,
    getCategory,
    isOverlayHandler
  };
};
export default __sfc_main;

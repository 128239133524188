/**
 * Функция формирует массив для пагинации состаяющий из страниц, которые нужно отобразить в пагинации
 * @property {number} wrapper - элемент на который будет подписываться слушатель
 * @property {number} trashold - кол-во страниц
 * @property {number} heightFromTop - количество пикселей от верха страницы когда скролл должен выключаться
 * @property {function} onEnable - срабатывает в момент показа
 * @property {function} onDisable - срабатывает в момент скрытия
 * @property {function} onRemove - срабатывает в момент скролла до вверха страницы
 * @returns {array}
 */

export default function scrollBar({
  wrapper = document.documentElement,
  trashold = 20,
  heightFromTop = 100,
  onRemove = () => {},
  onEnable = () => {},
  onDisable = () => {},
}: {
  wrapper?: HTMLElement | Window
  trashold?: number
  heightFromTop: number
  onRemove: () => void
  onEnable: () => void
  onDisable: () => void
}) {
  let lastScrollPosition = 0
  let toTopYStart: number | null
  let toBottomYStart: number | null
  let active: boolean

  wrapper.addEventListener('scroll', onScroll, { passive: true })

  function onScroll() {
    if (window.scrollY < heightFromTop) {
      onRemove()
    }
    else if (lastScrollPosition - window.scrollY > 0) {
      toBottomYStart = null
      scrollToTop()
    }
    else {
      toTopYStart = null
      scrollToBottom()
    }
    lastScrollPosition = window.scrollY
  }

  function scrollToTop() {
    toTopYStart = toTopYStart || window.scrollY
    const isNeedToEnable = toTopYStart - window.scrollY > trashold
    if (isNeedToEnable && !active) {
      onEnable()
      active = true
    }
  }

  function scrollToBottom() {
    toBottomYStart = toBottomYStart || window.scrollY
    const isNeedToDisable = window.scrollY - toBottomYStart > trashold
    if (isNeedToDisable && active) {
      onDisable()
      active = false
    }
  }

  function isActive() {
    return active
  }

  function reset() {
    wrapper.removeEventListener('scroll', onScroll)
    onRemove()
  }

  return {
    isActive,
    reset,
  }
}

import type { Events } from '~/plugins/emitter'

export default function saleSelect({ sale }: Events['sale-select']) {
  return {
    event: 'select_promotion',
    ecommerce: {
      items: [
        {
          promotion_id: sale?.id.toString(),
          promotion_name: sale?.marker.name,
        },
      ],
    },
  }
}

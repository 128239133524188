import type { Context } from '@nuxt/types'
import { stringify } from 'qs'
import { ProductSerialize } from '~/serializer/product/Product'
import type { Product } from '~/type/product/Product'

export default {
  async getByID(this: Context, { type, id }: { type: string; id: number }): Promise<{
    result: {
      GROUP_NAME: string
      ITEMS: Product[]
      TITLE: string
      product: Product[]
    }
  }> {
    const { $axios } = this

    const result = (
      await $axios.$get(
        `/api/v1/sliders?${stringify({
          type,
          section_id: id,
        })}`,
      )
    )?.result

    return {
      result: {
        ...result,
        product: result?.ITEMS?.length ? result.ITEMS.map((item: Product) => ProductSerialize(item)) : [],
      },
    }
  },
}

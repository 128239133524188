import { render, staticRenderFns } from "./ReviewCard.vue?vue&type=template&id=3cc540c5&scoped=true&"
import script from "./ReviewCard.vue?vue&type=script&lang=ts&"
export * from "./ReviewCard.vue?vue&type=script&lang=ts&"
import style0 from "./ReviewCard.vue?vue&type=style&index=0&id=3cc540c5&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cc540c5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LazyImg: require('/app/components/LazyImg/LazyImg.vue').default,TSProductRating: require('/app/components/ts-components/TSProductRating/TSProductRating.vue').default,ColorAvatar: require('/app/components/ColorAvatar/ColorAvatar.vue').default})

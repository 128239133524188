import { defineStore } from 'pinia'
import { computed, ref } from '@vue/composition-api'
import type { Context } from '@nuxt/types'
import { useUserListStore } from './userLists'
import { useTokenStore } from '~/store/token'
import type User from '~/type/User'
import { useBonusStore } from '~/store/bonus'
import { useCartStore } from '~/store/cart'

export const useUserStore = function wrapperStore(context?: Context) {
  const tmp = defineStore('user', () => {
    const user = ref<User>()
    const ip = ref<string>()

    function setData(data?: User) {
      user.value = data
    }

    function setIpAddress(value?: string) {
      ip.value = value
    }

    async function logout() {
      try {
        const tokenStore = useTokenStore(context)
        await tokenStore.reset()
        const bonusStore = useBonusStore(context?.$pinia)
        const userListsStore = useUserListStore(context?.$pinia)
        userListsStore.setLists({ type: 'user', lists: {} })
        const cartStore = useCartStore(context?.$pinia)
        cartStore.reset()
        bonusStore.reset()
        setData()
        /**
         * удаляем расшаренный токен, если он есть, потому что мы только что разлогинились и он больше не актуален
         */
        context?.$cookies.remove('user-access-token')
      }

      catch (e) {
        throw new Error(`Ошибка при логауте, ${e}`)
      }
    }

    const userAuth = computed(() => Boolean(user.value))
    const name = computed(() => user.value?.name || user.value?.login || `Пользователь ${user.value?.id}`)
    const isGuest = computed(() => !user.value)
    const isUser = computed(() => !!user.value)

    return {
      user,
      ip,
      setIpAddress,
      setData,
      logout,
      userAuth,
      name,
      isGuest,
      isUser,
    }
  }, {
    persist: true,
    share: {
      enable: true,
    },
  })
  return tmp()
}

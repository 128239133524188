import { defineNuxtPlugin } from '@nuxtjs/composition-api'

declare global {
  let PopMechanic: { update?: () => {}; watchLocation: boolean }
  let mindbox: {
    (type: string, value: {
      endpointId: string
    }): void
    queue: any[]
  }
  interface Window {
    PopMechanic: typeof PopMechanic
    mindbox: (type: string, value: {
      operation: string
      data: unknown
    }) => void
    PopMechanicPageData: {
      type: string
      category: {
        id: string
        name: string
      }
    }
  }
}

export default defineNuxtPlugin(({ app, $config }) => {
  // PopMechanic
  const script = document.createElement('script')
  script.src = 'https://static.popmechanic.ru/service/loader.js?c=31066'
  script.async = true
  script.id = 'popmechanic-script'

  script.onload = () => {
    app.router?.afterEach(() => {
      /**
       * Надо вызывать при изменении урла
       * в режиме SPA
       */
      PopMechanic.update?.()
    })
  }
  /**
   * Режим SPA для mindbox/PopMechanic
   */

  window.PopMechanic = { watchLocation: false }

  document.body.appendChild(script)

  // Mindboxs
  window.mindbox
    = window.mindbox
    || function () {
      // eslint-disable-next-line prefer-rest-params
      mindbox.queue.push(arguments)
    }
  mindbox.queue = mindbox.queue || []
  mindbox('create', {
    endpointId: $config.deployEnvironment === 'production' ? '05ru-website' : '05ru-website-test',
  })

  const scriptMindbox = document.createElement('script')
  scriptMindbox.src = 'https://api.mindbox.ru/scripts/v1/tracker.js'
  script.async = true
  document.getElementsByTagName('head')[0].appendChild(scriptMindbox)
})

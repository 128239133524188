
import { computed } from '@vue/composition-api';
import { useRouter } from '@nuxtjs/composition-api';
import { Button } from '05-ui-kit';
import { useCartStore } from '~/store/cart';
import { useUserStore } from '~/store/user';
import { useUserToolStore } from '~/store/userTool';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const cartStore = useCartStore();
  const userStore = useUserStore();
  const userToolStore = useUserToolStore();
  const router = useRouter();
  const cartAmount = computed(() => cartStore.cartAmount);
  const userAuth = computed(() => userStore.userAuth);
  const isActiveButton = computed(() => !!cartAmount.value && userAuth.value);
  function handleToCart() {
    router.push('/cart/', close);
  }
  function handleMouseEnter() {
    userToolStore.clearTimer();
  }
  function handleMouseLeave() {
    userToolStore.setTimer();
  }
  function close() {
    userToolStore.onCopyCode({
      active: false
    });
  }
  return {
    userToolStore,
    isActiveButton,
    handleToCart,
    handleMouseEnter,
    handleMouseLeave,
    close
  };
};
__sfc_main.components = Object.assign({
  Button
}, __sfc_main.components);
export default __sfc_main;

import { render, staticRenderFns } from "./InfoBlockController.vue?vue&type=template&id=5985ee24&scoped=true&"
import script from "./InfoBlockController.vue?vue&type=script&lang=ts&"
export * from "./InfoBlockController.vue?vue&type=script&lang=ts&"
import style0 from "./InfoBlockController.vue?vue&type=style&index=0&id=5985ee24&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5985ee24",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrderStatus: require('/app/components/OrderStatus/OrderStatus.vue').default,InfoBlockCart: require('/app/components/InfoBlocks/InfoBlockCart/InfoBlockCart.vue').default,InfoBlockBonus: require('/app/components/InfoBlocks/InfoBlockBonus/InfoBlockBonus.vue').default,InfoBlockAuthorization: require('/app/components/InfoBlocks/InfoBlockAuthorization/InfoBlockAuthorization.vue').default,InfoBlockAuthAnimated: require('/app/components/InfoBlocks/InfoBlockAuthAnimated/InfoBlockAuthAnimated.vue').default,InfoBlockAuthBonus: require('/app/components/InfoBlocks/InfoBlockAuthBonus/InfoBlockAuthBonus.vue').default,InfoBlockAuthCard: require('/app/components/InfoBlocks/InfoBlockAuthCard/InfoBlockAuthCard.vue').default,InfoBlockDarkstoreMakhachkala: require('/app/components/InfoBlocks/InfoBlockDarkstoreMakhachkala/InfoBlockDarkstoreMakhachkala.vue').default})

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "stories-slide",
    class: [{
      'stories-slide_all-promo': !_vm.src
    }]
  }, [_vm.src ? _c('nuxt-link', {
    staticClass: "stories-slide__link",
    attrs: {
      "to": _vm.link
    }
  }, [_c('img', {
    staticClass: "stories-slide__image",
    attrs: {
      "src": _vm.src,
      "srcset": _vm.srcset,
      "alt": _vm.alt
    }
  })]) : _c('nuxt-link', {
    staticClass: "stories-slide__link stories-slide__link_all-promo",
    attrs: {
      "to": "/sale"
    }
  }, [_c('p', {
    staticClass: "p b"
  }, [_vm._v("\n      Все акции »\n    ")]), _vm._v(" "), _c('p', {
    staticClass: "stories-slide__description"
  }, [_vm._v("\n      Узнайте о всех актуальных акциях\n    ")])]), _vm._v(" "), _vm.advertising ? _c('div', {
    staticClass: "stories-slide__advertising",
    on: {
      "click": function ($event) {
        return _vm.emit('advertising');
      }
    }
  }, [_vm._v("\n    Реклама\n    "), _c('img', {
    staticClass: "stories-slide__advertising-img",
    attrs: {
      "src": require("@/assets/images/main-slider/dots.svg"),
      "alt": "Реклама"
    }
  })]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }

import TSPrice from '~/components/ts-components/TSPrice/TSPrice.vue';
import TSCounter from '~/components/ts-components/TSCounter/TSCounter.vue';
import type { CartProduct } from '~/type/product/Product';
import type { Price } from '~/type/Price';
const __sfc_main = {};
__sfc_main.props = {
  item: {
    key: "item",
    required: true,
    type: null
  },
  id: {
    key: "id",
    required: true,
    type: Number
  },
  enableQuantity: {
    key: "enableQuantity",
    required: false,
    type: Boolean,
    default: false
  },
  loading: {
    key: "loading",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  function handleRemove() {
    emit('remove', props.item);
  }
  function onChange(type: 'minus' | 'plus', quantity: number) {
    if (type === 'minus') emit('minus', {
      id: props.id,
      quantity: +quantity,
      price: props.item?.price
    });
    if (type === 'plus') emit('plus', {
      id: props.id,
      quantity: +quantity,
      price: props.item?.price
    });
  }
  return {
    handleRemove,
    onChange
  };
};
__sfc_main.components = Object.assign({
  TSPrice,
  TSCounter
}, __sfc_main.components);
export default __sfc_main;


import { Button } from '05-ui-kit';
import { formatPrice } from '@artmizu/utils';
import { computed, inject, onMounted, ref } from '@vue/composition-api';
import { useCartStore } from '~/store/cart';
import type { EmitteryType } from '~/plugins/emitter';
import type { CartProduct } from '~/type/product/Product';
import UserMenu from '~/components/user/UserMenu/UserMenu.vue';
import UserPopup from '~/components/user/UserPopup/UserPopup.vue';
import UserPopupItem from '~/components/user/UserPopupItem/UserPopupItem.vue';
import ActionCompare from '~/action/ActionCompare.vue';
import ActionFavorite from '~/action/ActionFavorite.vue';
import ActionEntity from '~/action/ActionEntity.vue';
import ActionCart from '~/action/ActionCart.vue';
import { useUserListStore } from '~/store/userLists';
interface ListsType {
  list: CartProduct[];
  previous: string[];
  type?: 'compare' | 'favorite' | 'cart';
}
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const userListStore = useUserListStore();
  const cartStore = useCartStore();
  const emitter = inject<EmitteryType>('emitter')!;
  const compare = ref<ListsType>({
    list: [],
    previous: []
  });
  const favorite = ref<ListsType>({
    list: [],
    previous: []
  });
  const cart = ref<ListsType>({
    list: [],
    previous: [],
    type: 'cart'
  });
  const loadingActionCart = ref(false);
  const isLoadingCart = ref(false);
  const favoriteCount = computed<number>(() => userListStore.favoriteAmount);
  const favoriteIDArray = computed<string[]>(() => userListStore.favoriteIDArray);
  const compareCount = computed<number>(() => userListStore.compareAmount);
  const compareIDArray = computed<string[]>(() => userListStore.compareIDArray);
  const cartItemArray = computed(() => cartStore.data);
  const cartCount = computed(() => cartStore.cartAmount);
  const cartIDArray = computed(() => cartStore.cartIDArray);
  const sumAllProducts = ref('');
  onMounted(() => {
    if (favoriteIDArray.value?.length) emitter.emit('get-favorite-list', {
      productListId: favoriteIDArray.value
    });
    if (cartIDArray.value?.length) emitter.emit('get-cart-list', {
      productListId: cartIDArray.value,
      productListItem: cartItemArray.value
    });
  });
  function compareTwoList(listType: ListsType, actualList: typeof compareIDArray.value | typeof favoriteIDArray.value | typeof cartIDArray.value, fn: ({
    products
  }: {
    products: string;
  }) => void) {
    if (Array.isArray(listType.previous)) {
      if (!isEqual(actualList, listType.previous)) {
        fn({
          products: actualList.join(',')
        });
        listType.previous = [...actualList];
      }
    }
  }
  function isEqual(arr: string[], arr2: string[]) {
    if (arr?.length !== arr2?.length) return false;
    return arr.every(x => {
      return arr2.includes(x);
    });
  }
  function onChange() {
    isLoadingCart.value = false;
    loadingActionCart.value = true;
  }
  function onLoadList(list: ListsType, response: CartProduct[]) {
    list.list = response;
    isLoadingCart.value = true;
    if (list.type === 'cart') {
      const sum = cart.value?.list?.reduce((acc, current) => current?.price?.common?.value ? acc += current?.price?.common?.value * current?.quantity : acc += 0, 0) || 0;
      sumAllProducts.value = formatPrice(sum);
    }
  }
  function onRemoveItemFromList(listLype: ListsType, {
    id
  }: {
    id: number;
  }) {
    listLype.list = listLype.list?.filter(el => el.id !== id);
    listLype.previous = listLype.previous?.filter(el => +el !== id);
  }
  return {
    compare,
    favorite,
    cart,
    loadingActionCart,
    isLoadingCart,
    favoriteCount,
    favoriteIDArray,
    compareCount,
    compareIDArray,
    cartCount,
    cartIDArray,
    sumAllProducts,
    compareTwoList,
    onChange,
    onLoadList,
    onRemoveItemFromList
  };
};
__sfc_main.components = Object.assign({
  UserMenu,
  ActionEntity,
  UserPopup,
  ActionCompare,
  UserPopupItem,
  ActionFavorite,
  ActionCart,
  Button
}, __sfc_main.components);
export default __sfc_main;

import type { Context } from '@nuxt/types'
import { stringify } from 'qs'
import RootCategorySerializer from '~/serializer/RootCategory'
import { useLocationStore } from '~/store/location'

export default {
  async get(this: Context, { id, enhanced, seo, slim }: { id: number; enhanced: boolean; seo: boolean; slim: boolean }) {
    const { $axios, $nodeCache, $pinia } = this
    const locationStore = useLocationStore($pinia)

    return await $nodeCache.helper<
      { result: { list: ReturnType<typeof RootCategorySerializer> } }
    >({
      key: `catalog-root-${locationStore.currentCity?.id}`,
      request: async () => {
        const { result } = await $axios.$get(
          `/api/v1/catalog/sections?${stringify({
            section_id: id,
            enhanced,
            seo_sections: seo,
            slim,
          })}`,
        )

        return {
          result: {
            list: result?.length ? result.map((el: any) => RootCategorySerializer(el)) : [],
          },
        }
      },
    })
  },
  /**
   * Категори меню вынесено отдельно, чтобы его можно было легко кешировать т.к. это сильно актуально
   * в плане выйгрыша в производительности
   */
  async categoryMenu(this: Context) {
    const { $axios, $nodeCache, $pinia } = this
    const locationStore = useLocationStore($pinia)

    return await $nodeCache.helper<
      { result: { list: ReturnType<typeof RootCategorySerializer> } }
    >({
      key: `category-list-by-${locationStore?.code}`,
      request: async () => {
        const { result } = await $axios.$get(
          `/api/v1/catalog/sections?${stringify({
            seo_sections: true,
          })}`,
        )
        return {
          result: {
            list: result?.length ? result.map((el: any) => RootCategorySerializer(el)) : [],
          },
        }
      },
    })
  },
}

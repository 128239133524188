var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "order-status"
  }, [_c('div', {
    staticClass: "order-status__content-wrap"
  }, [_c('div', {
    class: ("order-status__img-wrap order-status__img-wrap_" + (_vm.getOrderStatusImg))
  }, [_c('div', {
    staticClass: "order-status__img"
  })]), _vm._v(" "), _c('div', {
    staticClass: "order-status__text"
  }, [_vm.price ? _c('div', {
    staticClass: "order-status__subtitle"
  }, [_vm._v("\n        №" + _vm._s(_vm.id) + " на сумму " + _vm._s(_vm.formatPrice(_vm.price)) + " ₽\n      ")]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "order-status__title"
  }, [_vm._v("\n        " + _vm._s(_vm.getOrderStatus) + "\n      ")])])]), _vm._v(" "), _c('nuxt-link', {
    staticClass: "order-status__details-button",
    attrs: {
      "to": "/user/order/"
    }
  }, [_c('div', {
    staticClass: "order-status__details-button"
  }, [_c('div', {
    staticClass: "order-status__details-dot"
  })])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
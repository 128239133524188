import type { Events } from '~/plugins/emitter'

export default function selectDelivery({ type }: Events['delivery-select']) {
  return {
    event: 'select_delivery',
    data: {
      delivery_method: type,
    },
  }
}

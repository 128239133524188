import type { BonusReward, BonusRewardName } from '~/type/BonusReward'

export default function (obj: any): BonusReward {
  const result: BonusReward = {}

  for (const key in obj) {
    const keyLowerCamelCase = key.charAt(0).toLowerCase() + key.slice(1)

    result[keyLowerCamelCase as BonusRewardName] = {
      isActive: obj[key].SHOW,
      bonus: obj[key].BONUSES,
    }
  }

  return result
}

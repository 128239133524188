import type { BaseFilter, SeoFilter } from '~/type/Filter'

function SeoFilterElement(el: { [ key: string ]: string }): BaseFilter {
  return {
    name: el.NAME,
    property: el.PATH,
    value: el.NAME,
  }
}

/**
 * Активным фильтр мы ставим лишь тогда, когда он только один и когда пользователь перешел только прямо по сео-сылке,
 * если активных фильтров несколько - мы ничего не активируем
 * Это связано с тем, что именно наше представление заточено только на один активный СЕО-элемент, Рашид же этот СЕО-фильтр,
 * под капотом, формирует из обычного фильтра типа чекбокс-список, поэтому у него может быть хоть все значения активны
 */
function getActiveFilter(arr: any, path: string) {
  const list: any[] = arr.filter((el: any) => el.SELECTED)
  const isInsidePath = path.includes(list?.[0]?.PATH)
  return (list.length === 1 && isInsidePath) ? list[0] : null
}

export default function (list: [], path: string): SeoFilter {
  const active = getActiveFilter(list, path)

  return {
    value: active ? SeoFilterElement(active) : undefined,
    list: list.map(el => SeoFilterElement(el)),
  }
}

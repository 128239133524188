export default {
  // Класс, который будет добавлен к корневому элементу. exp-{name}-{var}
  name: 'checkoutAccessories',
  // Идентификатор эксперимента для Google optimize
  id: '74Y4ltU_T_iyvWZbmTdHSg',

  // Количество секций для экспериментов MVT
  // sections: 1,

  // Количество времени для тестирования эксперимента
  // maxAge: 60 * 60 * 24, // 24 hours,

  // Отключить эксперимент если есть кука е2е, т.к эксперименты ломают нам е2е тесты
  isEligible: ({ $cookies }) => !$cookies.get('e2e'),
  // Варианты для эксперимента и их вес
  variants: [
    { name: 'default', weight: 1 },
    { name: 'new', weight: 1 },
  ],
}


import { declination } from '@artmizu/utils';
import { computed } from '@vue/composition-api';
import ColorAvatar from '../ColorAvatar/ColorAvatar.vue';
import TSProductRating from '~/components/ts-components/TSProductRating/TSProductRating.vue';
const __sfc_main = {};
__sfc_main.props = {
  count: {
    key: "count",
    required: false,
    type: Number,
    default: 0
  },
  rating: {
    key: "rating",
    required: true,
    type: Number
  },
  averageRate: {
    key: "averageRate",
    required: true,
    type: Number
  },
  title: {
    key: "title",
    required: true,
    type: String
  },
  name: {
    key: "name",
    required: true,
    type: String
  },
  date: {
    key: "date",
    required: true,
    type: String
  },
  img: {
    key: "img",
    required: true,
    type: String
  },
  comment: {
    key: "comment",
    required: true,
    type: String
  },
  linkProduct: {
    key: "linkProduct",
    required: true,
    type: String
  },
  linkReview: {
    key: "linkReview",
    required: true,
    type: String
  },
  linkAllReview: {
    key: "linkAllReview",
    required: true,
    type: String
  },
  photoLazyload: {
    key: "photoLazyload",
    required: true,
    type: Boolean
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const declOfNumGoods = computed(() => {
    return `${props.count} ${declination(props.count, ['отзыв', 'отзыва', 'отзывов'])}`;
  });
  return {
    declOfNumGoods
  };
};
__sfc_main.components = Object.assign({
  TSProductRating,
  ColorAvatar
}, __sfc_main.components);
export default __sfc_main;

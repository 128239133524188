import type { ReviewRating } from '~/type/Review'
import ProductRatingSerializer from '~/serializer/Rating'

export default function (data: { [ key: string ]: any }, count: number): ReviewRating {
  return {
    average: data?.MIDDLE || 0,
    count: Number(Object.values(data?.ITEMS || {})?.reduce<number>((acc: number, score) => (acc += Number(score)), 0) || 0),
    list: ProductRatingSerializer(data?.ITEMS || {}),
    reviewCount: count || 0,
  }
}

import type Banner from '~/type/Banner'

export default function (el: { [key: string]: any } = {}): Banner {
  return {
    code: el.CODE,
    src: {
      desk: el.IMAGE,
      mobile: el.MOBILE_IMAGE,
    },
    name: el.NAME,
    link: (el.TYPE && el.CODE) ? `/${el.TYPE as string}/${el.CODE as string}` : '',
  }
}

import OfferSerializer from '~/serializer/Offer'
import type { Offer, ProductOffer } from '~/type/product/Product'

export default function (offerList: any[], active: any[]) {
  let list: ProductOffer[]

  function isDisable(product: number[], index: number) {
    const oposite = getOposite(index)
    return oposite ? !getSimilar(product, oposite.product) : false
  }

  function getOposite(index: number) {
    const opositeIndex = index === 0 ? 1 : 0
    return list[opositeIndex]?.active
  }

  function getSimilar(first: number[], second: number[]) {
    return first.find((id) => {
      return second.find((id2) => {
        return id === id2
      })
    })
  }

  if (offerList?.length) {
    list = offerList.map((el, index) => OfferSerializer(el, active?.[index]))
    list.forEach((offer: ProductOffer, index: number) => {
      offer.list?.forEach((offerElement: Offer) => {
        offerElement.disable = offerElement.name === offer?.active?.name ? false : isDisable(offerElement.product, index)
      })
    })

    return list
  }
  else {
    return []
  }
}

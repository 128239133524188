var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "darkstore-bar"
  }, [_c('a', {
    staticClass: "darkstore-bar__link",
    attrs: {
      "href": ((_vm.$config.darkstoreUrl) + "?utm_source=main_banner_05_top&utm_medium=referral&utm_campaign=05")
    }
  }, [_vm._m(0), _vm._v(" "), _c('img', {
    staticClass: "darkstore-bar__image",
    attrs: {
      "src": require("@/assets/images/darkstore-topbar/Broccoli.svg"),
      "alt": "broccoli"
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "darkstore-bar__image",
    attrs: {
      "src": require("@/assets/images/darkstore-topbar/Cheese-wedge.svg"),
      "alt": "cheese"
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "darkstore-bar__image",
    attrs: {
      "src": require("@/assets/images/darkstore-topbar/Avocado.svg"),
      "alt": "avocado"
    }
  }), _vm._v(" "), _vm._m(1), _vm._v(" "), _c('img', {
    staticClass: "darkstore-bar__image",
    attrs: {
      "src": require("@/assets/images/darkstore-topbar/Mango.svg"),
      "alt": "mango"
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "darkstore-bar__image",
    attrs: {
      "src": require("@/assets/images/darkstore-topbar/Croissant.svg"),
      "alt": "croissant"
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "darkstore-bar__image",
    attrs: {
      "src": require("@/assets/images/darkstore-topbar/Eggplant.svg"),
      "alt": "eggplant"
    }
  }), _vm._v(" "), _vm._m(2)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "darkstore-bar__logo"
  }, [_c('img', {
    staticClass: "darkstore-bar__logo-img",
    attrs: {
      "src": require("@/assets/images/darkstore-topbar/darkstore-logo.svg"),
      "alt": "darkstore logo"
    }
  }), _vm._v(" "), _c('h2', {
    staticClass: "darkstore-bar__logo-title"
  }, [_vm._v("даркстор")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "darkstore-bar__description"
  }, [_c('p', {
    staticClass: "darkstore-bar__title"
  }, [_vm._v("Продукты с доставкой ")]), _vm._v(" "), _c('div', {
    staticClass: "darkstore-bar__time"
  }, [_vm._v("\n        за 30 минут\n      ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "darkstore-bar__button-container"
  }, [_c('div', {
    staticClass: "darkstore-bar__button darkstore-bar__button_white"
  }, [_c('img', {
    staticClass: "darkstore-bar__ellipse",
    attrs: {
      "src": require("@/assets/images/darkstore-topbar/ellipse.svg"),
      "alt": "ellipse"
    }
  }), _vm._v("\n        Каспийск\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "darkstore-bar__button"
  }, [_vm._v("\n        Перейти\n      ")]), _vm._v(" "), _c('img', {
    staticClass: "darkstore-bar__circle",
    attrs: {
      "src": require("@/assets/images/darkstore-topbar/circle.svg"),
      "alt": "circle"
    }
  })]);
}]

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "info-dk",
    attrs: {
      "href": ((_vm.$config.darkstoreUrl) + "?utm_source=main_infoblock_05_top&utm_medium=referral&utm_campaign=05")
    }
  }, [_c('img', {
    staticClass: "info-dk__logo",
    attrs: {
      "src": require("assets/images/infoblock-darkstore-makhachkala/title.svg"),
      "alt": "Близко от 30 минут"
    }
  }), _vm._v(" "), _vm._m(0), _vm._v(" "), _c('img', {
    staticClass: "info-dk__image info-dk__cola",
    attrs: {
      "src": require("assets/images/infoblock-darkstore-makhachkala/cola.png"),
      "srcset": require("assets/images/infoblock-darkstore-makhachkala/cola.png") + " 1x, " + require("assets/images/infoblock-darkstore-makhachkala/cola@2x.png") + " 2x, " + require("assets/images/infoblock-darkstore-makhachkala/cola@3x.png") + " 3x",
      "alt": "Кола"
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "info-dk__image info-dk__watermelon",
    attrs: {
      "src": require("assets/images/infoblock-darkstore-makhachkala/watermelon.png"),
      "srcset": require("assets/images/infoblock-darkstore-makhachkala/watermelon.png") + " 1x, " + require("assets/images/infoblock-darkstore-makhachkala/watermelon@2x.png") + " 2x, " + require("assets/images/infoblock-darkstore-makhachkala/watermelon@3x.png") + " 3x",
      "alt": "Арбуз"
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "info-dk__image info-dk__grape",
    attrs: {
      "src": require("assets/images/infoblock-darkstore-makhachkala/grape.png"),
      "srcset": require("assets/images/infoblock-darkstore-makhachkala/grape.png") + " 1x, " + require("assets/images/infoblock-darkstore-makhachkala/grape@2x.png") + " 2x, " + require("assets/images/infoblock-darkstore-makhachkala/grape@3x.png") + " 3x",
      "alt": "Виноград"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "info-dk__button"
  }, [_vm._v("\n    Перейти\n  ")])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-dk__text-block"
  }, [_c('p', {
    staticClass: "info-dk__text info-dk__text_large"
  }, [_vm._v("Продукты на расстоянии"), _c('br'), _vm._v("пары кликов")]), _vm._v(" "), _c('p', {
    staticClass: "info-dk__text info-dk__text_small"
  }, [_vm._v("На расстоянии"), _c('br'), _vm._v(" пары кликов")])]);
}]

export { render, staticRenderFns }
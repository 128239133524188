import type { Events } from '~/plugins/emitter'

export default function productView(data: Events['product-view']['product']) {
  return {
    name: 'view_item',
    data: {
      currency: 'RUB',
      value: data.price?.common?.value,
      category_id: data.category?.id,
      items: [
        {
          id: data.path?.match(/\/([^/]+)\/?$/)?.[1] || data.id.toString(),
          name: data.name,
          brand: data.brand?.name,
          category: data.category?.name,
          variant: data.offer?.length ? data.offer?.map(el => el.value || el.active?.value).join(', ') : '',
          quantity: 1,
          price: data.price?.common?.value,
          promotion_name: data.sale?.length ? data.sale[0].name : '',
          promotion_id: data.sale?.length ? data.sale[0].id.toString() : '',
        },
      ],
    },
  }
}

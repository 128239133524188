import { defineStore } from 'pinia'
import { ref } from '@vue/composition-api'

export const useEmailConfirmStore = defineStore('emailConfirmStore', () => {
  const active = ref(false)

  function enableEmailConfirm() {
    active.value = true
  }

  function disableEmailConfirm() {
    active.value = false
  }

  return { enableEmailConfirm, disableEmailConfirm, active }
})

import type { Events } from '~/plugins/emitter'

export default function productView(obj: Events['product-view']['product']) {
  return {
    event: 'view_item',
    ecommerce: {
      currency: 'RUB',
      value: obj.price?.common?.value,
      category_id: obj.category?.id,
      items: [
        {
          item_name: obj.name,
          item_id: obj.path?.match(/\/([^/]+)\/?$/)?.[1] || obj.id.toString(),
          price: obj.price?.common.value,
          item_brand: obj.brand?.name,
          item_category: obj.category?.name,
          item_variant: obj.offer?.length ? obj.offer?.map(el => el.value || el.active?.value).join(', ') : '',
          quantity: 1,
          promotion_name: obj.sale?.length ? obj.sale[0].name : '',
          promotion_id: obj.sale?.length ? obj.sale[0].id.toString() : '',
        },
      ],
    },
  }
}

import type { Events } from '~/plugins/emitter'

export default function saleView({ product, sale }: Events['sale-view']) {
  const items = Array.isArray(product)
    ? product.map((el, i) => ({
      item_id: el?.path.match(/\/([^/]+)\/?$/)?.[1],
      item_name: el?.name,
      price: el?.price?.common?.value,
      item_category: el?.category?.name,
      item_variant: el?.offer?.length ? el?.offer?.map(el => el.value).join(' ') : '',
      promotion_id: sale?.id,
      promotion_name: sale?.name,
      index: i + 1,
      quantity: 1,
    }))
    : []

  return {
    event: 'view_promotion',
    ecommerce: {
      items,
    },
  }
}

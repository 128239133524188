
import { ref, shallowRef } from '@vue/composition-api';
import { formatPhone } from '~/helper/formatPhone';
interface Props {
  modelValue: string;
  error?: boolean;
  success?: boolean;
  type?: 'border' | 'none';
  placeholder?: string;
}
const __sfc_main = {};
__sfc_main.props = {
  modelValue: {
    key: "modelValue",
    required: true,
    type: String
  },
  error: {
    key: "error",
    required: false,
    type: Boolean,
    default: false
  },
  success: {
    key: "success",
    required: false,
    type: Boolean,
    default: false
  },
  type: {
    key: "type",
    required: false,
    type: String,
    default: 'none'
  },
  placeholder: {
    key: "placeholder",
    required: false,
    type: String
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const currentValue = ref(props.modelValue);
  const root = shallowRef<HTMLInputElement>();
  function onInput(e: Event) {
    if (e.target instanceof HTMLInputElement) {
      currentValue.value = formatPhone(e.target.value);
      root.value!.value = currentValue.value;
      emit('update:model-value', currentValue.value);
    }
  }
  function handleKeyPress(e: KeyboardEvent) {
    if (e.key === 'Enter') emit('submit');
    if (e.key === 'Backspace') return;
    if (currentValue.value.length > 15) e.preventDefault();
  }
  return {
    currentValue,
    root,
    onInput,
    handleKeyPress
  };
};
export default __sfc_main;

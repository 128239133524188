import { render, staticRenderFns } from "./nam20.vue?vue&type=template&id=b822ec52&scoped=true&"
import script from "./nam20.vue?vue&type=script&lang=ts&"
export * from "./nam20.vue?vue&type=script&lang=ts&"
import style0 from "./nam20.vue?vue&type=style&index=0&id=b822ec52&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b822ec52",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppPromo: require('/app/components/AppPromo/AppPromo.vue').default,DarkstoreBar: require('/app/components/DarkstoreBar/DarkstoreBar.vue').default,Header: require('/app/components/Header/Header.vue').default,MainSliderEl: require('/app/components/sliders/MainSlider/MainSliderEl.vue').default,MainSlider: require('/app/components/sliders/MainSlider/MainSlider.vue').default,StoriesSlide: require('/app/components/sliders/StoriesSlide/StoriesSlide.vue').default,BaseRowSlider: require('/app/components/sliders/BaseRowSlider/BaseRowSlider.vue').default,ViewportToogle: require('/app/components/ViewportToogle/ViewportToogle.vue').default,Footer: require('/app/components/Footer/Footer.vue').default,GlobalTools: require('/app/components/GlobalTools/GlobalTools.vue').default})

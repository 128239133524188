
import { computed } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  price: {
    key: "price",
    required: true,
    type: Number
  },
  discount: {
    key: "discount",
    required: false,
    type: Number,
    default: 0
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const percent = computed(() => Math.ceil((props.price - props.discount) / props.price * 100));
  const color = computed(() => {
    if (percent.value < 20) return 'green';else if (percent.value < 40) return 'light-green';else if (percent.value < 60) return 'yellow';else if (percent.value < 80) return 'orange';else if (percent.value < 85) return 'pink';else if (percent.value >= 85) return 'red';else return null;
  });
  return {
    percent,
    color
  };
};
export default __sfc_main;

import type { PCBuildGroup } from '~/type/PCBuild'

export default function (el: any): PCBuildGroup {
  return {
    title: el.GROUP_NAME,
    path: el.SECTION_PAGE_PATH,
    required: el.REQUIRED,
    multiple: el.MULTIPLE,
    id: +el.SECTION_ID,
    filter: el.FILTER_CODES,
  }
}

import { render, staticRenderFns } from "./GGProduct.vue?vue&type=template&id=170e8bf1&scoped=true&"
import script from "./GGProduct.vue?vue&type=script&lang=js&"
export * from "./GGProduct.vue?vue&type=script&lang=js&"
import style0 from "./GGProduct.vue?vue&type=style&index=0&id=170e8bf1&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "170e8bf1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TSBonusCount: require('/app/components/ts-components/TSBonusCount/TSBonusCount.vue').default,ProductDiscountMarker: require('/app/components/ProductDiscountMarker/ProductDiscountMarker.vue').default,TSPrice: require('/app/components/ts-components/TSPrice/TSPrice.vue').default,CartTool: require('/app/components/CartTool/CartTool.vue').default,ActionCart: require('/app/action/ActionCart.vue').default})

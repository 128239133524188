export default (ctx, inject) => {
  const options = JSON.parse(`{"noscript":true,"useCDN":false,"verbose":true,"initParams":{"defer":true,"clickmap":true,"trackLinks":true,"accurateTrackBounce":true,"webvisor":true,"ecommerce":true},"id":"85054561"}`);
  ctx.app.router?.afterEach((to) => {
    window.ym(options.id, "hit", to.fullPath);
  });
  inject("metrika", {
    hit: (url) => {
      window.ym(options.id, "hit", url);
    },
    reachGoal: (identifyer) => {
      window.ym(options.id, "reachGoal", identifyer);
    }
  });
};

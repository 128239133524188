
import { onMounted, shallowRef } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const el = shallowRef<HTMLDivElement>();
  const topShadow = shallowRef<HTMLDivElement>();
  const bottomShadow = shallowRef<HTMLDivElement>();
  onMounted(() => {
    el.value?.addEventListener('scroll', checkScroll);
  });
  function checkScroll() {
    if (el.value) {
      if (el.value.scrollTop >= 100 && topShadow.value?.classList.contains('scroll-gradient__close')) addShow(topShadow.value);else if (el.value.scrollTop <= 101 && topShadow.value?.classList.contains('scroll-gradient__show')) addClose(topShadow.value);
      if (el.value.scrollTop >= el.value.scrollHeight - el.value.clientHeight - 100 && bottomShadow.value?.classList.contains('scroll-gradient__show')) addClose(bottomShadow.value);else if (el.value.scrollTop <= el.value.scrollHeight - el.value.clientHeight - 100 && bottomShadow.value?.classList.contains('scroll-gradient__close')) addShow(bottomShadow.value);
    }
  }
  function addShow(elem: HTMLDivElement) {
    elem.classList.remove('scroll-gradient__close');
    elem.classList.add('scroll-gradient__show');
  }
  function addClose(elem: HTMLDivElement) {
    elem.classList.remove('scroll-gradient__show');
    elem.classList.add('scroll-gradient__close');
  }
  return {
    el,
    topShadow,
    bottomShadow,
    checkScroll
  };
};
export default __sfc_main;

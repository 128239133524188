var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    attrs: {
      "overlay-theme": "light",
      "max-width": "514px",
      "show": _vm.show
    },
    on: {
      "close": _vm.close
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('h3', {
          staticClass: "business__title"
        }, [_vm._v("\n      Бизнес с 05.ru\n    ")])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c('div', {
    staticClass: "business__list"
  }, _vm._l([{
    name: 'Маркетплейс',
    url: 'https://market.05.ru/seller-promo/',
    isExternalLink: true,
    class: 'business__item-mp'
  }, {
    name: 'Оптовикам',
    url: '/about/optom/',
    class: 'business__item-optovikam'
  }, {
    name: 'Поставщикам',
    url: '/about/shippers/',
    class: 'business__item-postavshikam'
  }, {
    name: 'Арендодателям',
    url: '/about/arenda/',
    class: 'business__item-arendodatelyam'
  }, {
    name: 'Корпоративным клиентам',
    url: '/about/corp/',
    class: 'business__item-klientam'
  }], function (el, index) {
    return _c('div', {
      key: index
    }, [el.isExternalLink ? _c('a', {
      staticClass: "business__item link link_wu",
      class: [el.class],
      attrs: {
        "href": el.url
      }
    }, [_vm._v(" " + _vm._s(el.name))]) : _c('nuxt-link', {
      staticClass: "business__item link link_wu",
      class: [el.class],
      attrs: {
        "to": el.url
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.close.apply(null, arguments);
        }
      }
    }, [_vm._v("\n        " + _vm._s(el.name) + "\n      ")])], 1);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nuxt-link', {
    staticClass: "info-bonus",
    attrs: {
      "to": "/user/bonus"
    }
  }, [_c('div', {
    staticClass: "info-bonus__text"
  }, [_c('div', {
    staticClass: "info-bonus__text-title"
  }, [_vm._v("\n      Ваш бонусный баланс\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "info-bonus__text-title info-bonus__text-title_mobile"
  }, [_vm._v("\n      Бонусный баланс\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "info-bonus__text-description"
  }, [_vm._v("\n      1 бонус = 1₽\n    ")])]), _vm._v(" "), _c('div', {
    staticClass: "info-bonus__value"
  }, [_c('div', {
    staticClass: "info-bonus__value-count"
  }, [_vm._v("\n      " + _vm._s(_vm.countBonuses) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "info-bonus__value-icon"
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._t("default", null, {
    "list": [],
    "add": _vm.add,
    "remove": _vm.remove,
    "cart": _vm.state
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }

export default {
  name: 'GGDiscountMarker',
  props: {
    price: {
      type: Number,
      default: null,
    },
    discount: {
      type: Number,
      default: null,
    },
    size: {
      type: String,
      default: 'small',
    },
  },
  computed: {
    percent() {
      return Math.round(((this.price - this.discount) / this.price) * 100)
    },
    color() {
      if (this.percent < 20)
        return 'green'
      else if (this.percent >= 20 && this.percent < 40)
        return 'salad'
      else if (this.percent >= 40 && this.percent < 60)
        return 'yellow'
      else if (this.percent >= 60 && this.percent < 80)
        return 'orange'
      else if (this.percent >= 80 && this.percent < 85)
        return 'pink'
      else if (this.percent >= 85)
        return 'red'
      else
        return null
    },
  },
}

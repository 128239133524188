var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-goods",
    on: {
      "mouseleave": _vm.handleMouseLeave,
      "mouseenter": _vm.handleMouseEnter
    }
  }, [_c('div', {
    staticClass: "add-goods__left"
  }, [_c('nuxt-link', {
    staticClass: "add-goods__img-box",
    attrs: {
      "no-prefetch": "",
      "to": _vm.productLink
    }
  }, [_c('LazyImg', {
    staticClass: "add-goods__img",
    attrs: {
      "img": _vm.productImage,
      "alt": _vm.productName
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "add-goods__desc add-goods__desc_desktop"
  }, [_c('div', {
    staticClass: "add-goods__desc-total"
  }, [_vm._v("\n        Товар добавлен в сравнение\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "add-goods__desc-bottom"
  }, [_vm._v("\n        В сравнении " + _vm._s(_vm.productsCount) + "\n      ")])]), _vm._v(" "), _c('div', {
    staticClass: "add-goods__desc add-goods__desc_mobile"
  }, [_c('div', {
    staticClass: "add-goods__desc-total"
  }, [_vm._v("\n        " + _vm._s(_vm.productsCount) + " в сравнении\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "add-goods__desc-bottom"
  }, [_vm._v("\n        Товар добавлен\n      ")])])], 1), _vm._v(" "), _c('div', {
    staticClass: "add-goods__buttons"
  }, [_c('Button', {
    staticClass: "add-goods__button add-goods__button_desktop",
    on: {
      "click": _vm.handleToCompare
    }
  }, [_vm._v("\n      Перейти в сравнение\n    ")]), _vm._v(" "), _c('Button', {
    staticClass: "add-goods__button add-goods__button_mobile",
    attrs: {
      "size": "33"
    },
    on: {
      "click": _vm.handleToCompare
    }
  }, [_vm._v("\n      В сравнение\n    ")]), _vm._v(" "), _c('span', {
    staticClass: "add-goods__close",
    on: {
      "click": _vm.close
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
import { defineStore } from 'pinia'
import { ref } from '@vue/composition-api'

interface EnableProductMoreParams {
  id: number
  title: string
  path: string
}

export const useProductMoreStore = defineStore('productMore', () => {
  const active = ref(false)
  const title = ref<string>()
  const path = ref<string>()
  const id = ref<number>()

  function enableProductMore(data: EnableProductMoreParams) {
    active.value = true
    id.value = data.id
    title.value = data.title
    path.value = data.path
  }

  function disableProductMore() {
    active.value = false
    id.value = undefined
    title.value = undefined
    path.value = undefined
  }

  return { enableProductMore, disableProductMore, active, id, title, path }
})


import { conditionView } from '@artmizu/utils';
import { ref } from '@vue/composition-api';
import { onMounted, wrapProperty } from '@nuxtjs/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  options: {
    key: "options",
    required: true,
    type: Object
  },
  link: {
    key: "link",
    required: true,
    type: String
  },
  description: {
    key: "description",
    required: true,
    type: String
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const cookies = wrapProperty('$cookies', false)();
  const active = ref(false);
  onMounted(() => {
    const conditionInstance = conditionView({
      interval: props.options.interval,
      showForCertainOS: props.options.showForCertainOS,
      identifyer: props.options.identifyer,
      count: props.options.count
    });
    conditionInstance.isNeedToShow() ? enable() : disable();
  });
  function enable() {
    if (cookies.get('showAppPromo') === undefined || cookies.get('showAppPromo')) {
      cookies.set('showAppPromo', true);
      active.value = true;
    }
  }
  function disable() {
    cookies.set('showAppPromo', false);
    active.value = false;
  }
  return {
    active,
    disable
  };
};
export default __sfc_main;

import { stringify } from 'qs'
import type { Context } from '@nuxt/types'
import { getFormData } from '@artmizu/utils'
import UserAddressSerializer from '~/serializer/UserAddress'
import type UserAddress from '~/type/UserAddress'
import type { APIRequestSuccess } from '~/type/APIRequestStatus'

export default {
  async get(this: Context): Promise<UserAddress[]> {
    const { $axios } = this

    const response = (await $axios.$get('/api/v1/order/address'))?.result

    return response?.map((el: any) => UserAddressSerializer(el)) || []
  },

  async create(this: Context, data: Omit<UserAddress, 'id'>): Promise<UserAddress> {
    const { $axios } = this

    const response = (
      await $axios.$post(
        '/api/v1/order/address?RESPONSE=ADDRESS',
        stringify({
          city: data.city,
          house: data.house,
          street: data.street,
          entrance: data.entrance,
          apartment: data.apartment,
          floor: data.floor,
        }),
      )
    )?.result

    return response && UserAddressSerializer(response)
  },

  edit(this: Context, data: UserAddress): Promise<APIRequestSuccess<{}>> {
    const { $axios } = this

    return $axios.$post(
      `/api/v1/order/address/${data.id}`,
      getFormData({
        city: data.city || '',
        house: data.house || '',
        street: data.street || '',
        entrance: data.entrance || '',
        apartment: data.apartment || '',
        floor: data.floor || '',
      }),
      {
        headers: {
          // Нужно чтоб браузер сам проставил нужные заголовки для formdata
          post: null,
        },
      },
    )
  },

  remove(this: Context, id: number): Promise<APIRequestSuccess<{}>> {
    const { $axios } = this

    return $axios.$delete(`/api/v1/order/address/${id}`)
  },
}

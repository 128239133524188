import type { Seller } from '~/type/Seller'

export default function (el: { [ key: string ]: any } = {}): Seller {
  return {
    id: +el.STORE_ID,
    name: el.NAME,
    period: el.PERIOD_TEXT,
    price: el.PRICE,
    image: el.IMAGE,
  }
}

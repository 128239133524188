
import { computed, inject, onMounted, ref, shallowRef, watch } from '@vue/composition-api';
import { useRoute } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import scrollBar from '~/helper/scrollBar';
import { useChooseCityStore } from '~/store/modal/chooseCity';
import { useLocationStore } from '~/store/location';
import type { EmitteryType } from '~/plugins/emitter';
import ChooseProject from '~/components/ChooseProject/ChooseProject.vue';
import Business05 from '~/components/Business05/Business05.vue';
import SmallCallback from '~/components/SmallCallback/SmallCallback.vue';
import CategoryMenu from '~/components/CategoryMenu/CategoryMenu.vue';
import SearchBar from '~/components/SearchBar/SearchBar.vue';
import { useSocialStore } from '~/store/social';
import { useResizeStore } from '~/store/resize';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emitter = inject<EmitteryType>('emitter')!;
  const isProductPage = ref(false);
  const categoryMenuHydratation = ref(false);
  const menuState = ref(false);
  const isSupportModalActive = ref(false);
  const activeBusiness05 = ref(false);
  const activeChooseProject = ref(false);
  let $scrollBar: ReturnType<typeof scrollBar>;
  const cityStore = useChooseCityStore();
  const {
    enableChooseCity
  } = useChooseCityStore();
  const locationStore = useLocationStore();
  const route = useRoute();
  const socialStore = useSocialStore();
  const resizeStore = useResizeStore();
  const searchWrapper = shallowRef<HTMLDivElement>();
  const headerRef = shallowRef<HTMLDivElement>();
  const phone = computed(() => socialStore.social.phone);
  const socialState = computed(() => socialStore.social);
  const isMobile = computed(() => resizeStore.isMobile);
  const chooseCityActive = computed(() => cityStore.active);
  const currentCityName = computed(() => locationStore.currentCity?.name);
  const formatPhone = computed(() => phone.value.replace(/\s|-|[()]/g, ''));
  watch(() => route.value.name, val => {
    if (isMobile.value && val === 'catalog-route') isStickySearchHandler();else $scrollBar?.reset();
  });
  onMounted(() => {
    if (isMobile.value && route.value.name === 'catalog-route') isStickySearchHandler();
  });
  isProductPage.value = isProductPageCheck();
  resizeStore.$onAction(mutation => {
    /**
     * если переключились на декстоп, то скрываем мобильное модальное поддержки
     */
    if (mutation.name === 'setIsMobile' && !mutation.store.isMobile) isSupportModalActive.value = false;
  });
  function isStickySearchHandler() {
    const search = searchWrapper.value;
    $scrollBar = scrollBar({
      wrapper: window,
      heightFromTop: headerRef.value?.offsetHeight ?? 0,
      onRemove() {
        search?.classList.remove('header__search_sticky');
        search?.classList.remove('header__search_sticky-fade');
      },
      onEnable() {
        search?.classList.remove('header__search_sticky-fade');
        search?.classList.add('header__search_sticky');
      },
      onDisable() {
        search?.classList.add('header__search_sticky-fade');
      }
    });
  }
  function toggleMainMenu() {
    categoryMenuHydratation.value = true;
    // requestAnimationFrame используем чтобы не было разных состояний на сервере и на клиенте.
    menuState.value ? menuState.value = false : requestAnimationFrame(() => requestAnimationFrame(() => menuState.value = true));
  }
  function disableMainMenu() {
    menuState.value = false;
  }
  function onClickSupport() {
    isSupportModalActive.value = true;
    // если меню категорий открыто, то закрываем его
    if (menuState.value) disableMainMenu();
  }
  function closeSupportModal() {
    isSupportModalActive.value = false;
  }
  function isProductPageCheck() {
    switch (route.value.name) {
      case 'product-index':
      case 'product-properties':
      case 'review-route':
      case 'product-review-list':
      case 'question-route':
      case 'product-question-list':
      case 'product-accessories':
        return true;
      default:
        return false;
    }
  }
  return {
    emitter,
    isProductPage,
    categoryMenuHydratation,
    menuState,
    isSupportModalActive,
    activeBusiness05,
    activeChooseProject,
    enableChooseCity,
    locationStore,
    searchWrapper,
    headerRef,
    socialState,
    chooseCityActive,
    currentCityName,
    formatPhone,
    toggleMainMenu,
    disableMainMenu,
    onClickSupport,
    closeSupportModal
  };
};
__sfc_main.components = Object.assign({
  ChooseProject,
  Business05,
  SmallCallback,
  LazyHydrate,
  CategoryMenu,
  SearchBar
}, __sfc_main.components);
export default __sfc_main;

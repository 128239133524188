// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/404left.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/404right.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error{display:flex;align-items:center;justify-content:center;width:100%;height:100%}.error__title{margin-bottom:30px;font-size:105px;font-weight:500;line-height:88px}.error__subtitle{margin-bottom:20px;font-size:23px}.error__block{width:400px;padding:50px 60px;text-align:center;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") top 59px left no-repeat,url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") top 59px right no-repeat;border:1px solid #f2f2f2;border-radius:4px}@media (max-width:500px){.error__title{margin-bottom:13px;font-size:70px;line-height:66px}.error__block{padding:30px 15px 35px;background-image:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

import type { Events } from '~/plugins/emitter'

export default function cartProductRemove(product: Events['cart-product-remove']['product']) {
  return {
    event: 'remove_from_cart',
    ecommerce: {
      currency: 'RUB',
      value: product.price?.common?.value,
      items: [
        {
          item_name: product.name,
          item_id: product.path?.match(/\/([^/]+)\/?$/)?.[1] || product.id?.toString(),
          price: product.price?.common?.value,
          item_brand: product.brand?.name,
          item_category: product.category?.name,
          item_variant: product.offer?.length ? product.offer?.map(el => el.value).join(', ') : '',
          quantity: product.quantity,
          promotion_name: product.sale?.length ? product.sale[0].name : '',
          promotion_id: product.sale?.length ? product.sale[0].id.toString() : '',
        },
      ],
    },
  }
}

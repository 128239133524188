import { stringify } from 'qs'
import type { Context } from '@nuxt/types'
import type { APIRequestSuccess } from '~/type/APIRequestStatus'
import { FeedbackTopicSerializer } from '~/serializer/feedback'

export default {
  send(this: Context, { name, phone, subject, orderNumber, question }:
    { name?: string; phone: string; subject: string; orderNumber: string; question: string }): Promise<APIRequestSuccess> {
    const { $axios } = this

    return $axios.$post(
      '/api/v1/feedbacks',
      stringify({
        UF_PHONE_NUMBER: phone,
        UF_USER_NAME: name,
        APPEAL_TYPE: subject,
        UF_SOURCE: orderNumber,
        UF_USER_COMMENTS: question,
      }),
    )
  },

  async getFeedbackTopic(this: Context) {
    const { $axios } = this

    const response = (await $axios.$get<APIRequestSuccess>('/api/v1/feedbacks/paragraphs'))?.result

    return {
      result: response ? FeedbackTopicSerializer(response) : [],
    }
  },

  complaint(this: Context, { code, source, name, email, text }: { code: number; email?: string; name: string; source?: number; text: string }) {
    const { $axios } = this

    return $axios.$post(
      '/api/v1/complaint',
      stringify({
        product_id: code,
        source_id: source,
        user_name: name,
        user_email: email,
        text,
      }),
    )
  },

  foundError(this: Context, { text, source }: { text: string; source: number }) {
    const { $axios } = this

    return $axios.$post<APIRequestSuccess>(
      '/api/v1/feedbacks/error',
      stringify({
        UF_USER_COMMENTS: text,
        UF_SOURCE: source,
      }),
    )
  },
}

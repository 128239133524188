import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import type { Events } from '~/plugins/emitter'

export default function chooseDeliveryDate({ date }: Events['click-delivery-date']) {
  return {
    event: 'basket_click_delivery_date',
    delivery_day: format(new Date(date), 'd MMMM', { locale: ru }),
  }
}

import type { ErrorType } from '~/type/Error'

export default function (event: any): ErrorType {
  const api = !!event?.request
  const model = !!event?.isModelError
  const syntax = !model && ['TypeError', 'SyntaxError', 'RangeError', 'InternalError', 'ReferenceError'].includes(event?.name)
  const type = model ? 'model' : api ? 'api' : syntax ? 'syntax' : 'unknown'

  return {
    type,
    name: event?.name,
    message: event?.message,
    code: event?.response?.status,
    native: event,
    redirect: event.redirect,
  }
}

import type { Events } from '~/plugins/emitter'

export default function selectSearchResult(data: Events['select-search-result']) {
  return {
    event: 'select_search_result',
    data: {
      search_term: data.searchQuery,
      index: typeof data.index === 'number' ? (data.index + 1).toString() : '',
      select_result_type: data.selectResultType,
      select_result_item: data.selectResultItem,
    },
  }
}

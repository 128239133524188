import type RootCategory from '~/type/RootCategory'
import Brand from '~/serializer/Brand'
import { ProductSerialize } from '~/serializer/product/Product'
import { CategorySerializer } from '~/serializer/Category'

export default function (el: { [key: string]: any }): RootCategory {
  return {
    ...CategorySerializer(el),
    product: el.PRODUCTS?.length ? el.PRODUCTS.map((item: any) => ProductSerialize(item)) : [],
    brands: el.SECTION_POPULAR_BRANDS?.length ? el.SECTION_POPULAR_BRANDS.map((item: any) => Brand(item)) : [],
  }
}

import type { Availability } from '~/type/product/Product'

export default function (el: { [ key: string ]: string } = {}): Availability {
  return {
    name: el.NAME,
    color: el.COLOR,
    value: el.VALUE || 'Нет в наличии',
    cityId: +el?.CITY_ID,
  }
}

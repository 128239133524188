var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "lightweight-page p"
  }, [_c('nuxt-link', {
    staticClass: "lightweight-page__logo-wrapper",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticClass: "lightweight-page__logo",
    attrs: {
      "src": require("@/assets/images/logo.png"),
      "alt": "05.ру Интернет-магазин"
    }
  })]), _vm._v(" "), _c('Nuxt'), _vm._v(" "), _c('div', {
    staticClass: "lightweight-page__nav"
  }, [_c('nuxt-link', {
    staticClass: "link lightweight-page__nav-el",
    attrs: {
      "to": "/"
    }
  }, [_vm._v("\n      На главную\n    ")]), _vm._v(" "), _c('nuxt-link', {
    staticClass: "link lightweight-page__nav-el",
    attrs: {
      "to": "/catalog/"
    }
  }, [_vm._v("\n      Каталог\n    ")]), _vm._v(" "), _c('nuxt-link', {
    staticClass: "link lightweight-page__nav-el",
    attrs: {
      "to": "/about/contacts/"
    }
  }, [_vm._v("\n      Контакты\n    ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product",
    class: [("product_" + (_vm.size))]
  }, [_c('div', {
    staticClass: "product__wrap"
  }, [_vm.product.price && (_vm.product.price.discount || _vm.product.price.bonus) ? _c('div', {
    staticClass: "product__labels"
  }, [_vm.product.price.bonus ? _c('TSBonusCount', {
    staticClass: "product__bonus-count",
    attrs: {
      "count": _vm.product.price.bonus,
      "size": "regular"
    }
  }) : _vm._e(), _vm._v(" "), _vm.product.price.discount ? _c('ProductDiscountMarker', {
    staticClass: "product__discount",
    attrs: {
      "price": _vm.product.price.discount.value,
      "discount": _vm.product.price.common.value
    }
  }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _c('nuxt-link', {
    staticClass: "product__image-wrap",
    attrs: {
      "to": _vm.product.path
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.$emit('link-click');
      }
    }
  }, [_c('img', {
    staticClass: "product__img",
    attrs: {
      "src": _vm.size === 'big' ? _vm.product.thumbnail.x2 : _vm.product.thumbnail.x1,
      "srcset": ((_vm.product.thumbnail.x2) + " 2x"),
      "alt": _vm.product.name
    }
  })]), _vm._v(" "), _c('p', {
    staticClass: "product__title"
  }, [_c('nuxt-link', {
    staticClass: "link link_wu",
    attrs: {
      "to": _vm.product.path
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.$emit('link-click');
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.product.name) + "\n      ")])], 1), _vm._v(" "), _vm.product.price && _vm.product.price.common ? _c('TSPrice', {
    staticClass: "product__price",
    attrs: {
      "size": _vm.size,
      "price": _vm.product.price,
      "type": "promo"
    }
  }) : _vm._e(), _vm._v(" "), _vm.cart && _vm.product.status.code !== 'NOT_AVAILABLE' ? _c('div', {
    staticClass: "product__cart"
  }, [_c('ActionCart', {
    attrs: {
      "id": _vm.product.id,
      "product": _vm.product,
      "modal": {
        image: _vm.product.thumbnail.x1,
        link: _vm.product.path,
        name: _vm.product.name,
        price: _vm.product.price,
        category: _vm.product.category,
        offer: _vm.product.offer,
        id: _vm.product.id,
        bonus: {
          income: _vm.product.price && _vm.product.price.bonus
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var add = _ref.add,
            remove = _ref.remove,
            active = _ref.active,
            loading = _ref.loading;
        return [_c('CartTool', {
          attrs: {
            "active": active,
            "loading": loading
          },
          on: {
            "add": function ($event) {
              add($event);

              _vm.$emit('add-to-cart');
            },
            "remove": remove
          }
        }, [_c('span', [_vm._v(" В корзину ")])])];
      }
    }], null, false, 204211437)
  })], 1) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
import type { Context } from '@nuxt/types'
import { stringify } from 'qs'
import { getCancelToken } from './helper'
import BrandSerializer from '~/serializer/Brand'
import ProductPromoBlockSerializer from '~/serializer/product/ProductPromoBlock'
import { CategorySerializer } from '~/serializer/Category'
import BannerSerializer from '~/serializer/Banner'
import PageMainSerializer from '~/serializer/pages/Main'
import MetaBlockSerializer from '~/serializer/MetaBlock'
import FilterListSerializer from '~/serializer/filter/FilterList'
import { ThumbnailSerializer } from '~/serializer/Thumbnail'
import CatalogPageSerializer from '~/serializer/CatalogPage'
import { useLocationStore } from '~/store/location'

export default {
  async main(this: Context) {
    const { $axios, $pinia } = this
    const locationStore = useLocationStore($pinia)

    const main = (
      await $axios.$get('/api/v1/snippets/vue/main', {
        cache: {
          maxAge: 10 * 60 * 1000,
          key: locationStore.currentCity?.name as string,
        },
      })
    )?.result
    return {
      result: PageMainSerializer(main),
    }
  },
  async catalog(
    this: Context,
    { seo, id, page, sort, availabilitySort, customQuery, includedFilters, q, cancel = false, size = 60 }: any = {},
  ) {
    const { $axios, $pinia } = this
    const cancelToken = cancel ? getCancelToken({ name: 'catalogProduct', cancelToken: $axios.CancelToken }) : undefined
    const onlyBonusProduct = seo === '/catalog/bonuses/'
    const locationStore = useLocationStore($pinia)

    const result = (
      await $axios.$get(
        `/api/v1/snippets/vue/catalog?${stringify({
          seo_link:
            `${onlyBonusProduct ? '/catalog/filter/?' : seo}?${
             stringify({
              ...customQuery,
              section_id: id,
              page,
              sort,
              ...(onlyBonusProduct && { sort: 'bonus_desc', bonus_from: 1 }),
              size,
            })}`,
          q,
          availability_sort: availabilitySort,
          included_filters: includedFilters,
          ...(onlyBonusProduct && { mode: 'all_products', filter_groups: 'bonuses' }),
        })}`,
        {
          cache: {
            maxAge: 60 * 60 * 1000,
            key: locationStore.currentCity?.name as string,
          },
          cancelToken,
        },
      )
    )?.result

    return {
      result: {
        ...CatalogPageSerializer({ ...result, path: seo }),
        ...(onlyBonusProduct && { title: 'Тут много бонусов' }),
      },
    }
  },
  async category(this: Context, { seo, id, page, sort }: { seo: string; id: string; page: string; sort: string }) {
    const { $axios, $pinia } = this
    const locationStore = useLocationStore($pinia)

    const { result } = await $axios.$get<{ result:
    { BRANDS_BLOCK: any[]; BANNERS_BLOCK: any[]; META_BLOCK: any; SECTION_TREE_BLOCK: { CHILDREN: any[]; NAME: string; THUMBNAILS: any; ID: string }; PRODUCTS_BLOCK: { COUNT: number }; FILTERS_BLOCK: { FILTERS: any[] } } }>
    (
      `/api/v1/snippets/vue/catalog?${stringify({
        seo_link: seo,
        section_id: id,
        page,
        sort,
      })}`,
      {
        cache: {
          maxAge: 60 * 60 * 1000,
          key: locationStore.currentCity?.name as string,
        },
      },
    )

    const sliders = await $axios.$get<{
      result: any[]
    }>(
      `/api/v1/sliders?${stringify({
        section_id: result?.SECTION_TREE_BLOCK?.ID,
      })}`,
      {
        cache: {
          maxAge: 60 * 60 * 1000,
          key: locationStore.currentCity?.name as string,
        },
      },
    )

    const filter = FilterListSerializer(result?.FILTERS_BLOCK?.FILTERS)

    const sections = {
      meta: MetaBlockSerializer(result?.META_BLOCK),
      thumbnail: ThumbnailSerializer(result?.SECTION_TREE_BLOCK?.THUMBNAILS),
      banner: result?.BANNERS_BLOCK ? BannerSerializer(result?.BANNERS_BLOCK?.[0]) : undefined,
      children: Array.isArray(result?.SECTION_TREE_BLOCK?.CHILDREN)
        ? result?.SECTION_TREE_BLOCK?.CHILDREN.map(el => CategorySerializer(el))
        : [],
      name: result?.SECTION_TREE_BLOCK?.NAME,
      id: result?.SECTION_TREE_BLOCK?.ID,
      count: result?.PRODUCTS_BLOCK?.COUNT,
      sliders: sliders?.result?.length ? sliders?.result.map(el => ProductPromoBlockSerializer(el)) : [],
      brand: Array.isArray(result?.BRANDS_BLOCK) ? result?.BRANDS_BLOCK.map(el => BrandSerializer(el)) : [],
      filter: filter.list.filter((el, index) => el.type === 'checkbox' && index < 18),
    }

    return { result: sections }
  },
  async discount(this: Context, params: { page?: number; sort: string | (string | null)[]; cancel?: boolean; size: number }) {
    const { $api } = this

    const list = await Promise.all([$api.sale.sections.call(this), $api.sale.products.call(this, params)])

    return {
      result: {
        sections: list?.[0],
        products: list?.[1]?.result,
      },
    }
  },
}

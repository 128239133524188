import { addMinutes, format, formatISO, parse } from 'date-fns'
import { ru } from 'date-fns/locale'
import { ProductCartSerialize } from './product/ProductCart'
import type { Order } from '~/type/order/Order'

export default function (el: any): Order {
  function calcTotalBonus(): number {
    if (el.PRODUCTS?.length) {
      let data = el.PRODUCTS.map((el: { [key: string]: any }) => el.PRICES?.CURRENT?.BONUS)
      data = data.reduce((acc: number, currentValue: number) => acc + currentValue)
      return data
    }
    return 0
  }

  // TODO Убрать, когда бэк будет возвращать свойство истечения времени оплаты как в дарксторе
  function convertDateToISO8601(val: string) {
    const parsedDate = addMinutes(parse(val, 'dd.MM.yyyy HH:mm:ss', new Date()), 20)
    return formatISO(parsedDate)
  }

  function getStatusTypeVue(el: any) {
    switch (el.STATUS_ID.ID) {
      case 'N':
        return 'vueprocessing'
      case 'CM':
        return 'vuecompleted'
      case 'SD':
        return 'vuedelivery'
      case 'RV':
      case 'OD':
        return 'vuepickup'
      default:
        return 'notshow'
    }
  }

  function getStatusType(el: any) {
    if (!el.PAYED && el.CAN_BY_PAY)
      return 'not-paid'
    else if (!el.PAYED && !el.CAN_BY_PAY)
      return 'not-paid-yet'
    else if (el.STATUS_ID.NAME === 'Новый')
      return 'new'
    else if (el.STATUS_ID.ID === 'G')
      return 'transit'
    else
      return 'not-paid-yet'
  }

  return {
    id: el.ID,
    status: {
      name: el.STATUS_ID?.NAME,
      color: el.STATUS_ID?.COLOR,
      type: getStatusType(el),
      typeMain: getStatusTypeVue(el),
    },
    date:
      el.DATE_INSERT.length
      && format(parse(el.DATE_INSERT.split(' ')[0], 'dd.MM.yyyy', new Date(), { locale: ru }), 'd MMMM y', { locale: ru }),
    number: el.ACCOUNT_NUMBER,
    price: el.PRICE,
    bonusSpent: el.BONUSES_SPENT,
    delivery: {
      name: el.DELIVERY_NAME,
      date:
        el.DELIVERY_DATE.length
        && format(parse(el.DELIVERY_DATE.split(' ')[0], 'dd.MM.yyyy', new Date(), { locale: ru }), 'd MMMM', { locale: ru }),
      detail: {
        address: el.USER_ADDRESS,
        city: el.USER_LOCATION,
      },
    },
    products: el.PRODUCTS?.map((el: { [key: string]: any } = {}) => ProductCartSerialize(el)) || [],
    payment: {
      name: el.PAY_SYSTEM_NAME,
      link: el.PAY_SYSTEM_LINK,
      type: el.PAY_SYSTEM_IS_CASH ? 'cash' : 'card',
      payed: el.PAYED,
      expireTime: el.DATE_INSERT.length && convertDateToISO8601(el.DATE_INSERT),
    },
    isEvaluated: el.EVALUATED,
    buyer: {
      name: el.USER_NAME,
      email: el.USER_EMAIL,
      phone: el.USER_PHONE,
    },
    discount: el.DISCOUNT_VALUE,
    bonus: calcTotalBonus(),
  }
}

import type { Context } from '@nuxt/types'
import APIRequestStatus from '~/serializer/APIRequestStatus'
import { LocationSerializer } from '~/serializer/Location'

export default {
  async getCitiesWithCache(this: Context) {
    const { $api } = this
    if (process.client) {
      const isCitylistStorage = localStorage?.getItem('cityListV2')
      const localCityList = isCitylistStorage ? JSON.parse(isCitylistStorage) : []

      if (localCityList.length) {
        return {
          result: localCityList as Location[],
        }
      }
      else {
        const list = await $api.location.getCities.call(this)
        return {
          result: list,
        }
      }
    }
    else {
      return {
        result: await $api.location.getCities.call(this),
      }
    }
  },
  async getCities(this: Context) {
    const { $axios } = this

    const locations = await $axios.$get<{ result: any[] }>('/api/v1/locations')
    const cityList = locations.result.map(el => LocationSerializer(el))

    if (process.client)
      localStorage?.setItem('cityListV2', JSON.stringify(cityList))
    return cityList
  },
  async getCityCurrent(this: Context) {
    const { $axios } = this
    const location = (await $axios.$get<{ result: any[] }>('/api/v1/locations/current'))?.result

    return {
      result: LocationSerializer(location),
    }
  },
  async setCityCurrent(this: Context, { id }: { id: number }) {
    const { $axios } = this
    const response = await $axios.$post(`/api/v1/locations/${id}`)

    return APIRequestStatus(response)
  },
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "grid",
    class: [("grid_" + (_vm.count) + "-count"), ("grid_" + (_vm.largeCount) + "-large")]
  }, _vm._l(_vm.list, function (category, index) {
    var _category$children, _vm$colorList, _vm$colorList2, _category$thumbnail, _category$thumbnail2, _category$thumbnail3;

    return _c('div', {
      key: index,
      staticClass: "grid__item"
    }, [_c((_category$children = category.children) !== null && _category$children !== void 0 && _category$children.length && _vm.largeCount > index ? 'div' : 'nuxt-link', {
      tag: "component",
      staticClass: "category",
      class: [{
        category_big: _vm.largeCount > index,
        category_active: (_vm$colorList = _vm.colorList) === null || _vm$colorList === void 0 ? void 0 : _vm$colorList[index]
      }],
      style: {
        backgroundColor: (_vm$colorList2 = _vm.colorList) === null || _vm$colorList2 === void 0 ? void 0 : _vm$colorList2[index]
      },
      attrs: {
        "to": category.path
      },
      on: {
        "click": function ($event) {
          return _vm.emit('category-click', category);
        }
      }
    }, [_c('div', {
      staticClass: "category__text-wrap"
    }, [_c(_vm.largeCount > index ? 'nuxt-link' : 'div', {
      tag: "component",
      staticClass: "category__title",
      attrs: {
        "to": category.path
      },
      on: {
        "click": function ($event) {
          return _vm.emit('category-click', category);
        }
      }
    }, [_vm._v("\n          " + _vm._s(category.name) + "\n        ")]), _vm._v(" "), category.children && _vm.largeCount > index ? _c('div', {
      staticClass: "category__list"
    }, _vm._l(category.children, function (child, i) {
      return _c('nuxt-link', {
        key: i,
        staticClass: "category__list-item",
        attrs: {
          "to": child.path
        },
        nativeOn: {
          "click": function ($event) {
            return _vm.emit('category-click', child);
          }
        }
      }, [_vm._v("\n            " + _vm._s(child.name) + "\n          ")]);
    }), 1) : _vm._e()], 1), _vm._v(" "), _c('div', {
      staticClass: "category__img-wrap"
    }, [_c(_vm.largeCount > index ? 'nuxt-link' : 'div', {
      tag: "component",
      staticClass: "category__img-link",
      attrs: {
        "to": category.path
      },
      on: {
        "click": function ($event) {
          return _vm.emit('category-click', category);
        }
      }
    }, [_c('img', {
      ref: "imgRef",
      refInFor: true,
      staticClass: "category__img",
      attrs: {
        "crossorigin": "anonymous",
        "src": (_vm.largeCount > index ? (_category$thumbnail = category.thumbnail) === null || _category$thumbnail === void 0 ? void 0 : _category$thumbnail.x4 : (_category$thumbnail2 = category.thumbnail) === null || _category$thumbnail2 === void 0 ? void 0 : _category$thumbnail2.x3) || ((_category$thumbnail3 = category.thumbnail) === null || _category$thumbnail3 === void 0 ? void 0 : _category$thumbnail3.x2) || category.image || require('~/assets/images/empty.svg'),
        "alt": category.name
      }
    })])], 1)])], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }
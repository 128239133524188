var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-code",
    class: [("add-code_" + (_vm.state))]
  }, [_c('div', {
    staticClass: "add-code__wrapper",
    on: {
      "mouseleave": _vm.handleMouseLeave,
      "mouseenter": _vm.handleMouseEnter
    }
  }, [_c('div', {
    staticClass: "add-code__top"
  }, [_vm._m(0), _vm._v(" "), _vm.state === 'success' ? _c('div', {
    staticClass: "add-code__info"
  }, [_c('div', {
    staticClass: "add-code__title"
  }, [_vm._v("\n          Добавили ваш промокод\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "add-code__desc"
  }, [_vm._v("\n          Промокоды хранятся в личном кабинете\n        ")])]) : _vm.state === 'warning' ? _c('div', {
    staticClass: "add-code__info"
  }, [_c('div', {
    staticClass: "add-code__title"
  }, [_vm._v("\n          Промокод уже добавлен\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "add-code__desc"
  }, [_vm._v("\n          Наслаждайтесь покупками\n        ")])]) : _c('div', {
    staticClass: "add-code__info"
  }, [_c('div', {
    staticClass: "add-code__title"
  }, [_vm._v("\n          Промокод уже использован\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "add-code__desc"
  }, [_vm._v("\n          Повторно применить нельзя\n        ")])])]), _vm._v(" "), _vm.state === 'success' ? _c('div', {
    staticClass: "add-code__buttons"
  }, [_c('Button', {
    staticClass: "add-code__button",
    attrs: {
      "fluid": ""
    },
    on: {
      "click": _vm.handleToCode
    }
  }, [_vm._v("\n        Мои промокоды\n      ")]), _vm._v(" "), _c('Button', {
    staticClass: "add-code__button-mobile",
    attrs: {
      "size": "33",
      "fluid": ""
    },
    on: {
      "click": _vm.handleToCode
    }
  }, [_vm._v("\n        Мои промокоды\n      ")])], 1) : _vm._e(), _vm._v(" "), _c('span', {
    staticClass: "add-code__close",
    on: {
      "click": _vm.close
    }
  })])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-code__img-box"
  }, [_c('img', {
    staticClass: "add-code__img",
    attrs: {
      "src": require("@/assets/images/svg/code.svg"),
      "alt": "код"
    }
  })]);
}]

export { render, staticRenderFns }
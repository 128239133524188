import type { Events } from '~/plugins/emitter'

export default function searchConfirm(data: Events['search-confirm']) {
  return {
    name: 'search',
    data: {
      search_term: data.searchQuery,
      search_source: data.searchSource,
    },
  }
}

import type Thumbnail from '~/type/Thumbnail'

export function ThumbnailSerializer(el: { [key: string]: any } = {}): Thumbnail {
  return {
    x1: el?.['1X'] || require('~/assets/images/empty.svg'),
    x2: el?.['2X'] || require('~/assets/images/empty.svg'),
    x3: el?.['3X'],
    x4: el?.['4X'],
  }
}

import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import type NodeCache from 'node-cache'

interface CacheHelperParams {
  request: () => Promise<unknown>
  key: string
  ttl?: number
}

declare module '@nuxt/types' {
  interface Context {
    $nodeCache: {
      cache?: NodeCache
      helper: <T>(param: CacheHelperParams) => T
    }
  }
}

export default defineNuxtPlugin(({ ssrContext }, inject) => {
  const cache = (ssrContext as SSRContext)?.nodeCache

  inject('nodeCache', {
    cache,
    helper: cacheHelper,
  })

  async function cacheHelper({ request, key, ttl = 3600 }: CacheHelperParams) {
    if (process.server && cache) {
      if (cache.has(key)) {
        console.log(`from cache node cache ${key}`)
        return cache.get(key)
      }
      else {
        console.log('download on server')
        const tmp = await request()
        cache.set(key, tmp, ttl)
        return tmp
      }
    }
    else {
      return await request()
    }
  }
})

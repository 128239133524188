import type { Events } from '~/plugins/emitter'

export default function searchQuantity(data: Events['quantity-search']) {
  return {
    event: 'quantity_search',
    data: {
      search_term: data.searchQuery,
      quantity: data.quantity,
    },
  }
}

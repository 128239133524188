import { CourierAddressSerializer } from './CourierAddress'
import { PickupAddressSerializer } from './PickupAddress'
import type { CheckoutDelivery } from '~/type/Checkout'

export function CheckoutDeliverySeriazlier(el: { [ key: string ]: any } = {}): CheckoutDelivery {
  const isAllow = isAllowSelectCustomAddress(el.TYPE)
  return {
    id: +el.ID,
    code: el.TYPE,
    title: el.NAME,
    period: el.PERIOD,
    price: +el.PRICE,
    list: getAddressList(isAllow, el),
    allowSelectUserAddress: isAllow,
    image: el.LOGOTIP ?? null,
  }
}

/**
 * Определяем может ли человек выбирать свой адрес исходя из типа доставки,
 * на момент написания комментария мы поддерживаем лишь два таких типа
 */
function isAllowSelectCustomAddress(code: string) {
  return ['DPD', 'DELIVERY'].includes(code)
}

function getAddressList(isAllow: boolean, el: any) {
  if (isAllow) {
    const tmp = el?.DECODED_LAST_ADDRESSES?.map((address: any) => {
      return address?.DECODED_ADDRESS ? CourierAddressSerializer(address?.DECODED_ADDRESS) : null
    })
    return tmp?.filter((address: any) => !!address) || []
  }
  else {
    return el.STORE?.map((store: any) => PickupAddressSerializer(store))
  }
}

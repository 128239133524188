import type { Context } from '@nuxt/types'
import type { useContext } from '@nuxtjs/composition-api'
import { stringify } from 'qs'
import { ThumbnailSerializer } from '~/serializer/Thumbnail'
import type Thumbnail from '~/type/Thumbnail'

export interface Game {
  name: string
  code: string
  description: string
  isAccrued: boolean
  availableAttempts: number
  bonus: {
    total: number
    available: number
  }
  active: {
    from: string
    to: string
  }
}

export interface GetGame {
  game: Game
  questions: GetGameQuestion[]
}

export interface GetGameQuestion {
  id: number
  bonusCount: number
  answerVariants: GetAnswerVariant[]
  availableWords: string[]
  rightWord: string
}

interface GetAnswerVariant {
  answerId: number
  image: string
  thumbnails: Thumbnail
}

function getGame(el: { [key: string]: any }): Game {
  return {
    name: el?.NAME,
    code: el?.CODE,
    description: el?.DESCRIPTION,
    isAccrued: el?.IS_ACCRUED,
    availableAttempts: el?.AVAILABLE_ATTEMPTS,
    bonus: {
      total: el?.TOTAL_BONUS,
      available: el?.AVAILABLE_BONUS,
    },
    active: {
      from: el?.ACTIVE_FROM,
      to: el?.ACTIVE_TO,
    },
  }
}

function getGameQuestion(el: { [key: string]: any }): GetGameQuestion {
  return {
    id: el?.ID,
    bonusCount: el?.BONUS_COUNT,
    availableWords: el?.ANSWER_VARIANTS?.AVAILABLE_WORDS ? el?.ANSWER_VARIANTS?.AVAILABLE_WORDS : [],
    rightWord: el.ANSWER_VARIANTS.RIGHT_WORD,
    answerVariants: Array.isArray(el?.ANSWER_VARIANTS) ? el.ANSWER_VARIANTS.map(el => getAnswerVariant(el)) : [],
  }
}

function getAnswerVariant(el: { [key: string]: any }) {
  return {
    answerId: el?.ANSWER_ID,
    availableWords: el?.AVAILABLE_WORDS,
    image: el?.IMAGE,
    thumbnails: ThumbnailSerializer(el?.IMAGE_THUMBNAILS),
  }
}

export default {
  async getGames(this: Context) {
    const { $axios } = this
    const response = (await $axios.$get<{ result: { ITEMS: [] } }>('api/v1/games'))?.result

    return Array.isArray(response?.ITEMS) ? response?.ITEMS.map(el => getGame(el)) : []
  },
  async getGameQuestions(this: Context, { gameCode }: { gameCode: string }) {
    const { $axios } = this
    const response = (await $axios.$get(`/api/v1/games/${gameCode}/questions`))?.result
    const questions: GetGameQuestion[] = Array.isArray(response?.ITEMS) ? response.ITEMS?.map((el: any) => getGameQuestion(el)) : []
    return {
      game: getGame(response?.GAME),
      questions,
    }
  },
  async checkAnswer(this: Context | ReturnType<typeof useContext>, { questionId, answerId }: { questionId: number; answerId: number }) {
    const { $axios } = this
    const response = await $axios.$post(`/api/v1/games/questions/${questionId}/check?${stringify({
      answer_id: answerId,
    })}`)
    return response
  },
  cashBonus(this: Context, gameCode: { code: string }) {
    const { $axios } = this
    const response = $axios.$post(`/api/v1/games/${gameCode.code}/accrue`)
    return response
  },
  gameRestore(this: Context, gameCode: string) {
    const { $axios } = this
    const response = $axios.$post(`/api/v1/games/${gameCode}/restore`)
    return response
  },
  async getBonuses(this: Context, data: { gameCode: string; amount: number }) {
    const { $axios } = this
    const response = (await $axios.$post(`/api/v1/quizes/${data.gameCode}/bonuses?${stringify({ amount: 50 })}`))
    return response
  },
}

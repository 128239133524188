import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import type OrderCheck from '~/type/order/OrderCheck'

export default function (el: any): OrderCheck {
  return {
    orderId: el.ORDER_ID,
    dateCreated: el.CREATED_AT && format(new Date(el.CREATED_AT), 'd MMMM yyyy', { locale: ru }),
    price: el.ORDER_SUM,
    path: el.PAY_CHECK_LINK,
  }
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "darkstore-topbar",
    attrs: {
      "href": ((_vm.$config.darkstoreUrl) + "?utm_source=main_banner_05_top&utm_medium=referral&utm_campaign=05")
    }
  }, [_vm._m(0), _vm._v(" "), _c('img', {
    staticClass: "darkstore-topbar__image darkstore-topbar__cola",
    attrs: {
      "src": require("@/assets/images/darkstore-makhachkala-topbar/cola@1x.png"),
      "alt": "Кола",
      "srcset": require("@/assets/images/darkstore-makhachkala-topbar/cola@1x.png") + " 1x, " + require("@/assets/images/darkstore-makhachkala-topbar/cola@2x.png") + " 2x, " + require("@/assets/images/darkstore-makhachkala-topbar/cola.png") + " 3x"
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "darkstore-topbar__image darkstore-topbar__pumpkin",
    attrs: {
      "src": require("@/assets/images/darkstore-makhachkala-topbar/pumpkin@1x.png"),
      "alt": "Тыква",
      "srcset": require("@/assets/images/darkstore-makhachkala-topbar/pumpkin@1x.png") + " 1x, " + require("@/assets/images/darkstore-makhachkala-topbar/pumpkin@2x.png") + " 2x, " + require("@/assets/images/darkstore-makhachkala-topbar/pumpkin.png") + " 3x"
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "darkstore-topbar__image darkstore-topbar__milk",
    attrs: {
      "src": require("@/assets/images/darkstore-makhachkala-topbar/milk@1x.png"),
      "alt": "Молоко",
      "srcset": require("@/assets/images/darkstore-makhachkala-topbar/milk@1x.png") + " 1x, " + require("@/assets/images/darkstore-makhachkala-topbar/milk@2x.png") + " 2x, " + require("@/assets/images/darkstore-makhachkala-topbar/milk.png") + " 3x"
    }
  }), _vm._v(" "), _vm._m(1), _vm._v(" "), _c('img', {
    staticClass: "darkstore-topbar__image darkstore-topbar__cheese",
    attrs: {
      "src": require("@/assets/images/darkstore-makhachkala-topbar/cheese@1x.png"),
      "alt": "Сыр",
      "srcset": require("@/assets/images/darkstore-makhachkala-topbar/cheese@1x.png") + " 1x, " + require("@/assets/images/darkstore-makhachkala-topbar/cheese@2x.png") + " 2x, " + require("@/assets/images/darkstore-makhachkala-topbar/cheese.png") + " 3x"
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "darkstore-topbar__image darkstore-topbar__watermelon",
    attrs: {
      "src": require("@/assets/images/darkstore-makhachkala-topbar/watermelon@1x.png"),
      "alt": "Арбуз",
      "srcset": require("@/assets/images/darkstore-makhachkala-topbar/watermelon@1x.png") + " 1x, " + require("@/assets/images/darkstore-makhachkala-topbar/watermelon@2x.png") + " 2x, " + require("@/assets/images/darkstore-makhachkala-topbar/watermelon.png") + " 3x"
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "darkstore-topbar__image darkstore-topbar__grape",
    attrs: {
      "src": require("@/assets/images/darkstore-makhachkala-topbar/grape@1x.png"),
      "alt": "Виноград",
      "srcset": require("@/assets/images/darkstore-makhachkala-topbar/grape@1x.png") + " 1x, " + require("@/assets/images/darkstore-makhachkala-topbar/grape@2x.png") + " 2x, " + require("@/assets/images/darkstore-makhachkala-topbar/grape.png") + " 3x"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "darkstore-topbar__button"
  }, [_vm._v("\n    Перейти\n  ")])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "darkstore-topbar__logo"
  }, [_c('img', {
    staticClass: "darkstore-topbar__logo-img darkstore-topbar__logo-img_desktop",
    attrs: {
      "src": require("@/assets/images/darkstore-makhachkala-topbar/title.svg"),
      "alt": "Близко"
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "darkstore-topbar__logo-img darkstore-topbar__logo-img_mobile",
    attrs: {
      "src": require("@/assets/images/darkstore-makhachkala-topbar/logo.svg"),
      "alt": "Близко"
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "darkstore-topbar__info"
  }, [_c('div', {
    staticClass: "darkstore-topbar__title"
  }, [_vm._v("\n      Быстрая доставка продуктов в Махачкале и Каспийске\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "darkstore-topbar__title"
  }, [_vm._v("\n      Быстрая доставка продуктов в Махачкале и Каспийске\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "darkstore-topbar__title"
  }, [_vm._v("\n      Быстрая доставка продуктов в Махачкале и Каспийске\n    ")])]);
}]

export { render, staticRenderFns }
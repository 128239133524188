
import { declination, formatPrice } from '@artmizu/utils';
const __sfc_main = {};
__sfc_main.props = {
  quantity: {
    key: "quantity",
    required: true,
    type: Number
  },
  price: {
    key: "price",
    required: true,
    type: Number
  }
};
__sfc_main.setup = (__props, __ctx) => {
  function declinationValue(quantity: number) {
    return declination(quantity || 0, ['товар', 'товара', 'товаров']);
  }
  return {
    formatPrice,
    declinationValue
  };
};
export default __sfc_main;

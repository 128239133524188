
import { computed, inject, ref, watch } from '@vue/composition-api';
import { Button, InputText, Modal, StatusBlock } from '05-ui-kit';
import { storeToRefs } from 'pinia';
import type { EmitteryType } from '~/plugins/emitter';
import type BrandGroup from '~/type/BrandGroup';
import type { Location } from '~/type/Location';
import { useLocationStore } from '~/store/location';
interface GrouppedLocation {
  char: string;
  list: Location[];
}
const __sfc_main = {};
__sfc_main.props = {
  success: {
    key: "success",
    required: false,
    type: Boolean,
    default: false
  },
  isModalActive: {
    key: "isModalActive",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const emitter = inject<EmitteryType>('emitter')!;
  const changeCityID = ref<number>();
  const query = ref('');
  const cities = ref<Location[]>();
  const searchResult = ref<{
    item: BrandGroup;
    matches?: {
      value: string;
    }[];
  }[]>();
  const cityList = ref<GrouppedLocation[]>();
  const {
    setConfirmed
  } = useLocationStore();
  const {
    confirmed,
    currentCity
  } = storeToRefs(useLocationStore());
  const topCities = computed(() => {
    return cities?.value?.filter(city => {
      return city.name === 'Москва' || city.name === 'Махачкала' || city.name === 'Каспийск' || city.name === 'Дербент' || city.name === 'Хасавюрт';
    });
  });
  const currentCityName = computed(() => {
    return currentCity?.value?.name;
  });
  function isCurrentCity() {
    /**
     * Если пользователь из Дагестана или он подтвердил город, то показываем страндартную модалку с списком городов
     */
    return confirmed.value || currentCity?.value?.region?.name === 'Республика Дагестан';
  }

  /**
   * Если пользователь не из Дагестана и не подтвердил свой город, то показываем окно с подтверждением города.
   */
  const isDagestanConfirmed = computed(() => !confirmed.value && currentCity?.value?.region?.name !== 'Республика Дагестан');
  const selectedCity = ref(isCurrentCity());
  const showCityList = ref(isCurrentCity());
  watch(() => props.success, state => {
    if (state) selectedCity.value = true;
  });
  function getSearchResult(result: {
    item: BrandGroup;
  }[]) {
    searchResult.value = result;
  }
  function input(text: string) {
    query.value = text;
  }
  function onSelectCity() {
    emit('close');
    showCityList.value = true;
    selectedCity.value = true;
    emitter.emit('set-user-city');
    setConfirmed();
  }
  function showCities() {
    showCityList.value = true;
  }
  function onCityListLoad({
    response
  }: {
    response: {
      result: Location[];
    };
  }) {
    cities.value = response.result;
    const grouppedLocations = response?.result.reduce((grouppedLocations: {
      [key: string]: GrouppedLocation;
    }, location: Location) => {
      const char = location.name[0];
      if (!grouppedLocations[char]) grouppedLocations[char] = {
        char,
        list: [location]
      };else grouppedLocations[char]?.list?.push(location);
      return grouppedLocations;
    }, {});
    cityList.value = Object.values(grouppedLocations).sort((a, b) => a.char > b.char ? 1 : -1);
  }
  function onClick(data: Location) {
    changeCityID.value = data.id;
    emit('select-city', data);
  }
  function onClickSearch(data: string) {
    if (query.value) {
      const cityObj = cities.value?.find(city => city.name === data);
      emit('select-city', cityObj);
    }
  }
  return {
    emit,
    query,
    searchResult,
    cityList,
    topCities,
    currentCityName,
    isDagestanConfirmed,
    selectedCity,
    showCityList,
    getSearchResult,
    input,
    onSelectCity,
    showCities,
    onCityListLoad,
    onClick,
    onClickSearch
  };
};
__sfc_main.components = Object.assign({
  Modal,
  Button,
  InputText,
  StatusBlock
}, __sfc_main.components);
export default __sfc_main;


import { computed } from '@vue/composition-api';
import { formatPrice } from '@artmizu/utils';
const __sfc_main = {};
__sfc_main.props = {
  current: {
    key: "current",
    required: true,
    type: String
  },
  target: {
    key: "target",
    required: true,
    type: String
  },
  price: {
    key: "price",
    required: false,
    type: Boolean
  },
  level: {
    key: "level",
    required: false,
    type: Number
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const getPercents = computed(() => {
    if (props.current.endsWith('%')) {
      return props.current;
    } else {
      const current = Number(props.current);
      const target = Number(props.target);
      if (target === 0) return '0%';
      const percentage = current / target * 100;
      return `${percentage}%`;
    }
  });
  const progressValueClass = computed(() => {
    return props.level !== undefined ? `progress__value_${props.level}` : '';
  });
  return {
    formatPrice,
    getPercents,
    progressValueClass
  };
};
export default __sfc_main;


import { computed, ref, watch } from '@vue/composition-api';
interface Props {
  interactive: boolean;
  isActive: number;
  /**
  * Показывать ли статус товара
  */
  showStatus: boolean;
  /* * Размер рейтинга
  */
  size: '12' | '15' | '20' | '22' | '24' | '32';
  /**
  * Размер рейтинга в мобильной версии
  */
  mobileSize: '12' | '15' | '18' | '20' | '22' | '24' | '25' | '32';
  /**
  * Нужен ли цвет для элемента
  */
  yellow: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  interactive: {
    key: "interactive",
    required: false,
    type: Boolean,
    default: false
  },
  isActive: {
    key: "isActive",
    required: false,
    type: Number,
    default: 0
  },
  showStatus: {
    key: "showStatus",
    required: false,
    type: Boolean,
    default: false
  },
  size: {
    key: "size",
    required: false,
    type: String,
    default: '15'
  },
  mobileSize: {
    key: "mobileSize",
    required: false,
    type: String,
    default: '15'
  },
  yellow: {
    key: "yellow",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const count = ref(props.isActive);
  const hoverCount = ref(props.isActive);
  const status = computed(() => {
    switch (count.value) {
      case 1:
        return 'Плохо';
      case 2:
        return 'Не понравился';
      case 3:
        return 'Понравился';
      case 4:
        return 'Хорошо';
      case 5:
        return 'Рекомендует этот товар';
      default:
        return '';
    }
  });
  function onClick(i: number) {
    count.value = i;
    hoverCount.value = i;
    emit('update:model-value', count.value);
  }
  function onMouseove(i: number) {
    hoverCount.value = count.value ? count.value : i;
  }
  watch(() => props.isActive, () => {
    count.value = props.isActive;
    hoverCount.value = props.isActive;
  });
  return {
    count,
    hoverCount,
    status,
    onClick,
    onMouseove
  };
};
export default __sfc_main;

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "site-carcass"
  }, [_c('AppPromo', {
    attrs: {
      "options": {
        interval: 0,
        showForCertainOS: ['android'],
        identifyer: '#123wqdsaxc',
        count: 9999
      },
      "link": "https://play.google.com/store/apps/details?id=com.x05ru.release",
      "description": "Бесплатно в Google Play"
    }
  }), _vm._v(" "), _c('DarkstoreMakhachkalaBar'), _vm._v(" "), _c('Header'), _vm._v(" "), _c('main', {
    staticClass: "site-carcass__container"
  }, [_c('div', {
    staticClass: "site-carcass__content",
    style: {
      padding: _vm.contentPadding
    }
  }, [_vm.isOffline ? _c('LostConnection') : [!_vm.$slots.default ? _c('nuxt') : _vm._e(), _vm._v(" "), _vm._t("default")]], 2)]), _vm._v(" "), _c('Footer'), _vm._v(" "), _c('GlobalTools')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
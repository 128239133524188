import type { PickupAddress } from '~/type/DeliveryAddress'
export function PickupAddressSerializer(el: { [key: string]: any } = {}): PickupAddress {
  return {
    type: 'pickup',
    id: +el.ID,
    address: el.ADDRESS,
    lat: +el.GPS_N,
    lon: +el.GPS_S,
    schedule: el.SCHEDULE,
  }
}

var render = function () {
  var _vm$userData, _vm$userData2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-panel-mobile",
    class: [{
      'user-panel-mobile_menu-active': !_vm.menuState
    }]
  }, [_c('div', {
    staticClass: "user-panel-mobile__el",
    on: {
      "click": function ($event) {
        return _vm.emit('click-support');
      }
    }
  }, [_c('div', {
    staticClass: "user-panel-mobile__icon user-panel-mobile__icon_support"
  }), _vm._v(" "), _c('div', {
    staticClass: "user-panel-mobile__text"
  }, [_vm._v("\n      Поддержка\n    ")])]), _vm._v(" "), _c('a', {
    staticClass: "user-panel-mobile__el",
    attrs: {
      "href": ((_vm.$config.darkstoreUrl) + "?utm_source=tapbar_05&utm_medium=referral&utm_campaign=05")
    },
    on: {
      "click": _vm.changeDarkstoreTagVisible
    }
  }, [_c('div', {
    staticClass: "user-panel-mobile__icon user-panel-mobile__icon_darkstore",
    class: [{
      'user-panel-mobile__icon_darkstore_show-tag': _vm.showDarkstoreTag
    }]
  }), _vm._v(" "), _c('div', {
    staticClass: "user-panel-mobile__text"
  }, [_vm._v("Продукты")])]), _vm._v(" "), _c('div', {
    staticClass: "user-panel-mobile__el",
    class: [{
      'user-panel-mobile__el_active': _vm.menuState
    }],
    on: {
      "click": function ($event) {
        return _vm.emit('click');
      }
    }
  }, [_c('div', {
    staticClass: "user-panel-mobile__icon user-panel-mobile__icon_catalog"
  }), _vm._v(" "), _c('div', {
    staticClass: "user-panel-mobile__text"
  }, [_vm._v("\n      Каталог\n    ")])]), _vm._v(" "), _c('nuxt-link', {
    staticClass: "user-panel-mobile__el",
    attrs: {
      "to": "/cart/"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.emit('disable');
      }
    }
  }, [_c('div', {
    staticClass: "user-panel-mobile__icon user-panel-mobile__icon_cart"
  }), _vm._v(" "), _vm.cartCount ? _c('div', {
    staticClass: "user-panel-mobile__icon-marker"
  }, [_vm._v("\n      " + _vm._s(_vm.cartCount) + "\n    ")]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "user-panel-mobile__text"
  }, [_vm._v("\n      Корзина\n    ")])]), _vm._v(" "), _c('nuxt-link', {
    staticClass: "user-panel-mobile__el",
    attrs: {
      "to": "/user/"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.onUserClick.apply(null, arguments);
      }
    }
  }, [!_vm.userAuth ? _c('div', {
    staticClass: "user-panel-mobile__icon user-panel-mobile__icon_profile"
  }) : _c('ColorAvatar', {
    staticClass: "user-panel-mobile__color-avatar",
    attrs: {
      "img": (_vm$userData = _vm.userData) === null || _vm$userData === void 0 ? void 0 : _vm$userData.avatar,
      "name": (_vm$userData2 = _vm.userData) === null || _vm$userData2 === void 0 ? void 0 : _vm$userData2.name,
      "size": "30",
      "mobile-size": "25"
    }
  }), _vm._v(" "), _vm.favoriteLabel && _vm.favoriteCount ? _c('div', {
    staticClass: "user-panel-mobile__icon-marker-label"
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "user-panel-mobile__text user-panel-mobile__text_profile"
  }, [_vm._v("\n      Профиль\n    ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _ref;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "slider",
    class: {
      slider_active: _vm.active
    },
    style: {
      paddingBottom: _vm.paddingBottom
    }
  }, [_c('div', {
    ref: "sliderWrapper",
    staticClass: "slider__wrapper",
    class: [(_ref = {
      slider_drag: _vm.drag.active
    }, _ref[_vm.wrapperClass] = _vm.wrapperClass, _ref)],
    style: {
      transform: _vm.offset,
      transition: _vm.transition,
      pointerEvents: _vm.pointerEvents
    },
    on: {
      "mousedown": _vm.mouseDragStart,
      "touchstart": _vm.touchDragStart,
      "touchmove": _vm.touchDragMove,
      "touchend": _vm.touchDragEnd
    }
  }, _vm._l(_vm.arr, function (el, index) {
    return _c('div', {
      key: index,
      ref: "slide",
      refInFor: true,
      staticClass: "slide",
      style: {
        marginRight: ((_vm.offsetBetweenElements) + "px")
      }
    }, [_vm._t("default", null, {
      "active": index === _vm.baseIndex,
      "el": el,
      "resetAutoplay": _vm.resetAutoplay,
      "autoplayInterval": _vm.autoplayInterval,
      "slideCount": _vm.innerCount
    })], 2);
  }), 0), _vm._v(" "), _vm._t("nav", null, {
    "currentIndex": _vm.currentIndex,
    "slideTo": _vm.slideTo,
    "isAutoplayActive": _vm.isAutoplayActive,
    "resetAutoplay": _vm.resetAutoplay,
    "autoplayInterval": _vm.autoplayInterval,
    "slideCount": _vm.list.length,
    "prev": _vm.prev,
    "next": _vm.next
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
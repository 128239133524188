// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/header/down-arrow.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".user-menu[data-v-2914c14a]{position:relative;display:flex;align-items:flex-end;min-height:16px;margin-bottom:6px}.user-menu_active .user-menu__name[data-v-2914c14a]{opacity:.4}.user-menu_active .user-menu__hidden-block[data-v-2914c14a]{position:absolute;top:-10px;left:-13px;z-index:100;display:flex;flex-direction:column;width:calc(100% + 30px);min-width:145px;padding:40px 13px 14px;background-color:#fff;border:1px solid #f2f2f2}.user-menu__login[data-v-2914c14a],.user-menu__registration[data-v-2914c14a]{font-size:13px;cursor:pointer}.user-menu__login[data-v-2914c14a]{margin-right:10px}.user-menu__name[data-v-2914c14a]{position:relative;z-index:101;display:block;max-height:15px;padding-right:10px;overflow:hidden;font-size:14px;font-weight:400;line-height:14px;color:#000;text-decoration:none;text-overflow:ellipsis;white-space:nowrap;cursor:pointer;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:100%;transition:opacity,.2s}.user-menu__name-wrap[data-v-2914c14a]{position:relative;flex-grow:1}.user-menu__wrap[data-v-2914c14a]{position:relative;display:flex;align-items:flex-end;max-width:239px;padding-right:38px}.user-menu__wrap .user-menu__bonuses[data-v-2914c14a]{top:1px;flex-shrink:0;min-width:37px;margin-left:12px;text-align:left}.user-menu__hidden-block[data-v-2914c14a]{display:none}.user-menu__hidden-el[data-v-2914c14a]{margin-bottom:13px;font-size:14px;text-decoration:none;cursor:pointer}.user-menu__hidden-el[data-v-2914c14a]:last-child{margin-bottom:0}.user-menu #icon-container[data-v-2914c14a]{visibility:hidden}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

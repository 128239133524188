import { ProductSerialize } from '~/serializer/product/Product'
import type { PhotoFilter } from '~/type/PhotoFilter'
import PhotoFilterSerializer from '~/serializer/PhotoFilter'
import SortSerializer from '~/serializer/Sort'
import MetaBlockSerializer from '~/serializer/MetaBlock'
import { CategorySerializer } from '~/serializer/Category'
import BrandSerializer from '~/serializer/Brand'
import PaginationSerializer from '~/serializer/Pagination'
import SeoFilterSerializer from '~/serializer/filter/SeoFilter'
import FilterListSerializer from '~/serializer/filter/FilterList'
import isAppleWarranty from '~/helper/isAppleWarranty'
import isLargeЕquipment from '~/helper/isLargeЕquipment'
import BannerSerializer from '~/serializer/Banner'
import { ThumbnailSerializer } from '~/serializer/Thumbnail'
import type Category from '~/type/Category'
import type Brand from '~/type/Brand'
import type Sort from '~/type/Sort'
import type { Product } from '~/type/product/Product'
import type { Breadcrumb } from '~/type/Breadcrumb'
import type { FilterList, SeoFilter } from '~/type/Filter'
import type Banner from '~/type/Banner'

export default function (data: { [key: string]: any } = {}): {
  meta: ReturnType<typeof MetaBlockSerializer>
  breadcrumb: Breadcrumb[]
  title: string
  id: string
  count: number
  brand: ReturnType<typeof BrandSerializer>[]
  pagination: ReturnType<typeof PaginationSerializer>
  sort: Sort[]
  product: ReturnType<typeof ProductSerialize>[]
  sectionNav: Category[]
  parentCategoryPath: string
  isAppleWarranty: boolean
  isLargeЕquipment: boolean
  activeAppleNotify: boolean
  thumbnail: ReturnType<typeof ThumbnailSerializer>
  filterPhotoRow: PhotoFilter[]
  filter: {
    brand?: SeoFilter
    model?: SeoFilter
    main: FilterList
  }
  banner: Banner
} {
  const lastCategoryID = getIdLastCategory(data?.SECTION_NAV_CHAIN_BLOCK)
  const sectionTreeID = data?.SECTION_TREE_BLOCK?.ID
  const sectionTreeCategories = data?.SECTION_TREE_BLOCK?.CHILDREN?.map((el: any) => ({ ...el, CHILDREN: [] })) || []

  /**
   * Возвращает id последней категории
   */
  function getIdLastCategory(data: any): string {
    return data?.[0]?.CHILDREN?.length ? getIdLastCategory(data[0].CHILDREN) : data?.[0]?.ID
  }

  /**
   * Возвращает хлебные крошки в зависимости от того, в какой категории мы находимся
   */
  function getBreadcrumb(nav: any) {
    const list = [
      { name: '05.ru', url: '/' },
      { name: 'Каталог', url: '/catalog' },
    ]
    let current = nav?.[0]?.id === lastCategoryID ? null : nav?.[0]

    while (current) {
      list.push({ name: current?.name, url: current?.path })
      current = current.id === lastCategoryID ? null : current?.children?.[0]
    }
    return list
  }

  /**
   * Если id совпадают, то добавляем к последней категории ее детей
   */
  let sectionNav = sectionTreeID === lastCategoryID ? addCategoryChildren(data?.SECTION_NAV_CHAIN_BLOCK) : data?.SECTION_NAV_CHAIN_BLOCK
  sectionNav = convertCategories(sectionNav)

  const filterMain = data?.FILTERS_BLOCK?.FILTERS && FilterListSerializer(data.FILTERS_BLOCK.FILTERS)

  /**
     * Добавляет к последней категории ее детей, если они есть
     */
  function addCategoryChildren(source: any): Category[] {
    if (Array.isArray(source)) {
      return source.map(item => ({
        ...item,
        CHILDREN: Array.isArray(item.CHILDREN) ? addCategoryChildren(item.CHILDREN) : sectionTreeCategories,
      }))
    }
    else {
      return []
    }
  }
  /**
  * Преобразовывает дерево категорий в нужную структуру
  */
  function convertCategories(nav: any) {
    return Array.isArray(nav) ? nav.map(item => CategorySerializer(item)) : []
  }

  function getPhotoFilter(filter: any[], category: Category[]) {
    const data = filter?.length ? filter : category
    const photoFIlterList = data?.map((el: PhotoFilter) => PhotoFilterSerializer(el)) || []
    // TODO - Исключаем временно ссылки pc-builds
    return photoFIlterList.filter((item: PhotoFilter) => !item.link?.includes('/pc-builds/'))
  }

  return {
    meta: MetaBlockSerializer(data?.META_BLOCK),
    breadcrumb: getBreadcrumb(sectionNav),
    thumbnail: ThumbnailSerializer(data?.SECTION_TREE_BLOCK?.THUMBNAILS),
    title: data?.SECTION_TREE_BLOCK?.NAME,
    id: lastCategoryID,
    count: data?.PRODUCTS_BLOCK?.COUNT,
    brand: data?.BRANDS_BLOCK ? data.BRANDS_BLOCK.map((el: Brand) => BrandSerializer(el)) : [],
    pagination: PaginationSerializer(data?.PRODUCTS_BLOCK?.PAGINATION || null),
    sort: data?.FILTERS_BLOCK?.SORT_TYPES?.map((el: Sort) => SortSerializer(el)) || [],
    filterPhotoRow: getPhotoFilter(data?.ADDITIONAL_FILTERS_BLOCK, data?.SECTION_TREE_BLOCK?.CHILDREN),
    filter: {
      brand: data?.SEO_FILTERS?.[0] ? SeoFilterSerializer(data?.SEO_FILTERS?.[0], data.path) : undefined,
      model: data?.SEO_FILTERS?.[1] ? SeoFilterSerializer(data?.SEO_FILTERS?.[1], data.path) : undefined,
      main: filterMain ? { ...filterMain, list: filterMain.list.filter((el: any, index: number) => index < 60) } : null,
    },
    product: data?.PRODUCTS_BLOCK?.ITEMS?.map((el: Product) => ProductSerialize(el)) || [],
    activeAppleNotify: !!data?.NOTIFY_BLOCK,
    banner: data.BANNERS_BLOCK?.length && BannerSerializer(data.BANNERS_BLOCK[0]),
    sectionNav,
    parentCategoryPath: `${data?.SECTION_TREE_BLOCK?.SECTION_PAGE_PATH}`,
    isAppleWarranty: isAppleWarranty({
      category: data?.SECTION_TREE_BLOCK?.NAME,
      path: data?.SECTION_TREE_BLOCK?.SECTION_PAGE_PATH,
    }),
    isLargeЕquipment: isLargeЕquipment({
      path: data?.SECTION_TREE_BLOCK?.SECTION_PAGE_PATH,
    }),
  }
}

import { defineStore } from 'pinia'
import { computed, ref } from '@vue/composition-api'
import type { Location } from '~/type/Location'

export const useLocationStore = defineStore('location', () => {
  const currentCity = ref<Location>()
  const confirmed = ref(false)

  function setCurrentCity(location: Location) {
    currentCity.value = location
  }

  function setConfirmed() {
    confirmed.value = true
  }

  const isDagestan = computed(() => currentCity.value?.region?.id === 37)
  const isMakhachkala = computed(() => currentCity.value?.id === 1359)
  const code = computed(() => currentCity.value?.code)
  const cityName = computed(() => currentCity.value?.name || 'Не определен')
  const сityID = computed(() => currentCity.value?.id)
  const regionID = computed(() => currentCity.value?.region?.id)
  const regionName = computed(() => currentCity.value?.region?.name)

  return {
    currentCity,
    confirmed,
    setCurrentCity,
    setConfirmed,
    isDagestan,
    isMakhachkala,
    code,
    cityName,
    сityID,
    regionID,
    regionName,
  }
}, {
  persist: true,
  share: {
    enable: true,
  },
})


import { useResizeStore } from '~/store/resize'
/**
 * Компонент горизонтального слайдера, который не содержит внутри себя минимальное представление. Он умеет лишь перемещать
 * ряд элементов внутри влево-вправо. Этот компонент можно использовать сам по себе или брать за основу для других слайдеров,
 * например можно добавить какую-то специфичную навигацию по аналогии с компонентом RowSliderNew
 *
 * Одно из преимущств этого слайдера в том, что он работает быстро и активируется только при необходимости т.е. когда пользователь
 * начинает скроллить слайдер через тач-жесты или навигацию, до этого момента слайдер не активен и он накладывает минимальный оверхед на страницу
 */
export default {
  name: 'BaseRowSlider',
  props: {
    /**
     * Основной пропс, через который нужно передать список сущностей для слайдера. После каждый отдельный элемент будет доступен через параметр el внутри slot-scope
     */
    list: {
      type: Array,
      required: true,
    },
    /**
     * Тайминг анимации при переходе между разными элементами слайдера с помощью функцию next и prev
     */
    transitionDuration: {
      type: String,
      default: '0.2s',
    },
    /**
     * Горизонтальный отступ между элементами слайдера
     */
    offsetBetweenElements: {
      type: Number,
      default: 0,
    },
    overflow: {
      type: String,
      default: 'hidden',
    },
    /**
     * Пропс для передачи шины событий (event bus), который реализуется с помощью пакета mitt. Применять нужно так https://artmizu.ru/screenshots/TestSlider.vue__vue-store_2021-04-16_23-48-24.jpg
     */
    emitter: {
      type: Object,
      default: null,
    },
    /**
     * Пропс для обнуления состоянии слайдера, если изменился контент
     */
    reset: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['is-end'],
  data() {
    return {
      duration: this.transitionDuration,
      innerCount: this.list.length,
      sliderWidth: null,
      slideWidth: null,
      sliderWrapperWidth: null,
      maxTranslateX: null,
      pointerEvents: 'auto',
      isActiveDrag: 0,
      offset: 0,
      typeEvent: null,
      postion: {
        x: 0,
        y: 0,
      },

      drag: {
        start: null,
        end: null,
        active: false,
        offset: 0,
      },
    }
  },
  computed: {
    transition() {
      return `transform ${this.duration} ease-out`
    },
    translate() {
      return `translateX(${this.offset - this.drag.offset}px)`
    },
    isStart() {
      return this.offset === 0
    },
    isEnd() {
      return this.offset === this.maxTranslateX || this.sliderWrapperWidth < this.sliderWidth
    },
  },
  watch: {
    offset(val) {
      this.$emit('is-end', val === this.maxTranslateX)
    },
    list: {
      immediate: true,
      handler(newList, oldList) {
        if (this.reset && (newList !== oldList)) {
          this.innerCount = this.list.length
          if (this.$el)
            this.recalculate({ force: true })
          this.offset = 0
          this.getActiveDrag()
        }
      },
    },
  },
  created() {
    this.resizeStore = useResizeStore(this.$pinia)
  },
  mounted() {
    this.resizeHandler = this.resizeStore.$onAction((mutation) => {
      if (mutation.name === 'setIsMobile')
        this.recalculate({ force: true })
    })
    this.getActiveDrag()
  },
  destroyed() {
    if (this.resizeHandler)
      this.resizeHandler()
  },
  methods: {
    recalculate({ force = false } = {}) {
      if (!this.sliderWidth || force) {
        this.sliderWidth = this.$el.offsetWidth
        this.slideWidth = this.$refs.slotSlide?.[0]?.offsetWidth
        this.sliderWrapperWidth = this.slideWidth * this.innerCount
        this.maxTranslateX = this.sliderWidth - this.sliderWrapperWidth + this.offsetBetweenElements // у последнего элемента отступ не нужен
        this.offset = this.limitOffset(this.offset)
      }
    },
    limitOffset(offset) {
      return Math.min(Math.max(this.maxTranslateX, offset), 0)
    },
    getOffset(count) {
      return count * this.slideWidth
    },
    prev(num = 1) {
      this.recalculate()
      if (this.offset === this.maxTranslateX) {
        const remainder = Math.abs(this.maxTranslateX % this.slideWidth)
        this.offset += remainder
        num -= 1
      }
      this.offset = this.limitOffset(this.offset + this.getOffset(num))
    },
    next(num = 1) {
      this.recalculate()
      this.offset = this.limitOffset(this.offset - this.getOffset(num))
    },
    mouseDragStart(e) {
      this.recalculate()
      this.drag.start = e.pageX
      window.addEventListener('mousemove', this.mouseDragMove)
      window.addEventListener('mouseup', this.mouseDragEnd)
      window.getSelection().empty() // Снимаем выделение, если пользователь выделил элементы слайдера курсором
      this.dragStart()
    },
    getActiveDrag() {
      this.isActiveDrag = this.list.length * this.$refs.slotSlide?.[0]?.offsetWidth > this.$el?.offsetWidth
    },
    touchDragStart(e) {
      /**
       * Запоминаем позиции нажатии пользователем, чтобы определить, произошел скролл страницы или же свайп сладейра
       */
      this.recalculatePosition(e)
      if (this.typeEvent !== 'scroll') {
        this.recalculate()
        this.drag.start = e.changedTouches[0].pageX
        this.dragStart()
      }
    },
    dragStart() {
      this.duration = '0s'
      this.drag.active = true
    },
    mouseDragMove(e) {
      this.drag.end = e.pageX
      this.dragMove()
    },
    touchDragMove(e) {
      this.swipeAction(e)
      if (this.typeEvent !== 'scroll') {
        this.drag.end = e.changedTouches[0].pageX
        this.dragMove()
      }
    },
    dragMove() {
      this.pointerEvents = 'none'
      window.requestAnimationFrame(() => (this.drag.offset = this.drag.start - this.drag.end))
    },
    mouseDragEnd(e) {
      this.drag.end = e.pageX
      this.dragEnd()
    },
    touchDragEnd(e) {
      /**
       * если тач-координаты нет, значит сработал только touchStart и пользователь скорее всего ушел со страницы, значит он ничего не свайпал
       */
      if (this.typeEvent !== 'scroll') {
        this.drag.end = e.changedTouches[0]?.pageX || this.drag.start
        this.dragEnd()
      }
      this.resetTypeEvent()
    },
    dragEnd() {
      this.drag.offset = this.drag.start - this.drag.end
      const slideTo = this.drag.offset > 0 ? 'next' : 'prev'
      const count = Math.max(Math.round(Math.abs(this.drag.offset) / this.slideWidth), 1)
      // исключаем случаные движения мышью
      if (Math.abs(this.drag.offset) > 30)
        this[slideTo](count)

      this.resetDrag()
    },
    resetDrag() {
      this.drag.active = false
      this.drag.start = null
      this.drag.end = null
      this.drag.offset = null
      this.duration = this.transitionDuration
      this.pointerEvents = 'auto'
      window.removeEventListener('mousemove', this.mouseDragMove)
      window.removeEventListener('mouseup', this.mouseDragEnd)
    },
    swipeAction(e) {
      const event = e.touches[0]
      const posX = this.postion.x - event.clientX
      this.postion.x = event.clientX
      const posY = Math.abs(this.postion.y - event.clientY)
      if (!this.typeEvent) {
        // определение действия свайп или скролл
        this.typeEvent = (posY > 7 || posX === 0) ? 'scroll' : 'swipe'
        const type = this.typeEvent === 'swipe' ? 'add' : 'remove'
        document.body.classList[type]('slider__overflow')
      }
    },
    resetTypeEvent() {
      this.typeEvent = null
      this.pointerEvents = 'auto'
      document.body.classList.remove('slider__overflow')
    },
    recalculatePosition(e) {
      this.postion = {
        y: e.touches[0].clientY,
        x: e.touches[0].clientX,
      }
    },
  },
}

import type { Events } from '~/plugins/emitter'
import type { CartProduct } from '~/type/product/Product'

export default function afterCheckout({ order }: Events['after-checkout']) {
  let value = 0

  const items = order.product
    ? order.product.map((el: CartProduct) => {
      value += el.price?.common?.value ?? 0
      return {
        item_id: el.path?.match(/\/([^/]+)\/?$/)?.[1] || el.id?.toString(),
        item_name: el.name,
        price: el.price?.common?.value,
        item_category: el.category?.name,
        item_variant: el.offer?.length ? el.offer?.map(el => el.value || el.active?.name).join(', ') : '',
        quantity: el.quantity,
        bonus_for_item: order.bonus?.income || 0,
        promotion_name: el.sale?.length ? el.sale[0].name : '',
        promotion_id: el.sale?.length ? el.sale[0].id.toString() : '',
      }
    })
    : []

  return {
    event: 'purchase',
    ecommerce: {
      bonus_sum_add: order?.total?.bonus?.income || 0,
      bonus_sum_paid: order?.summary?.bonusSpent || 0,
      transaction_id: order.id?.toString(),
      delivery_method: order.deliveryType,
      value,
      shipping: order.price?.delivery ?? 0,
      currency: 'RUB',
      coupon: Array.isArray(order.coupon) ? order.coupon?.map((el: any) => el.coupon).join(', ') : order.coupon,
      items,
    },
  }
}


const __sfc_main = {};
__sfc_main.props = {
  countBonuses: {
    key: "countBonuses",
    required: true,
    type: Number
  }
};
export default __sfc_main;

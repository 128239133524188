import type { Events } from '~/plugins/emitter'

export default function cartProductAdd(data: Events['cart-product-add']['product']) {
  return {
    event: 'add_to_cart',
    ecommerce: {
      currency: 'RUB',
      value: data.price?.common?.value,
      items: [
        {
          item_list_name: data.source ?? '',
          item_name: data.name,
          item_id: data.path?.match(/\/([^/]+)\/?$/)?.[1] || data.id?.toString(),
          price: data.price?.common?.value,
          item_brand: data.brand?.name,
          item_category: data.category?.name,
          item_variant: data.offer?.length ? data.offer?.map(el => el.value).join(', ') : '',
          quantity: data.quantity,
          promotion_name: data.sale?.length ? data.sale[0].name : '',
          promotion_id: data.sale?.length ? data.sale[0].id.toString() : '',
        },
      ],
    },
  }
}

import type { NewOrderComplete } from '~/type/NewOrder'

export function NewOrderCompleteSerializer(el: any): NewOrderComplete {
  return {
    hash: el.result.HASH,
    paySystemLink: el.result.PAY_SYSTEM_LINK,
    products: el.result.USER.BASKET_PRODUCTS,
    id: el.result.ORDER_ID,
  }
}

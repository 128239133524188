import striptags from 'striptags'
import type SalePromo from '~/type/SalePromo'

export default function (el: { [key: string]: any }): SalePromo {
  function getUrl() {
    if (el.LINK?.TYPE === 'CATALOG')
      return new URL(el.LINK?.VALUE).pathname
    return `/sales/${el.CODE}/`
  }
  return {
    id: el.ID,
    image: el.IMAGE,
    story: el.MOBILE_IMAGE,
    name: el.NAME,
    preview: el.PREVIEW_TEXT,
    rule: el.DETAIL_TEXT,
    // Вынуждены ввести это свойство, чтобы если пришёл пустой html у условий акции, мы не отображали блок с условиями
    ruleText: striptags(el.DETAIL_TEXT).replace('\n', ''),
    announcement: el.ANNOUNCEMENT,
    code: el.CODE,
    expiration: el.DATE_ACTIVE_TO,
    path: getUrl(),
    promocode: el.PROMOCODE,
    advertising: el?.ADVERTISING,
  }
}

import { getFormData } from '@artmizu/utils'

export default {
  async post({ key, data }: { key: string; data: { [key: string]: string | number | undefined } }) {
    await fetch(`https://script.google.com/macros/s/${key}/exec`, {
      method: 'POST',
      body: getFormData(data),
    })
  },
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "slide",
    class: [{
      slide_active: _vm.active
    }]
  }, [_c('nuxt-link', {
    staticClass: "slide__el",
    style: ("background-image: url(" + (_vm.img) + ")"),
    attrs: {
      "to": _vm.link
    }
  }), _vm._v(" "), _vm.advertising ? _c('Tooltip', {
    staticClass: "slide__tooltip",
    attrs: {
      "placement": "bottom",
      "width-tool": "100%"
    },
    scopedSlots: _vm._u([{
      key: "handler",
      fn: function () {
        return [_c('div', {
          staticClass: "slide__advertising"
        }, [_vm._v("\n        Реклама\n      ")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_vm._v("\n      " + _vm._s(_vm.advertising) + "\n    ")];
      },
      proxy: true
    }], null, false, 2612203200)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
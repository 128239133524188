import { defineStore } from 'pinia'
import { ref } from '@vue/composition-api'
import type { BonusCard } from '@/type/BonusCard'

export const useBonusStore = defineStore('bonus', () => {
  const bonusData = ref<BonusCard>()

  function setBonus(value?: BonusCard) {
    bonusData.value = value
  }

  function reset() {
    bonusData.value = undefined
  }

  function decreaseBalance(currentData: number) {
    if (bonusData?.value?.balance)
      bonusData.value.balance -= currentData
  }

  function isBonus() {
    return Boolean(bonusData.value)
  }

  return { setBonus, decreaseBalance, isBonus, bonusData, reset }
}, {
  persist: true,
  share: {
    enable: true,
  },
})

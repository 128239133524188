var render = function () {
  var _vm$innerError$field;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Note', {
    staticClass: "p",
    attrs: {
      "theme": "reddish"
    }
  }, [_vm.innerError.title ? _c('div', [_vm._v("\n    " + _vm._s(_vm.innerError.title) + "\n  ")]) : _vm._e(), _vm._v(" "), ((_vm$innerError$field = _vm.innerError.field) === null || _vm$innerError$field === void 0 ? void 0 : _vm$innerError$field.length) > 0 ? _c('div', {
    staticClass: "dash-list mt-3"
  }, _vm._l(_vm.innerError.field, function (e, index) {
    return _c('div', {
      key: index,
      staticClass: "dash-list__item"
    }, [_vm._v("\n      " + _vm._s(e.description) + "\n    ")]);
  }), 0) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }
import { ref } from '@vue/composition-api'
import { defineStore } from 'pinia'

export const useSharedStore = defineStore('shared', () => {
  const isOffline = ref(false)
  const traceParent = ref<string>()
  const currentDate = ref<number>()

  function setIsOffline(val: boolean) {
    isOffline.value = val
  }

  function setTraceParent(val: string) {
    traceParent.value = val
  }

  return {
    isOffline,
    traceParent,
    currentDate,
    setIsOffline,
    setTraceParent,
  }
})


import { computed, getCurrentInstance, inject, onMounted, ref, shallowRef } from '@vue/composition-api';
import { useContext, useRoute } from '@nuxtjs/composition-api';
import { Modal, StatusBlock } from '05-ui-kit';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import type { EmitteryType } from '~/plugins/emitter';
import AppPromo from '~/components/AppPromo/AppPromo.vue';
import DarkstoreBar from '~/components/DarkstoreBar/DarkstoreBar.vue';
import Header from '~/components/Header/Header.vue';
import Footer from '~/components/Footer/Footer.vue';
import GlobalTools from '~/components/GlobalTools/GlobalTools.vue';
import { useSocialStore } from '~/store/social';
import { getStatusPromo } from '~/helper/getStatusPromo';
import { useBonusStore } from '~/store/bonus';
import { useUserStore } from '~/store/user';
import { useAuthStore } from '~/store/modal/auth';
interface BannerType {
  id: number;
  image: string;
  link: string;
}
const __sfc_main = {
  head() {
    const canonical = this.$getCanonical({
      path: this.$route.fullPath,
      exclude: /\/(catalog.*\d+)\/.+/
    });
    return {
      link: canonical ? [canonical] : []
    };
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const emitter = inject<EmitteryType>('emitter')!;
  const headerSticky = shallowRef<HTMLDivElement>();
  const isSticky = ref(false);
  const {
    $pageError
  }: {
    $pageError: any;
  } = useContext();
  const context = useContext();
  const statusPage = getStatusPromo({
    startDate: '2023.09.01',
    endDate: '2023.10.29'
  });
  if (!statusPage) {
    throw new $pageError({
      message: 'Страница не найдена',
      code: 404
    });
  }
  const bannerPage = shallowRef<HTMLDivElement>();
  function scrollToCalendar() {
    if (bannerPage.value) {
      scrollIntoView(bannerPage.value, {
        block: 'start',
        behavior: 'smooth'
      });
    }
  }
  function scrollFromBanners(item: BannerType) {
    if (item.id !== 4 && item.id !== 5) scrollToCalendar();
  }
  const showRulesModal = ref(false);
  const showBonusModal = ref(false);
  const socialStore = useSocialStore();
  const userStore = useUserStore();
  const bonusStore = useBonusStore();
  const authStore = useAuthStore();
  const {
    enable: enableAuthModal
  } = authStore;
  const userName = computed(() => userStore.name);
  const bonusCount = computed(() => bonusStore.bonusData?.balance ?? 0);
  const isAuth = computed(() => userStore.userAuth);
  function useNuxt() {
    const instance = getCurrentInstance();
    return (instance as {
      proxy: Vue;
    }).proxy.$nuxt;
  }
  const $nuxt = useNuxt();
  const tabs = ref([{
    title: 'Про акцию',
    titleMobile: 'Акция',
    img: {
      active: require('~/assets/images/nam20/twenty-white.svg'),
      notActive: require('~/assets/images/nam20/twenty-gray.svg')
    },
    link: '/nam20/'
  }, {
    title: 'Активности',
    img: {
      active: require('~/assets/images/nam20/zap-white.svg'),
      notActive: require('~/assets/images/nam20/zap-gray.svg')
    },
    link: '/nam20/activity/'
  }, {
    title: 'Розыгрыши',
    img: {
      active: require('~/assets/images/nam20/pidarok-colored.svg'),
      notActive: require('~/assets/images/nam20/pidarok-gray.svg')
    },
    link: '/nam20/raffle/'
  }]);
  const desktopBanners = ref<BannerType[]>([{
    id: 1,
    image: require('~/assets/images/nam20/banners/1pc.jpg'),
    link: '/nam20/'
  }, {
    id: 2,
    image: require('~/assets/images/nam20/banners/2pc.jpg'),
    link: '/nam20/raffle/'
  }, {
    id: 3,
    image: require('~/assets/images/nam20/banners/3pc.jpg'),
    link: '/nam20/activity/'
  }, {
    id: 4,
    image: require('~/assets/images/nam20/banners/4pc.jpg'),
    link: '/sales/nam_20/'
  }, {
    id: 5,
    image: require('~/assets/images/nam20/banners/5pc.jpg'),
    link: '/bonus/'
  }]);
  const mobileBanners = ref<BannerType[]>([{
    id: 1,
    image: require('~/assets/images/nam20/banners/1mb.jpg'),
    link: '/nam20/'
  }, {
    id: 2,
    image: require('~/assets/images/nam20/banners/2mb.jpg'),
    link: '/nam20/raffle/'
  }, {
    id: 3,
    image: require('~/assets/images/nam20/banners/3mb.jpg'),
    link: '/nam20/activity/'
  }, {
    id: 4,
    image: require('~/assets/images/nam20/banners/4mb.jpg'),
    link: '/sales/nam_20/'
  }, {
    id: 5,
    image: require('~/assets/images/nam20/banners/5mb.jpg'),
    link: '/bonus/'
  }]);
  function handleScroll() {
    if (headerSticky.value) headerSticky.value.offsetTop > 163 ? isSticky.value = true : isSticky.value = false;
  }
  function saleSelect() {
    emitter.emit('mainpage/on-sale-banner-click');
  }
  onMounted(() => {
    handleScroll();
    document.addEventListener('scroll', handleScroll, {
      passive: true
    });
    $nuxt.$on('show-terms-modal', () => showRulesModal.value = true);
  });
  return {
    route,
    headerSticky,
    isSticky,
    statusPage,
    bannerPage,
    scrollFromBanners,
    showRulesModal,
    showBonusModal,
    socialStore,
    enableAuthModal,
    userName,
    bonusCount,
    isAuth,
    tabs,
    desktopBanners,
    mobileBanners,
    saleSelect
  };
};
__sfc_main.components = Object.assign({
  AppPromo,
  DarkstoreBar,
  Header,
  StatusBlock,
  Modal,
  Footer,
  GlobalTools
}, __sfc_main.components);
export default __sfc_main;


/**
 * "Бесконечный" слайдер в котором можно двигаться бесконечно в одном направлении и он никогда не закончится
 * т.к. при каждом переключении слайда изменяется его структура и внутренние элементы меняются местами
 *
 * Сам по себе слайдер содержит минимально кол-во представления и его можно использовать как самого по себе,
 * так и брать за основу для других слайдеров (по аналогии, например, с компонентом MainSlider)
 */

import isEqual from 'lodash.isequal'
import { useResizeStore } from '~/store/resize'

export default {
  name: 'BaseInfiniteSlider',
  props: {
    /**
     * Индекс первоначального слайда
     */
    initial: {
      type: Number,
      default: 0,
    },
    /**
     * Кол-во клонов, которое нужно создавать относительно оригинального массива, зачастую больше клонов
     * нужно лишь тогда, когда слайды маленькие по ширине и их не хватает, чтобы заполнить всю ширину рабочей области
     */
    cloneCount: {
      type: Number,
      default: 2,
    },
    /**
     * Основной пропс, через который нужно передать список сущностей для слайдера. После каждый отдельный элемент будет доступен через параметр el внутри slot-scope
     */
    list: {
      type: Array,
      required: true,
    },
    /**
     * Тайминг анимации при переходе между разными элементами слайдера с помощью функцию next и prev
     */
    transitionDuration: {
      type: String,
      default: '0.2s',
    },
    /**
     * Интервал в ms по истечению которого будет происходить переключение на следующий слайд
     */
    autoplayInterval: {
      type: Number,
      default: 0,
    },
    /**
     * Горизонтальный отступ между элементами слайдера
     */
    offsetBetweenElements: {
      type: Number,
      default: 0,
    },
    /**
     * Выравнивание текущего слайда относительно ширины всего слайдера
     * @values left, center
     */
    align: {
      type: String,
      default: 'left',
      validator(el) {
        return ['left', 'center'].includes(el)
      },
    },
    /**
     * Класс, который будет повешан на wrapper, который оборачивает список из всех слайдов
     */
    wrapperClass: {
      type: String,
      default: null,
    },
    /**
     * Нижний отступ для слайдера
     */
    paddingBottom: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      active: false,
      arr: null,
      sliderAutoplay: null,
      duration: null,
      current: null,
      baseIndex: null,
      offsetNum: null,
      innerCount: this.list.length,
      sliderWidth: null,
      slideWidth: null,
      sliderWrapperWidth: null,
      maxTranslateX: null,
      pointerEvents: 'auto',
      transitionInner: '',
      animationInProgress: false,
      innerCloneCount: this.cloneCount > this.list?.length ? this.list?.length : this.cloneCount,
      drag: {
        start: null,
        end: null,
        active: false,
        offset: 0,
      },
    }
  },
  computed: {
    currentIndex() {
      this.$emit('current-slide', this.current)
      return this.list.findIndex(el => isEqual(el, this.current))
    },
    transition() {
      return `transform ${this.duration} ease-out`
    },
    offset() {
      const num = this.offsetNum * (this.slideWidth + this.offsetBetweenElements) * -1 - this.drag.offset + this.alignOffset
      return num ? `translateX(${num}px)` : null
    },
    alignOffset() {
      if (this.align === 'center')
        return Math.round(this.sliderWidth / 2) - Math.round(this.slideWidth / 2)
      else
        return 0
    },
    isAutoplayActive() {
      return !!this.sliderAutoplay
    },
  },
  created() {
    this.resizeStore = useResizeStore(this.$pinia)
    this.arr = this.constructArray({ list: this.list })
    this.offsetNum = this.innerCloneCount + this.initial
    this.baseIndex = this.innerCloneCount + this.initial
    this.current = this.arr[this.baseIndex]

    if (this.autoplayInterval > 0 && process.client)
      this.enableAutoplay()
  },
  mounted() {
    this.recalculate()
    this.active = true
    /**
     * Duration нужно ставить под конец кадра, чтобы сначала слайдер активировался, сместился и лишь
     * потом появился transition, иначе в появлении будет участвовать лишний сдвиг не аккуратный
     */
    window.requestAnimationFrame(() => {
      this.duration = this.transitionDuration
    })
    this.resizeHandler = this.resizeStore.$onAction((mutation) => {
      if (mutation.name === 'setIsMobile')
        this.recalculate()
    })
  },
  destroyed() {
    if (this.resizeHandler)
      this.resizeHandler()
    this.resetAutoplay()
  },
  methods: {
    enableAutoplay() {
      this.resetAutoplay() // чтобы сбросить предыдущий, если он есть
      this.sliderAutoplay = setInterval(this.next, this.autoplayInterval)
    },
    resetAutoplay() {
      if (this.sliderAutoplay) {
        clearInterval(this.sliderAutoplay)
        this.sliderAutoplay = null
      }
    },
    constructArray({ list } = {}) {
      const arr = [...list]
      const lastOne = arr.slice(this.innerCloneCount * -1)
      const firstOne = arr.slice(0, this.innerCloneCount)
      arr.unshift(...lastOne)
      arr.push(...firstOne)
      return arr
    },
    recalculate() {
      this.sliderWidth = this.$el.offsetWidth
      this.slideWidth = this.$refs.slide?.[0]?.offsetWidth
      this.sliderWrapperWidth = this.slideWidth * this.innerCount
    },
    next({ count = 1, resetAutoplay = false } = {}) {
      if (this.animationInProgress)
        return
      if (resetAutoplay)
        this.resetAutoplay()
      this.animationInProgress = true
      this.offsetNum += count
      this.$refs.sliderWrapper.addEventListener(
        'transitionend',
        () => {
          this.duration = '0s'
          this.offsetNum -= count
          this.appendToRight({ count })
          window.requestAnimationFrame(() => {
            window.requestAnimationFrame(() => {
              this.animationInProgress = false
              this.duration = this.transitionDuration
            })
          })
        },
        {
          once: true,
        },
      )
      const index = this.baseIndex + count
      this.current = this.arr[index]
    },
    appendToRight({ count = 1 } = {}) {
      const piece = this.arr.slice(this.innerCloneCount * 2, this.innerCloneCount * 2 + count)
      this.arr.push(...piece)
      this.arr.splice(0, Math.min(this.innerCloneCount, count))
    },
    slideTo({ index }) {
      const result = this.slideToDirection({ to: index })
      if (result)
        this[result.direction]({ count: result.count })
    },
    slideToDirection({ to } = {}) {
      const el = this.list[to]
      const index = this.arr.findIndex(item => isEqual(item, el))
      if (index !== -1) {
        this.resetAutoplay()
        if (index > this.baseIndex) {
          return {
            direction: 'next',
            count: index - this.baseIndex,
          }
        }
        else if (index < this.baseIndex) {
          return {
            direction: 'prev',
            count: this.baseIndex - index,
          }
        }
      }
    },
    prev({ count = 1, resetAutoplay = false } = {}) {
      if (this.animationInProgress)
        return
      if (resetAutoplay)
        this.resetAutoplay()
      this.animationInProgress = true
      this.offsetNum -= count
      this.$refs.sliderWrapper.addEventListener(
        'transitionend',
        () => {
          this.duration = '0s'
          this.offsetNum += count
          this.prependToLeft(count)
          window.requestAnimationFrame(() => {
            window.requestAnimationFrame(() => {
              this.animationInProgress = false
              this.duration = this.transitionDuration
            })
          })
        },
        {
          once: true,
        },
      )
      const index = this.baseIndex - count
      this.current = this.arr[index]
    },
    prependToLeft({ count = 1 } = {}) {
      const piece = this.arr.slice(this.innerCloneCount * -2 - count, this.innerCloneCount * -2)
      this.arr.unshift(...piece)
      this.arr.splice(Math.min(this.innerCloneCount, count) * -1)
    },
    mouseDragStart(e) {
      this.drag.start = e.pageX
      window.addEventListener('mousemove', this.mouseDragMove)
      window.addEventListener('mouseup', this.mouseDragEnd)
      this.dragStart()
    },
    touchDragStart(e) {
      this.drag.start = e.changedTouches[0].pageX
      this.dragStart()
    },
    dragStart() {
      this.duration = '0s'
      this.resetAutoplay()
      this.drag.active = true
    },
    mouseDragMove(e) {
      this.drag.end = e.pageX
      this.dragMove()
    },
    touchDragMove(e) {
      this.drag.end = e.changedTouches[0].pageX
      this.dragMove()
    },
    dragMove() {
      this.pointerEvents = 'none'
      this.drag.offset = this.drag.start - this.drag.end
    },
    mouseDragEnd(e) {
      this.drag.end = e.pageX
      this.dragEnd()
    },
    touchDragEnd(e) {
      /**
       * если тач-координаты нет, значит сработал только touchStart и пользователь скорее всего ушел со страницы, значит он ничего не свайпал
       */
      this.drag.end = e.changedTouches[0].pageX || this.drag.start
      this.dragEnd()
    },
    dragEnd() {
      this.drag.offset = this.drag.start - this.drag.end
      const slideTo = this.drag.offset > 0 ? 'next' : 'prev'
      const count = Math.max(Math.round(Math.abs(this.drag.offset) / this.slideWidth), 1)
      // исключаем случаные движения мышью
      if (Math.abs(this.drag.offset) > 30)
        this[slideTo]({ count })

      this.resetDrag()
    },
    resetDrag() {
      this.drag.active = false
      this.drag.start = null
      this.drag.end = null
      this.drag.offset = null
      this.duration = this.transitionDuration
      this.pointerEvents = 'auto'
      window.removeEventListener('mousemove', this.mouseDragMove)
      window.removeEventListener('mouseup', this.mouseDragEnd)
    },
  },
}

import Vue from 'vue'
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
  adapter: {
    loaded({ el }: { el: HTMLImageElement }) {
      el.srcset = el.dataset.srcset || ''
    },
    loading({ el, src }: { el: HTMLImageElement; src: string }) {
      el.srcset = require('@/assets/images/svg/blank.svg')
      el.src = src
    },
    error({ el, src }: { el: HTMLImageElement; src: string }) {
      el.srcset = require('@/assets/images/empty-product.png')
      el.src = src
    },
    preLoad: 1.8,
  },
})

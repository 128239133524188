import { differenceInCalendarDays, parse, parseISO, toDate } from 'date-fns'
import type { Context } from '@nuxt/types'
import { ru } from 'date-fns/locale'
import type { PromoTask } from '~/type/PromoTask'
import { useSharedStore } from '~/store/shared'

export function TaskSerialize(contextData: Context, el: { [key: string]: any }): PromoTask {
  const { $pinia } = contextData
  const { currentDate } = useSharedStore($pinia)
  const currentDateFormat = toDate(currentDate || new Date())
  const startDate = parseISO(el.ACTIVE_FROM)
  const endDate = parseISO(el.ACTIVE_TO) || parse('2023.11.01', 'yyyy.MM.dd', new Date(), { locale: ru })
  const daysToUnlock = differenceInCalendarDays(currentDateFormat, startDate) < 0 ? Math.abs(differenceInCalendarDays(currentDateFormat, startDate)) : 0

  return {
    id: el.ID,
    code: el.CODE,
    type: el.CODE ? 'game' : 'task',
    title: el.NAME,
    description: el.DESCRIPTION,
    bonuses: el.AMOUNT || el.AVAILABLE_BONUS,
    bonusesTotal: el.TOTAL_BONUS,
    isAccruedBonuses: !!el.IS_ACCRUED,
    daysToUnlock,
    isPassed: currentDateFormat >= endDate,
    isActiveWordle: el.NAME === 'Wordle' && (currentDateFormat >= startDate && currentDateFormat <= endDate),
    isAvailable: (currentDateFormat >= startDate && currentDateFormat <= endDate),
    completed: el.IS_PASSED || !!el.AVAILABLE_BONUS,
    link: el.LINK,
  }
}

var render = function () {
  var _ref;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "unavailable",
    class: [{
      unavailable_injected: _vm.injected
    }]
  }, [_c('div', {
    staticClass: "unavailable__wrapper"
  }, [_c('div', {
    staticClass: "unavailable__img",
    class: [(_ref = {}, _ref[("unavailable__img_" + (_vm.icon))] = _vm.icon, _ref)]
  }), _vm._v(" "), _vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
import ReviewRating from '~/serializer/ReviewRating'
import PropertyGroup from '~/serializer/ProductCharacteristic'
import RichContent from '~/serializer/RichContent'
import AvailabilitySerializer from '~/serializer/Availability'
import Brand from '~/serializer/Brand'
import isAppleWarranty from '~/helper/isAppleWarranty'
import type { ProductExtended } from '~/type/product/Product'
import { ProductSerialize } from '~/serializer/product/Product'
import Delivery from '~/serializer/Delivery'
import Seller from '~/serializer/Seller'
import Review from '~/serializer/Review'
import Question from '~/serializer/Question'

export default function (el: any): ProductExtended {
  function getBasicProperties() {
    if (Array.isArray(el.BASIC_PROPERTIES) && el.BASIC_PROPERTIES.length) {
      return el.BASIC_PROPERTIES.map((el: any) => ({
        name: el.NAME, value: el.VALUE,
      }))
    }

    if (Array.isArray(el.PROPERTIES) && el.PROPERTIES.length)
      return PropertyGroup(el.PROPERTIES[0]).list
  }

  return {
    ...ProductSerialize(el),
    code: el.CODE,
    brand: el.BRAND ? Brand(el.BRAND) : undefined,
    availability: el.ADDITIONAL?.REMAINS?.ITEMS?.length ? el.ADDITIONAL.REMAINS.ITEMS.map((el: any) => AvailabilitySerializer(el)) : [],
    delivery: el.DELIVERIES?.ITEMS?.length ? el.DELIVERIES.ITEMS.map((el: any) => Delivery(el)) : [],
    payment: el.PAYMENTS?.ITEMS?.length ? el.PAYMENTS.ITEMS.map((el: any) => ({ name: el.NAME, description: el.DESCRIPTION })) : [],
    seller: el.CUSTOM_SELLERS?.length ? el.CUSTOM_SELLERS?.map((el: any) => Seller(el)) : [],
    thumb: el.IMAGES ? el.IMAGES : [require('@/assets/images/empty.svg')],
    richContent: el.RICH_CONTENT ? RichContent(el.RICH_CONTENT) : undefined,
    description: el.DETAIL_TEXT,
    basicProperties: getBasicProperties(),
    propertyGroup: el.PROPERTIES ? el.PROPERTIES?.map((el: any) => PropertyGroup(el)) : [],
    rate: ReviewRating(el.REVIEWS_BLOCK?.RATES, el.REVIEWS_COUNT) || undefined,
    review: el.REVIEWS_BLOCK?.ITEMS?.length ? el.REVIEWS_BLOCK.ITEMS.map((el: any) => Review(el)) : [],
    question: el.QUESTIONS_BLOCK?.ITEMS?.length ? el.QUESTIONS_BLOCK.ITEMS.map((el: any) => Question(el)) : [],
    questionCount: el.QUESTIONS_BLOCK?.PAGINATION?.ROWS ? el.QUESTIONS_BLOCK.PAGINATION.ROWS : 0,
    accessory: el.ACCESSORIES ? el.ACCESSORIES?.map((el: any) => ProductSerialize(el)) : [],
    isCreditAvailable: el.CREDIT_BUY_AVAILABILITY || false,
    guarantee: el.MAIN_PROPERTIES?.length ? el.MAIN_PROPERTIES.find((item: any) => item?.CODE === 'CML2_GARANT')?.VALUE : undefined,
    isAppleWarranty: isAppleWarranty({ category: el.NAME, path: el.DETAIL_PAGE_PATH }),
    isBought: el.BOUGHT_BY_CURRENT_USER,
    freeLift: el.FREE_DELIVERY_AND_LIFT,
    metaRobot: {
      accessory: el?.ELEMENT_PAGES_META_ROBOT_INDEX?.ACCESSORIES,
      property: el?.ELEMENT_PAGES_META_ROBOT_INDEX?.PROPERTIES,
      question: el?.ELEMENT_PAGES_META_ROBOT_INDEX?.QUESTIONS,
      review: el?.ELEMENT_PAGES_META_ROBOT_INDEX?.REVIEWS,
    },
  }
}

import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import Vue from 'vue'
import { Model } from 'objectmodel'
import Tooltip from '05-ui-kit/lib/Tooltip'
import ErrorSeriazlier from '~/serializer/Error'
import type { ErrorType } from '~/type/Error'
import { useLocationStore } from '~/store/location'
import { useUserStore } from '~/store/user'

Model.prototype.errorCollector = function (errors: string[]) {
  errors.forEach((error) => {
    console.error('Ошибка в моделях', error)
  })

  const modelError = new TypeError(...errors) as TypeError & { isModelError: boolean }
  modelError.isModelError = true
  throw modelError
}

export default defineNuxtPlugin((context, inject) => {
  Vue.config.errorHandler = (e) => {
    new BaseError({ ...ErrorSeriazlier(e), loggerTitle: 'Ошибка в глобальном обработчике вью' })
  }

  if (process.client) {
    window.onunhandledrejection = (e) => {
      new BaseError({ ...ErrorSeriazlier(e), loggerTitle: 'Ошибка в глобальном обработчике window.onunhandledrejection' })
    }
  }

  class BaseError extends Error {
    constructor(arg: ErrorType) {
      super(arg?.message)

      if (Error.captureStackTrace)
        Error.captureStackTrace(this, BaseError)

      this.name = 'BaseError'
      this.sendErrorToSentry(arg)
      this.log(arg)
    }

    sendErrorToSentry(e: ErrorType) {
      const locationStore = useLocationStore(context.$pinia)
      const { user: userData, ip: ip_address } = useUserStore(context)

      if (process.env.NODE_ENV === 'production') {
        if (userData) {
          const { name, login, email, id } = { ...userData }
          context.$sentry.setUser({
            name,
            login,
            email,
            id: id.toString(),
            ip_address,
          })
        }
        else {
          context.$sentry.setUser({
            ip_address,
          })
        }

        if (locationStore) {
          context.$sentry.setContext('User Location', {
            currentRegionName: locationStore?.regionName,
            currentRegionId: locationStore?.regionID,
            currentCityName: locationStore?.cityName,
            currentCityId: locationStore?.сityID,
          })
        }

        context.$sentry.setContext('Error description', e)
        context.$sentry.captureException(e?.message || 'произошла ошибка')
      }
    }

    log(e: ErrorType) {
      console.log(`%c${e?.loggerTitle || 'Произошла ошибка'}`, 'font-size:17px;color:red')
      console.log(e)
      if (e?.native) {
        if (process.client)
          console.error(e.native)
        else
          console.log(e.native)
      }
    }
  }

  class PageError extends BaseError {
    constructor({ type, name, message, code = 500, native }: ErrorType) {
      super({ type, name, message, code, native, loggerTitle: 'Ошибка на странице' })
      this.name = 'PageError'
      context.error({
        message,
        statusCode: code,
      })
    }
  }

  class SimpleError extends BaseError {
    constructor({ type, name = 'Произошла ошибка', message = 'Информация отправлена разработчикам', code, native }: ErrorType) {
      super({ type, name, message, code, native })
      this.name = 'SimpleError'
      if (process.client) {
        Tooltip({
          type: 'error',
          title: name,
          description: message,
        })
      }
    }
  }

  inject('baseError', BaseError)
  inject('pageError', PageError)
  inject('simpleError', SimpleError)
})

type ErrorConstructorType = new (data: ErrorType) => unknown

declare module 'vue/types/vue' {
  interface Vue {
    $baseError: ErrorConstructorType
    $simpleError: ErrorConstructorType
    $pageError: ErrorConstructorType
  }
}

declare module '@nuxt/types' {
  interface Context {
    $baseError: ErrorConstructorType
    $simpleError: ErrorConstructorType
    $pageError: ErrorConstructorType
  }

  interface NuxtAppOptions {
    $baseError: ErrorConstructorType
    $simpleError: ErrorConstructorType
    $pageError: ErrorConstructorType
  }

  interface NuxtOptions {
    $baseError: ErrorConstructorType
    $simpleError: ErrorConstructorType
    $pageError: ErrorConstructorType
  }
}


import { Button } from '05-ui-kit'

export default {
  name: 'Error',
  components: {
    Button,
  },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/svg/compare.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/svg/favorite.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/images/svg/cart.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".user-panel[data-v-908c211e]{display:flex;flex-direction:column}.user-panel__cart[data-v-908c211e]{padding-top:6px;margin-bottom:7px}.user-panel__wrap[data-v-908c211e]{position:relative;display:flex;align-items:center;padding-right:44px}.user-panel__el[data-v-908c211e]{position:relative;display:block;padding-left:19px;margin-right:6px;font-size:14px;color:#000;text-decoration:none;background-repeat:no-repeat;background-position:0;border-radius:2px}.user-panel__el[data-v-908c211e]:last-child{margin-right:0}.user-panel__el_compare[data-v-908c211e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.user-panel__el_favorite[data-v-908c211e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.user-panel__el_cart[data-v-908c211e]{width:29px;height:25px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.user-panel__el_cart .user-panel__count[data-v-908c211e]{top:0;right:-2px;left:auto;width:15px;height:15px;font-size:8px;line-height:15px}.user-panel__count[data-v-908c211e]{position:absolute;top:-5px;left:5px;width:15px;height:15px;font-size:8px;font-weight:700;line-height:15px;color:#fff;text-align:center;background:url(\"data:image/svg+xml;charset=utf-8,%3Csvg width='15' height='15' xmlns='http://www.w3.org/2000/svg' fill='%23e30613'%3E%3Ccircle cx='7.5' cy='7.5' r='6.5' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E\") no-repeat 50%;background-size:15px;border-radius:100%;transition:opacity,.2s}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "error"
  }, [_c('div', {
    staticClass: "error__block"
  }, [_c('h3', {
    staticClass: "error__subtitle b"
  }, [_vm._v("\n      Нет соединения с интернетом\n    ")]), _vm._v(" "), _c('Button', {
    staticClass: "mt-26",
    attrs: {
      "size": "39",
      "theme": "white",
      "fluid": ""
    },
    on: {
      "click": _vm.reload
    }
  }, [_vm._v("\n      Обновить\n    ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
import type { Context } from '@nuxt/types'
import { stringify } from 'qs'
import { ProductSerialize } from '~/serializer/product/Product'
import SortSerializer from '~/serializer/Sort'
import PaginationSerializer from '~/serializer/Pagination'
import APIRequestStatus from '~/serializer/APIRequestStatus'

export default {
  async get(this: Context, { page, size, sort, products }: { page: string; size: number; sort: string; products: string }) {
    const { $axios } = this
    const result = (await $axios.$get<{ result: { ITEMS: any[]; SORT_TYPES: any[]; PAGINATION: any } }>(`/api/v2/favorite?${stringify({ page, size, sort, products })}`))?.result

    return {
      result: {
        product: result?.ITEMS?.length ? result?.ITEMS.map(item => ProductSerialize(item)) : [],
        sort: result?.SORT_TYPES?.length ? result?.SORT_TYPES.map(el => SortSerializer(el)) : [],
        pagination: PaginationSerializer(result?.PAGINATION || {}),
      },
    }
  },
  async getPanel(this: Context, { products }: { products: string }) {
    const { $axios } = this

    const result = (await $axios.$get<{ result: { ITEMS: any[] } }>(`/api/v2/favorite?${stringify({ products })}`))?.result

    return {
      result: {
        product: result?.ITEMS?.length ? result?.ITEMS?.map(el => ProductSerialize(el)) : [],
      },
    }
  },
  async add(this: Context, id: number) {
    const { $axios } = this

    const data = await $axios.$put(`/api/v1/favorite/${id}`, null, {
      'axios-retry': {
        retries: 2,
      },
    })

    return APIRequestStatus(data)
  },
  async remove(this: Context, id: number) {
    const { $axios } = this

    try {
      const data = await $axios.$delete(`/api/v1/favorite/${id}`, {
        'axios-retry': {
          retries: 2,
        },
      })

      return APIRequestStatus(data)
    }
    catch (e: any) {
      /**
       * TODO - Пока не добавят роут для синхронизации избранного.
       * Это реализованно для того, чтобы удалялись товары которые бьли добавлены пользователем, когда он был неавторизован.
       */
      return e?.response?.data?.error?.description === 'Товар не существует' ? Promise.resolve('success') : Promise.reject(e)
    }
  },
  async removeAll(this: Context) {
    const { $axios } = this

    const result = await $axios.$delete('/api/v1/favorite/all')

    return APIRequestStatus(result)
  },
}

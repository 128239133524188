var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-panel"
  }, [_c('UserMenu'), _vm._v(" "), _c('div', {
    staticClass: "user-panel__wrap"
  }, [_c('ActionEntity', {
    attrs: {
      "actions": {
        send: _vm.$api.compare.getPanel
      }
    },
    on: {
      "success": function ($event) {
        var _$event$response;

        return _vm.onLoadList(_vm.compare, $event === null || $event === void 0 ? void 0 : (_$event$response = $event.response) === null || _$event$response === void 0 ? void 0 : _$event$response.product);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var send = _ref.send,
            loading = _ref.loading,
            error = _ref.error;
        return [_c('UserPopup', {
          attrs: {
            "list": _vm.compare.list,
            "error": error,
            "pending": loading,
            "count": _vm.compareCount,
            "type": "compare",
            "link": "/compare/",
            "button-text": "Сравнить товары"
          },
          on: {
            "enable": function ($event) {
              return _vm.compareTwoList(_vm.compare, _vm.compareIDArray, send);
            }
          },
          scopedSlots: _vm._u([{
            key: "button",
            fn: function () {
              return [_c('nuxt-link', {
                staticClass: "user-panel__el user-panel__el_compare link link_wu",
                attrs: {
                  "to": "/compare/"
                }
              }, [_vm._v("\n            Сравнение\n            "), _vm.compareCount ? _c('span', {
                staticClass: "user-panel__count"
              }, [_vm._v(_vm._s(_vm.compareCount))]) : _vm._e()])];
            },
            proxy: true
          }, {
            key: "default",
            fn: function (_ref2) {
              var item = _ref2.item;
              return [_c('ActionCompare', {
                attrs: {
                  "id": item.id
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function (_ref3) {
                    var remove = _ref3.remove;
                    return [_c('UserPopupItem', {
                      key: item.id,
                      attrs: {
                        "id": item.id,
                        "item": item
                      },
                      on: {
                        "remove": function ($event) {
                          remove($event), _vm.onRemoveItemFromList(_vm.compare, $event);
                        }
                      }
                    })];
                  }
                }], null, true)
              })];
            }
          }], null, true)
        })];
      }
    }])
  }), _vm._v(" "), _c('ActionEntity', {
    attrs: {
      "actions": {
        send: _vm.$api.favorite.getPanel
      }
    },
    on: {
      "success": function ($event) {
        var _$event$response2;

        return _vm.onLoadList(_vm.favorite, $event === null || $event === void 0 ? void 0 : (_$event$response2 = $event.response) === null || _$event$response2 === void 0 ? void 0 : _$event$response2.product);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var send = _ref4.send,
            loading = _ref4.loading,
            error = _ref4.error;
        return [_c('UserPopup', {
          attrs: {
            "list": _vm.favorite.list,
            "error": error,
            "pending": loading,
            "count": _vm.favoriteCount,
            "type": "favorite",
            "link": "/favorite/",
            "button-text": "Перейти в избранное"
          },
          on: {
            "enable": function ($event) {
              return _vm.compareTwoList(_vm.favorite, _vm.favoriteIDArray, send);
            }
          },
          scopedSlots: _vm._u([{
            key: "button",
            fn: function () {
              return [_c('nuxt-link', {
                staticClass: "user-panel__el user-panel__el_favorite link link_wu",
                attrs: {
                  "to": "/favorite/"
                }
              }, [_vm._v("\n            Избранное\n            "), _vm.favoriteCount ? _c('span', {
                staticClass: "user-panel__count"
              }, [_vm._v(_vm._s(_vm.favoriteCount))]) : _vm._e()])];
            },
            proxy: true
          }, {
            key: "default",
            fn: function (_ref5) {
              var item = _ref5.item;
              return [_c('ActionFavorite', {
                attrs: {
                  "id": item.id
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function (_ref6) {
                    var remove = _ref6.remove;
                    return [_c('UserPopupItem', {
                      key: item.id,
                      attrs: {
                        "id": item.id,
                        "item": item
                      },
                      on: {
                        "remove": function ($event) {
                          remove($event), _vm.onRemoveItemFromList(_vm.favorite, $event);
                        }
                      }
                    })];
                  }
                }], null, true)
              })];
            }
          }], null, true)
        })];
      }
    }])
  }), _vm._v(" "), _c('ActionEntity', {
    attrs: {
      "actions": {
        send: _vm.$api.cart.getPanel
      }
    },
    on: {
      "success": function ($event) {
        var _$event$response3;

        return _vm.onLoadList(_vm.cart, $event === null || $event === void 0 ? void 0 : (_$event$response3 = $event.response) === null || _$event$response3 === void 0 ? void 0 : _$event$response3.product);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        var send = _ref7.send,
            loading = _ref7.loading,
            error = _ref7.error;
        return [_c('UserPopup', {
          attrs: {
            "list": _vm.cart.list,
            "error": error,
            "pending": _vm.isLoadingCart && loading,
            "count": _vm.cartCount,
            "type": "cart"
          },
          on: {
            "enable": function ($event) {
              return _vm.compareTwoList(_vm.cart, _vm.cartIDArray, send);
            }
          },
          scopedSlots: _vm._u([{
            key: "button",
            fn: function () {
              return [_c('div', {
                staticClass: "user-panel__cart"
              }, [_c('nuxt-link', {
                staticClass: "user-panel__el user-panel__el_cart link link_wu",
                attrs: {
                  "to": "/cart/"
                }
              }, [_vm.cartCount ? _c('span', {
                staticClass: "user-panel__count"
              }, [_vm._v(_vm._s(_vm.cartCount))]) : _vm._e()])], 1)];
            },
            proxy: true
          }, {
            key: "default",
            fn: function (_ref8) {
              var item = _ref8.item;
              return [_c('ActionCart', {
                attrs: {
                  "id": item.id,
                  "product": item,
                  "receive-quantity-from-api": ""
                },
                on: {
                  "success": function ($event) {
                    send({
                      product: _vm.cartIDArray.join(','),
                      cancel: true
                    });
                    _vm.loadingActionCart = false;
                    $event.response === 0 && _vm.onRemoveItemFromList(_vm.cart, item);
                  },
                  "error": function ($event) {
                    _vm.loadingActionCart = false;
                  }
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function (_ref9) {
                    var remove = _ref9.remove,
                        add = _ref9.add,
                        decreaseAmount = _ref9.decreaseAmount,
                        loadingCart = _ref9.loading,
                        quantity = _ref9.quantity;
                    return [_c('UserPopupItem', {
                      key: item.id,
                      style: {
                        pointerEvents: _vm.loadingActionCart ? 'none' : null
                      },
                      attrs: {
                        "id": item.id,
                        "item": Object.assign({}, item, {
                          quantity: quantity
                        }),
                        "loading": loadingCart,
                        "enable-quantity": ""
                      },
                      on: {
                        "plus": function ($event) {
                          _vm.onChange();

                          add($event);
                        },
                        "minus": function ($event) {
                          _vm.onChange();

                          decreaseAmount($event);
                        },
                        "remove": function ($event) {
                          _vm.onChange();

                          remove($event);
                        }
                      }
                    })];
                  }
                }], null, true)
              })];
            }
          }, {
            key: "buttonBottom",
            fn: function () {
              return [_c('nuxt-link', {
                staticClass: "link link_wu",
                attrs: {
                  "to": "/cart/"
                }
              }, [_c('Button', {
                attrs: {
                  "loading": loading,
                  "fluid": "",
                  "size": "39",
                  "theme": "red"
                }
              }, [_vm._v("\n              В корзину, товаров на " + _vm._s(_vm.sumAllProducts) + "₽\n            ")])], 1)];
            },
            proxy: true
          }], null, true)
        })];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
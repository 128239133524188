import type Question from '~/type/Question'
import QuestionSerializer from '~/serializer/Question'

export default function (data: { [ key: string ]: any } = {}): Question {
  return {
    id: data.ID,
    date: data.UF_DATE,
    name: data.UF_USER_NAME || data.UF_TEXT,
    text: data.UF_USER_TEXT,
    like: {
      count: +data.UF_POSITIVE_RATES || 0,
      active: data.UF_LIKED || false,
    },
    dislike: {
      count: +data.UF_NEGATIVE_RATES || 0,
      active: data.UF_DISLIKED || false,
    },
    answer: data.UF_QUESTION_ANSWERS?.map((data: any) => QuestionSerializer(data)) || [],
  }
}

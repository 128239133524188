var render = function () {
  var _vm$price$discount;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "price",
    class: [("price_" + (_vm.color)), _vm.type && ("price_" + (_vm.type)), ("price_" + (_vm.size)), _vm.reverseOrder ? 'price_reverse-order' : '']
  }, [_c('div', {
    staticClass: "price__cols"
  }, [_c('div', {
    staticClass: "price__col"
  }, [_c('div', {
    staticClass: "price__common",
    class: [_vm.getCodeStyleCommon(_vm.price.discount && _vm.price.discount.type)],
    attrs: {
      "data-e2e-active-item-price": "true"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.beautifyPriceCommon) + "₽\n      ")])]), _vm._v(" "), (_vm$price$discount = _vm.price.discount) !== null && _vm$price$discount !== void 0 && _vm$price$discount.value ? _c('div', {
    staticClass: "price__col price__col_discount"
  }, [_c('div', {
    class: _vm.getCodeStyle(_vm.price.discount && _vm.price.discount.type)
  }, [_vm._v("\n        " + _vm._s(_vm.beautifyPriceDiscount) + "₽\n      ")])]) : _vm._e()]), _vm._v(" "), _vm.seller && _vm.seller.length ? _c('div', {
    staticClass: "price__seller"
  }, [_vm._v("\n    Продавец " + _vm._s(_vm.seller.map(function (el) {
    return el.name;
  }).join(', ')) + "\n  ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }
import type LoyaltyLevel from '~/type/LoyaltyLevel'

export default function (el: any): { current: { value: number; name: string }; levelsData: LoyaltyLevel[] } {
  return {
    current: {
      value: el.LEVEL.VALUE,
      name: el.LEVEL.NAME,
    },
    levelsData: el.ALL_LEVELS.map((level: any) => {
      return {
        name: level.NAME,
        value: level.VALUE,
        conditions: {
          personal: level.CONDITIONS.PERSONAL_DATA
            ? {
                name: level.CONDITIONS.PERSONAL_DATA.NAME,
                currentProgress: level.CONDITIONS.PERSONAL_DATA.CURRENT_PROGRESS,
                targetProgress: level.CONDITIONS.PERSONAL_DATA.TARGET_PROGRESS,
                isDone: level.CONDITIONS.PERSONAL_DATA.IS_DONE,
              }
            : undefined,
          review: level.CONDITIONS.REVIEW
            ? {
                name: level.CONDITIONS.REVIEW.NAME,
                currentProgress: level.CONDITIONS.REVIEW.CURRENT_PROGRESS,
                targetProgress: level.CONDITIONS.REVIEW.TARGET_PROGRESS,
                isDone: level.CONDITIONS.REVIEW.IS_DONE,
              }
            : undefined,
          buy: level.CONDITIONS.BUY
            ? {
                name: level.CONDITIONS.BUY.NAME,
                currentProgress: level.CONDITIONS.BUY.CURRENT_PROGRESS,
                targetProgress: level.CONDITIONS.BUY.TARGET_PROGRESS,
                isDone: level.CONDITIONS.BUY.IS_DONE,
              }
            : undefined,
        },
        privilege: level.PRIVILEGE.map((item: any) => {
          return {
            title: item.TITLE,
            description: item.DESCRIPTION,
          }
        }),
      }
    }),
  }
}

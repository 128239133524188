
import { Button, Form, FormElement, Modal, Note, Textarea } from '05-ui-kit';
import { ref } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
const __sfc_main = {};
__sfc_main.props = {
  productId: {
    key: "productId",
    required: true,
    type: Number
  },
  show: {
    key: "show",
    required: true,
    type: Boolean
  },
  loading: {
    key: "loading",
    required: false,
    type: Boolean,
    default: false
  },
  success: {
    key: "success",
    required: false,
    type: Boolean,
    default: false
  },
  error: {
    key: "error",
    required: false,
    type: Boolean,
    default: false
  },
  response: {
    key: "response",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const text = ref('');
  function onClose() {
    /**
     * Срабатывает при нажатии на крестик
     */
    emit('close', false);
  }
  function onSubmit() {
    /**
    * Срабатывает при отправки запроса
    */
    emit('send', {
      source: props.productId,
      text: text.value
    });
  }
  return {
    text,
    onClose,
    onSubmit
  };
};
__sfc_main.components = Object.assign({
  Modal,
  ValidationObserver,
  Form,
  ValidationProvider,
  FormElement,
  Textarea,
  Note,
  Button
}, __sfc_main.components);
export default __sfc_main;

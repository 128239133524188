import { format } from 'date-fns'
import type { BonusCard } from '~/type/BonusCard'

export function BonusCardSerializer(el: any): BonusCard {
  return {
    number: el.UF_CARD_NUM,
    dateRegister: el.DATE_REGISTER ? format(new Date(el.DATE_REGISTER), 'dd.MM.yyyy') : '',
    balance: el.UF_BALANCE,
    unavailableBalance: el.UF_UNAVAILABLE_BALANCE,
    expiring: {
      date: el.UF_EXPIRING_BONUSES_DATE ? format(new Date(el.UF_EXPIRING_BONUSES_DATE), 'yyyy-MM-dd\'T\'HH:mm:ss') : undefined,
      value: el.UF_EXPIRING_BONUSES,
    },
  }
}

import type { Context } from '@nuxt/types'
import StoresSerializer from '~/serializer/Store'

export default {
  async getStores(this: Context) {
    const { $axios } = this
    const stores: any[] = (await $axios.$get('/api/v1/support/stores'))?.result

    return {
      result: stores.length ? stores.map(store => StoresSerializer(store)) : [],
    }
  },
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "progress"
  }, [_c('div', {
    staticClass: "progress__bar"
  }, [_c('div', {
    staticClass: "progress__value",
    class: _vm.progressValueClass,
    style: {
      width: _vm.getPercents
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "progress__desc"
  }, [_c('p', [_vm._v("\n      " + _vm._s(((_vm.price ? ((_vm.formatPrice(_vm.current)) + " ₽") : _vm.current) + " из " + (_vm.price ? ((_vm.formatPrice(_vm.target)) + " ₽") : _vm.target))) + "\n    ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }

const __sfc_main = {};
__sfc_main.props = {
  image: {
    key: "image",
    required: false,
    type: String
  },
  link: {
    key: "link",
    required: false,
    type: String
  },
  name: {
    key: "name",
    required: false,
    type: String
  }
};
export default __sfc_main;

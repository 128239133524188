
/**
 * Экшен для работы со сравнением, с помощью него можно легко удалять или добавлять в сравнение нужный товар
 */
import Tooltip from '05-ui-kit/lib/Tooltip'
import { mapState } from 'pinia'
import dataActionMixin from '~/mixins/dataActionMixin'
import { useUserStore } from '~/store/user'
import { useUserToolStore } from '~/store/userTool'
import { useUserListStore } from '~/store/userLists'

export default {
  name: 'ActionCompare',
  mixins: [dataActionMixin],
  inject: ['emitter'],
  props: {
    /**
     * id товара, будет использоваться при запросе
     */
    id: {
      type: [Number, String],
      default: null,
    },
    /**
     * начальное состояние элемента т.е. добавлен ли он уже в список на момент первоначальной загрузки компонента
     */
    active: {
      type: Boolean,
      default: null,
    },
    /**
     * Товар, будет использоваться для аналитики и для запросов
     */
    product: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Показывает модальное окно при добавлении товара в корзину
     * @param { String } modal.image Изображение для превью в модалке
     * @param { String } modal.link Адрес детальной страницы
     * @param { String } modal.name Название товара для alt изображения
     */
    modal: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      externalAPI: {
        active: this.active,
        modal: this.modal,
      },
    }
  },
  computed: {
    ...mapState(useUserStore, {
      isUser: store => store.isUser,
    }),
    ...mapState(useUserListStore, {
      userListStoreComputed: store => store,
    }),
    isActive() {
      return !!this.userListStoreComputed.compare[this.id]
    },
  },
  watch: {
    isActive(active) {
      this.externalAPI.active = active
    },
  },
  created() {
    this.userToolStore = useUserToolStore(this.$pinia)
    this.userListStore = useUserListStore(this.$pinia)

    this.appendMethodToAPI({
      name: 'add',
      fn: () => {
        this.request({
          api: () => {
            /*  Ограничение на длину пользовательских списков определил бизнес, сейчас лимит 50  */
            if (this.userListStore.compareAmount >= 50 && !this.$cookies.get('devMode')) {
              return Tooltip({
                type: 'error',
                title: 'Ошибка',
                description: 'Превышен лимит товаров в сравнении',
                mobileOffset: [5, 5, 63, 5],
              })
            }
            else if (this.isUser) {
              return this.$api.compare.add(this.id)
            }
            else {
              return Promise.resolve('success')
            }
          },
          onSuccess: (data) => {
            /* Ограничение на длину пользовательских списков определил бизнес, сейчас лимит 50 */
            if (this.userListStore.compareAmount < 50 || this.$cookies.get('devMode')) {
              this.userListStore.add({ type: 'compare', id: this.id })
              this.externalAPI.response = data
              this.externalAPI.active = true

              if (this.modal && this.userListStore.compareAmount > 1)
                this.userToolStore.showModalCompare(this.modal)

              this.emitter.emit('add-to-compare', { product: this.product })
            }
          },
          onError: (error) => {
            this.userToolStore.closeAllTools()

            this.externalAPI.response
              = error?.response?.data?.error?.description || 'Ошибка при добавлении товара в сравнение, попробуйте позже'
            Tooltip({ type: 'error', title: 'Ошибка', description: 'Не удалось добавить товар в сравнение', mobileOffset: [5, 5, 63, 5] })
          },
        })
      },
    })

    this.appendMethodToAPI({
      name: 'remove',
      fn: ({ id } = {}) => {
        this.request({
          api: () => {
            if (this.isUser)
              return this.$api.compare.remove(id || this.id)
            else
              return Promise.resolve('success')
          },
          onSuccess: (data) => {
            this.userListStore.remove({ type: 'compare', id: id || this.id })
            this.externalAPI.response = data
            this.externalAPI.active = false

            if (this.modal)
              this.userToolStore.onChangeCompare(false)
          },
          onError: (error) => {
            this.userToolStore.closeAllTools()

            this.externalAPI.response
              = error?.response?.data?.error?.description || 'Ошибка при удалении товара из сравнения, попробуйте позже'
            Tooltip({ type: 'error', title: 'Ошибка', description: 'Не удалось удалить товар из сравнения', mobileOffset: [5, 5, 63, 5] })
          },
        })
      },
    })

    this.appendMethodToAPI({
      name: 'removeProducts',
      fn: () => {
        this.request({
          api: () => {
            if (this.isUser)
              return this.$api.compare.removeProducts()
            else
              return Promise.resolve('success')
          },
          onSuccess: (data) => {
            this.userListStore.removeProducts('compare')
            this.externalAPI.response = data
            this.externalAPI.active = false
          },
          onError: (error) => {
            this.externalAPI.response
              = error?.response?.data?.error?.description || 'Ошибка при удалении товаров из сравнения, попробуйте позже'
            Tooltip({ type: 'error', title: 'Ошибка', description: 'Не удалось удалить товары из сравнения', mobileOffset: [5, 5, 63, 5] })
          },
        })
      },
    })

    this.appendMethodToAPI({
      name: 'removeCategoryProducts',
      fn: ({ sectionID, blockID, ids } = {}) => {
        this.request({
          api: () => {
            if (this.isUser)
              return this.$api.compare.removeCategoryProducts({ sectionID, blockID })
            else
              return Promise.resolve('success')
          },
          onSuccess: (data) => {
            this.userListStore.removeCategoryProducts({ type: 'compare', ids })
            this.externalAPI.response = data
            this.externalAPI.active = false
          },
          onError: (error) => {
            this.externalAPI.response
              = error?.response?.data?.error?.description || 'Ошибка при удалении товаров из сравнения, попробуйте позже'
            Tooltip({ type: 'error', title: 'Ошибка', description: 'Не удалось удалить товары из сравнения', mobileOffset: [5, 5, 63, 5] })
          },
        })
      },
    })
  },
  mounted() {
    if (this.externalAPI.active === null)
      this.externalAPI.active = this.userListStoreComputed.compare[this.id] || false
  },
}

import type { Events } from '~/plugins/emitter'

export default function fastOrdering(data: Events['fast-ordering']) {
  return {
    event: 'fast_ordering',
    data: {
      phone: data.phone?.replace(/\D/g, ''),
      item_id: data.product.path?.match(/\/([^/]+)\/?$/)?.[1] || data.product.id?.toString(),
      item_name: data.product.name,
      item_category: data.product.category?.name,
      price: data.product.price?.common.value,
      quantity: 1,
      promotion_name: data.product.sale?.length ? data.product.sale[0].name : '',
      promotion_id: data.product.sale?.length ? data.product.sale[0].id.toString() : '',
    },
  }
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "group",
    class: [{
      group_factoid: _vm.$slots.factoid
    }]
  }, [_vm.$slots.title ? _c('div', {
    staticClass: "group__title-wrap"
  }, [_c('h2', {
    staticClass: "group__title"
  }, [_vm._t("title")], 2), _vm._v(" "), _vm.link ? _c('nuxt-link', {
    staticClass: "group__desktop-link",
    attrs: {
      "to": _vm.link
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.$emit('link-click');
      }
    }
  }, [_vm._v("\n      " + _vm._s(_vm.linkText ? _vm.linkText : 'Перейти') + "\n    ")]) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.$slots.factoid ? _c('div', {
    staticClass: "group__factoid",
    class: [("group__factoid_" + (_vm.order))]
  }, [_vm._t("factoid")], 2) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "group__content"
  }, [_vm._t("content")], 2), _vm._v(" "), _vm.link ? _c('nuxt-link', {
    staticClass: "group__mobile-link",
    attrs: {
      "to": _vm.link
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.$emit('link-click');
      }
    }
  }, [_vm._v("\n    " + _vm._s(_vm.linkText ? _vm.linkText : 'Смотреть все товары') + "\n  ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }

const __sfc_main = {};
__sfc_main.props = {
  link: {
    key: "link",
    required: false,
    type: String
  },
  src: {
    key: "src",
    required: true,
    type: String
  },
  srcset: {
    key: "srcset",
    required: false,
    type: String
  },
  alt: {
    key: "alt",
    required: false,
    type: String
  },
  advertising: {
    key: "advertising",
    required: false,
    type: String
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  return {
    emit
  };
};
export default __sfc_main;

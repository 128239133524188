import { render, staticRenderFns } from "./NotifyModalOrder.vue?vue&type=template&id=3b9ac165&scoped=true&"
import script from "./NotifyModalOrder.vue?vue&type=script&lang=ts&"
export * from "./NotifyModalOrder.vue?vue&type=script&lang=ts&"
import style0 from "./NotifyModalOrder.vue?vue&type=style&index=0&id=3b9ac165&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b9ac165",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ViewportToogle: require('/app/components/ViewportToogle/ViewportToogle.vue').default,LazyImg: require('/app/components/LazyImg/LazyImg.vue').default})

import { render, staticRenderFns } from "./index.vue?vue&type=template&id=322ceb77&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=322ceb77&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "322ceb77",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainPromoBanners: require('/app/components/nam20/MainPromoBanners/index.vue').default,MainSliderEl: require('/app/components/sliders/MainSlider/MainSliderEl.vue').default,MainSlider: require('/app/components/sliders/MainSlider/MainSlider.vue').default,StoriesSlide: require('/app/components/sliders/StoriesSlide/StoriesSlide.vue').default,BaseRowSlider: require('/app/components/sliders/BaseRowSlider/BaseRowSlider.vue').default,ViewportToogle: require('/app/components/ViewportToogle/ViewportToogle.vue').default,InfoBlockController: require('/app/components/InfoBlockController/InfoBlockController.vue').default,CategoryGrid: require('/app/components/CategoryGrid/CategoryGrid.vue').default,PopularCategory: require('/app/components/PopularCategory/PopularCategory.vue').default,ActionCartExtended: require('/app/components/ActionCartExtended/ActionCartExtended.vue').default})

import type { Location } from '~/type/Location'

export function LocationSerializer(el: any): Location {
  return {
    id: +el.ID,
    code: el.CODE,
    name: el.NAME,
    lat: +el.LATITUDE || 0,
    lon: +el.LONGITUDE || 0,
    region: {
      id: +el.PARENT_ID,
      name: el.PARENT_NAME,
    },
  }
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Popover', {
    staticClass: "small-callback",
    attrs: {
      "width": "280px"
    },
    scopedSlots: _vm._u([{
      key: "handler",
      fn: function () {
        return [_c('span', {
          staticClass: "link small-callback__title"
        }, [_vm._v(" Перезвоните мне ")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "fz-16 b mb-4"
        }, [_vm._v("\n      Перезвоните мне\n    ")]), _vm._v(" "), _c('div', {
          staticClass: "fz-14 lh-15 mb-8"
        }, [_vm._v("\n      Оператор перезванивает в рабочее время в течение 2 часов.\n    ")]), _vm._v(" "), _c('ValidationObserver', {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref) {
              var handleSubmit = _ref.handleSubmit;
              return [_c('Form', {
                attrs: {
                  "type": "vertical",
                  "data-e2e": "callback-pop-up__form"
                },
                on: {
                  "submit": function ($event) {
                    return handleSubmit(_vm.send);
                  }
                }
              }, [_c('FormElement', {
                attrs: {
                  "hint-min-height": "0"
                }
              }, [_c('InputText', {
                attrs: {
                  "data-e2e": "callback-pop-up__name",
                  "placeholder": "Имя"
                },
                model: {
                  value: _vm.data.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "name", $$v);
                  },
                  expression: "data.name"
                }
              })], 1), _vm._v(" "), _c('ValidationProvider', {
                attrs: {
                  "rules": "required|phone",
                  "name": "телефон"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function (_ref2) {
                    var errors = _ref2.errors;
                    return [_c('FormElement', {
                      attrs: {
                        "error": errors[0],
                        "hint-min-height": "0"
                      }
                    }, [_c('InputText', {
                      attrs: {
                        "model-value": _vm.data.phone,
                        "error": !!errors[0],
                        "placeholder": "+7 (ХХХ) ХХХ - ХХ - ХХ",
                        "data-e2e": "callback-pop-up__phone"
                      },
                      on: {
                        "input": _vm.onInput
                      }
                    })], 1)];
                  }
                }], null, true)
              }), _vm._v(" "), _c('FormElement', {
                attrs: {
                  "hint-min-height": "0"
                }
              }, [_c('RadioList', {
                attrs: {
                  "name": "subjectType",
                  "list": _vm.list
                },
                model: {
                  value: _vm.data.subject,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "subject", $$v);
                  },
                  expression: "data.subject"
                }
              })], 1), _vm._v(" "), _vm.error ? _c('ErrorNote', {
                staticClass: "mt-15 mb-10",
                attrs: {
                  "error": _vm.responseError,
                  "data-e2e": "callback-pop-up__error"
                }
              }) : _vm._e(), _vm._v(" "), _vm.success ? _c('Button', {
                staticClass: "mb-15",
                attrs: {
                  "size": "33",
                  "theme": "submited",
                  "icon": "tick",
                  "disabled": ""
                }
              }, [_vm._v("\n          Заявка отправлена\n        ")]) : _vm.loading ? _c('Button', {
                attrs: {
                  "size": "33",
                  "theme": "red",
                  "loading": ""
                }
              }) : _c('Button', {
                staticClass: "mb-15",
                attrs: {
                  "theme": "red",
                  "size": "33",
                  "data-e2e": "callback-pop-up__submit"
                }
              }, [_vm._v("\n          Отправить заявку\n        ")]), _vm._v(" "), _c('div', {
                staticClass: "fz-13 grey-text"
              }, [_vm._v("\n          Нажимая кнопку «Отправить», я подтверждаю согласие с\n          "), _c('nuxt-link', {
                staticClass: "link link_grey",
                attrs: {
                  "to": "/about/terms-of-use/"
                }
              }, [_vm._v("\n            условиями политики обработки персональных данных.\n          ")])], 1)], 1)];
            }
          }])
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
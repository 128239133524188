
import { cityIn } from 'lvovich';
import { formatPrice } from '@artmizu/utils';
import { computed, inject, ref, shallowRef, watch } from '@vue/composition-api';
import { useRouter } from '@nuxtjs/composition-api';
import debounce from 'lodash.debounce';
import { useLocationStore } from '~/store/location';
import type { Product } from '~/type/product/Product';
import type Category from '~/type/Category';
import type { EmitteryType } from '~/plugins/emitter';
import { useResizeStore } from '~/store/resize';
interface Props {
  searchResult?: {
    product: Product[];
    phrase: Category[];
    searchHints: Category[];
    brand?: Product;
    redirectTo?: string;
  };
  loading?: boolean;
  error?: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  searchResult: {
    key: "searchResult",
    required: false,
    type: Object,
    default: () => ({
      product: [],
      phrase: [],
      searchHints: []
    })
  },
  loading: {
    key: "loading",
    required: false,
    type: Boolean,
    default: false
  },
  error: {
    key: "error",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const router = useRouter();
  const mobileInput = shallowRef();
  const locationStore = useLocationStore();
  const emitter = inject<EmitteryType>('emitter')!;
  const localSave = {
    version: 1,
    name: 'savedPhrases'
  };
  const query = ref('');
  const isVisible = ref(false);
  const searchItems = ref<Product[]>([]);
  const searchHints = ref<Category[]>([]);
  const savedPhrases = ref<Category[]>([]);
  const popularItems = ref<Product[]>([]);
  const popularPhrases = ref<Category[]>([]);
  const resizeStore = useResizeStore();
  const isMobile = computed(() => resizeStore.isMobile);
  const items = computed(() => query.value ? searchItems.value : popularItems.value);
  // если ничего не вернулось или вернулся пустой массив товаров
  const isNotProducts = computed(() => {
    return !props.loading && query.value && (searchHints.value?.length === 0 || searchItems.value?.length === 0);
  });
  const itemsTitle = computed(() => query.value ? 'Товары' : 'Популярные товары');
  const declCityName = computed(() => cityIn(locationStore.currentCity?.name ?? ''));
  const searchProductItem = computed(() => props.searchResult?.brand ? [props.searchResult?.brand, ...props.searchResult.product.filter((_, index) => index < 4)] : props.searchResult.product);
  const linkTo = computed(() => props.searchResult.redirectTo ? props.searchResult.redirectTo : `/search/?q=${query.value}`);
  watch(() => props.searchResult, data => {
    if (!popularItems.value?.length) {
      popularItems.value = searchProductItem.value || [];
      popularPhrases.value = data?.phrase || [];
    } else if (query.value) {
      searchItems.value = searchProductItem.value || [];
      searchHints.value = data?.searchHints || [];
    }
  });
  watch(() => searchItems.value, data => {
    if (data?.length === 0) emitter.emit('on-empty-search');
  });
  resizeStore.$onAction(mutation => {
    if (mutation.name === 'setIsMobile') {
      const type = mutation.store.isMobile && isVisible.value ? 'add' : 'remove';
      document.body.classList[type]('search-bar__overflow');
    }
  });
  const debouncedOnInput = debounce(onInput, 400);
  function onInput() {
    if (query.value) {
      emit('input', {
        query: query.value,
        size: 5,
        cancel: true
      });
    } else {
      searchItems.value = [];
      searchHints.value = [];
    }
  }
  function onCategoryClick(path?: string) {
    if (path) {
      router.push(path);
      hideModal();
    }
  }
  function onProductSelectClick({
    item,
    index
  }: {
    item: Product;
    index: number;
  }) {
    addPhraseStorage({
      name: item.name,
      path: item.path
    });
    emitter.emit('select-search-result', {
      searchQuery: query.value,
      index,
      selectResultType: 'product',
      selectResultItem: item.name
    });
    emit('select-product', {
      id: item.id,
      path: item.path
    });
    hideModal();
  }
  function addPhraseStorage<T extends {
    name: string;
  }>(item: T) {
    const indexItem = savedPhrases.value.findIndex(el => el.name === item.name);
    if (indexItem !== -1) savedPhrases.value.splice(indexItem, 1);
    const newSavedPhrases = [item, ...savedPhrases.value];
    saveToLocalStorage(newSavedPhrases);
  }
  function saveToLocalStorage<T>(data: T) {
    localStorage?.setItem(localSave.name, JSON.stringify({
      version: localSave.version,
      searchResult: data
    }));
  }
  function removePhraseStorage(item: Category) {
    const indexItem = savedPhrases.value.findIndex(el => el.name === item.name);
    savedPhrases.value.splice(indexItem, 1);
    saveToLocalStorage(savedPhrases.value);
  }
  function onPopularPhrasesClick({
    item,
    index
  }: {
    item: Category;
    index: number;
  }) {
    addPhraseStorage(item);
    hideModal();
    emitter.emit('search-confirm', {
      searchQuery: item.name,
      searchSource: 'popular'
    });
  }
  function onSavedPhrasesClick({
    index,
    item
  }: {
    item: Category;
    index: number;
  }) {
    emitter.emit('search-confirm', {
      searchQuery: item.name,
      searchSource: 'history'
    });
    addPhraseStorage(item);
    hideModal();
  }
  function onSearchHintClick({
    index,
    item
  }: {
    item: Category;
    index: number;
  }) {
    addPhraseStorage(item);
    emitter.emit('select-search-result', {
      selectResultType: 'catalog',
      searchQuery: query.value ?? item.name,
      index,
      selectResultItem: item.name
    });
    emitter.emit('search-confirm', {
      searchQuery: query.value ?? item.name,
      searchSource: 'hints'
    });
    hideModal();
  }
  function searchData() {
    if (query.value) {
      router.push(linkTo.value);
      isVisible.value = false;
      document.body.classList.remove('search-bar__overflow');
      addPhraseStorage({
        name: query.value,
        path: linkTo.value
      });
      emitter.emit('search-confirm', {
        searchQuery: query.value,
        searchSource: 'confirm'
      });
    }
  }
  function init() {
    isVisible.value = true;
    window.requestAnimationFrame(() => window.addEventListener('click', onWindowClick));
    savedPhrases.value = getHistoryFromLocalstorage();
    if (!popularItems.value?.length) {
      /**
        * При фокусе на инпуте поиска
        */
      emit('focus');
    }
    if (isMobile.value) {
      document.body.classList.add('search-bar__overflow');
      // ждем исполнения кадра, чтобы зафокусить инпут
      window.requestAnimationFrame(() => mobileInput.value.focus());
    }
  }
  function onWindowClick({
    target
  }: {
    target: any;
  }) {
    if (!isMobile.value) {
      const search = target.closest('.search-bar__quicksearch-wrapper');
      const clearHistoryButton = target.closest('.search-bar__saved-phrases-icon');
      if (!search && !clearHistoryButton) {
        isVisible.value = false;
        window.removeEventListener('click', onWindowClick);
      }
    }
  }
  function getHistoryFromLocalstorage() {
    const fromStore = localStorage ? JSON.parse(localStorage.getItem(localSave.name)!) : undefined;
    if (fromStore?.version === localSave.version) {
      return fromStore?.searchResult || [];
    } else {
      localStorage?.removeItem(localSave.name);
      return [];
    }
  }
  function hideModal() {
    isVisible.value = false;
    document.body.classList.remove('search-bar__overflow');
  }
  function onClickClear() {
    query.value = '';
    mobileInput.value.focus();
  }
  function getBeautifyPrice(val: string) {
    return val ? formatPrice(val) : val;
  }
  function allResults() {
    if (query.value) {
      addPhraseStorage({
        name: query.value,
        path: linkTo.value
      });
      hideModal();
      emitter.emit('search-confirm', {
        searchQuery: query.value,
        searchSource: 'confirm'
      });
    }
  }
  return {
    mobileInput,
    query,
    isVisible,
    searchHints,
    savedPhrases,
    popularPhrases,
    items,
    isNotProducts,
    itemsTitle,
    declCityName,
    linkTo,
    debouncedOnInput,
    onCategoryClick,
    onProductSelectClick,
    removePhraseStorage,
    onPopularPhrasesClick,
    onSavedPhrasesClick,
    onSearchHintClick,
    searchData,
    init,
    hideModal,
    onClickClear,
    getBeautifyPrice,
    allResults
  };
};
export default __sfc_main;

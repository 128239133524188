import { ThumbnailSerializer } from './Thumbnail'
import type Brand from '~/type/Brand'

export default function (el: { [ key: string ]: any } = {}): Brand {
  return {
    id: el.ID,
    code: el.UF_LINK || el.CODE,
    thumbnail: ThumbnailSerializer(el.THUMBNAILS),
    name: el.UF_NAME || el.NAME,
    image: el.UF_FILE || el.IMAGE,
  }
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    attrs: {
      "show": _vm.show,
      "title": null
    },
    on: {
      "close": function ($event) {
        return _vm.emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "product-more"
  }, [_c('UIActionEntity', {
    attrs: {
      "get": _vm.$api.user.addUserAchievement
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var get = _ref.get;
        return [_c('div', {
          staticClass: "product-more__share",
          on: {
            "click": function ($event) {
              _vm.enableSocialShare({
                title: _vm.title,
                path: _vm.path
              });

              if (_vm.isUser) { get({
                code: 'ambassador05ru'
              }); }
            }
          }
        }, [_vm._v("\n        Поделиться\n      ")])];
      }
    }])
  }), _vm._v(" "), _c('div', {
    staticClass: "product-more__compare"
  }, [_c('CompareInlineTool', {
    attrs: {
      "active": _vm.active,
      "loading": _vm.loading
    },
    on: {
      "add": function ($event) {
        return _vm.emit('add');
      },
      "remove": function ($event) {
        return _vm.emit('remove');
      }
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "city-search-field"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.text,
      expression: "text"
    }],
    staticClass: "city-search-field__input",
    attrs: {
      "type": "text",
      "placeholder": "Быстрый поиск города"
    },
    domProps: {
      "value": _vm.text
    },
    on: {
      "focus": _vm.debounceOnFocus,
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.text = $event.target.value;
      }, _vm.searchResult]
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "city-search-field__icon"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }
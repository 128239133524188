var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "el",
    staticClass: "scroll-gradient",
    on: {
      "&scroll": function ($event) {
        return _vm.checkScroll.apply(null, arguments);
      }
    }
  }, [_c('div', {
    ref: "topShadow",
    staticClass: "scroll-gradient__overlay-top scroll-gradient__close"
  }), _vm._v(" "), _vm._t("default"), _vm._v(" "), _c('div', {
    ref: "bottomShadow",
    staticClass: "scroll-gradient__overlay-bottom scroll-gradient__show"
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }

import { computed } from '@vue/composition-api';
import { useRouter } from '@nuxtjs/composition-api';
import { Button } from '05-ui-kit';
import { declination } from '@artmizu/utils';
import { useUserToolStore } from '~/store/userTool';
import { useUserListStore } from '~/store/userLists';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const userToolStore = useUserToolStore();
  const userListStore = useUserListStore();
  const router = useRouter();
  const compareInfo = userToolStore.compareInfo;
  const productsCount = computed(() => {
    const compareAmount = userListStore.compareAmount;
    return `${compareAmount} ${declination(compareAmount, ['товар', 'товара', 'товаров'])}`;
  });
  const productImage = computed(() => compareInfo?.image);
  const productName = computed(() => compareInfo?.name);
  const productLink = computed(() => compareInfo?.link) || '#';
  function handleToCompare() {
    router.push('/compare/', close);
  }
  function close() {
    userToolStore.onChangeCompare(false);
  }
  function handleMouseEnter() {
    userToolStore.clearTimer();
  }
  function handleMouseLeave() {
    userToolStore.setTimer();
  }
  return {
    productsCount,
    productImage,
    productName,
    productLink,
    handleToCompare,
    close,
    handleMouseEnter,
    handleMouseLeave
  };
};
__sfc_main.components = Object.assign({
  Button
}, __sfc_main.components);
export default __sfc_main;

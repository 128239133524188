import type SocialNetwork from '~/type/SocialNetwork'

export default function (el: any): SocialNetwork {
  return {
    subscriberAmount: el.SUBSCRIBERS_COUNT < 1000 ? `${el.SUBSCRIBERS_COUNT}` : `${Math.floor(el.SUBSCRIBERS_COUNT / 1000)} тыс`,
    type: el.TYPE,
    path: el.LINK,
    name: el.NAME,
  }
}

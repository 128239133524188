import type OrderPromo from '~/type/order/OrderPromo'

export default function (el: any): OrderPromo {
  return {
    id: el.ORDER?.ID,
    date: el.ORDER?.DATE_INSERT,
    name: el.PRODUCT?.NAME,
    image: el.PRODUCT?.IMAGE,
    path: el.PRODUCT?.DETAIL_PAGE_URL,
    bonusCount: el.PRODUCT?.REWARD,
  }
}

import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import { useAuthStore } from '~/store/modal/auth'
import { useUserStore } from '~/store/user'

export default defineNuxtMiddleware ((context) => {
  const userStore = useUserStore(context)
  if (userStore.isGuest) {
    const { enable } = useAuthStore(context.$pinia)
    context.redirect(302, '/')
    enable()
  }
})

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/app-promo__close.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/app-promo__icon.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-promo[data-v-48d2a337]{padding:9px 15px 9px 7px;background-color:#fffae5}.app-promo[data-v-48d2a337],.app-promo__wrapper[data-v-48d2a337]{display:flex;align-items:center}.app-promo__wrapper[data-v-48d2a337]{width:100%;color:#000;text-decoration:none}.app-promo__close-wrapper[data-v-48d2a337]{flex-shrink:0;padding-right:6px}.app-promo__close[data-v-48d2a337]{width:20px;height:20px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0;background-size:19px}.app-promo__icon[data-v-48d2a337]:before{display:block;width:32px;height:32px;margin-right:8px;content:\"\";background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%;background-size:contain}.app-promo__text[data-v-48d2a337]{flex-grow:1}.app-promo__title[data-v-48d2a337]{font-size:20px;font-weight:500;line-height:18px}.app-promo__description[data-v-48d2a337]{font-size:13px;line-height:14px;color:#97917a}.app-promo__button-wrapper[data-v-48d2a337]{flex-shrink:0;padding-left:8px}.app-promo__button[data-v-48d2a337]{display:flex;align-items:center;justify-content:center;height:32px;padding-right:11px;padding-left:11px;font-size:15px;font-weight:500;color:#fff;background-color:#e42013;border:0;border-radius:3px}@media (min-width:901px){.app-promo[data-v-48d2a337]{position:fixed;top:0;right:0;left:0;z-index:2}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

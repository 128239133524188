var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "window-cart"
  }, [_c('div', {
    staticClass: "window-cart__wrapper",
    on: {
      "mouseleave": _vm.handleMouseLeave,
      "mouseenter": _vm.handleMouseEnter
    }
  }, [_c('div', {
    staticClass: "window-cart__left"
  }, [_c('nuxt-link', {
    staticClass: "window-cart__img-box",
    attrs: {
      "no-prefetch": "",
      "to": _vm.productLink || '#'
    }
  }, [_c('LazyImg', {
    staticClass: "window-cart__img",
    attrs: {
      "img": _vm.productImage,
      "alt": _vm.productName
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "window-cart__desc"
  }, [_c('div', {
    staticClass: "window-cart__desc-top"
  }, [_vm._v("\n          Товар добавлен в корзину\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "window-cart__desc-bottom"
  }, [_vm._v("\n          В корзине " + _vm._s(_vm.productsCount) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "window-cart__desc-mobile"
  }, [_vm._v("\n          " + _vm._s(_vm.productsCount) + "\n        ")])])], 1), _vm._v(" "), _c('div', {
    staticClass: "window-cart__buttons"
  }, [_c('Button', {
    staticClass: "window-cart__button",
    on: {
      "click": _vm.handleToCart
    }
  }, [_vm._v("\n        Перейти в корзину\n      ")]), _vm._v(" "), _c('Button', {
    staticClass: "window-cart__button-mobile",
    attrs: {
      "size": "33"
    },
    on: {
      "click": _vm.handleToCart
    }
  }, [_vm._v("\n        В корзину\n      ")]), _vm._v(" "), _c('Popover', {
    staticClass: "window-cart__quik-modal window-cart__quik-modal_desktop",
    attrs: {
      "padding": "21px 20px"
    },
    scopedSlots: _vm._u([{
      key: "handler",
      fn: function () {
        return [_c('button', {
          staticClass: "window-cart__quick-order window-cart__quick-order_desktop",
          attrs: {
            "data-e2e": "quick-order-button"
          }
        }, [_vm._v("\n            Быстрое оформление\n          ")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "window-cart__title"
        }, [_vm._v("\n            Покупка в один клик\n          ")]), _vm._v(" "), _c('div', {
          staticClass: "window-cart__subtitle"
        }, [_vm._v("\n            Оставьте ваш номер телефона, оператор перезвонит в течение 5 минут и уточнит детали.\n          ")]), _vm._v(" "), _c('ValidationObserver', {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref) {
              var handleSubmit = _ref.handleSubmit;
              return [_c('Form', {
                attrs: {
                  "type": "vertical"
                },
                on: {
                  "submit": function ($event) {
                    return handleSubmit(_vm.onSubmit);
                  }
                }
              }, [_c('ValidationProvider', {
                attrs: {
                  "rules": "required|phone",
                  "name": "телефон"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function (_ref2) {
                    var errors = _ref2.errors;
                    return [_c('FormElement', {
                      attrs: {
                        "hint-min-height": "0",
                        "error": errors[0],
                        "data-e2e": "quick-order-form-phone"
                      }
                    }, [_c('InputText', {
                      directives: [{
                        name: "mask",
                        rawName: "v-mask",
                        value: '+7 (###) ###-##-##',
                        expression: "'+7 (###) ###-##-##'"
                      }],
                      attrs: {
                        "placeholder": "Введите номер телефона",
                        "data-e2e": "quick-order-phone"
                      },
                      model: {
                        value: _vm.buyOneClick.number,
                        callback: function ($$v) {
                          _vm.$set(_vm.buyOneClick, "number", $$v);
                        },
                        expression: "buyOneClick.number"
                      }
                    })], 1)];
                  }
                }], null, true)
              }), _vm._v(" "), _vm.error ? _c('Note', {
                staticClass: "mb-10",
                attrs: {
                  "theme": "reddish"
                }
              }, [_vm._v("\n                " + _vm._s('Что-то пошло не так') + "\n              ")]) : _vm._e(), _vm._v(" "), _c('div', {
                staticClass: "mb-11"
              }, [_vm.submited ? _c('Button', {
                attrs: {
                  "size": "39",
                  "theme": "submited",
                  "disabled": "",
                  "fluid": ""
                }
              }, [_vm._v("\n                  Заявка отправлена\n                ")]) : _vm.loading ? _c('Button', {
                attrs: {
                  "size": "39",
                  "theme": "red",
                  "loading": "",
                  "fluid": ""
                }
              }) : _c('Button', {
                attrs: {
                  "size": "39",
                  "data-e2e": "quick-order-submit",
                  "fluid": ""
                }
              }, [_vm._v("\n                  Отправить заявку\n                ")])], 1), _vm._v(" "), _c('div', {
                staticClass: "window-cart__info"
              }, [_vm._v("\n                Нажимая кнопку «Отправить заявку», я подтверждаю согласие с условиями политики обработки персональных данных.\n              ")])], 1)];
            }
          }])
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c('button', {
    staticClass: "window-cart__quick-order window-cart__quick-order_mobile",
    attrs: {
      "data-e2e": "quick-order-button"
    },
    on: {
      "click": _vm.open
    }
  }, [_vm._v("\n        Быстрое оформление\n      ")]), _vm._v(" "), _c('span', {
    staticClass: "window-cart__close",
    on: {
      "click": _vm.close
    }
  })], 1)]), _vm._v(" "), _c('Modal', {
    attrs: {
      "show": _vm.show,
      "type": "bottom",
      "title": "Покупка в один клик"
    },
    on: {
      "close": function ($event) {
        _vm.show = false;
      }
    }
  }, [_c('div', {
    staticClass: "window-cart__subtitle"
  }, [_vm._v("\n      Оставьте ваш номер телефона, оператор перезвонит в течение 5 минут и уточнит детали.\n    ")]), _vm._v(" "), _c('ValidationObserver', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var handleSubmit = _ref3.handleSubmit;
        return [_c('Form', {
          attrs: {
            "type": "vertical"
          },
          on: {
            "submit": function ($event) {
              return handleSubmit(_vm.onSubmit);
            }
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required|phone",
            "name": "телефон"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref4) {
              var errors = _ref4.errors;
              return [_c('FormElement', {
                attrs: {
                  "hint-min-height": "0",
                  "error": errors[0],
                  "data-e2e": "quick-order-form-phone"
                }
              }, [_c('InputText', {
                directives: [{
                  name: "mask",
                  rawName: "v-mask",
                  value: '+7 (###) ###-##-##',
                  expression: "'+7 (###) ###-##-##'"
                }],
                attrs: {
                  "placeholder": "Введите номер телефона",
                  "data-e2e": "quick-order-phone"
                },
                model: {
                  value: _vm.buyOneClick.number,
                  callback: function ($$v) {
                    _vm.$set(_vm.buyOneClick, "number", $$v);
                  },
                  expression: "buyOneClick.number"
                }
              })], 1)];
            }
          }], null, true)
        }), _vm._v(" "), _vm.error ? _c('Note', {
          staticClass: "mb-10",
          attrs: {
            "theme": "reddish"
          }
        }, [_vm._v("\n          " + _vm._s(_vm.response || 'Что-то пошло не так') + "\n        ")]) : _vm._e(), _vm._v(" "), _c('div', {
          staticClass: "mb-11"
        }, [_vm.submited ? _c('Button', {
          attrs: {
            "size": "39",
            "theme": "submited",
            "disabled": "",
            "fluid": ""
          }
        }, [_vm._v("\n            Заявка отправлена\n          ")]) : _vm.loading ? _c('Button', {
          attrs: {
            "size": "39",
            "theme": "red",
            "loading": "",
            "fluid": ""
          }
        }) : _c('Button', {
          attrs: {
            "size": "39",
            "data-e2e": "quick-order-submit",
            "fluid": ""
          }
        }, [_vm._v("\n            Отправить заявку\n          ")])], 1), _vm._v(" "), _c('div', {
          staticClass: "window-cart__info"
        }, [_vm._v("\n          Нажимая кнопку «Отправить заявку», я подтверждаю согласие с условиями политики обработки персональных данных.\n        ")])], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
import type { Context } from '@nuxt/types'
import merge from 'lodash.merge'
import ErrorSerializer from '~/serializer/Error'
import { useTokenStore } from '~/store/token'
import { useBonusStore } from '~/store/bonus'
import { useCartStore } from '~/store/cart'
import { useUserStore } from '~/store/user'
import { useUserListStore } from '~/store/userLists'

export default async function ({ contextData, response, request }:
{
  contextData: Context
  response: { [key: string]: any }
  request: ({ compare, favorite }: { compare: {}; favorite: {} }) => void
}) {
  const { $baseError, $cookies, $config, $pinia } = contextData
  const cartStore = useCartStore($pinia)
  const bonusStore = useBonusStore($pinia)
  const userStore = useUserStore(contextData)
  const userListStore = useUserListStore($pinia)
  userStore.setData(response.user)
  const tokenStore = useTokenStore(contextData)

  tokenStore.set(response.token)
  bonusStore.setBonus(response.bonus)

  // проставляем куку токена для кросс-авторизации между ДС и ИМ
  $cookies.set('user-access-token', response.token.accessToken.value, {
    domain: $config.crossAuthDomain,
    maxAge: 60 * 60 * 24 * 30,
  })

  const { compare, favorite } = response.panel
  const { compare: compareGuest, favorite: favoriteGuest } = userListStore.data.guest

  function convertUserListToArray(list: {}) {
    return Object.entries(list).map(([key]) => {
      return { id: +key }
    })
  }

  function mergeUserListValues(list: {}, listGuest: {}) {
    return Object.fromEntries(
      Object.entries(listGuest).filter(([key]) => {
        return !Object.keys(list).includes(key)
      }),
    )
  }

  try {
    await request({
      compare: convertUserListToArray(mergeUserListValues(compare, compareGuest)),
      favorite: convertUserListToArray(mergeUserListValues(favorite, favoriteGuest)),
    })

    const panel = merge({ compare, favorite }, userListStore.data.guest)
    userListStore.setLists({
      type: 'user',
      lists: panel,
    })
    cartStore.setList(response.panel.cart)

    return true
  }
  catch (e) {
    const error = ErrorSerializer(e)
    new $baseError(error)
  }
}

var render = function () {
  var _vm$userStore$user, _vm$userStore$user2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "color-avatar",
    class: [("color-avatar_" + (_vm.size)), ("color-avatar_mobile-" + (_vm.getMobileSize))],
    style: {
      backgroundColor: _vm.img ? undefined : _vm.color
    }
  }, [_vm.img ? _c('LazyImg', {
    staticClass: "color-avatar__image",
    attrs: {
      "img": _vm.img,
      "alt": _vm.name
    }
  }) : !_vm.img && (_vm$userStore$user = _vm.userStore.user) !== null && _vm$userStore$user !== void 0 && _vm$userStore$user.inLoyaltyProgram && (_vm$userStore$user2 = _vm.userStore.user) !== null && _vm$userStore$user2 !== void 0 && _vm$userStore$user2.loyaltyLevel.value ? _c('img', {
    staticClass: "color-avatar__image",
    attrs: {
      "src": require(("~/assets/images/loyalty/loyalty-avatar" + (_vm.userStore.user.loyaltyLevel.value) + ".svg"))
    }
  }) : [_vm._v("\n    " + _vm._s(_vm.firstSign) + "\n  ")]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
import Review from './Review'
import type ReviewPromo from '~/type/ReviewPromo'
import { ThumbnailSerializer } from '~/serializer/Thumbnail'

export function ReviewPromoSerializer(data: any): ReviewPromo {
  const obj = Review(data.REVIEW)
  return {
    ...obj,
    product: {
      name: data.NAME,
      reviewCount: data.UF_REVIEWS_COUNT,
      averageRate: data.RATING,
      thumbnail: ThumbnailSerializer(data.THUMBNAILS),
    },
    path: {
      product: data.DETAIL_PAGE_PATH,
      reviewList: `${data.DETAIL_PAGE_PATH}review/`,
    },
  }
}

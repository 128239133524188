
import { ref } from '@vue/composition-api';
import Fuse from 'fuse.js';
import debounce from 'lodash.debounce';
import type BrandGroup from '~/type/BrandGroup';
import type Brand from '~/type/Brand';
const __sfc_main = {};
__sfc_main.props = {
  list: {
    key: "list",
    required: true,
    type: Array
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const text = ref('');
  const cityList = ref<BrandGroup[]>();
  const debounceOnFocus = debounce(onFocus, 400);
  function onFocus() {
    if (!cityList.value) cityList.value = props.list;
    return new Fuse(cityList.value, {
      includeScore: true,
      threshold: 0.25,
      location: 0,
      includeMatches: true,
      distance: 100,
      minMatchCharLength: 1,
      keys: ['list.name']
    });
  }
  function searchResult() {
    /**
    * Срабатывает при вводе текста в input
    */
    emit('search-result', onFocus().search(text.value));
    /**
     * Срабатывает при вводе текста в input
    */
    emit('input', text.value);
  }
  return {
    text,
    debounceOnFocus,
    searchResult
  };
};
export default __sfc_main;

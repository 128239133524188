var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.active ? _c('div', {
    staticClass: "app-promo app-promo_js-android-app"
  }, [_c('div', {
    staticClass: "app-promo__close-wrapper"
  }, [_c('div', {
    staticClass: "app-promo__close",
    on: {
      "click": _vm.disable
    }
  })]), _vm._v(" "), _c('a', {
    staticClass: "app-promo__wrapper",
    attrs: {
      "href": _vm.link
    }
  }, [_c('div', {
    staticClass: "app-promo__icon"
  }), _vm._v(" "), _c('div', {
    staticClass: "app-promo__text"
  }, [_c('div', {
    staticClass: "app-promo__title"
  }, [_vm._v("05.ru")]), _vm._v(" "), _c('div', {
    staticClass: "app-promo__description"
  }, [_vm._v(_vm._s(_vm.description))])]), _vm._v(" "), _vm._m(0)])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-promo__button-wrapper"
  }, [_c('button', {
    staticClass: "app-promo__button"
  }, [_vm._v("Скачать")])]);
}]

export { render, staticRenderFns }
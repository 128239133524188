import type { Price, PriceItem } from '~/type/Price'

export function PriceSerializer(el: any[]): Price {
  /* Пишем такое условие из-за типа "Цена онлайн" и "Цена в магазине". "Цена онлайн" должна выглядеть
    как обычная цена, поэтому для неё пишется type: 'DEFAULT'
    "Цена в магазине" должна быть зачёркнутой, но только если она входит в "Цена онлайн",
    поэтому для неё пишется type: 'PROMO'
  */
  if (el[1] && Object.keys(el[1]).length) {
    return {
      common: {
        name: 'Новая цена',
        type: 'DEFAULT',
        value: el[0]?.PRICE,
        description: 'Стоимость товара с учетом скидки',
      },
      discount: {
        name: 'Старая цена',
        type: 'PROMO',
        value: el[1].PRICE,
        description: 'Стоимость товара без скидки',
      },
      bonus: el[0]?.BONUS || 0,
    }
  }
  else if (el[1]?.CODE === 'PROMO') {
    return {
      common: {
        name: el[0]?.NAME,
        type: 'DEFAULT',
        value: el[0]?.PRICE,
        description: 'Cтоимость товара при оформлении заказе через сайт',
      },
      discount: {
        name: el[1].NAME,
        type: 'OFFLINE',
        value: el[1].PRICE,
        description: 'Стоимость товара в оффлайн магазинах',
      },
      bonus: el[0]?.BONUS || 0,
    }
  }
  else if (el[1]?.CODE === 'RRC') {
    return {
      common: {
        name: el[0]?.NAME,
        type: 'DEFAULT',
        value: el[0]?.PRICE,
        description: 'Стоимость товара для авторизованных пользователей',
      },
      discount: {
        name: el[1].NAME,
        type: 'RRC',
        value: el[1].PRICE,
        description: 'Цена рекомендованная производителем',
      },
      bonus: el[0]?.BONUS || 0,
    }
  }
  else {
    return {
      common: PriceItemSerialize(el[0]),
      discount: el[1] ? PriceItemSerialize(el[1]) : undefined,
      bonus: el[0]?.BONUS || 0,
    }
  }
}

export function PriceItemSerialize(el: any): PriceItem {
  return {
    name: el.NAME,
    type: el.CODE,
    value: el.PRICE,
  }
}

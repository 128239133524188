var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "slider",
    style: {
      overflow: _vm.overflow
    }
  }, [_c('div', {
    staticClass: "slider__wrapper",
    class: {
      slider_drag: _vm.drag.active
    },
    style: {
      transform: _vm.translate,
      transition: _vm.transition,
      pointerEvents: _vm.pointerEvents
    },
    on: {
      "mousedown": _vm.mouseDragStart,
      "touchstart": _vm.touchDragStart,
      "touchmove": _vm.touchDragMove,
      "touchend": _vm.touchDragEnd
    }
  }, _vm._l(_vm.list, function (el, index) {
    return _c('div', {
      key: index,
      ref: "slotSlide",
      refInFor: true,
      staticClass: "slider__slide",
      style: {
        paddingRight: ((_vm.offsetBetweenElements) + "px"),
        flexShrink: 0
      }
    }, [_vm._t("default", null, {
      "el": el,
      "index": index
    })], 2);
  }), 0), _vm._v(" "), _vm._t("nav", null, {
    "prev": _vm.prev,
    "next": _vm.next,
    "isStart": _vm.isStart,
    "isEnd": _vm.isEnd,
    "isActiveDrag": _vm.isActiveDrag
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
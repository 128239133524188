import type { CheckoutTotal } from '~/type/Checkout'

export function CheckoutTotalSerializer({ total, list, availableBonuses }: { [key: string]: any }): CheckoutTotal {
  /**
     * Мы сами считаем итоговую скидку, потому что бэк на своей стороне не учитывает
     * скидки у конкретных товаров за счет, например, акций, он учитывает только скидки
     * когда на товары применяется промокод, но этого не достаточно
     */
  const discount = list.reduce((acc: any, current: any) => {
    const d = current.price?.discount?.value || 0
    const v = current.price?.common?.value || 0

    const maxPrice = Math.max(d, v)
    return (acc += ((current.isActive && maxPrice - v) || 0) * current.quantity)
  }, 0)

  const product = list.reduce((acc: any, current: any) => {
    const price = current.price?.discount?.value || current.price?.common?.value
    return (acc += ((current.isActive && price) || 0) * current.quantity)
  }, 0)

  return {
    quantity: total.QUANTITY!,
    activeQuantity: list.reduce((acc: any, current: any) => {
      return (acc += current.isActive ? current.quantity : 0)
    }, 0),
    freeDeliveryAt: total.FREE_DELIVERY_PRICE!,
    price: {
      total: total.PRICE!,
      product,
      discount,
    },
    bonus: {
      balance: (total?.BONUS_CARD && total?.BONUS_CARD.UF_BALANCE) ? +total?.BONUS_CARD.UF_BALANCE : 0,
      income: +total!.BONUSES_RECEIVED!,
      available: availableBonuses || 0,
    },
  }
}

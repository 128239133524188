


import { inject, onMounted, ref } from '@vue/composition-api';
import { useContext } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import Tooltip from '05-ui-kit/lib/Tooltip';
import type { Context } from '@nuxt/types';
import ErrorSerializer from '~/serializer/Error';
import { useFoundErrorStore } from '~/store/modal/foundError';
import { useProductMoreStore } from '~/store/modal/productMore';
import { useReportStore } from '~/store/modal/report';
import { useSocialShareStore } from '~/store/modal/socialShare';
import { useChooseCityStore } from '~/store/modal/chooseCity';
import { useLocationStore } from '~/store/location';
import { useCartStore } from '~/store/cart';
import type { EmitteryType } from '~/plugins/emitter';
import type { Location } from '~/type/Location';
import type { CartModal } from '~/store/userTool';
import { useUserStore } from '~/store/user';
import { useUserToolStore } from '~/store/userTool';
import SocialShare from '~/components/modal/SocialShare/SocialShare.vue';
import Notification from '~/components/Notification/Notification.vue';
import ChooseCity from '~/components/modal/ChooseCity/ChooseCity.vue';
import LazyReport from '~/components/modal/Report/Report.vue';
import LazyProductMore from '~/components/modal/ProductMore/ProductMore.vue';
import LazyFoundErrorModal from '~/components/modal/FoundErrorModal/FoundErrorModal.vue';
import LazyAddCart from '~/components/modal/AddCart/AddCart.vue';
import LazyAddCode from '~/components/modal/AddCode/AddCode.vue';
import LazyCopyCode from '~/components/modal/CopyCode/CopyCode.vue';
import LazyAddCompare from '~/components/modal/AddCompare/AddCompare.vue';
import EmailConfirm from '~/components/modal/EmailConfirm/EmailConfirm.vue';
import { useEmailConfirmStore } from '~/store/modal/emailConfirm';
import AchievementModal from '~/components/modal/AchievementModal/AchievementModal.vue';
import { useAchievementStore } from '~/store/modal/achievement';
import { useAuthStore } from '~/store/modal/auth';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emitter = inject<EmitteryType>('emitter')!;
  const showChat = ref(false);
  const context = useContext();
  let ctx: Context;
  const foundErrorStore = useFoundErrorStore();
  const productMoreStore = useProductMoreStore();
  const reportStore = useReportStore();
  const socialShareStore = useSocialShareStore();
  const chooseCityStore = useChooseCityStore();
  const locationStore = useLocationStore();
  const {
    remove: removeFromCart
  } = useCartStore();
  const currentCityData = ref<Location>();
  const userStore = useUserStore();
  const authStore = useAuthStore();
  const userTool = useUserToolStore();
  const emailConfirmStore = useEmailConfirmStore();
  const achievementStore = useAchievementStore();
  onMounted(() => {
    // текущий город определяем на клиенте, потому что запрос нужно делать с того устройства, локацию которого пытаемся определить
    requestCurrentCity();
    window.jivo_onLoadCallback = () => {
      showChat.value = true;
    };
  });
  function onSuccessFastOrder(cartInfo?: CartModal) {
    emitter.emit('after-checkout', {
      userId: userStore.user?.id,
      order: {
        ...cartInfo,
        contact: {
          phone: userStore.user?.phone
        },
        payment: {
          type: {
            title: 'Быстрое оформление'
          }
        },
        total: {
          price: {
            total: cartInfo?.price?.common?.value
          }
        },
        product: [{
          id: cartInfo?.id,
          name: cartInfo?.name,
          price: cartInfo?.price,
          category: cartInfo?.category,
          offer: cartInfo?.offer,
          quantity: 1
        }]
      }
    });
    /**
    * При оформлении заказа в один клик, товар должен удаляться из корзины в userList'e, т.к он удаляется из API
    */
    if (cartInfo?.id) {
      removeFromCart({
        id: cartInfo?.id
      });
      context.$api.cart.remove.call(ctx, cartInfo?.id);
    }
  }
  function setCurrentCityData(data?: Location) {
    currentCityData.value = data;
    if (data) locationStore.setCurrentCity(data);
  }
  function onSuccessChangeCity() {
    if (currentCityData.value) locationStore.setCurrentCity(currentCityData.value);
    locationStore.setConfirmed();
    location.reload();
  }
  function errorChangeCity() {
    Tooltip({
      type: 'error',
      title: 'Ошибка',
      description: 'Не удалось изменить город'
    });
  }
  async function requestCurrentCity() {
    const notValid = !locationStore.currentCity;
    if (notValid) {
      try {
        const response = await context.$api.location.getCityCurrent.call(ctx);
        locationStore.setCurrentCity(response?.result);
        if (locationStore.regionName !== 'Республика Дагестан' && !locationStore.confirmed) chooseCityStore.enableChooseCity();
      } catch (e) {
        const error = ErrorSerializer(e);
        new context.$baseError(error);
        chooseCityStore.enableChooseCity();
      }
    }
  }
  return {
    emitter,
    showChat,
    foundErrorStore,
    productMoreStore,
    reportStore,
    socialShareStore,
    chooseCityStore,
    userStore,
    authStore,
    userTool,
    emailConfirmStore,
    achievementStore,
    onSuccessFastOrder,
    setCurrentCityData,
    onSuccessChangeCity,
    errorChangeCity
  };
};
__sfc_main.components = Object.assign({
  LazyAddCode,
  LazyCopyCode,
  LazyAddCompare,
  LazyHydrate,
  LazyProductMore,
  LazyAddCart,
  LazyFoundErrorModal,
  LazyReport,
  SocialShare,
  ChooseCity,
  Notification,
  EmailConfirm,
  AchievementModal
}, __sfc_main.components);
export default __sfc_main;

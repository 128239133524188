var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DefaultLayout', {
    attrs: {
      "content-padding": "0"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
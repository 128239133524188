import type { Context } from '@nuxt/types'
import { stringify } from 'qs'
import { BonusCardSerializer } from '~/serializer/BonusCard'
import type { APIRequestSuccess } from '~/type/APIRequestStatus'
import type { BonusCard } from '~/type/BonusCard'
import PaginationSerializer from '~/serializer/Pagination'
import BonusTransactionSerializer from '~/serializer/BonusTransaction'

export default {
  async get(this: Context): Promise<{ result: BonusCard }> {
    const { $axios } = this

    const response = await $axios.$get('/api/v1/bonus')
    return {
      result: response.result && BonusCardSerializer(response.result),
    }
  },

  register(this: Context, { name, lastName, secondName }: { name: string; lastName: string; secondName: string }): Promise<APIRequestSuccess<{}>> {
    const { $axios } = this

    return $axios.$post(
      `/api/v1/bonus?${stringify({
        name,
        last_name: lastName,
        second_name: secondName,
      })}`,
    )
  },

  physicalRegister(this: Context, { number, code }: { number: string; code: string }): Promise<APIRequestSuccess<{}>> {
    const { $axios } = this

    return $axios.$post(
      `/api/v1/bonus/physical?${stringify({
        number,
        password: code,
      })}`,
    )
  },

  physicalRegisterByID(this: Context, hash: string): Promise<APIRequestSuccess<{}>> {
    const { $axios } = this

    return $axios.$post(`/api/v1/bonus/physical/${hash}`)
  },

  async addCardToAppleWallet(this: Context) {
    const { $axios } = this

    return (await $axios.$get('/api/v1/bonus/apple-wallet'))?.result
  },

  async getHistory(this: Context) {
    const { $axios } = this

    const currentDate = new Date().toISOString()

    const response = (await $axios.$get<{ result: { ITEMS: any[]; PAGINATION: any; TOTAL_AMOUNT: number } }>(`/api/v1/bonus/transactions?filter[date_accrued_from]=${currentDate}`))?.result

    return {
      result: {
        list: response.ITEMS?.length ? response.ITEMS.map(el => BonusTransactionSerializer(el)) : [],
        pagination: PaginationSerializer(response.PAGINATION),
        total: response.TOTAL_AMOUNT,
      },
    }
  },
  async getHistoryAll(this: Context, { page, size }: { page?: number; size?: number }) {
    const { $axios } = this

    const response = (await $axios.$get<{ result: { ITEMS: any[]; PAGINATION: any; TOTAL_AMOUNT: number } }>(`/api/v1/bonus/transactions?${stringify({ page, size })}`))?.result

    return {
      result: {
        list: response.ITEMS?.length ? response.ITEMS.map(el => BonusTransactionSerializer(el)) : [],
        pagination: PaginationSerializer(response.PAGINATION),
        total: response.TOTAL_AMOUNT,
      },
    }
  },
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    staticClass: "confirm-email-modal",
    attrs: {
      "show": _vm.show,
      "max-width": "447px"
    },
    on: {
      "close": _vm.close
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('p', {
          staticClass: "confirm-email-modal__title"
        }, [_vm._v("\n      Подтверждение почты\n    ")])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c('div', {
    staticClass: "confirm-email-modal__content"
  }, [_vm.statusRef !== 'success' ? [_c('p', {
    staticClass: "p confirm-email-modal__description"
  }, [_vm._v("\n        Мы отправим вам письмо со ссылкой. Перейдите по ней для подтверждения\n      ")]), _vm._v(" "), _c('ValidationObserver', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('Form', {
          attrs: {
            "type": "vertical"
          },
          on: {
            "submit": function ($event) {
              return handleSubmit(_vm.onSubmit);
            }
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required|email",
            "name": "почту"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var errors = _ref2.errors;
              return [_c('FormElement', {
                attrs: {
                  "title": "Email:",
                  "autocomplete": "off",
                  "error": errors[0] || (_vm.status === 'error' ? _vm.errorMessage : null)
                }
              }, [_c('InputText', {
                attrs: {
                  "placeholder": "Введите почту"
                },
                model: {
                  value: _vm.emailRef,
                  callback: function ($$v) {
                    _vm.emailRef = $$v;
                  },
                  expression: "emailRef"
                }
              })], 1)];
            }
          }], null, true)
        }), _vm._v(" "), _c('div', {
          staticClass: "confirm__button-wrapper"
        }, [_vm.status === 'loading' ? _c('Button', {
          attrs: {
            "size": "39",
            "theme": "red",
            "loading": "",
            "fluid": ""
          }
        }) : _c('Button', {
          attrs: {
            "theme": "red",
            "size": "39",
            "fluid": ""
          }
        }, [_vm._v("\n              Получить ссылку\n            ")])], 1)], 1)];
      }
    }], null, false, 2316538763)
  })] : [_c('p', {
    staticClass: "p confirm-email-modal__description"
  }, [_vm._v("\n        Мы отправили вам письмо с ссылкой на почту\n        "), _c('span', {
    staticClass: "confirm-email-modal__email"
  }, [_vm._v(_vm._s(_vm.emailRef))]), _vm._v(" "), _c('button', {
    staticClass: "confirm-email-modal__button",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.statusRef = 'initial';
      }
    }
  }, [_vm._v("\n          изменить\n        ")])]), _vm._v(" "), _c('Button', {
    attrs: {
      "size": "39",
      "theme": "submited",
      "disabled": "",
      "fluid": ""
    }
  }, [_vm._v("\n        Ссылка отправлена\n      ")])]], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
import { format } from 'date-fns'
import type User from '~/type/User'

export default function (el: any): User {
  return {
    id: +el.ID,
    login: el.LOGIN,
    phone: el.PERSONAL_PHONE,
    email: el.EMAIL,
    name: el.NAME,
    lastName: el.LAST_NAME,
    birthday: el.PERSONAL_BIRTHDAY,
    gender: el.PERSONAL_GENDER,
    promocodeAmount: el.PROMOCODE_AMOUNT,
    avatar: el.PERSONAL_PHOTO,
    cart: el.BASKET_AMOUNT || 0,
    dateRegister: el?.DATE_REGISTER ? format(new Date(el.DATE_REGISTER), 'dd.MM.yyyy') : '',
    favorite: el.FAVORITE_AMOUNT || 0,
    compare: el.COMPARE_AMOUNT || 0,
    order: el.ORDERS_AMOUNT || 0,
    bonusCardRequest: el.UF_BONUS_CARD_REQUEST || false,
    referralKey: el.UF_REFERRAL_KEY || '',
    confirm: {
      email: el.UF_EMAIL_CONFIRMED || false,
      phone: el.UF_PHONE_CONFIRMED || false,
    },
    socials: {
      instagram: el.UF_INSTAGRAM_LINK,
      telegram: el.UF_TELEGRAM_LINK,
      facebook: el.UF_FACEBOOK_LINK,
    },
    inLoyaltyProgram: el.IN_LOYALTY_PROGRAM,
    loyaltyLevel: {
      name: el.LEVEL.NAME,
      value: el.LEVEL.VALUE,
    },
  }
}

import type { Events } from '~/plugins/emitter'

export default function productListView(data: Events['product-list-view']) {
  return {
    event: 'view_item_list',
    ecommerce: {
      items: data.list
        ? data.list.map((el, i) => {
          return {
            item_id: el.path?.match(/\/([^/]+)\/?$/)?.[1],
            item_name: el.name,
            price: el.price?.common?.value,
            item_category: data.category || el.category?.name,
            item_variant: el.offer?.length ? el.offer?.map(el => el.value).join(', ') : '',
            item_list_name: data.listName,
            index: i + 1,
            quantity: 1,
          }
        })
        : [],
    },
  }
}

var render = function () {
  var _vm$orders;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrapper"
  }, [(_vm$orders = _vm.orders) !== null && _vm$orders !== void 0 && _vm$orders.length ? _vm._l(_vm.orders, function (order) {
    var _order$payload;

    return _c('div', {
      key: order.id
    }, [_c('DataEntity', {
      attrs: {
        "get": _vm.$api.product.getByID,
        "params": {
          id: (_order$payload = order.payload) === null || _order$payload === void 0 ? void 0 : _order$payload.productId,
          removeCode: true
        }
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          var _order$payload2, _response$product$pri;

          var response = _ref.response,
              success = _ref.success;
          return [success ? _c('NotifyModalOrder', {
            attrs: {
              "img": response.product.image,
              "name": response.product.name,
              "detail": response.product.path,
              "date": (_order$payload2 = order.payload) === null || _order$payload2 === void 0 ? void 0 : _order$payload2.date,
              "price": (_response$product$pri = response.product.price) === null || _response$product$pri === void 0 ? void 0 : _response$product$pri.common.value
            },
            on: {
              "remove": function ($event) {
                return _vm.handleRemove(order);
              },
              "later": function ($event) {
                return _vm.handleLater(order);
              }
            }
          }) : _vm._e()];
        }
      }], null, true)
    })], 1);
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }





import { computed } from '@vue/composition-api';
interface Props {
  /**
  * Путь до изображения
  */
  img?: string;
  /**
  * Путь до 2х изображения
  */
  srcset?: string;
  /**
  * alt для изображения
  */
  alt?: string;
  /**
  * Вырубает lazy-load и в итоге компонент выводит просто картинку, если она есть
  */
  disable: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  img: {
    key: "img",
    required: false,
    type: String
  },
  srcset: {
    key: "srcset",
    required: false,
    type: String
  },
  alt: {
    key: "alt",
    required: false,
    type: String
  },
  disable: {
    key: "disable",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const srcSetPath = computed(() => {
    if (props.img && props.srcset) return `${props.img}, ${props.srcset} 2x`;else return props.img;
  });
  return {
    srcSetPath
  };
};
export default __sfc_main;

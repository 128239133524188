var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cart-tool"
  }, [_c('div', {
    staticClass: "cart-tool-link link-icon link-icon_black fz-14 lh-14",
    on: {
      "click": _vm.handleClickCart
    }
  }, [_c('span', {
    staticClass: "cart-icon",
    class: [{
      'link-icon__icon-cart': !_vm.loading
    }, {
      'link-icon_cart-added': _vm.active && !_vm.loading
    }, {
      'icon-loading-oval icon-loading-oval_white': _vm.loading
    }]
  }), _vm._v(" "), !_vm.small ? _c('span', {
    staticClass: "b cart-tool-text"
  }, [_vm._t("default", function () {
    return [_vm._v("В корзину")];
  })], 2) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
import { defineStore } from 'pinia'
import { ref } from '@vue/composition-api'
import type { Price } from '~/type/Price'
import type Category from '~/type/Category'

export interface CartModal {
  id: number
  category: Category
  name: string
  link: string
  image: string
  price: Price
  offer: string
  bonus?: number
}

interface CompareModal {
  name: string
  link: string
  image: string
}

type codeStateType = 'success' | 'error' | 'warning'

export const useUserToolStore = defineStore('user-tool', () => {
  const cartInfo = ref<CartModal>()
  const cartTool = ref(false)
  const compareInfo = ref<CompareModal>()
  const compareTool = ref(false)
  const copyCodeTool = ref(false)
  const codeTool = ref(false)
  const codeState = ref<{
    active: boolean
    code?: string
    type?: codeStateType
  }>({
    active: false,
    code: '',
  })
  const timer = ref<NodeJS.Timer>()

  function showModalCart({ name, link, image, offer, price, category, id, bonus }: CartModal) {
    cartInfo.value = {
      name: name || '',
      link: link || '#',
      offer,
      price,
      category,
      image,
      id,
      bonus,
    }

    onChangeCart(true)
  }

  function showModalCompare({ name, link, image }: CompareModal) {
    compareInfo.value = {
      name: name || '',
      link: link || '#',
      image,
    }

    onChangeCompare(true)
  }

  function onChangeCart(value: boolean) {
    closeAllTools()
    setTimer()

    cartTool.value = value
  }

  function onChangeCompare(value: boolean) {
    closeAllTools()
    setTimer()

    compareTool.value = value
  }

  // Вызывается в компоненте ActionFavorite без параметров. В add, и remove.
  function onChangeFavorite() {
    compareTool.value = false
  }

  // Вызывается в компоненте AddCode. Передаетcя только false
  function onAddCode({ active, type }: { active: boolean; type?: codeStateType }) {
    closeAllTools()
    setTimer()

    codeTool.value = active
    codeState.value.type = type
  }

  function onCopyCode({ active, code }: { active: boolean; code?: string }) {
    closeAllTools()
    setTimer()

    copyCodeTool.value = active
    codeState.value.code = code
  }

  function closeAllTools() {
    clearTimer()

    cartTool.value = compareTool.value = codeTool.value = copyCodeTool.value = false
  }

  function clearTimer() {
    if (timer.value)
      clearTimeout(timer.value)
  }

  function setTimer() {
    clearTimer()

    timer.value = setTimeout(() => {
      closeAllTools()
    }, 4000)
  }

  return {
    cartInfo,
    cartTool,
    compareInfo,
    compareTool,
    copyCodeTool,
    codeTool,
    codeState,
    showModalCart,
    showModalCompare,
    onChangeCart,
    onChangeCompare,
    onChangeFavorite,
    onAddCode,
    onCopyCode,
    closeAllTools,
    clearTimer,
    setTimer,
  }
}, {
  share: {
    enable: true,
  },
})

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/main-slider_slide-mobile-all-promo.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/main-slider_slide-mobile-all-promo@2x.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stories-slide[data-v-4b22d038]{position:relative;flex-shrink:0;border-radius:3px}.stories-slide_all-promo[data-v-4b22d038]{background:#fff3cd url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat}@media (-webkit-min-device-pixel-ratio:2),(min-resolution:192dpi){.stories-slide_all-promo[data-v-4b22d038]{background:#fff3cd url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:contain}}.stories-slide__link[data-v-4b22d038]{display:block;width:110px;height:220px;color:#000;text-decoration:none}.stories-slide__link_all-promo[data-v-4b22d038]{padding:12px 17px 10px 13px}.stories-slide__image[data-v-4b22d038]{max-width:100%;min-height:100%}.stories-slide__description[data-v-4b22d038]{font-size:11px;line-height:13px;color:#7b735a}.stories-slide__advertising[data-v-4b22d038]{position:absolute;right:0;bottom:0;display:flex;align-items:center;padding:2px 1px 2px 8px;font-size:11px;background-color:hsla(0,0%,100%,.9490196078431372);border-radius:4px 0 0 0}.stories-slide__advertising-img[data-v-4b22d038]{width:16px;height:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

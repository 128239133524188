import type { Context } from '@nuxt/types'
import PcBuildSerializer from '~/serializer/PCBuild'

export default {
  async getScheme(this: Context) {
    const { $axios } = this
    const response = (await $axios.$get<{ result: any[] }>('/api/v1/pcbuild/scheme'))?.result
    const result = response?.length ? response.map(el => PcBuildSerializer(el)) : []

    return {
      result,
    }
  },
}
